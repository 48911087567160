import * as Apollo from "@apollo/client"
import { gql } from "@apollo/client"
const defaultOptions = {} as const

export type PossibleTypesResultData = {
  possibleTypes: {
    GenericMorph: [
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiTab",
      "ComponentUiText",
      "Home",
      "Layout",
      "ReactIconsIconlibrary",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    GenericMorph: [
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiTab",
      "ComponentUiText",
      "Home",
      "Layout",
      "ReactIconsIconlibrary",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
    ],
  },
}
export default result

export type InvestorBuyAlerts = Pick<
  InvestorBuyAlert,
  | "ID"
  | "status"
  | "stoID"
  | "shares"
  | "shareTypeID"
  | "isHiddenForInvestor"
  | "date"
  | "isBuySharesSigned"
  | "purchasePriceOffered"
  | "fromCurrencyID"
  | "isSellRequest"
> & {
  shareType: Pick<
    ShareType,
    | "ID"
    | "title"
    | "stoID"
    | "totalShares"
    | "companyShares"
    | "nominalValue"
    | "custodianShares"
    | "isBlockchain"
    | "premiumValue"
    | "currencyID"
    | "minimumSharesToBuyByInvestor"
    | "sellToCompany"
    | "sellValue"
    | "isShareNosApplicable"
    | "isCertificateNosApplicable"
    | "availableShare"
    | "totalPrice"
    | "blockchaindecimals"
    | "blockchainProtocolID"
    | "reduceSharesForPurchase"
    | "walletCustodyType"
    | "maximumSharesToBuyByInvestor"
    | "isInvestorTradable"
  > & { currency: Pick<Currency, "ID" | "currency" | "abbreviation" | "symbol" | "isBlockchainBased"> }
  channel?: Maybe<{
    currency: Pick<Currency, "ID" | "abbreviation" | "symbol" | "isBlockchainBased" | "currency">
    currencyToConvert?: Maybe<Pick<Currency, "ID" | "abbreviation" | "symbol" | "isBlockchainBased" | "currency">>
  }>
}

export type InvestorShares = Pick<
  Share,
  | "sharesHistoryID"
  | "investorID"
  | "shares"
  | "isBlockchainAuthorized"
  | "isBlockchainFrozen"
  | "shareTypeID"
  | "stoID"
  | "ID"
> & {
  shareType: Pick<
    ShareType,
    | "ID"
    | "title"
    | "stoID"
    | "totalShares"
    | "companyShares"
    | "nominalValue"
    | "custodianShares"
    | "isBlockchain"
    | "premiumValue"
    | "currencyID"
    | "sellToCompany"
    | "sellValue"
    | "isShareNosApplicable"
    | "isCertificateNosApplicable"
    | "minimumSharesToBuyByInvestor"
    | "availableShare"
    | "totalPrice"
    | "blockchaindecimals"
    | "blockchainProtocolID"
    | "reduceSharesForPurchase"
    | "walletCustodyType"
    | "isInvestorTradable"
  > & { currency: Pick<Currency, "ID" | "currency" | "abbreviation" | "symbol" | "isBlockchainBased"> }
}

export type Kyc = {
  currency: "USD" | "EUR" | undefined
  amount: string | undefined
  identity: [] | FileUploaded[]
  address: [] | FileUploaded[]
  ["type-investor"]: "retail" | "angel" | undefined
}


export const AdminMeDocument = gql`
  query adminMe {
    adminMe {
      user {
        ID
        FirstName
        LastName
        Username
        email
        stoid
      }
    }
  }
`
export type AdminMeUser = {
  ID: Scalars["Int"]
  FirstName: Scalars["String"]
  LastName: Scalars["String"]
  Username: Scalars["String"]
  email: Scalars["String"]
  stoid: Scalars["Int"]
}

export type AdminMeQuery = {
  adminMe: {
    user: Pick<AdminMeUser, "ID" | "FirstName" | "LastName" | "Username" | "email">
  }
}

export function useAdminMeQuery(baseOptions?: Apollo.QueryHookOptions<AdminMeQuery>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdminMeQuery>(AdminMeDocument, options)
}
export function useAdminMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminMeQuery>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AdminMeQuery>(AdminMeDocument, options)
}
export type AdminMeQueryHookResult = ReturnType<typeof useAdminMeQuery>
export type AdminMeLazyQueryHookResult = ReturnType<typeof useAdminMeLazyQuery>
export type AdminMeQueryResult = Apollo.QueryResult<AdminMeQuery>

export const FindAdminsbyEmailDocument = gql`
  query findAdminsbyEmail($email: [String!]) {
    findAdmins(email: $email) {
      ID
      firstName
      lastName
      username
      stoID
    }
  }
`

export type FindAdminsbyEmail = {
  ID: Scalars["Int"]
  firstName: Scalars["String"]
  lastName: Scalars["String"]
  username: Scalars["String"]
  stoID: Scalars["Int"]
}

export type FindAdminsbyEmailQuery = {
  findAdmins: Array<Pick<FindAdminsbyEmail, "ID" | "firstName" | "lastName" | "username" | "stoID">>
}

export type FindAdminsbyEmailQueryVariables = Exact<{
  email: Scalars["String"]
}>

export function useFindAdminsbyEmailQuery(
  baseOptions?: Apollo.QueryHookOptions<FindAdminsbyEmailQuery, FindAdminsbyEmailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindAdminsbyEmailQuery, FindAdminsbyEmailQueryVariables>(FindAdminsbyEmailDocument, options)
}
export function useFindAdminsbyEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindAdminsbyEmailQuery, FindAdminsbyEmailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindAdminsbyEmailQuery, FindAdminsbyEmailQueryVariables>(
    FindAdminsbyEmailDocument,
    options
  )
}
export type FindAdminsbyEmailQueryHookResult = ReturnType<typeof useFindAdminsbyEmailQuery>
export type FindAdminsbyEmailLazyQueryHookResult = ReturnType<typeof useFindAdminsbyEmailLazyQuery>
export type FindAdminsbyEmailQueryResult = Apollo.QueryResult<FindAdminsbyEmailQuery, FindAdminsbyEmailQueryVariables>

export const FindStoByIdDocument = gql`
  query findStoByID($stoID: Int!) {
    findSto(ID: $stoID) {
      ID
      title
      details
      documents {
        ID
        title
        type
        url
        countries
        stoID
        shareTypes {
          ID
          assetTag
          companyShares
          availableShare
          currency {
            currency
            abbreviation
            ID
            symbol
          }
          stoID
          title
          totalPrice
          totalShares
        }
      }
      fullDetails
      images {
        url
      }
      logoUrl
      meta {        
        key       
        stoID
        value
      }
      picture
      presentation {
        url
      }
      projectAddress
      legalDetails
      title
    }
  }
`

export type FindStoByIdQueryVariables = Exact<{
  stoID: Scalars['Int'];
}>;

export type FindStoByIdQuery = {
  findSto?: Maybe<
    Pick<
      Partial<Sto>,
      | 'ID'
      | 'title'
      | 'details'
      | 'picture'
      | 'fullDetails'      
      | 'logoUrl'      
      | 'projectAddress'
      | 'legalDetails'      
    > & {
      images: Array<
        Pick<PropertyFile, 'url'>
      >;
      documents?: Maybe<
        Array<Pick<PropertyFile, 'ID' | 'stoID' | 'title' | 'url' | 'type' | 'countries'>>
      >;
      presentation?: Maybe<Pick<PropertyFile, 'url'>>;      
      meta: Array<
        Pick<
          StoMetaValue,
          'stoID' | 'key' | 'value' 
        >
      >;      
    }
  >;
};

export function useFindStoByIdQuery(
  baseOptions: Apollo.QueryHookOptions<FindStoByIdQuery, FindStoByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindStoByIdQuery, FindStoByIdQueryVariables>(FindStoByIdDocument, options);
}
export function useFindStoByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindStoByIdQuery, FindStoByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindStoByIdQuery, FindStoByIdQueryVariables>(
    FindStoByIdDocument,
    options,
  );
}
export type FindStoByIdQueryHookResult = ReturnType<typeof useFindStoByIdQuery>;
export type FindStoByIdLazyQueryHookResult = ReturnType<typeof useFindStoByIdLazyQuery>;
export type FindStoByIdQueryResult = Apollo.QueryResult<FindStoByIdQuery, FindStoByIdQueryVariables>;

export const FindVisibleOfferingsDocument = gql`
  query findVisibleOfferings {
    findAllSto {
      ID
      baseCurrencyID
      companyType
      details
      disclaimer
      documents {
        ID
        title
        type
        url
        clearanceLevels {
          ID
          level
          order
        }
        countries
        stoID
        shareTypes {
          ID
          assetTag
          blockchainProtocol {
            ID
            blockchain {
              ID
              chainNumber
              chainNumberTestnet
              explorerURL
              explorerURLTestnet
              isEVMBased
              key
              keyTestnet
              link
              linkTestnet
              nodeProviderURL
              nodeProviderURLTestnet
              password
              passwordTestnet
              swapContractAddress
              swapContractAddressTestnet
              title
              username
              usernameTestnet
            }
            blockchainID
            protocolName
          }
          blockchainProtocolID
          blockchaindecimals
          channelIDForAutoPayments
          companyShares
          availableShare
          currency {
            currency
            abbreviation
            Address
            ID
            blockchainID
            cryptoReceivingAddress
            isBlockchainBased
            isNative
            symbol
          }
          currencyID
          custodianShares
          ethereumBlockchainPublicAddress
          ethereumContractAddress
          investorCanPurchaseDirectly
          isBlockchain
          isCertificateNosApplicable
          isEnabled
          isInvestorTradable
          isShareNosApplicable
          keyStoreFileAutoPayments
          keyStoreFileAutoPaymentsPassword
          maximumSharesToBuyByInvestor
          minimumSharesToBuyByInvestor
          nominalValue
          polyMeshDistributionVenueID
          polymeshAccountID
          premiumValue
          reduceSharesForPurchase
          sellToCompany
          sellValue
          shareOwnershipLimit
          stoID
          title
          totalPrice
          totalShares
          tradingDetails {
            closeTradeDate
            maxTradeValue
            minTradeValue
            openTradeDate
            shareTypeID
          }
          walletCustodyType
        }
      }
      emailFooter
      ethereumContractAddress
      ethereumWhitelistAddress
      exchangeOpenDate
      externalSystemID
      helloSignClientID
      fullDetails
      images {
        url
      }
      investmentReturn
      isActive
      isBimountEnabled
      isNDAFlowEnabled
      isProjectPDFPresentationEnabled
      isBuyButtonEnabled
      logo
      logoUrl
      meta {
        categoryKey
        categoryKeyTranslation
        display
        displayClosedProp
        isManagedStoTableColumn
        key
        order
        stoID
        translation
        type
        value
      }
      parsedSettings {
        favicon
        investorCategories {
          label
          value
        }
        isInternalExchangeEnabled
        tabTitle
      }
      picture
      popularity
      presentation {
        title
        url
      }
      projectAddress
      projectCost
      propertyPicture
      registrationSuccessText
      registrationText
      sellSideEntity {
        ID
        areInvestorsPublic
        details
        isPublic
        isPubliclyAvailableToChat
        logoUrl
        title
      }
      stoDetailsInvestNowLink
      stoInvestorTypes
      stoInvestorTypesNotOnShareRegister
      stoType
      stolink
      stolinkfull
      legalDetails
      title
      verifyInvestorComHostToken
    }
  }
`
export type VisibleOfferings = {
  ID: Scalars["Int"]
  baseCurrencyID: Scalars["Int"]
  companyType: Scalars["Int"]
  details: Scalars["String"]
  disclaimer: Scalars["String"]
  documents: Array<{
    ID: Scalars["Int"]
    title: Scalars["String"]
    type: FileType
    url: Scalars["String"]
    countries: Array<Scalars["String"]>
    stoID: Scalars["Int"]
    shareTypes: {
      ID: Scalars["Int"]
      assetTag: Scalars["String"]
      blockchainProtocol: {
        ID: Scalars["Int"]
        blockchain: {
          ID: Scalars["Int"]
          chainNumber: Scalars["String"]
          chainNumberTestnet: Scalars["String"]
          explorerURL: Scalars["String"]
          explorerURLTestnet: Scalars["String"]
          isEVMBased: Scalars["Boolean"]
          key: Scalars["String"]
          keyTestnet: Scalars["String"]
          link: Scalars["String"]
          linkTestnet: Scalars["String"]
          nodeProviderURL: Scalars["String"]
          nodeProviderURLTestnet: Scalars["String"]
          password: Scalars["String"]
          passwordTestnet: Scalars["String"]
          swapContractAddress: Scalars["String"]
          swapContractAddressTestnet: Scalars["String"]
          title: Scalars["String"]
          username: Scalars["String"]
          usernameTestnet: Scalars["String"]
        }
        blockchainID: Scalars["Int"]
        protocolName: Scalars["String"]
      }
      blockchainProtocolID: Scalars["Int"]
      blockchaindecimals: Scalars["Int"]
      channelIDForAutoPayments: Scalars["Int"]
      companyShares: Scalars["Float"]
      availableShare: Scalars["Float"]
      currency: {
        currency: Scalars["String"]
        abbreviation: Scalars["String"]
        Address: Scalars["String"]
        ID: Scalars["Int"]
        blockchainID: Scalars["Int"]
        cryptoReceivingAddress: Scalars["String"]
        isBlockchainBased: Scalars["Boolean"]
        isNative: Scalars["Float"]
        symbol: Scalars["String"]
      }
      currencyID: Scalars["Int"]
      custodianShares: Scalars["Float"]
      ethereumBlockchainPublicAddress: Scalars["String"]
      ethereumContractAddress: Scalars["String"]
      investorCanPurchaseDirectly: Scalars["Boolean"]
      isBlockchain: Scalars["Boolean"]
      isBimountEnabled: Scalars["Int"]
      isCertificateNosApplicable: Scalars["Boolean"]
      isEnabled: Scalars["Boolean"]
      isInvestorTradable: Scalars["Boolean"]
      isShareNosApplicable: Scalars["Boolean"]
      keyStoreFileAutoPayments: Scalars["String"]
      keyStoreFileAutoPaymentsPassword: Scalars["String"]
      maximumSharesToBuyByInvestor: Scalars["Int"]
      minimumSharesToBuyByInvestor: Scalars["Float"]
      nominalValue: Scalars["Float"]
      polyMeshDistributionVenueID: Scalars["Int"]
      polymeshAccountID: Scalars["Int"]
      premiumValue: Scalars["Float"]
      reduceSharesForPurchase: Scalars["Float"]
      sellToCompany: Scalars["Boolean"]
      sellValue: Scalars["Float"]
      shareOwnershipLimit: Scalars["Float"]
      stoID: Scalars["Int"]
      title: Scalars["String"]
      totalPrice: Scalars["Float"]
      totalShares: Scalars["Float"]
      tradingDetails: {
        closeTradeDate: Scalars["DateTime"]
        maxTradeValue: Scalars["Float"]
        minTradeValue: Scalars["Float"]
        openTradeDate: Scalars["DateTime"]
        shareTypeID: Scalars["Int"]
      }
      walletCustodyType: Scalars["Int"]
    }
  }> | null
  emailFooter: Scalars["String"]
  ethereumContractAddress: Scalars["String"]
  ethereumWhitelistAddress: Scalars["String"]
  exchangeOpenDate: Scalars["String"]
  externalSystemID: Scalars["Int"]
  helloSignClientID: Scalars["String"] | null
  fullDetails: Scalars["String"]
  images: Array<{
    url: Scalars["String"]
  }>
  investmentReturn: Scalars["Float"]
  isActive: Scalars["Int"]
  isBimountEnabled: Scalars["Int"] | null
  isNDAFlowEnabled: Scalars["Boolean"]
  isProjectPDFPresentationEnabled: Scalars["Boolean"]
  isBuyButtonEnabled: Scalars["Boolean"]
  logo: Scalars["String"]
  logoUrl: Scalars["String"]
  meta: Array<{
    categoryKey: Scalars["String"]
    categoryKeyTranslation: Scalars["String"]
    display: Scalars["Boolean"]
    displayClosedProp: Scalars["Boolean"]
    isManagedStoTableColumn: Scalars["Boolean"]
    key: Scalars["String"]
    order: Scalars["Float"]
    stoID: Scalars["Int"]
    translation: Scalars["String"]
    value: Scalars["String"]
  }>
  parsedSettings: {
    favicon: Scalars["String"]
    investorCategories: Array<{
      label: Scalars["String"]
      value: Scalars["Int"]
    }>
    isInternalExchangeEnabled: Scalars["Int"]
    tabTitle: Scalars["String"]
  }
  picture: Scalars["String"]
  popularity: Scalars["Int"]
  presentation: {
    title: Scalars["String"]
    url: Scalars["String"]
  } | null
  projectAddress: Scalars["String"]
  projectCost: Scalars["Int"] | null
  propertyPicture: Scalars["String"] | null
  registrationSuccessText: Scalars["String"]
  registrationText: Scalars["String"]
  sellSideEntity: {
    ID: Scalars["Int"]
    areInvestorsPublic: Scalars["Boolean"]
    details: Scalars["String"]
    isPublic: Scalars["Boolean"]
    isPubliclyAvailableToChat: Scalars["Boolean"]
    logoUrl: Scalars["String"]
    title: Scalars["String"]
  } | null
  stoDetailsInvestNowLink: Scalars["String"]
  stoInvestorTypes: Array<Scalars["Int"]>
  stoInvestorTypesNotOnShareRegister: Array<Scalars["Int"]>
  stoType: Scalars["Int"]
  stolink: Scalars["String"]
  stolinkfull: Scalars["String"]
  legalDetails: Scalars["String"]
  title: Scalars["String"]
  verifyInvestorComHostToken: Scalars["String"]
}

export type FindVisibleOfferingsQuery = {
  findAllSto: Array<
    Pick<
      VisibleOfferings,
      | "ID"
      | "baseCurrencyID"
      | "companyType"
      | "details"
      | "disclaimer"
      | "documents"
      | "emailFooter"
      | "ethereumContractAddress"
      | "ethereumWhitelistAddress"
      | "exchangeOpenDate"
      | "externalSystemID"
      | "helloSignClientID"
      | "fullDetails"
      | "images"
      | "investmentReturn"
      | "isActive"
      | "isBimountEnabled"
      | "isNDAFlowEnabled"
      | "isProjectPDFPresentationEnabled"
      | "isBuyButtonEnabled"
      | "logo"
      | "logoUrl"
      | "meta"
      | "parsedSettings"
      | "picture"
      | "popularity"
      | "presentation"
      | "projectAddress"
      | "projectCost"
      | "propertyPicture"
      | "registrationSuccessText"
      | "registrationText"
      | "sellSideEntity"
      | "stoDetailsInvestNowLink"
      | "stoInvestorTypes"
      | "stoInvestorTypesNotOnShareRegister"
      | "stoType"
      | "stolink"
      | "stolinkfull"
      | "legalDetails"
      | "title"
      | "verifyInvestorComHostToken"
    >
  >
}

export function useFindVisibleOfferingsQuery(baseOptions?: Apollo.QueryHookOptions<FindVisibleOfferingsQuery>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindVisibleOfferingsQuery>(FindVisibleOfferingsDocument, options)
}
export function useFindVisibleOfferingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindVisibleOfferingsQuery>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindVisibleOfferingsQuery>(FindVisibleOfferingsDocument, options)
}
export type FindVisibleOfferingsQueryHookResult = ReturnType<typeof useFindVisibleOfferingsQuery>
export type FindVisibleOfferingsLazyQueryHookResult = ReturnType<typeof useFindVisibleOfferingsLazyQuery>
export type FindVisibleOfferingsQueryResult = Apollo.QueryResult<FindVisibleOfferingsQuery>


export const OwnerSignInDocument = gql`
  mutation ownerSignIn($sto: Int, $password: String!, $username: String!) {
    adminSignIn(STO: $sto, password: $password, username: $username)
  }
`

export type OwnerSignInMutationVariables = Exact<{
  username: Scalars["String"]
  password: Scalars["String"]
  sto: Scalars["Int"]
}>

export type OwnerSignInMutation = Pick<Mutation, "adminSignIn">

export function useOwnerSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<OwnerSignInMutation, OwnerSignInMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<OwnerSignInMutation, OwnerSignInMutationVariables>(OwnerSignInDocument, options)
}
export type OwnerSignInMutationHookResult = ReturnType<typeof useOwnerSignInMutation>
export type OwnerSignInMutationResult = Apollo.MutationResult<OwnerSignInMutation>
export type OwnerSignInMutationOptions = Apollo.BaseMutationOptions<OwnerSignInMutation, OwnerSignInMutationVariables>

export type OwnerSignInMutationFn = Apollo.MutationFunction<OwnerSignInMutation, OwnerSignInMutationVariables>

export type SubmitedDocument = {  
  ID: Scalars["Int"],
  document: Document,
  signature?: Partial<CloudFiles>
  signatureFilePath: Scalars["String"],
  status: Scalars["Int"],
  stoID: Scalars["Int"]
};

/** Denotes the providers supported by the platform */
export enum AccreditationProviderEnum {
  Accredd = 'Accredd',
  VerifyInvestor = 'VerifyInvestor',
}

/** Denotes the step at which the platform should require the investor to pass Accreditation checks */
export enum AccreditationRequirementEnum {
  Ignore = 'Ignore',
  OnPurchase = 'OnPurchase',
  OnRegister = 'OnRegister',
  PrePayment = 'PrePayment',
}

/** Enum for Status of Accreditation */
export enum AccreditationStatus {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
}

export type ActiveProperty = {
  ID: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  details: Scalars['String'];
  isBuyButtonEnabled: Scalars['Boolean'];
  isInvestorBuyEnabled: Scalars['Boolean'];
  isInvestorInvited: Scalars['Boolean'];
  isNDAFlowEnabled: Scalars['Boolean'];
  isProjectPDFPresentationEnabled: Scalars['Boolean'];
  meta: Array<StoMetaValue>;
  picture: Scalars['String'];
  popularity: Scalars['Int'];
  projectCost: Scalars['Float'];
  sellSideEntity?: Maybe<SellSideEntity>;
  stoDetailsInvestNowLink?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type Admin = {
  STO: Sto;
  user: AdminUser;
};

/** Change password data by admin */
export type AdminChangePasswordInput = {
  data: ChangePasswordInput;
  investorID: Scalars['Int'];
};

export type AdminChatQueryInput = {
  isDeleted?: Maybe<Scalars['Boolean']>;
  receiverTypes?: Maybe<Array<ReceiverType>>;
  stoID?: Maybe<Scalars['Int']>;
};

export type AdminGetInvestorCustomKycFieldsQuery = {
  investorID: Scalars['Int'];
};

export type AdminParameters = {
  KYCPersonalInfoScreen: Scalars['Int'];
  adminDashboardTheme: Scalars['String'];
  adminSelectedTheme: AdminTheme;
  docusignViewSignedDocumentsUrl: Scalars['String'];
  isAdminActivityLogsUIEnabled: Scalars['Boolean'];
  isBlockchainTestnetEnabled: Scalars['Boolean'];
  isDriversLicenseEnabled: Scalars['Boolean'];
  isInvestorNotesEnabled: Scalars['Boolean'];
  isOptionKinInfoCollectionEnabled: Scalars['Boolean'];
  sharePurchaseDocumentsMode: SharePurchaseModeEnum;
};

/** Available admin themes */
export enum AdminTheme {
  Bootstrap = 'BOOTSTRAP',
  Custom = 'CUSTOM',
  Digishares = 'DIGISHARES',
}

export type AdminUser = {
  FirstName?: Maybe<Scalars['String']>;
  ID: Scalars['Int'];
  LastName?: Maybe<Scalars['String']>;
  Username: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Int']>;
  isPlatformAdminLogin?: Maybe<Scalars['Int']>;
  stoid?: Maybe<Scalars['Int']>;
  twofactorenable?: Maybe<Scalars['Int']>;
};

export type AllMeeting = {
  current: Array<Meeting>;
  future: Array<Meeting>;
  past: Array<Meeting>;
};

export type AppParameters = {
  AccreddRedirectLink: Scalars['String'];
  AccreditationProvider: AccreditationProviderEnum;
  BlockchainBalanceInFractions: Scalars['Int'];
  IsCheckMarkSignatureActive: Scalars['Boolean'];
  IsDarwSignatureActive: Scalars['Boolean'];
  IsDocuSignActive: Scalars['Boolean'];
  IsHelloSignActive: Scalars['Boolean'];
  IsInternalWalletDisabled: Scalars['Boolean'];
  IsInternalWalletGlobal: Scalars['Boolean'];
  IsInternalWalletStoSpecific: Scalars['Boolean'];
  IsMarketSpace: Scalars['Boolean'];
  IsMoonpayEnabled: Scalars['Boolean'];
  KycProvider: KycProviders;
  KycRequirementStep: KycRequirementStep;
  SSORedirectFrontEnd: Scalars['String'];
  accreditationRequirementStep: AccreditationRequirementEnum;
  accreditationRequiringCountries: Array<Scalars['String']>;
  activePaymentProcessor: PaymentProcessor;
  adminOverviewPageIconUrl: Scalars['String'];
  allowInvestorsToRegister: Scalars['Boolean'];
  areSTOHostnamesEnabled: Scalars['Boolean'];
  areSellSideEntityChatLinksEnabled: Scalars['Boolean'];
  atomicSwapContractAddress: Scalars['String'];
  atomicSwapContractAddressBinance: Scalars['String'];
  atomicSwapContractAddressPolygon: Scalars['String'];
  backgroundImageUrl: Scalars['String'];
  binanceWeb3Address: Scalars['String'];
  clientKYC: Scalars['String'];
  currencySymbolDisplayMode: CurrencySymbolDisplayModeEnum;
  defaultApiLanguage: Scalars['String'];
  defaultInvestorLanguage: Scalars['String'];
  defaultLoginRoute: Scalars['String'];
  defaultPropertyOrder: DefaultPropertySortingConfig;
  doAutomaticBlockchainTransactionChecks: Scalars['Boolean'];
  doAutomaticPurchase: Scalars['Boolean'];
  doAutomaticSellBack: Scalars['Boolean'];
  drawSignaturePrefillFonts: Array<Scalars['String']>;
  frontendRedirectConfig: FrontendRedirectConfig;
  hideContractsTilPostPurchase: Scalars['Boolean'];
  internalKycDocumentIssuingStateOptions: Array<Scalars['String']>;
  internalKycStateOptions: Array<Scalars['String']>;
  investorDashboardTheme: Scalars['String'];
  investorLogoProperties: InvestorLogoProperties;
  investorNavbarTitleProperties: CommonInvestorNavbarProperties;
  investorPageScriptProps: InvestorPageScriptProps;
  investorStoSwitchProperties: CommonInvestorNavbarProperties;
  is2FAEnabledByDefault: Scalars['Boolean'];
  is2FAForcedForAll: Scalars['Boolean'];
  isAccreditationEnabled: Scalars['Boolean'];
  isAccreditationModuleEnabled: Scalars['Boolean'];
  isAccreditationStatusEmailEnabled: Scalars['Boolean'];
  isActiveOfferingsFilterCardEnabled: Scalars['Boolean'];
  isActiveOfferingsModuleEnabled: Scalars['Boolean'];
  isActivePropertyBigTombstoneEnabled: Scalars['Boolean'];
  isAdminDropDownEnabled: Scalars['Boolean'];
  isAdminInstructionalEmailEnabled: Scalars['Boolean'];
  isAdminInvestorNewDesignEnabled: Scalars['Boolean'];
  isAllDocumentsSignedPopUpEnabled: Scalars['Boolean'];
  isBankDetailsSwitchEnabled: Scalars['Boolean'];
  isBlockchainAddressSwitchEnabled: Scalars['Boolean'];
  isBlockchainPurchaseRestrictionEnabled: BlockchainPurchaseRestrictionParam;
  isBlockchainTestnetEnabled: Scalars['Boolean'];
  isBuyStoEnabledEmailEnabled: Scalars['Boolean'];
  isBuyStoWhitelistPerInvestorEnabled: Scalars['Boolean'];
  isChatModuleEnabled: Scalars['Boolean'];
  isClosedOfferingsCardEnabled: Scalars['Boolean'];
  isClosedOfferingsFilterCardEnabled: Scalars['Boolean'];
  isCloudStorageEnabled: Scalars['Boolean'];
  isCloudUploadingEnabled: Scalars['Boolean'];
  isContactTheSponsorFontSwitchEnabled: Scalars['Boolean'];
  isContractsModuleEnabled: Scalars['Boolean'];
  isCorporateActionsModuleEnabled: Scalars['Boolean'];
  isCountryFlagsInSelectorEnabled: Scalars['Boolean'];
  isDiscountsEnabled: Scalars['Boolean'];
  isDisplayEntitiesStoAdminChatHistoryEnabled: Scalars['Boolean'];
  isDisplayEntitiesStoAdminChatListEnabled: Scalars['Boolean'];
  isDisplayEntitiesStoAdminInvestorListEnabled: Scalars['Boolean'];
  isDividendNewDesignEnabled: Scalars['Boolean'];
  isDocumentSubscriptionCompletedEmailForAdminEnabled: Scalars['Boolean'];
  isDocumentSubscriptionCompletedEmailForInvestorEnabled: Scalars['Boolean'];
  isDocumentSubscriptionReminderEmailEnabled: Scalars['Boolean'];
  isDocumentsESignedEmailEnabled: Scalars['Boolean'];
  isDriversLicenseEnabled: Scalars['Boolean'];
  isEmailFromInvestorEnabled: Scalars['Boolean'];
  isEnableDisableInvestorAccountEmailEnabled: Scalars['Boolean'];
  isEntityAccreditedFieldEnabled: Scalars['Boolean'];
  isEntityAsInvestorEnabled: Scalars['Boolean'];
  isEntityClosingFieldEnabled: Scalars['Boolean'];
  isEntityInvitedFieldEnabled: Scalars['Boolean'];
  isEntityLevelReturnRatesEnabled: Scalars['Boolean'];
  isEntityManagementMeetingFieldEnabled: Scalars['Boolean'];
  isEntityOptional: Scalars['Boolean'];
  isEntityReceivedSubscriptionAgreementFieldEnabled: Scalars['Boolean'];
  isEntityReceivedTermSheetFieldEnabled: Scalars['Boolean'];
  isExternalDividendPayoutEnabled: Scalars['Boolean'];
  isExternalModuleEnabled: ExternalModuleParam;
  isFastTrackPaymentEnabled: Scalars['Boolean'];
  isFeesEnabled: Scalars['Boolean'];
  isFilterPreferencesEnabled: Scalars['Boolean'];
  isFinalizedInvestmentEmailEnabled: Scalars['Boolean'];
  isFirstTimeLoginEnabled: Scalars['Boolean'];
  isFooterTextCentered: Scalars['Boolean'];
  isForumModuleEnabled: Scalars['Boolean'];
  isForumRegistrationEnabled: Scalars['Boolean'];
  isIcoProjectEnabled: Scalars['Boolean'];
  isIndividualLevelReturnRatesEnabled: Scalars['Boolean'];
  isInstructionalEmailEnabled: Scalars['Boolean'];
  isInterestedInStoEmailEnabled: Scalars['Boolean'];
  isInternalTokenizedPurchaseEnabled: Scalars['Boolean'];
  isInvestingEntityEnabled: Scalars['Boolean'];
  isInvestingEntityRepresentativeListed: Scalars['Boolean'];
  isInvestmentDetailsCardEnabled: Scalars['Boolean'];
  isInvestmentDetailsCardUnfolded: Scalars['Boolean'];
  isInvestmentReturnCalculationEnabled: Scalars['Boolean'];
  isInvestorDashboardConnectWalletEnabled: Scalars['Boolean'];
  isInvestorDashboardSwitchEnabled: Scalars['Boolean'];
  isInvestorDisassociationSwitchEnabled: InvestorAssociationStoSwitchEnum;
  isInvestorInvitedBySellerEntityEmailEnabled: Scalars['Boolean'];
  isInvestorLanguageSelectorEnabled: Scalars['Boolean'];
  isInvestorNavigationDisabled: Scalars['Boolean'];
  isInvestorNotesEnabled: Scalars['Boolean'];
  isInvestorOfferingsDashboard: Scalars['Boolean'];
  isInvestorOwnershipModuleEnabled: Scalars['Boolean'];
  isInvestorPolymeshModuleEnabled: Scalars['Boolean'];
  isInvestorPortfolioBalanceSwitchEnabled: Scalars['Boolean'];
  isInvestorPortfolioDividendsSwitchEnabled: Scalars['Boolean'];
  isInvoiceOverviewEnabled: Scalars['Boolean'];
  isInvoicingEnabled: Scalars['Boolean'];
  isLimitedInvestingEntityInfo: Scalars['Boolean'];
  isLimitedInvestingEntityPreferences: Scalars['Boolean'];
  isMarketSpaceRegistrationFormEnabled: Scalars['Boolean'];
  isMercuryDepositInstructionEmailEnabled: Scalars['Boolean'];
  isMergingPaymentsSharesRequestsEnabled: Scalars['Boolean'];
  isMetakeysFilterTagsDisplayEnabled: Scalars['Boolean'];
  isMyPortfolioModuleEnabled: Scalars['Boolean'];
  isNDAFlowEnabled: Scalars['Boolean'];
  isNdaApprovedEmailEnabled: Scalars['Boolean'];
  isNewAccreditationEmailForAdminEnabled: Scalars['Boolean'];
  isNewEntityMemberEmailEnabled: Scalars['Boolean'];
  isNewUserAdminEmailEnabled: Scalars['Boolean'];
  isNewsModuleEnabled: Scalars['Boolean'];
  isNotificationEmailForAdminEnabled: Scalars['Boolean'];
  isNotificationEmailForInvestorEnabled: Scalars['Boolean'];
  isNotifyAllAdminsOnNotificationEnabled: Scalars['Boolean'];
  isNotifyNewSubmissionEmailEnabled: Scalars['Boolean'];
  isOfferSubmittedEmailEnabled: Scalars['Boolean'];
  isOfferedContractsEnabled: Scalars['Boolean'];
  isOfferingProgressTrackingEnabled: Scalars['Boolean'];
  isOptionKinInfoCollectionEnabled: Scalars['Boolean'];
  isPasswordResetEmailEnabled: Scalars['Boolean'];
  isPaymentProcessorSandbox: Scalars['Boolean'];
  isPlatformOperatorHidden: Scalars['Boolean'];
  isPortfolioOverviewEnabled: Scalars['Boolean'];
  isProfileMatchesFilterCardEnabled: Scalars['Boolean'];
  isProjectCardImageDisplayEnabled: Scalars['Boolean'];
  isProjectCardTagsDisplayEnabled: Scalars['Boolean'];
  isProjectPDFPresentationEnabled: Scalars['Boolean'];
  isPropertySortingEnabled: Scalars['Boolean'];
  isPublicLanguageSelectorEnabled: Scalars['Boolean'];
  isRegDRegSSeparationEnabled: Scalars['Boolean'];
  isRegisteredInvestorCreationEnabled: Scalars['Boolean'];
  isRegistrationFormStepsInstructionsEnabled: Scalars['Boolean'];
  isSSOModeEnabled: Scalars['Boolean'];
  isSaveAndNextAtPersonalInfoEnabled: Scalars['Boolean'];
  isSellBackEnabled: Scalars['Boolean'];
  isSellSideEntityEnabled: Scalars['Boolean'];
  isSellSideEntityManagedStosTombstoneViewEnabled: Scalars['Boolean'];
  isSellSideSecrecyEnabled: Scalars['Boolean'];
  isSend2FAEmailEnabled: Scalars['Boolean'];
  isSendAccreditationStatusEmailToInvestorEnabled: Scalars['Boolean'];
  isSendMercuryUnderpayInvestorEmailEnabled: Scalars['Boolean'];
  isSendMercuryWrongAmountAdminEmailEnabled: Scalars['Boolean'];
  isSendNetkiSignUpEmailEnabled: Scalars['Boolean'];
  isSendTellAFriendEmailEnabled: Scalars['Boolean'];
  isSendToAllNotificationEmailEnabled: Scalars['Boolean'];
  isSettingsModuleEnabled: Scalars['Boolean'];
  isShareCertificateButtonEnabled: Scalars['Boolean'];
  isShareCertificatePageEnabled: Scalars['Boolean'];
  isShareOwnershipLimitEnabled: Scalars['Boolean'];
  isShareTransferEmailEnabled: Scalars['Boolean'];
  isSharesSoldBackNonBlockchainEmailEnabled: Scalars['Boolean'];
  isSharesTransferredAdminEmailEnabled: Scalars['Boolean'];
  isSharesTransferredNonBlockchainEmailEnabled: Scalars['Boolean'];
  isSto0SmtpFallbackEnabled: Scalars['Boolean'];
  isStoSpecificPaymentChannelsEnabled: Scalars['Boolean'];
  isSupportModuleEnabled: Scalars['Boolean'];
  isTellAFriendModuleEnabled: Scalars['Boolean'];
  isTradingBuyOrdersEnabled: Scalars['Boolean'];
  isTradingModuleEnabled: Scalars['Boolean'];
  isUserProfileUpdatedEmailEnabled: Scalars['Boolean'];
  isVerificationEmailEnabled: Scalars['Boolean'];
  isVerificationUploadedEmailEnabled: Scalars['Boolean'];
  isWalletConnectEnabled: Scalars['Boolean'];
  isWalletManagementModuleEnabled: Scalars['Boolean'];
  leftSideMenuFont: Scalars['String'];
  maxFileUploadLimit: Scalars['Int'];
  phonePrefillCountryCode: Scalars['String'];
  platformConfiguration: Scalars['Int'];
  platformWalletMode: InternalWalletMode;
  polygonWeb3Address: Scalars['String'];
  poweredByLabel: Scalars['String'];
  rightLayoutDesignOfferingsDetailPage: Scalars['Boolean'];
  scheduledEmailNotificationTimer: Scalars['Int'];
  shareCertificateMode: Scalars['Int'];
  sharePurchaseDocumentsMode: SharePurchaseModeEnum;
  showPropertyDetailReturnButton: Scalars['Boolean'];
  skipDocumentSignScreen: Scalars['Boolean'];
  termsAndConditionsConfig: TermsAndConditionsConfig;
  toggleThemeEditor: Scalars['Boolean'];
  txScanner: Array<ProtocolTxScanner>;
  verifyInvestorComKeylessIntegration: VerifyInvestorKeyLessParam;
  walletConnectProjectKey: Scalars['String'];
  web3Address: Scalars['String'];
  whitelistedCorsUrls: Array<Scalars['String']>;
  wizardCompleteHtml: Scalars['String'];
};

/** Statuses of atomic swap operations */
export enum AtomicSwapStatus {
  Accepted = 'ACCEPTED',
  AwaitingBuyerPayment = 'AWAITING_BUYER_PAYMENT',
  BuyerCommitted = 'BUYER_COMMITTED',
  BuyerCompleted = 'BUYER_COMPLETED',
  NotInitialized = 'NOT_INITIALIZED',
  Processed = 'PROCESSED',
  SellerCommitted = 'SELLER_COMMITTED',
  SellerSent = 'SELLER_SENT',
  Unsuccessful = 'UNSUCCESSFUL',
}

/** All Kinds of Broker Types */
export enum BrokerType {
  Broker = 'Broker',
  Investor = 'Investor',
}

export type BlockchainProtocol = {
  ID: Scalars['Int'];
  blockchain: Blockchains;
  blockchainID: Scalars['Int'];
  protocolName: Scalars['String'];
};

/** Parameter used to enable blockchain purchasing restrictions based on the token address provided, if enabled and token address provided before a send purchase request happens it will check if the investor wallet used contains the desired token */
export type BlockchainPurchaseRestrictionParam = {
  /** Determines if the restriction is turned on */
  isEnabled: Scalars['Boolean'];
  /** Address of the token which should be checked */
  tokenAddress: Scalars['String'];
};

/** Investor blockchain share transaction create data */
export type BlockchainSharesTransferTransactionsInput = {
  adminID: Scalars['Float'];
  amountToSend: Scalars['Float'];
  buyAlertID: Scalars['Int'];
  investmentAmount: Scalars['Float'];
  investmentDetails?: Maybe<Scalars['String']>;
  investorID?: Maybe<Scalars['Float']>;
  recordDate: Scalars['DateTime'];
  reduceInvestorBalance: Scalars['Float'];
  shareTypeID: Scalars['Float'];
  status: Scalars['Float'];
  stoID?: Maybe<Scalars['Float']>;
  toAddress: Scalars['String'];
  transactionID: Scalars['String'];
};

/** Status of a BuyAlert */
export enum BuyAlertStatus {
  Accepted = 'Accepted',
  AccreditationPending = 'AccreditationPending',
  AccreditationRequired = 'AccreditationRequired',
  BlockchainProcessing = 'BlockchainProcessing',
  Declined = 'Declined',
  KycRequired = 'KycRequired',
  PaymentAwaiting = 'PaymentAwaiting',
  PaymentFailure = 'PaymentFailure',
  PaymentOngoing = 'PaymentOngoing',
  Pending = 'Pending',
  PendingDocuments = 'PendingDocuments',
  Unused = 'Unused',
}

/** Filter used to fetch only the ActiveProperties that an Investor can buy/not buy shares from */
export type BuyEnabledFilterInput = {
  /** If not provided, the investorID will be taken from the request's context */
  investorID?: Maybe<Scalars['Int']>;
  /** If enabled, the API will fetch only the ActiveProperties that the investor can buy shares from */
  isBuyStoEnabled: Scalars['Boolean'];
};

/** Types of Overview Cards the admins can see */
export enum CardTypes {
  AccessesToDealRoom = 'AccessesToDealRoom',
  ClosingEntity = 'ClosingEntity',
  EntityAssociations = 'EntityAssociations',
  EntityNdAsSigned = 'EntityNDAsSigned',
  Invited = 'Invited',
  ManagementMeetings = 'ManagementMeetings',
  ReceivedItemSheet = 'ReceivedItemSheet',
  ReceivedSubscriptionAgreement = 'ReceivedSubscriptionAgreement',
  TotalEntities = 'TotalEntities',
}

/** Between Who Chatting Happens */
export enum ChatBetween {
  InvestorAdmin = 'InvestorAdmin',
  InvestorInvestor = 'InvestorInvestor',
  InvestorPlatform = 'InvestorPlatform',
}

/** In Which Context Chatting Happens */
export enum ChatContext {
  ExchangeOrder = 'ExchangeOrder',
}

/** All Kinds of Commission Types */
export enum CommissionType {
  Flat = 'Flat',
  Percentage = 'Percentage',
}

/** Types of corporate actions */
export enum CorporateActionType {
  InternalPoll = 'INTERNAL_POLL',
  Meeting = 'MEETING',
  PublicPoll = 'PUBLIC_POLL',
}

/** All modes of displaying the currency in the UI */
export enum CurrencySymbolDisplayModeEnum {
  LocalBased = 'LOCAL_BASED',
  NumberFirst = 'NUMBER_FIRST',
  SymbolFirst = 'SYMBOL_FIRST',
}

export type ChangeAddressRequest = {
  activatedDate?: Maybe<Scalars['DateTime']>;
  investorID: Scalars['Int'];
  isActivated: Scalars['Boolean'];
  publicKey?: Maybe<Scalars['String']>;
  stoID?: Maybe<Scalars['Int']>;
  tokens: Scalars['Int'];
};

/** Change password data */
export type ChangePasswordInput = {
  newPassword: Scalars['String'];
  oldPassword?: Maybe<Scalars['String']>;
  repeatPassword: Scalars['String'];
};

export type Chat = {
  ID: Scalars['Int'];
  admin?: Maybe<User>;
  adminID: Scalars['Int'];
  cloudFile?: Maybe<CloudFile>;
  context?: Maybe<ChatContext>;
  contextID?: Maybe<Scalars['Int']>;
  contextReceiverID?: Maybe<Scalars['Int']>;
  dateRead?: Maybe<Scalars['Timestamp']>;
  dateSent: Scalars['Timestamp'];
  investor?: Maybe<Investor>;
  investorID: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  isEdited: Scalars['Boolean'];
  isRead: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  receiver: ReceiverType;
  sender: SenderType;
  stoID: Scalars['Int'];
  type: MessageType;
};

/** Chat Data */
export type ChatInput = {
  adminID: Scalars['Int'];
  cloudFileID?: Maybe<Scalars['Int']>;
  context?: Maybe<ChatContext>;
  contextID?: Maybe<Scalars['Int']>;
  contextReceiverID?: Maybe<Scalars['Int']>;
  dateSent?: Maybe<Scalars['Timestamp']>;
  investorID: Scalars['Int'];
  location?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  receiver: ReceiverType;
  sender: SenderType;
  stoID: Scalars['Int'];
  type?: Maybe<MessageType>;
};

export type ChatOverview = {
  createdAt: Scalars['Timestamp'];
  investorFirstName: Scalars['String'];
  investorID: Scalars['Int'];
  investorLastName: Scalars['String'];
  isRead: Scalars['Boolean'];
  totalCount: Scalars['Int'];
  unreadCount: Scalars['Int'];
};

export type ClearanceLevel = {
  ID: Scalars['Int'];
  level: ClearanceLevel;
  order: Scalars['Int'];
};

export type ClosedInvestorAccount = {
  caseDetails?: Maybe<Scalars['String']>;
  caseFilePath?: Maybe<Scalars['String']>;
  caseNotes?: Maybe<Scalars['String']>;
  caseTitle?: Maybe<Scalars['String']>;
  dateClosed?: Maybe<Scalars['DateTime']>;
  investorID: Scalars['Int'];
  isPartial: Scalars['Boolean'];
  stoID?: Maybe<Scalars['Int']>;
  tokens: Scalars['Int'];
};

export type CloudFile = {
  name: Scalars['String'];
  url: Scalars['String'];
};

export type CloudFiles = {
  ID: Scalars['Int'];
  fileName: Scalars['String'];
  modified: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

/** Common Investor Navbar Interface */
export type CommonInvestorNavbarProperties = {
  /** font-style of text in pixels */
  fontSize: Scalars['String'];
  /** display item on navbar */
  isEnabled: Scalars['Boolean'];
};

export type CompanyNews = {
  ID: Scalars['Int'];
  coverPhoto: Scalars['String'];
  details: Scalars['String'];
  stoID: Scalars['Int'];
  title: Scalars['String'];
  updateDate: Scalars['String'];
};

/** Company news creation data */
export type CompanyNewsCreateInput = {
  coverPhoto?: Maybe<Scalars['String']>;
  details: Scalars['String'];
  memberIDs?: Maybe<Array<Scalars['Float']>>;
  recipient: EmailRecipient;
  stoID: Scalars['Float'];
  subject?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updateID?: Maybe<Scalars['Int']>;
};

export type ComponentCustomization = {
  ID: Scalars['Int'];
  body: Scalars['String'];
  component: Scalars['String'];
};

/** Component customization data */
export type ComponentCustomizationInput = {
  body?: Maybe<Scalars['String']>;
  component: Scalars['String'];
};

/** Necessary fields to create a new invitation record */
export type CreateInvitationInput = {
  investingMemberID: Scalars['Int'];
  /** To which offering the invitation is */
  targetStoID: Scalars['Int'];
};

/** Create a new Share Type with the following data */
export type CreateShareTypeInput = {
  assetName?: Maybe<Scalars['String']>;
  assetTag?: Maybe<Scalars['String']>;
  blockchainProtocolID?: Maybe<Scalars['Int']>;
  companyShares: Scalars['Float'];
  currencyID: Scalars['Int'];
  ethereumBlockchainPublicAddress?: Maybe<Scalars['String']>;
  ethereumContractAddress?: Maybe<Scalars['String']>;
  ethereumWhitelistAddress?: Maybe<Scalars['String']>;
  investorCanPurchaseDirectly?: Maybe<Scalars['Boolean']>;
  isBlockchain: Scalars['Boolean'];
  isCertificateNosApplicable?: Maybe<Scalars['Boolean']>;
  isInvestorTradable?: Maybe<Scalars['Boolean']>;
  isMeetingRightsApplicable?: Maybe<Scalars['Boolean']>;
  isShareNosApplicable?: Maybe<Scalars['Boolean']>;
  isVotingRightsApplicable: Scalars['Boolean'];
  maximumSharesToBuyByInvestor?: Maybe<Scalars['Float']>;
  minimumSharesToBuyByInvestor: Scalars['Float'];
  needAuthorization: Scalars['Boolean'];
  nominalValue: Scalars['Float'];
  premiumValue: Scalars['Float'];
  reduceSharesForPurchase?: Maybe<Scalars['Float']>;
  sellToCompany?: Maybe<Scalars['Boolean']>;
  sellValue?: Maybe<Scalars['Float']>;
  shareOwnershipLimit?: Maybe<Scalars['Float']>;
  stoID: Scalars['Int'];
  tanganyWalletID?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  totalShares: Scalars['Float'];
  tradingDetails?: Maybe<ShareTypeTradingDetailsInput>;
  votingPower: Scalars['Float'];
  walletCustodyType?: Maybe<Scalars['Int']>;
};

export type Currency = {
  Address?: Maybe<Scalars['String']>;
  ID: Scalars['Int'];
  abbreviation: Scalars['String'];
  blockchainID: Scalars['Int'];
  cryptoReceivingAddress?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  isBlockchainBased: Scalars['Boolean'];
  isNative: Scalars['Float'];
  symbol: Scalars['String'];
};

/** Describes where the discount is meant to be applied */
export enum DiscountAppliesTo {
  AllSharePurchases = 'ALL_SHARE_PURCHASES',
  AllShareSellbacks = 'ALL_SHARE_SELLBACKS',
}

/** Type of discount */
export enum DiscountType {
  Flat = 'FLAT',
  Percentage = 'PERCENTAGE',
}

/** Default Property Sorting Config. Will affect the way properties are sorted in the investor frontend. */
export type DefaultPropertySortingConfig = {
  /** Default sorting option */
  default: Scalars['String'];
  /** Holds all possible sorting options */
  options: Array<DefaultPropertySortingConfigOption>;
};

/** Default Property Sorting Config Options */
export type DefaultPropertySortingConfigOption = {
  /** holds the translation key or display text for the sorting option */
  label: Scalars['String'];
  /** holds the sorting value. Must be composed as an ActiveProperty-type, where type can be either "asc" or "desc" and ActiveProperty is a property of the object ActiveProperty */
  value: Scalars['String'];
};

export type DepositAlertDisplayParams = {
  ID: Scalars['Int'];
  canDisplayBankAccount: Scalars['Boolean'];
  canDisplayBankName: Scalars['Boolean'];
  canDisplayCardBody: Scalars['Boolean'];
  canDisplayDetails: Scalars['Boolean'];
  canDisplaySwift: Scalars['Boolean'];
  paymentChannel: PaymentChannel;
  paymentChannelID: Scalars['Int'];
};

export type DepositAlertDisplayParamsInput = {
  canDisplayBankAccount?: Maybe<Scalars['Boolean']>;
  canDisplayBankName?: Maybe<Scalars['Boolean']>;
  canDisplayCardBody?: Maybe<Scalars['Boolean']>;
  canDisplayDetails?: Maybe<Scalars['Boolean']>;
  canDisplaySwift?: Maybe<Scalars['Boolean']>;
  paymentChannelID: Scalars['Int'];
};

export type DetailProperty = {
  ID: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  details: Scalars['String'];
  documents?: Maybe<Array<PropertyFile>>;
  fullDetails: Scalars['String'];
  images?: Maybe<Array<PropertyFile>>;
  isBuyButtonEnabled: Scalars['Boolean'];
  isInvestorBuyEnabled: Scalars['Boolean'];
  isInvestorInvited: Scalars['Boolean'];
  isNDAFlowEnabled: Scalars['Boolean'];
  isProjectPDFPresentationEnabled: Scalars['Boolean'];
  meta: Array<StoMetaValue>;
  picture: Scalars['String'];
  popularity: Scalars['Int'];
  presentation?: Maybe<PropertyFile>;
  projectCost: Scalars['Float'];
  sellSideEntity?: Maybe<SellSideEntity>;
  stoDetailsInvestNowLink?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type Discount = {
  ID: Scalars['Int'];
  amount: Scalars['Float'];
  appliesTo: DiscountAppliesTo;
  stoIDs: Array<Scalars['Int']>;
  stos: Array<Sto>;
  triggers: Array<PriceTransformerTrigger>;
  type: DiscountType;
};

export type DiscountInput = {
  amount: Scalars['Float'];
  appliesTo: DiscountAppliesTo;
  stoIDs: Array<Scalars['Int']>;
  type: DiscountType;
};

export type DiscountUpdateInput = {
  ID: Scalars['Int'];
  amount: Scalars['Float'];
  appliesTo: DiscountAppliesTo;
  stoIDs: Array<Scalars['Int']>;
  type: DiscountType;
};

/** Award strategy used to distribute payouts in the dividend. */
export enum DividendAwardStrategy {
  DividendAmountDistributed = 'dividendAmountDistributed',
  FeePerShare = 'feePerShare',
  Formula = 'formula',
  PercentPremiumValuePerShare = 'percentPremiumValuePerShare',
}

export type DividendDistribution = {
  /** ID of the distribution. Maps to DividendPayout ID. */
  ID: Scalars['Int'];
  adminID: Scalars['Int'];
  /** @deprecated Currently unimplemented */
  awardFormula?: Maybe<Scalars['String']>;
  awardStrategy: DividendAwardStrategy;
  awardValue: Scalars['Float'];
  channelID?: Maybe<Scalars['Int']>;
  companyShares: Scalars['Int'];
  contractAddress?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  currencyID: Scalars['Int'];
  currentlyPaidAmount: Scalars['Int'];
  currentlyPaidInvestors: Scalars['Int'];
  dateTimeDue: Scalars['Date'];
  dateTimeFrom: Scalars['Date'];
  /**
   * Template group ID, denotes similarity to other dividends
   * @deprecated not implemented
   */
  historyID?: Maybe<Scalars['Int']>;
  investors: Scalars['Int'];
  isActive: Scalars['Boolean'];
  lastUpdatedAt?: Maybe<Scalars['Date']>;
  /** Total dividend payment status over the distribution */
  payoutStatus: DividendPayoutStatus;
  period: Scalars['Int'];
  periodUnit: DividendPeriodUnit;
  shareTypeID: Scalars['Int'];
  stoID: Scalars['Int'];
  /** Maps to the DividendTemplate ID in case you want to recycle it. WILL update the template data. */
  templateID: Scalars['Int'];
  title: Scalars['String'];
  totalAmount: Scalars['Float'];
  totalInvestorShares: Scalars['Int'];
};

export type DividendDistributionEditInput = {
  ID: Scalars['Int'];
  adminID?: Maybe<Scalars['Int']>;
  awardFormula?: Maybe<Scalars['String']>;
  awardStrategy?: Maybe<DividendAwardStrategy>;
  awardValue?: Maybe<Scalars['Float']>;
  /** Leave undefined for internal payments */
  channelID?: Maybe<Scalars['Int']>;
  currencyID?: Maybe<Scalars['Int']>;
  dateTimeDue?: Maybe<Scalars['Date']>;
  dateTimeFrom?: Maybe<Scalars['Date']>;
  paymentStatus?: Maybe<DividendPayoutStatus>;
  period?: Maybe<Scalars['Int']>;
  periodUnit?: Maybe<DividendPeriodUnit>;
  shareTypeID?: Maybe<Scalars['Int']>;
  status: DividendPayoutStatus;
  stoID: Scalars['Int'];
  templateID?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type DividendDistributionInput = {
  adminID?: Maybe<Scalars['Int']>;
  awardFormula?: Maybe<Scalars['String']>;
  awardStrategy: DividendAwardStrategy;
  awardValue: Scalars['Float'];
  /** Leave undefined for internal payments */
  channelID?: Maybe<Scalars['Int']>;
  currencyID: Scalars['Int'];
  dateTimeDue?: Maybe<Scalars['Date']>;
  dateTimeFrom?: Maybe<Scalars['Date']>;
  period?: Maybe<Scalars['Int']>;
  periodUnit?: Maybe<DividendPeriodUnit>;
  shareTypeID?: Maybe<Scalars['Int']>;
  status?: Maybe<DividendPayoutStatus>;
  stoID: Scalars['Int'];
  templateID?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
};

export type DividendInvestorPayout = {
  ID: Scalars['Int'];
  amount: Scalars['Float'];
  investor: Investor;
  investorID: Scalars['Int'];
  investorName?: Maybe<Scalars['String']>;
  investorShares: Scalars['Float'];
  lastUpdatedAt: Scalars['Timestamp'];
  payout: DividendPayout;
  payoutID: Scalars['Int'];
  publicKeys?: Maybe<PublicKey>;
  status: DividendPayoutStatus;
};

export type DividendInvestorPayoutWithDistribution = {
  ID: Scalars['Int'];
  amount: Scalars['Float'];
  distribution: DividendDistribution;
  investor: Investor;
  investorID: Scalars['Int'];
  investorName?: Maybe<Scalars['String']>;
  investorShares: Scalars['Float'];
  lastUpdatedAt: Scalars['Timestamp'];
  payout: DividendPayout;
  payoutID: Scalars['Int'];
  publicKeys?: Maybe<PublicKey>;
  status: DividendPayoutStatus;
};

export type DividendPayout = {
  ID: Scalars['Int'];
  admin?: Maybe<User>;
  adminID?: Maybe<Scalars['Int']>;
  awardValue?: Maybe<Scalars['Float']>;
  companyShares: Scalars['Float'];
  /** The address of the smart contract which one was created when an onchain based dividend was deployed. */
  contractAddress?: Maybe<Scalars['String']>;
  currentlyPaidAmount: Scalars['Float'];
  currentlyPaidInvestors: Scalars['Int'];
  dateTimeDue: Scalars['DateTime'];
  dateTimeFrom: Scalars['DateTime'];
  investors: Scalars['Int'];
  lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  status: DividendPayoutStatus;
  template: DividendTemplate;
  templateID: Scalars['Int'];
  totalAmount: Scalars['Float'];
  totalInvestorShares: Scalars['Float'];
};

export enum DividendPayoutStatus {
  Completed = 'completed',
  Exception = 'exception',
  Future = 'future',
  Pending = 'pending',
  Rejected = 'rejected',
  Reverted = 'reverted',
  Reverting = 'reverting',
}

/** Time unit for the period of dividends */
export enum DividendPeriodUnit {
  Days = 'days',
  Months = 'months',
}

export type DividendTemplate = {
  ID: Scalars['Int'];
  awardFormula?: Maybe<Scalars['String']>;
  awardValue: Scalars['Float'];
  channelID?: Maybe<Scalars['Int']>;
  currency: Currency;
  currencyID?: Maybe<Scalars['Int']>;
  historyID?: Maybe<Scalars['Int']>;
  /** Is recurring payment active */
  isActive: Scalars['Boolean'];
  period: Scalars['Int'];
  periodUnit: Scalars['String'];
  shareTypeID?: Maybe<Scalars['Int']>;
  status: DividendTemplateStatus;
  stoID: Scalars['Int'];
  title?: Maybe<Scalars['Float']>;
};

/** The status of the template. */
export enum DividendTemplateStatus {
  Historical = 'historical',
  Unused = 'unused',
  Used = 'used',
}

export type Document = {
  ID: Scalars['Int'];
  contents?: Maybe<Scalars['String']>;
  directoryID: Scalars['Float'];
  docusignDocumentID?: Maybe<Scalars['String']>;
  filetype: Scalars['Float'];
  helloSignDocumentID?: Maybe<Scalars['String']>;
  offer?: Maybe<OfferedDocument>;
  sharePurchaseDocument?: Maybe<SharePurchaseDocument>;
  submittedDocument?: Maybe<DocumentUser>;
  title: Scalars['String'];
};

export type DocumentComment = {
  ID: Scalars['Int'];
  dateReplyComment?: Maybe<Scalars['DateTime']>;
  documentID: Scalars['Int'];
  investorID: Scalars['Int'];
  isAccepted: Scalars['Int'];
  isNew: Scalars['Int'];
  modified?: Maybe<Scalars['DateTime']>;
  reply: DocumentCommentReply;
  replyByID: Scalars['Int'];
  replyText: Scalars['String'];
  stoID?: Maybe<Scalars['Float']>;
  text: Scalars['String'];
};

export type DocumentCommentReply = {
  investorID: Scalars['Int'];
  modified: Scalars['DateTime'];
  text: Scalars['String'];
};

export type DocumentField = {
  ID: Scalars['Int'];
  documentID: Scalars['Int'];
  externalFileDataLabel: Scalars['String'];
  fieldID: Scalars['String'];
  helperText: Scalars['String'];
  stoID: Scalars['Int'];
  title: Scalars['String'];
  type: Scalars['Int'];
};

/** Document Submitted entiry data */
export type DocumentFieldValueDto = {
  ID: Scalars['String'];
  value: Scalars['String'];
};

export type DocumentUser = {
  ID: Scalars['Int'];
  buyAlert?: Maybe<InvestorBuyAlert>;
  contents: Scalars['String'];
  directoryID?: Maybe<Scalars['Int']>;
  /** @deprecated use getDocument instead */
  document: Document;
  documentID?: Maybe<Scalars['Int']>;
  documentOfferInvestorID: Scalars['Int'];
  fieldValues?: Maybe<Array<DocumentUserFieldValue>>;
  fieldValuesJson: Scalars['String'];
  /** fetches the document related to this signature */
  getDocument: Document;
  getInvestor: Investor;
  investorID?: Maybe<Scalars['Int']>;
  sharePurchaseID?: Maybe<Scalars['Float']>;
  signature?: Maybe<CloudFiles>;
  signatureDate?: Maybe<Scalars['DateTime']>;
  signatureFileID?: Maybe<Scalars['Float']>;
  signatureFilePath?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
  stoID?: Maybe<Scalars['Int']>;
};

export type DocumentUserFieldValue = {
  ID?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type DpayAuth = {
  ID: Scalars['Int'];
  apiKey?: Maybe<Scalars['String']>;
  /** The ID given to us by the payment provider to identify the client/user */
  clientId?: Maybe<Scalars['String']>;
  /** The ID created by the payment provider API to identify the person/business entity behind the accounts */
  identityId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  paymentProcessor: PaymentProcessor;
  refreshToken?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  tokenExpiryDate?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  /** The webhook secret used to verify webhook requests */
  webhookSecret?: Maybe<Scalars['String']>;
};

export type DpayAuthCredentialsInput = {
  apiKey?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  webhookSecret?: Maybe<Scalars['String']>;
};

export type DpayEntity = {
  ID: Scalars['Int'];
  balance: Scalars['String'];
  investorID?: Maybe<Scalars['Int']>;
  owner: DpayEntity;
  ownerID: Scalars['Int'];
  /** Remote ID of the account */
  paymentAccountID: Scalars['String'];
  paymentProcessor: PaymentProcessor;
  refreshToken?: Maybe<Scalars['String']>;
  rsyncStatus: DpayEntityRsyncStatus;
  stoID?: Maybe<Scalars['Int']>;
  storage?: Maybe<Scalars['JSON']>;
  subAccounts: DpayEntity;
  token: Scalars['String'];
  tokenExpiryDate?: Maybe<Scalars['String']>;
  topUpSourceId?: Maybe<Scalars['String']>;
  userType: DpayUserType;
};

/** Remote synchronization status between an account and a processor */
export enum DpayEntityRsyncStatus {
  Nosync = 'nosync',
  Outdated = 'outdated',
  Synced = 'synced',
  Uploading = 'uploading',
}

/** Denotes what type of record a payment will resolve */
export enum DpayResolver {
  BuyAlert = 'BuyAlert',
  /** direct payments like bank transfers, wire transfers, checks, etc. */
  DepositAlert = 'DepositAlert',
  DividendDistribution = 'DividendDistribution',
  ExchangeOffer = 'ExchangeOffer',
  Fee = 'Fee',
  InvestorDividend = 'InvestorDividend',
  Invoice = 'Invoice',
  /** internal wallet top-ups. Can be settled by different payment channels */
  TopUpAlert = 'TopUpAlert',
}

export type DpayTransaction = {
  ID: Scalars['Int'];
  amount: Scalars['String'];
  currencyCode: Scalars['String'];
  externalID?: Maybe<Scalars['String']>;
  externalUrl: Scalars['String'];
  fees: Scalars['Float'];
  investorID: Scalars['Int'];
  invoice?: Maybe<InvestorInvoices>;
  invoiceID?: Maybe<Scalars['Int']>;
  localStatus: DpayTransactionStatus;
  resolveID?: Maybe<Scalars['Int']>;
  resolver?: Maybe<Scalars['String']>;
  storage?: Maybe<Scalars['JSON']>;
  timeFinalized?: Maybe<Scalars['DateTime']>;
  timeStarted: Scalars['DateTime'];
  timeUpdated: Scalars['DateTime'];
};

/** Represents a payment integrator transaction status */
export enum DpayTransactionStatus {
  Aborted = 'Aborted',
  Failed = 'Failed',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted',
  Successful = 'Successful',
}

/** Who is using DPAY. Can be platform, investor, user */
export enum DpayUserType {
  InvestingEntity = 'InvestingEntity',
  Investor = 'Investor',
  Platform = 'Platform',
  User = 'User',
}

/** Custom actions from our Dwolla-Plaid integration */
export enum DwollaCustomActions {
  AdminTopupBalanceFromBank = 'AdminTopupBalanceFromBank',
  AdminWithdrawBalanceToBank = 'AdminWithdrawBalanceToBank',
  ClientAuth = 'ClientAuth',
  CreateAdminFundingSource = 'CreateAdminFundingSource',
  GetAdminDwollaBalance = 'GetAdminDwollaBalance',
  GetAdminFundingSources = 'GetAdminFundingSources',
  GetPlaidLinkToken = 'GetPlaidLinkToken',
  GetPlaidProcessorToken = 'GetPlaidProcessorToken',
}

/** Entity types */
export enum EntityType {
  /** entity describing a company/organization under which investors can work at. Among other things, Investors can purchase shares and hold investments in it */
  InvestingEntity = 'INVESTING_ENTITY',
  /** entity describing an investor of type private/physical person */
  Investor = 'INVESTOR',
  /** entity describing  a sub-organization which can control a group of offerings on the administrative/sell side of the platform */
  SellSideEntity = 'SELL_SIDE_ENTITY',
}

/** Who can be notified about a new company update */
export enum EmailRecipient {
  Bulk = 'BULK',
  None = 'NONE',
  Specific = 'SPECIFIC',
}

/** Data sent by the GCash KYC service */
export type EncryptedDataInput = {
  encryptedData: Scalars['String'];
  iv: Scalars['String'];
  signature: Scalars['String'];
};

export type ExchangeBuyOrderInput = {
  atomicSwapSharesWalletID?: Maybe<Scalars['Int']>;
  atomicSwapTokenAddressAcceptable?: Maybe<Scalars['String']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  rateFrom: Scalars['Float'];
  shareTypeID: Scalars['Int'];
  shares: Scalars['Float'];
};

export type ExchangeOffer = {
  ID: Scalars['Int'];
  atomicBuyerPublicKey?: Maybe<Scalars['String']>;
  atomicSwapAccepted?: Maybe<Scalars['Boolean']>;
  atomicSwapExpireDate?: Maybe<Scalars['Timestamp']>;
  atomicSwapSecret?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  exchangeOrder: ExchangeOrder;
  exchangeOrderID: Scalars['Int'];
  investorID: Scalars['Int'];
  invoices: Array<InvestorInvoices>;
  /** The Offered Price of One Unit Share */
  pricePerShare: Scalars['Float'];
  /** The Minimum Range of the Offered Total Price */
  rateFrom: Scalars['Float'];
  /** The Maximum Range of the Offered Total Price */
  rateTo: Scalars['Float'];
  sharesPartial: Scalars['Float'];
  status: OfferStatus;
  stoID: Scalars['Int'];
};

export type ExchangeOfferInput = {
  atomicBuyerPublicKey: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  exchangeOrderID: Scalars['Int'];
  rateFrom: Scalars['Float'];
  sharesPartial: Scalars['Float'];
};

export type ExchangeOrder = {
  ID: Scalars['Int'];
  atomicSwapAcceptable: Scalars['Boolean'];
  atomicSwapCurrentStatus: AtomicSwapStatus;
  atomicSwapExchangeOffersID?: Maybe<Scalars['Int']>;
  atomicSwapSharesWallet?: Maybe<SharesWallet>;
  atomicSwapSharesWalletID: Scalars['Int'];
  atomicSwapTokenAddressAcceptable?: Maybe<Scalars['String']>;
  dateFrom: Scalars['String'];
  dateTo: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  initialShares: Scalars['Float'];
  investorID: Scalars['Int'];
  /** The Ordered Price of One Unit Share */
  pricePerShare: Scalars['Float'];
  /** The Minimum Range of the Ordered Total Price */
  rateFrom: Scalars['Float'];
  /** The Maximum Range of the Ordered Total Price */
  rateTo: Scalars['Float'];
  shareType: ShareType;
  shareTypeID: Scalars['Int'];
  shares: Scalars['Float'];
  stoID: Scalars['Int'];
  type: ExchangeType;
};

export type ExchangeSellOrderInput = {
  atomicSwapSharesWalletID?: Maybe<Scalars['Int']>;
  atomicSwapTokenAddressAcceptable?: Maybe<Scalars['String']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  rateFrom: Scalars['Float'];
  shareTypeID: Scalars['Int'];
  shares: Scalars['Float'];
};

/** Data Structure Representing An Exchange Transaction */
export type ExchangeTransactionData = {
  ID: Scalars['Int'];
  amount: Scalars['Float'];
  buyerName: Scalars['String'];
  currency: Currency;
  dateCompleted: Scalars['Timestamp'];
  pricePerShare: Scalars['Float'];
  sellerName: Scalars['String'];
  shareTypeTitle: Scalars['String'];
  stoTitle: Scalars['String'];
  totalPrice: Scalars['Float'];
  type: TransactionType;
};

/** Data Structure Used For Filtering Exchange Transactions */
export type ExchangeTransactionFilterInput = {
  shareTypeID?: Maybe<Scalars['Int']>;
  stoID?: Maybe<Scalars['Int']>;
  type?: Maybe<TransactionType>;
};

/** Types of exchange operations */
export enum ExchangeType {
  Buy = 'BUY',
  Sell = 'SELL',
}

export type ExchangeUpdateOrderInput = {
  dateTo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  rateFrom: Scalars['Float'];
};

export type ExhaustiveTranslationsState = {
  locale: Scalars['String'];
  missingTranslations: Array<Translation>;
  missingTranslationsCount: Scalars['Int'];
  translations: Array<Translation>;
};

/** Parameter used to describe the external module data. It only redirects investors to a specified URL */
export type ExternalModuleParam = {
  /** Name of the icon to use for the module */
  icon: Scalars['String'];
  /** Determines if this module is on */
  isEnabled: Scalars['Boolean'];
  /** Url to which the investors will be redirected to */
  redirectUrl: Scalars['String'];
};

/** All Kinds of Fee Beneficiaries */
export enum FeeBeneficiary {
  Broker = 'Broker',
  Platform = 'Platform',
}

/** All Kinds of Fee Types */
export enum FeeType {
  BuyExchange = 'BuyExchange',
  BuyShares = 'BuyShares',
  Deposit = 'Deposit',
  Registration = 'Registration',
  SellBack = 'SellBack',
  SellExchange = 'SellExchange',
}

/** Type of file */
export enum FileType {
  Document = 'DOCUMENT',
  Image = 'IMAGE',
  Presentation = 'PRESENTATION',
}

export type Fee = {
  ID: Scalars['Int'];
  amount: Scalars['Float'];
  beneficiary: FeeBeneficiary;
  countries: Array<Scalars['String']>;
  status: CommissionType;
  stoID: Scalars['Int'];
  type: FeeType;
};

export type FeeCommission = {
  ID: Scalars['Int'];
  amount: Scalars['Float'];
  beneficiaryID: Scalars['Int'];
  beneficiaryType?: Maybe<BrokerType>;
  dateEarned: Scalars['Timestamp'];
  feeID: Scalars['Int'];
  /** The ID of the Investor Whose Action Leads to a FEE or COMMISSION */
  investorID: Scalars['Int'];
  status: PaymentStatus;
  transactionID: Scalars['Int'];
};

/** Fee Commission data */
export type FeeCommissionInput = {
  amount: Scalars['Float'];
  beneficiaryID: Scalars['Int'];
  beneficiaryType?: Maybe<BrokerType>;
  dateEarned?: Maybe<Scalars['Timestamp']>;
  feeID: Scalars['Int'];
  investorID: Scalars['Int'];
  status?: Maybe<PaymentStatus>;
  transactionID: Scalars['Int'];
};

/** Fee data */
export type FeeInput = {
  amount: Scalars['Float'];
  beneficiary: FeeBeneficiary;
  countries: Array<Scalars['String']>;
  status: CommissionType;
  stoID: Scalars['Int'];
  type: FeeType;
};

export type FileUploaded = {
  link: Scalars['String'];
  name: Scalars['String'];
};

/** admin data exposed publicly without any authentication required */
export type ForumAdmin = {
  offering: PublicSto;
  username: Scalars['String'];
};

/** investor data exposed publicly without any authentication required */
export type ForumInvestor = {
  ID: Scalars['Int'];
};

export type ForumPost = {
  ID: Scalars['Int'];
  admin?: Maybe<ForumAdmin>;
  adminID?: Maybe<Scalars['Int']>;
  body: Scalars['String'];
  createdAt: Scalars['Timestamp'];
  deletedAt?: Maybe<Scalars['Timestamp']>;
  investor?: Maybe<ForumInvestor>;
  investorID?: Maybe<Scalars['Int']>;
  /** Has value when this Post is considered as a Reply to another Post */
  repliesTo?: Maybe<Scalars['Int']>;
  thread: ForumThread;
  threadID: Scalars['Int'];
};

export type ForumPostInput = {
  body: Scalars['String'];
  repliesTo?: Maybe<Scalars['Int']>;
  threadID: Scalars['Int'];
};

export type ForumThread = {
  ID: Scalars['Int'];
  adminID?: Maybe<Scalars['Int']>;
  body: Scalars['String'];
  createdAt: Scalars['Timestamp'];
  deletedAt?: Maybe<Scalars['Timestamp']>;
  investorID?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
};

export type ForumThreadInput = {
  body: Scalars['String'];
  title: Scalars['String'];
};

/** Frontend redirection configuration */
export type FrontendRedirectConfig = {
  /** URL to redirect after payment */
  afterPaymentUrl: Scalars['String'];
};

export type GetSignedAndSettledInput = {
  investorID: Scalars['Int'];
  /** the ID of the offering / sto */
  offeringID?: Maybe<Scalars['Int']>;
};

/** HTTP method */
export enum Http {
  Get = 'Get',
  Post = 'Post',
}

/** Tells when the "interested" switch should be displayed in the investor dashboard */
export enum InvestorAssociationStoSwitchEnum {
  AlwaysDisplay = 'ALWAYS_DISPLAY',
  NeverDisplay = 'NEVER_DISPLAY',
  OnlyWhenInterested = 'ONLY_WHEN_INTERESTED',
}

/** Investor meta keys types */
export enum InvestorMetaKeysType {
  Boolean = 'BOOLEAN',
  Category = 'CATEGORY',
  Json = 'JSON',
  Number = 'NUMBER',
  Richtext = 'RICHTEXT',
  Text = 'TEXT',
}

/** The Type of changeable params by an Investor */
export enum InvestorParam {
  LeftSideMenuFont = 'LEFT_SIDE_MENU_FONT',
}

export enum InvitationStatus {
  /** Just created by the administrator */
  Created = 'CREATED',
  /** The administrator has invited this member to register on the platform */
  Invited = 'INVITED',
  /** The member has registered an investor account */
  Registered = 'REGISTERED',
}

/** InvoiceStatusType */
export enum InvoiceStatusType {
  Cancelled = 'Cancelled',
  /** the invoice was declined */
  Declined = 'Declined',
  /** the invoice has been successfully paid */
  Paid = 'Paid',
  /** awaiting payment processor interaction */
  PaymentAwaiting = 'PaymentAwaiting',
  /** failure with the payment processor */
  PaymentFailure = 'PaymentFailure',
  /** the payment processor has started this invoice, but not finished yet */
  PaymentOngoing = 'PaymentOngoing',
  /** the invoice requires manual verification from an admin */
  PendingVerification = 'PendingVerification',
  /** the invoice has not been paid yet */
  Unpaid = 'Unpaid',
}

export type Inbox = {
  ID: Scalars['Int'];
  date: Scalars['String'];
  details: Scalars['String'];
  investorID: Scalars['Int'];
  isResponded: Scalars['Int'];
  response?: Maybe<Scalars['String']>;
  responseDate?: Maybe<Scalars['String']>;
  stoID: Scalars['Int'];
  title: Scalars['String'];
};

/** Investor inbox create data */
export type InboxInput = {
  content: Scalars['String'];
  stoID: Scalars['Float'];
  title: Scalars['String'];
};

export type InitiatePurchaseInput = {
  invoiceID: Scalars['Int'];
  payingWalletAddress: Scalars['String'];
};

export type InitiatePurchaseResult = {
  blockchainTransactionID: Scalars['String'];
  shareTypeVenueID: Scalars['Int'];
};

/** Denotes the mode in which the platform treats investor balances */
export enum InternalWalletMode {
  Disabled = 'Disabled',
  Global = 'Global',
  StoSpecific = 'StoSpecific',
}

/** Top Up Alert for the internal wallets */
export type InternalWalletTopUpAlertInput = {
  amount: Scalars['Float'];
  currencyID: Scalars['Int'];
  status?: Maybe<TopUpAlertStatus>;
  stoID: Scalars['Int'];
};

export type InvestingEntity = {
  ID: Scalars['Int'];
  accredited: Scalars['Boolean'];
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  /** Investor, usually the owner account. Can be self-owned account if the investor.isEntityID === this ID. */
  investor?: Maybe<Investor>;
  /** The investor part of the entity, which can hold individual investments, shares, STO relationship data, etc. */
  investorCounterpart?: Maybe<Investor>;
  investorID?: Maybe<Scalars['Int']>;
  isApprovedByAdmin: Scalars['Int'];
  /** returns which owner was the first investor to ever perform the specified action on the platform, that is related to this investing entity. If no action is passed, it defaults to first details page viewing */
  logDetails?: Maybe<LogDetails>;
  members: Array<InvestingEntityMember>;
  meta: Array<MetaValues>;
  name: Scalars['String'];
  nickname: Scalars['String'];
  /** Returns offering options for the entity */
  offeringOptions?: Maybe<InvestingEntitySto>;
  paymentMethod: InvestingEntityPaymentMethods;
  postalCode: Scalars['String'];
  registrationState: Scalars['String'];
  returnRates: Array<InvestingEntityReturns>;
  state: Scalars['String'];
  taxId: Scalars['String'];
  type: InvestingEntityTypes;
  typeID: Scalars['Int'];
};

export type InvestingEntityLogDetailsArgs = {
  logAction?: Maybe<LogAction>;
  stoIDs?: Maybe<Array<Scalars['Int']>>;
};

export type InvestingEntityMetaArgs = {
  entityID?: Maybe<Scalars['Int']>;
  keys?: Maybe<Array<ReservedMetaKeys>>;
  stoID?: Maybe<Scalars['Int']>;
};

/** Investors Investing Entity data for creating with meta values */
export type InvestingEntityCreateInput = {
  accredited: Scalars['Boolean'];
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  investingEntityPreferences?: Maybe<Array<MetaValuesInput>>;
  metaValues?: Maybe<Array<MetaValuesInput>>;
  name: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  paymentMethod: InvestingEntityPaymentMethods;
  postalCode: Scalars['String'];
  registrationState: Scalars['String'];
  state: Scalars['String'];
  taxId: Scalars['String'];
  typeID: Scalars['Float'];
};

/** Investors Investing Entity creation options */
export type InvestingEntityCreationOptions = {
  /** Modifies the investor to act as an entity on the platform */
  createEntityAsInvestor?: Maybe<Scalars['Boolean']>;
  /** If entity secrecy is enabled, will be visible all STOs and sell agents */
  globallyVisible?: Maybe<Scalars['Boolean']>;
  /** Toggles creating an entity investor representative */
  skipEntityRepresentative?: Maybe<Scalars['Boolean']>;
  /** Do not create owner as a member of the entity */
  skipOwnerAsMember?: Maybe<Scalars['Boolean']>;
};

/** Investors Investing Entity data for creating or updating */
export type InvestingEntityInput = {
  accredited: Scalars['Boolean'];
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  name: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  paymentMethod: InvestingEntityPaymentMethods;
  postalCode: Scalars['String'];
  registrationState: Scalars['String'];
  state: Scalars['String'];
  taxId: Scalars['String'];
  typeID: Scalars['Float'];
};

export type InvestingEntityMember = {
  ID: Scalars['Int'];
  createdAt: Scalars['Timestamp'];
  email: Scalars['String'];
  entityID: Scalars['Int'];
  firstName: Scalars['String'];
  investingEntity: InvestingEntity;
  investorID?: Maybe<Scalars['Int']>;
  invitation?: Maybe<InvestingEntityMemberInvitation>;
  lastName: Scalars['String'];
  role: InvestingEntityMemberRoles;
  signatory: Scalars['Boolean'];
};

export type InvestingEntityMemberInvitationArgs = {
  offeringID: Scalars['Int'];
};

/** Investors Investing Entity members data */
export type InvestingEntityMemberInput = {
  doNotEmail?: Maybe<Scalars['Boolean']>;
  doNotMarkInvited?: Maybe<Scalars['Boolean']>;
  /** Do not mark registered in STO 0 */
  doNotMarkRegistered?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  entityID: Scalars['Float'];
  firstName: Scalars['String'];
  investorID?: Maybe<Scalars['Int']>;
  lastName: Scalars['String'];
  role: InvestingEntityMemberRoles;
  sellAgentID?: Maybe<Scalars['Int']>;
  signatory: Scalars['Boolean'];
  stoID?: Maybe<Scalars['Int']>;
};

export type InvestingEntityMemberInvitation = {
  ID: Scalars['Int'];
  investingMemberID: Scalars['Int'];
  investor?: Maybe<Investor>;
  /** links this invitation to an investor, once the person registers an account on the platform */
  investorID?: Maybe<Scalars['Int']>;
  member: InvestingEntityMember;
  /** link used to set the password for a new member */
  resetPasswordLink?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['Timestamp']>;
  status: InvitationStatus;
  sto: Sto;
  /** which sto they were invited to */
  stoID: Scalars['Int'];
};

/** Roles of the Investing entity member */
export enum InvestingEntityMemberRoles {
  Accountant = 'ACCOUNTANT',
  Advisor = 'ADVISOR',
  Investor = 'INVESTOR',
  Owner = 'OWNER',
}

/** Payment methods of the Investing entity */
export enum InvestingEntityPaymentMethods {
  Ach = 'ACH',
  Blockchain = 'BLOCKCHAIN',
  Mail = 'MAIL',
  NotApplicable = 'NOT_APPLICABLE',
  Unset = 'UNSET',
  Wire = 'WIRE',
}

export type InvestingEntityReturns = {
  ID: Scalars['Int'];
  entityID: Scalars['Int'];
  offering: Sto;
  returnRate: Scalars['Float'];
  stoID: Scalars['Int'];
};

/** Investing entity data for creating or updating returns on investments */
export type InvestingEntityReturnsInput = {
  entityID: Scalars['Float'];
  returnRate: Scalars['Float'];
  stoID: Scalars['Float'];
};

export type InvestingEntitySto = {
  ID: Scalars['Int'];
  closing: Scalars['Boolean'];
  entityID: Scalars['Int'];
  /** indicating the first time when one of the member of a certain Investing entity entered the data room */
  firstAccessedDataRoom?: Maybe<Scalars['DateTime']>;
  invited: Scalars['Boolean'];
  isBuyStoEnabled: Scalars['Boolean'];
  isNDASigned: Scalars['Boolean'];
  managementMeetings: Scalars['Boolean'];
  receivedSubscriptionAgreements: Scalars['Boolean'];
  receivedTermSheet: Scalars['Boolean'];
  stoID: Scalars['Int'];
  visible: Scalars['Boolean'];
};

/** Investing entity sto data for updating boolean fields */
export type InvestingEntityStoManualInputs = {
  ID?: Maybe<Scalars['Int']>;
  closing?: Maybe<Scalars['Boolean']>;
  firstAccessedDataRoom?: Maybe<Scalars['DateTime']>;
  isBuyStoEnabled?: Maybe<Scalars['Boolean']>;
  isNDASigned?: Maybe<Scalars['Boolean']>;
  managementMeetings?: Maybe<Scalars['Boolean']>;
  receivedSubscriptionAgreements?: Maybe<Scalars['Boolean']>;
  receivedTermSheet?: Maybe<Scalars['Boolean']>;
};

export type InvestingEntityStoPermissions = {
  ID?: Maybe<Scalars['Int']>;
  closing?: Maybe<Scalars['Boolean']>;
  firstAccessedDataRoom?: Maybe<Scalars['DateTime']>;
  isBuyStoEnabled?: Maybe<Scalars['Boolean']>;
  isNDASigned?: Maybe<Scalars['Boolean']>;
  managementMeetings?: Maybe<Scalars['Boolean']>;
  receivedSubscriptionAgreements?: Maybe<Scalars['Boolean']>;
  receivedTermSheet?: Maybe<Scalars['Boolean']>;
};

export type InvestingEntityType = {
  ID: Scalars['Int'];
  title: Scalars['String'];
};

export type InvestingEntityTypes = {
  ID: Scalars['Int'];
  countries: Array<Scalars['String']>;
  title: Scalars['String'];
};

export type Investments = {
  amount?: Maybe<Scalars['Float']>;
  /** Returns currency for an investment */
  currency: Currency;
  currencyID: Scalars['Int'];
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['Int']>;
  investorID: Scalars['Int'];
  /** Returns sharetype for an investment */
  share: ShareType;
  shareTypeID: Scalars['Int'];
  stoID?: Maybe<Scalars['Int']>;
  tokens?: Maybe<Scalars['Float']>;
  userID: Scalars['Int'];
};

export type InvestmentsCalculation = {
  dividendPayout: Scalars['Float'];
  entityName: Scalars['String'];
  investmentName: Scalars['String'];
  investmentValue: Scalars['Float'];
  leapDays: Scalars['Float'];
  leapProfit: Scalars['Float'];
  normalDays: Scalars['Float'];
  normalProfit: Scalars['Float'];
  originatingProjectName: Scalars['String'];
  shareTypeID: Scalars['Int'];
  shares: Scalars['Float'];
  totalProfit: Scalars['Float'];
  yearlyRate: Scalars['Float'];
};

export type Investor = {
  ID: Scalars['Int'];
  address?: Maybe<Scalars['String']>;
  allowedTotalInvestment: Scalars['Float'];
  asEntity?: Maybe<InvestingEntity>;
  birthDate?: Maybe<Scalars['String']>;
  brokerID?: Maybe<Scalars['String']>;
  cell?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryBusiness?: Maybe<Scalars['String']>;
  countryCitizenship?: Maybe<Scalars['String']>;
  dateIncorporation?: Maybe<Scalars['String']>;
  dpayEntities: Array<DpayEntity>;
  driversLicenseID?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  employerAddress?: Maybe<Scalars['String']>;
  employerName?: Maybe<Scalars['String']>;
  faxNumber?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  govtID?: Maybe<Scalars['String']>;
  investmentLimitUpdateDate: Scalars['String'];
  investorType: Scalars['Int'];
  isEntity: Scalars['Boolean'];
  isEntityID?: Maybe<Scalars['Int']>;
  isTax: Scalars['Int'];
  isTwoFactorEnabled: Scalars['Boolean'];
  kinemail?: Maybe<Scalars['String']>;
  kinname?: Maybe<Scalars['String']>;
  kinphone?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  mailingAddress?: Maybe<Scalars['String']>;
  maritalStatus: Scalars['Int'];
  meta: Array<MetaValues>;
  middleName?: Maybe<Scalars['String']>;
  nationalID?: Maybe<Scalars['String']>;
  occupation?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  politicallyExposedPerson: Scalars['Float'];
  powerToBindCompany: Scalars['Int'];
  primaryContactEmail?: Maybe<Scalars['String']>;
  primaryContactName?: Maybe<Scalars['String']>;
  primaryContactPhone?: Maybe<Scalars['String']>;
  retirementAccount: Scalars['Int'];
  secondaryContactEmail?: Maybe<Scalars['String']>;
  secondaryContactName?: Maybe<Scalars['String']>;
  secondaryContactPhone?: Maybe<Scalars['String']>;
  socialSecurity?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  taxCountry?: Maybe<Scalars['String']>;
  taxID?: Maybe<Scalars['String']>;
  titleWithinCompany?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  trustOrBusinessEntity: InvestorCompanyTypes;
  userName: Scalars['String'];
  yearlyTotalInvestment: Scalars['Float'];
  zip?: Maybe<Scalars['String']>;
};

export type InvestorMetaArgs = {
  entityID?: Maybe<Scalars['Int']>;
  keys?: Maybe<Array<ReservedMetaKeys>>;
  stoID?: Maybe<Scalars['Int']>;
};

export type InvestorBalance = {
  ID: Scalars['Int'];
  amount: Scalars['Float'];
  currency: Currency;
  currencyID: Scalars['Int'];
  investorID: Scalars['Int'];
  stoID: Scalars['Int'];
};

/** Investor Bank Account Input */
export type InvestorBankAccountInput = {
  accountHolderAddress: Scalars['String'];
  accountHolderCity: Scalars['String'];
  accountHolderCountry: Scalars['String'];
  accountHolderName: Scalars['String'];
  accountNo: Scalars['String'];
  accountPostalCode: Scalars['String'];
  accountTitle: Scalars['String'];
  bankAddress: Scalars['String'];
  bankCity: Scalars['String'];
  bankCountry: Scalars['String'];
  bankName: Scalars['String'];
  iban: Scalars['String'];
  routingNumber: Scalars['String'];
  swift: Scalars['String'];
};

export type InvestorBanks = {
  ID: Scalars['Float'];
  accountHolderAddress: Scalars['String'];
  accountHolderCity: Scalars['String'];
  accountHolderCountry: Scalars['String'];
  accountHolderName: Scalars['String'];
  accountNo: Scalars['String'];
  accountPostalCode: Scalars['String'];
  accountTitle: Scalars['String'];
  bankAddress: Scalars['String'];
  bankCity: Scalars['String'];
  bankCountry: Scalars['String'];
  bankName: Scalars['String'];
  iban: Scalars['String'];
  investorID: Scalars['Float'];
  routingNumber: Scalars['String'];
  swift?: Maybe<Scalars['String']>;
};

export type InvestorBasicCsvBulkUploadOpts = {
  createEntitiesUnderMissingInvestors: Scalars['Boolean'];
  dryRun: Scalars['Boolean'];
  shareTypeID: Scalars['Int'];
  stoID: Scalars['Int'];
  uploadMissingInvestors: Scalars['Boolean'];
};

export type InvestorBasicCsvBulkUploadResults = {
  columns: Array<Scalars['String']>;
  updateTotal: Scalars['Int'];
  updated: Array<Scalars['String']>;
  uploadTotal: Scalars['Int'];
  uploaded: Array<Scalars['String']>;
};

/** Investor beneficial data */
export type InvestorBeneficialInput = {
  ID: Scalars['Float'];
  beneficialAddress: Scalars['String'];
  beneficialBirth: Scalars['String'];
  beneficialCity: Scalars['String'];
  beneficialCountry: Scalars['String'];
  beneficialEmail: Scalars['String'];
  beneficialFirstName: Scalars['String'];
  beneficialLastName: Scalars['String'];
  beneficialNationality: Scalars['String'];
};

export type InvestorBuyAlert = {
  ID: Scalars['Int'];
  /** Alternative payment channel */
  channel?: Maybe<PaymentChannel>;
  /** Alternative payment channel ID */
  channelID?: Maybe<Scalars['Int']>;
  date: Scalars['String'];
  entityID?: Maybe<Scalars['Int']>;
  fromCurrencyID?: Maybe<Scalars['Int']>;
  isBuySharesSigned: Scalars['Int'];
  isHiddenForInvestor: Scalars['Boolean'];
  isSellRequest: Scalars['Boolean'];
  purchasePriceOffered?: Maybe<Scalars['Float']>;
  shareType: ShareType;
  shareTypeID: Scalars['Int'];
  shares: Scalars['Float'];
  status: BuyAlertStatus;
  stoID: Scalars['Int'];
};

/** Investors buy alert data */
export type InvestorBuyAlertInput = {
  /** Alternative payment channel */
  channelID?: Maybe<Scalars['Int']>;
  details?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['Int']>;
  /** Determines if this request is on blockchain */
  isBlockchain?: Maybe<Scalars['Boolean']>;
  /** Investor has acknowledged or deliberately invisible */
  isHiddenForInvestor?: Maybe<Scalars['Boolean']>;
  /** Determines if this is a request to sell shares or to buy them */
  isSellBackRequest?: Maybe<Scalars['Boolean']>;
  publicKey?: Maybe<Scalars['String']>;
  shareTypeID: Scalars['Int'];
  shares: Scalars['Float'];
  /** Optionally set a custom status upon insertion if you have your own purchasing flow. Restricted to API users and platform admins. */
  status?: Maybe<BuyAlertStatus>;
  stoID: Scalars['Int'];
};

/** Investors buy alert data */
export type InvestorBuyAlertInputAdmin = {
  /** Alternative payment channel */
  channelID?: Maybe<Scalars['Int']>;
  details?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['Int']>;
  investorID: Scalars['Int'];
  /** Determines if this request is on blockchain */
  isBlockchain?: Maybe<Scalars['Boolean']>;
  /** Investor has acknowledged or deliberately invisible */
  isHiddenForInvestor?: Maybe<Scalars['Boolean']>;
  /** Determines if this is a request to sell shares or to buy them */
  isSellBackRequest?: Maybe<Scalars['Boolean']>;
  publicKey?: Maybe<Scalars['String']>;
  shareTypeID: Scalars['Int'];
  shares: Scalars['Float'];
  /** Optionally set a custom status upon insertion if you have your own purchasing flow. Restricted to API users and platform admins. */
  status?: Maybe<BuyAlertStatus>;
  stoID: Scalars['Int'];
};

/** Custom flow options for purchasing */
export type InvestorBuyAlertOptions = {
  /** Will allow you to create multiple requests for the same share type (can lead to spam!). Please be sure to know what you are doing. */
  ignoreAllPreviousRequests?: Maybe<Scalars['Boolean']>;
  /** Ignore investing entity */
  ignoreEntity?: Maybe<Scalars['Boolean']>;
  /** Will automatically set requests as signed, ignoring all signature requirements */
  ignoreSignatures?: Maybe<Scalars['Boolean']>;
  /** Do not restrict based on wallet balance */
  ignoreWalletBalance?: Maybe<Scalars['Boolean']>;
};

export type InvestorCategory = {
  label: Scalars['String'];
  value: Scalars['Int'];
};

/** Types of the Investing entity as an Investor */
export enum InvestorCompanyTypes {
  Corporation = 'CORPORATION',
  Limitedpartnership = 'LIMITEDPARTNERSHIP',
  NotDefined = 'NOT_DEFINED',
  Other = 'OTHER',
  Trust = 'TRUST',
  Usbased = 'USBASED',
}

/** Customization options for creating investor via API */
export type InvestorCreateOptions = {
  /** Applies only if you provide the kyc field. Assumes you trust and approve the KYC input for this investor. Marks the user as KYC-approved and enables related actions. */
  autoAcceptKyc?: Maybe<Scalars['Boolean']>;
  /** Custom registration status */
  customKYCstatus?: Maybe<Scalars['Int']>;
};

export type InvestorDepositAlert = {
  ID: Scalars['Int'];
  amount: Scalars['Float'];
  buyAlert?: Maybe<InvestorBuyAlert>;
  buyAlertID?: Maybe<Scalars['Int']>;
  conversionRateLock?: Maybe<Scalars['Int']>;
  conversionRateLockID?: Maybe<Scalars['Int']>;
  currency: Currency;
  currencyID: Scalars['Int'];
  dateApproved: Scalars['Timestamp'];
  dateReceived: Scalars['Timestamp'];
  details: Scalars['String'];
  invoice?: Maybe<InvestorInvoices>;
  invoiceID?: Maybe<Scalars['Int']>;
  isApproved: Scalars['Int'];
  isWithdrawFundsRequest: Scalars['Boolean'];
  stoID: Scalars['Int'];
  transactionHash?: Maybe<Scalars['String']>;
};

/** Mandatory to provide at least one investor identity field */
export type InvestorDepositInput = {
  amount: Scalars['Float'];
  currency: Scalars['String'];
  /** Date of approval is automatically set to current datetime. Set this field if you want to change it. */
  date?: Maybe<Scalars['DateTime']>;
  investorEmail?: Maybe<Scalars['String']>;
  investorGovtID?: Maybe<Scalars['String']>;
  investorID?: Maybe<Scalars['Int']>;
  investorSSN?: Maybe<Scalars['String']>;
  investorTaxID?: Maybe<Scalars['String']>;
  invoiceID?: Maybe<Scalars['Int']>;
  /** Update the balance in the company account with this ID (assuming project-specific wallets are enabled). If wallet mode is set to "global", this field is ignored. */
  stoID?: Maybe<Scalars['Float']>;
};

export type InvestorDepositInputOptions = {
  /** The upload will normally be stopped if any of the data is invalid. This field makes it continue. NOTE: The upload will ALWAYS continue on internal server error and you may be left with partial uploads. Check your errors! */
  doNotStopOnError?: Maybe<Scalars['Boolean']>;
  /** Optional idempotency token (preferably UUID) for the operation. Prevents duplicate uploads. */
  idempotency?: Maybe<Scalars['String']>;
  /** Will update the amount deposited on deposit requets if the amount is not the same as the original. It will mark the deposit as completed. */
  resolveDepositAlertsWhereAmountDiffers?: Maybe<Scalars['Boolean']>;
};

export type InvestorDepositQueryInput = {
  invoiceID: Scalars['Int'];
};

export type InvestorDepositResult = {
  errors: Array<Scalars['JSON']>;
  fullSuccess: Scalars['Boolean'];
};

/** Investors buy alert common fields */
export type InvestorDepositWithdrawAlertInput = {
  amount: Scalars['Float'];
  bankAccount?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  buyAlertID?: Maybe<Scalars['Float']>;
  channelID: Scalars['Int'];
  details?: Maybe<Scalars['String']>;
  invoiceID?: Maybe<Scalars['Float']>;
  isWithdrawRequest: Scalars['Boolean'];
  stoID: Scalars['Int'];
  swiftCode?: Maybe<Scalars['String']>;
  transactionHash?: Maybe<Scalars['String']>;
  transactionID?: Maybe<Scalars['String']>;
};

export type InvestorDocument = {
  ID: Scalars['Int'];
  documentTitle?: Maybe<Scalars['String']>;
  fileUrl: Scalars['String'];
  investorID?: Maybe<Scalars['Float']>;
  link?: Maybe<Scalars['String']>;
  stoID?: Maybe<Scalars['Float']>;
  uploadDate?: Maybe<Scalars['String']>;
};

/** Investor document data */
export type InvestorDocumentInput = {
  cloudFileID?: Maybe<Scalars['Int']>;
  documentTitle?: Maybe<Scalars['String']>;
  investorID: Scalars['Int'];
  link?: Maybe<Scalars['String']>;
  stoID: Scalars['Int'];
};

/** kvp of investor ID and investing entities */
export type InvestorIdEntitiesTuple = {
  /** The investor ID */
  key: Scalars['Int'];
  /** The investing entities */
  value: Array<InvestingEntity>;
};

export type InvestorInvitation = {
  ID: Scalars['Int'];
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailText?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  investorID: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  stoID: Scalars['Int'];
};

export type InvestorInvoiceInput = {
  investorWallet: Scalars['String'];
  invoiceDescription: Scalars['String'];
  isBlockchain: Scalars['Boolean'];
  paymentChannelID?: Maybe<Scalars['Int']>;
  resolveID: Scalars['Int'];
  resolver: DpayResolver;
  shareTypeID: Scalars['Int'];
  stoID: Scalars['Int'];
};

export type InvestorInvoiceQueryInput = {
  investorID?: Maybe<Scalars['Int']>;
  /** Whether to look for invoices where the investor is the seller INSTEAD OF the buyer */
  isWithdrawal?: Maybe<Scalars['Boolean']>;
  /** fetch only the status types mentioned here. If both the blacklist and whitelist are passed, both will be ignored */
  statusTypes?: Maybe<Array<InvoiceStatusType>>;
  /** fetch every other status type, except the ones mentioned here. If both the blacklist and whitelist are passed, both will be ignored */
  statusTypesBlacklist?: Maybe<Array<InvoiceStatusType>>;
  /**
   * If the user is an admin of the main project, or a platform admin.
   *     Otherwise default is behavior is to fetch strictly for the offering this user is managing
   */
  stoID?: Maybe<Scalars['Int']>;
};

export type InvestorInvoices = {
  ID: Scalars['Int'];
  amountToPay: Scalars['Float'];
  buyAlert?: Maybe<InvestorBuyAlert>;
  buyAlertID: Scalars['Int'];
  /** The type of the entity which is required to pay this invoice. During primary share purchase - an 'investor', investorID points to that person */
  buyer: DpayUserType;
  dateCreated: Scalars['Timestamp'];
  dateUpdated?: Maybe<Scalars['Timestamp']>;
  investor?: Maybe<Investor>;
  investorID: Scalars['Int'];
  investorWallet?: Maybe<Scalars['String']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  isBlockchain: Scalars['Boolean'];
  paymentChannel?: Maybe<PaymentChannel>;
  paymentChannelID?: Maybe<Scalars['Int']>;
  resolveID?: Maybe<Scalars['Int']>;
  resolver?: Maybe<DpayResolver>;
  /** The type of the entity which receives payment from this invoice. During primary share purchase - the 'platform'. */
  seller: DpayUserType;
  shareType: ShareType;
  shareTypeID: Scalars['Int'];
  shares: Scalars['Float'];
  status: InvoiceStatusType;
  sto?: Maybe<Sto>;
  stoID: Scalars['Int'];
};

/** Investor KYC data that can be updated by the admin. */
export type InvestorKycInput = {
  investorID: Scalars['Int'];
  isKyc: Scalars['Boolean'];
  kycApplied: Scalars['Boolean'];
  status: Scalars['Int'];
};

/** Investor Logo properties */
export type InvestorLogoProperties = {
  /** display logo on navbar */
  displayInvestorLogo: Scalars['Boolean'];
  /** height of investor logo in pixels */
  height: Scalars['String'];
  /** width of investor logo in pixels */
  width: Scalars['String'];
};

export type InvestorMarketSpaceInput = {
  address?: Maybe<Scalars['String']>;
  brokerID?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  investorType?: Maybe<Scalars['Int']>;
  kyc: Scalars['JSON'];
  lastName: Scalars['String'];
  metaValues?: Maybe<Array<MetaValuesInput>>;
  notes?: Maybe<Scalars['String']>;
  options?: Maybe<InvestorCreateOptions>;
  password: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  referredBy?: Maybe<Scalars['String']>;
  referredByID?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['String']>;
  stoID: Scalars['Int'];
  zip?: Maybe<Scalars['String']>;
};

export type InvestorPageScriptProps = {
  id: Scalars['String'];
  src: Scalars['String'];
};

/** Investor preferred return rate data for creation/update */
export type InvestorPreferredReturnInput = {
  investorID: Scalars['Float'];
  returnRate: Scalars['Float'];
  stoID: Scalars['Float'];
};

export type InvestorPreferredReturns = {
  ID: Scalars['Int'];
  investorID: Scalars['Int'];
  returnRate: Scalars['Float'];
  stoID: Scalars['Int'];
};

/** Investor profile data */
export type InvestorProfileInput = {
  address: Scalars['String'];
  birthDate: Scalars['String'];
  country: Scalars['String'];
  driversLicenseID: Scalars['String'];
  firstName: Scalars['String'];
  kinemail?: Maybe<Scalars['String']>;
  kinname?: Maybe<Scalars['String']>;
  kinphone?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  nationalID: Scalars['String'];
  notes: Scalars['String'];
  passportNumber: Scalars['String'];
  phone: Scalars['String'];
  state: Scalars['String'];
  town: Scalars['String'];
  zip: Scalars['String'];
};

export type InvestorSto = {
  ID: Scalars['Int'];
  KycExpiryDate?: Maybe<Scalars['String']>;
  applied: Scalars['Boolean'];
  beneficialAddress?: Maybe<Scalars['String']>;
  beneficialBirth?: Maybe<Scalars['String']>;
  beneficialCity?: Maybe<Scalars['String']>;
  beneficialCountry?: Maybe<Scalars['String']>;
  beneficialEmail?: Maybe<Scalars['String']>;
  beneficialFirstName?: Maybe<Scalars['String']>;
  beneficialLastName?: Maybe<Scalars['String']>;
  beneficialNationality?: Maybe<Scalars['String']>;
  expectedInvestment: Scalars['Int'];
  expectedShares: Scalars['Int'];
  investor: Investor;
  investorID: Scalars['Int'];
  inviteFriendEmailText?: Maybe<Scalars['String']>;
  isAccountClosed: Scalars['Int'];
  isActive: Scalars['Int'];
  isBuyStoEnabled: Scalars['Boolean'];
  isKYC: Scalars['Int'];
  isNDASigned: Scalars['Boolean'];
  isUsufructuary: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
  stoID: Scalars['Int'];
  updateDate?: Maybe<Scalars['String']>;
  usufructuaryAddress?: Maybe<Scalars['String']>;
  usufructuaryCity?: Maybe<Scalars['String']>;
  usufructuaryCountry?: Maybe<Scalars['String']>;
  usufructuaryEmail?: Maybe<Scalars['String']>;
  usufructuaryFirstName?: Maybe<Scalars['String']>;
  usufructuaryLastName?: Maybe<Scalars['String']>;
};

export type InvestorUser = {
  investor: Investor;
  investorSto: InvestorSto;
  sto: Sto;
};

/** Investor usufructuary data */
export type InvestorUsufructuaryInput = {
  ID: Scalars['Float'];
  isUsufructuary: Scalars['Float'];
  usufructuaryAddress: Scalars['String'];
  usufructuaryCity: Scalars['String'];
  usufructuaryCountry: Scalars['String'];
  usufructuaryEmail: Scalars['String'];
  usufructuaryFirstName: Scalars['String'];
  usufructuaryLastName: Scalars['String'];
};

/** KYC data */
export type KycData = {
  /** user ID */
  ID: Scalars['Float'];
  /** denotes if the login info is active */
  isActive: Scalars['Boolean'];
  isKYC: Scalars['Boolean'];
  /** investorSto status */
  status: Scalars['Float'];
};

export type KycField = {
  description?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  placeholder?: Maybe<Scalars['String']>;
  required: Scalars['Boolean'];
  type: Scalars['String'];
  values?: Maybe<Array<KycFiledValue>>;
};

export type KycFile = {
  ID: Scalars['Int'];
  cloudFileID: Scalars['Int'];
  createdAt: Scalars['Timestamp'];
  filename: Scalars['String'];
  investorID: Scalars['Int'];
  name: Scalars['String'];
  side?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

/** Kyc file data */
export type KycFileCreateInput = {
  cloudFileID: Scalars['Int'];
  name: Scalars['String'];
  side?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type KycFiledValue = {
  label?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type KycPage = {
  fields: Array<KycField>;
  icon?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pages: Array<KycPage>;
  title: Scalars['String'];
};

/** Kyc providers supported by the platform */
export enum KycProviders {
  BlockPass = 'BlockPass',
  GCash = 'GCash',
  Internal = 'Internal',
  Netki = 'Netki',
  SumSub = 'SumSub',
}

/** Denotes the step at which the platform should require the investor to pass KYC */
export enum KycRequirementStep {
  Ignore = 'Ignore',
  OnPurchase = 'OnPurchase',
  OnRegister = 'OnRegister',
  PrePayment = 'PrePayment',
}

/** The Type of action you wish to log */
export enum LogAction {
  AdminCreateEntityReturnRate = 'AdminCreateEntityReturnRate',
  AdminDeleteEntityReturnRate = 'AdminDeleteEntityReturnRate',
  AdminDepositApproved = 'AdminDepositApproved',
  AdminEditEntityReturnRate = 'AdminEditEntityReturnRate',
  AdminInvoiceReject = 'AdminInvoiceReject',
  AdminPasswordReset = 'AdminPasswordReset',
  AdminPasswordResetByAdmin = 'AdminPasswordResetByAdmin',
  AdminSharePurchaseRequestReceived = 'AdminSharePurchaseRequestReceived',
  CompanySharesCreated = 'CompanySharesCreated',
  CompanySharesRemoved = 'CompanySharesRemoved',
  DividendAward = 'DividendAward',
  DividendErase = 'DividendErase',
  EmailSent = 'EmailSent',
  GCashKycResponse = 'GCashKYCResponse',
  InvestorAccessedDealRoom = 'InvestorAccessedDealRoom',
  InvestorAccessedOfferingDetails = 'InvestorAccessedOfferingDetails',
  InvestorApproved = 'InvestorApproved',
  InvestorAuthorization = 'InvestorAuthorization',
  InvestorBalanceUpdated = 'InvestorBalanceUpdated',
  InvestorBulkUpload = 'InvestorBulkUpload',
  InvestorExpressedInterest = 'InvestorExpressedInterest',
  InvestorInvestmentBulkUpload = 'InvestorInvestmentBulkUpload',
  InvestorKycDocumentUpdate = 'InvestorKYCDocumentUpdate',
  InvestorLogin = 'InvestorLogin',
  InvestorLoginSso = 'InvestorLoginSSO',
  InvestorNewAddressApproved = 'InvestorNewAddressApproved',
  InvestorNewAddressDenied = 'InvestorNewAddressDenied',
  InvestorNewAddressWhitelisted = 'InvestorNewAddressWhitelisted',
  InvestorPasswordReset = 'InvestorPasswordReset',
  InvestorPasswordResetByAdmin = 'InvestorPasswordResetByAdmin',
  InvestorSharesTokenover = 'InvestorSharesTokenover',
  MercuryTransferReceived = 'MercuryTransferReceived',
  NonBlockchainSharesCreated = 'NonBlockchainSharesCreated',
  NonBlockchainSharesRemoved = 'NonBlockchainSharesRemoved',
  NonBlockchainSharesTransferred = 'NonBlockchainSharesTransferred',
  PlatformAdminApiAction = 'PlatformAdminApiAction',
  ProjectAdminLogin = 'ProjectAdminLogin',
  SellBackAlertReceived = 'SellBackAlertReceived',
  SharePurchaseRequestReceived = 'SharePurchaseRequestReceived',
  SharePurchaseStatusUpdateApi = 'SharePurchaseStatusUpdateApi',
  SharesTransferred = 'SharesTransferred',
  SystemUserCreated = 'SystemUserCreated',
  SystemUserLoginAuthorization = 'SystemUserLoginAuthorization',
  SystemUserModified = 'SystemUserModified',
  TellAFriendEmail = 'TellAFriendEmail',
}

export type Log = {
  ID: Scalars['Int'];
  activityType: Scalars['Int'];
  admin?: Maybe<AdminUser>;
  description?: Maybe<Scalars['String']>;
  investorID?: Maybe<Scalars['Int']>;
  investors?: Maybe<Investor>;
  logDate?: Maybe<Scalars['Timestamp']>;
  stoID?: Maybe<Scalars['Int']>;
  userID?: Maybe<Scalars['Int']>;
};

/** Log details referring to the first time an owner has done the action */
export type LogDetails = {
  /** The timestamp at which they did this action */
  date: Scalars['Timestamp'];
  /** The investor ID of the owner that did this action */
  investorID: Scalars['Int'];
  /** The action performed */
  logAction: LogAction;
};

/** Log arguments */
export type LogFilterInput = {
  activity?: Maybe<LogAction>;
  fromDate?: Maybe<Scalars['Float']>;
  toDate?: Maybe<Scalars['Float']>;
};

/** All Types of Message */
export enum MessageType {
  File = 'File',
  Message = 'Message',
}

/** Type of meta key  */
export enum MetaKeyTypes {
  Category = 'CATEGORY',
  Chart = 'CHART',
  Checkbox = 'CHECKBOX',
  FilterCheckbox = 'FILTER_CHECKBOX',
  FilterDate = 'FILTER_DATE',
  FilterNumber = 'FILTER_NUMBER',
  FilterSelect = 'FILTER_SELECT',
  Richtext = 'RICHTEXT',
  Tabs = 'TABS',
  Text = 'TEXT',
}

export type Meeting = {
  ID: Scalars['Int'];
  closeDate?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<VotingDocuments>>;
  emailProxyPerson?: Maybe<Scalars['String']>;
  emailResponsiblePerson?: Maybe<Scalars['String']>;
  nameProxyPerson?: Maybe<Scalars['String']>;
  nameResponsiblePerson?: Maybe<Scalars['String']>;
  openDate?: Maybe<Scalars['DateTime']>;
  phoneProxyPerson?: Maybe<Scalars['String']>;
  phoneResponsiblePerson?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  stoID: Scalars['Int'];
  timePadding: Scalars['Int'];
  timezone: Scalars['String'];
  title: Scalars['String'];
  type: CorporateActionType;
  voteType: Scalars['Int'];
};

export type MercuryAccount = {
  accountNumber: Scalars['String'];
  address: MercuryAddress;
  electronicAccountType: Scalars['String'];
  routingNumber: Scalars['String'];
};

export type MercuryAddress = {
  address1: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  postalCode: Scalars['String'];
  region: Scalars['String'];
};

export enum MercuryConfigMode {
  AutoShareTransfer = 'AUTO_SHARE_TRANSFER',
  Disabled = 'DISABLED',
  GlobalBalance = 'GLOBAL_BALANCE',
  PerStoBalance = 'PER_STO_BALANCE',
}

export type MercuryInfo = {
  accountNumber: Scalars['String'];
  routingNumber: Scalars['String'];
};

export type MercuryRecipient = {
  electronicRoutingInfo: MercuryAccount;
  emails: Array<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  paymentMethod: Scalars['String'];
};

export type MetaKeys = {
  categoryID?: Maybe<Scalars['Int']>;
  editableByInvestor: Scalars['Boolean'];
  entityType: EntityType;
  key: Scalars['String'];
  order: Scalars['Int'];
  sellerEntityID?: Maybe<Scalars['Int']>;
  stoSpecific?: Maybe<Scalars['Boolean']>;
  translation: Scalars['String'];
  type: InvestorMetaKeysType;
  visibleForInvestor: Scalars['Boolean'];
};

/** New investor meta key data */
export type MetaKeysInput = {
  categoryID?: Maybe<Scalars['Int']>;
  editableByInvestor: Scalars['Boolean'];
  entityType: EntityType;
  key: Scalars['String'];
  order: Scalars['Float'];
  sellerEntityID?: Maybe<Scalars['Int']>;
  stoSpecific?: Maybe<Scalars['Boolean']>;
  /** used to create an english translation for this meta key */
  translation: Scalars['String'];
  type: InvestorMetaKeysType;
  visibleForInvestor: Scalars['Boolean'];
};

export type MetaStoRelation = {
  ID: Scalars['Float'];
  key: Scalars['String'];
  stoID: Scalars['Float'];
};

export type MetaStoRelationInput = {
  key: Scalars['String'];
  stoID: Scalars['Float'];
};

export type MetaValues = {
  display?: Maybe<Scalars['Boolean']>;
  displayClosedProp?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  order: Scalars['Float'];
  translation?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  value: Scalars['String'];
};

/** Meta values data for update */
export type MetaValuesInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type MetadataKeyInput = {
  areValuesMutuallyExclusive: Scalars['Boolean'];
  categoryKey: Scalars['String'];
  display: Scalars['Int'];
  displayClosedProp: Scalars['Int'];
  isManagedStoTableColumn: Scalars['Int'];
  key: Scalars['String'];
  order: Scalars['Int'];
  requireNDA: Scalars['Boolean'];
  /** used to create an english translation for this meta key */
  translation: Scalars['String'];
  type: Scalars['String'];
};

export type Metric = {
  metricTitle: Scalars['String'];
  subtotalValues: Array<MetricSubtotal>;
  total: Scalars['Int'];
  type: CardTypes;
};

export type MetricSubtotal = {
  sto: Sto;
  value: Scalars['Float'];
};

export type MigrationFile = {
  ID: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  newUrl: Scalars['String'];
  oldUrl: Scalars['String'];
};

export type MoonpayConfig = {
  /** Preferred highlight color for the widget as specified in the MoonPay documentation */
  colorCode?: Maybe<Scalars['String']>;
  /** Will price shares to this cryptocurrency. */
  defaultCurrency?: Maybe<Scalars['String']>;
  /** Can not be set manually. It's the ID of the platform currency automatically matching defaultCurrency. */
  defaultCurrencyID?: Maybe<Scalars['String']>;
  /** Value between 0-1. Will round down purchased shares whose fractional remainder is below this value. */
  doRoundDownOn?: Maybe<Scalars['Float']>;
  /** Value between 0-1. Will round up purchased shares whose fractional remainder is above this value. */
  doRoundUpOn?: Maybe<Scalars['Float']>;
  /** Enable/disable Moonpay as payment processor. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** ISO 639-1 standard language code. Without this, the language used is the user's browser default. */
  language?: Maybe<Scalars['String']>;
  /** Use live data. CAUTION */
  liveMode?: Maybe<Scalars['Boolean']>;
  /** The MoonPay base URL for live-data transactions */
  liveUrl?: Maybe<Scalars['String']>;
  /** As specified in the MoonPay documentation */
  lockAmount?: Maybe<Scalars['Boolean']>;
  /** Parameter from your Moonpay company account. */
  publishableKey?: Maybe<Scalars['String']>;
  /** MoonPay will redirect here on payment completion. */
  redirectUrl?: Maybe<Scalars['String']>;
  /** The MoonPay base URL for sandboxed transactions */
  sandboxUrl?: Maybe<Scalars['String']>;
  /** Parameter from your Moonpay company account. */
  secretKey?: Maybe<Scalars['String']>;
  /** Set up wallets for your company Moonpay crypto. Can set one global and/or multiple STO-specific wallets. The system will detect the STO and match it to a wallet when a payment is performed. */
  stoWallets?: Maybe<Array<MoonpayStoWallet>>;
  /** Parameter from your Moonpay company account. */
  webhookKey?: Maybe<Scalars['String']>;
};

export type MoonpayConfigInput = {
  /** Preferred highlight color for the widget as specified in the MoonPay documentation */
  colorCode?: Maybe<Scalars['String']>;
  /** Will price shares to this cryptocurrency. */
  defaultCurrency?: Maybe<Scalars['String']>;
  /** Can not be set manually. It's the ID of the platform currency automatically matching defaultCurrency. */
  defaultCurrencyID?: Maybe<Scalars['String']>;
  /** Value between 0-1. Will round down purchased shares whose fractional remainder is below this value. */
  doRoundDownOn?: Maybe<Scalars['Float']>;
  /** Value between 0-1. Will round up purchased shares whose fractional remainder is above this value. */
  doRoundUpOn?: Maybe<Scalars['Float']>;
  /** Enable/disable Moonpay as payment processor. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** ISO 639-1 standard language code. Without this, the language used is the user's browser default. */
  language?: Maybe<Scalars['String']>;
  /** Use live data. CAUTION */
  liveMode?: Maybe<Scalars['Boolean']>;
  /** The MoonPay base URL for live-data transactions */
  liveUrl?: Maybe<Scalars['String']>;
  /** As specified in the MoonPay documentation */
  lockAmount?: Maybe<Scalars['Boolean']>;
  /** Parameter from your Moonpay company account. */
  publishableKey?: Maybe<Scalars['String']>;
  /** MoonPay will redirect here on payment completion. */
  redirectUrl?: Maybe<Scalars['String']>;
  /** The MoonPay base URL for sandboxed transactions */
  sandboxUrl?: Maybe<Scalars['String']>;
  /** Parameter from your Moonpay company account. */
  secretKey?: Maybe<Scalars['String']>;
  /** Set up wallets for your company Moonpay crypto. Can set one global and/or multiple STO-specific wallets. The system will detect the STO and match it to a wallet when a payment is performed. */
  stoWallets?: Maybe<Array<MoonpayStoWalletInput>>;
  /** Parameter from your Moonpay company account. */
  webhookKey?: Maybe<Scalars['String']>;
};

export type MoonpayStoWallet = {
  /** We will use the wallet for this STO ID only, and prefer it over any default wallet. Set stoID to 0 to set as global default wallet, used in case the current STO (when the payment is happening) has no set wallet. */
  stoID: Scalars['Int'];
  /** Use this to configure a single wallet address */
  walletAddress: Scalars['String'];
  /** (optional) The secondary cryptocurrency wallet address identifier for coins such as EOS, XRP and XMR */
  walletAddressTag?: Maybe<Scalars['String']>;
};

export type MoonpayStoWalletInput = {
  /** We will use the wallet for this STO ID only, and prefer it over any default wallet. Set stoID to 0 to set as global default wallet, used in case the current STO (when the payment is happening) has no set wallet. */
  stoID: Scalars['Int'];
  /** Use this to configure a single wallet address */
  walletAddress: Scalars['String'];
  /** (optional) The secondary cryptocurrency wallet address identifier for coins such as EOS, XRP and XMR */
  walletAddressTag?: Maybe<Scalars['String']>;
};

export type MoonpayTransactionData = {
  ID: Scalars['Float'];
  dateUpdated: Scalars['DateTime'];
  investorID?: Maybe<Scalars['Int']>;
  localStatus: Scalars['String'];
  /** Data as we got it from MoonPay. */
  object?: Maybe<Scalars['JSON']>;
  objectType: Scalars['String'];
  referenceID?: Maybe<Scalars['Int']>;
  shareTypeID?: Maybe<Scalars['Int']>;
};

export type MoonpayTransactionJson = {
  /** Our own data for this transaction. Also contains `object` which == transactionJSON */
  localData: MoonpayTransactionData;
  /** JSON as gotten from MoonPay. If we have it locally. */
  transactionJSON?: Maybe<Scalars['JSON']>;
};

export type Mutation = {
  /** Mutation for Accepting Atomic Swap through Blockchain */
  acceptBlockchainSwap: Scalars['Boolean'];
  /** Mutation for accept atomic swap */
  acceptInternalSwap: Scalars['Boolean'];
  /** Upload a despoit for an investor(s). Will resolve or create resolved deposit alerts. Returns array of errors. By default, nothing is uploaded if there are errors, unless you set the option 'doNotStopOnError'. Most input fields are optional but you must provide an investor identitiy field for each record and a currency ammount. */
  addDeposits: InvestorDepositResult;
  /** Add new language model to translations */
  addLanguageModel: Scalars['Boolean'];
  /** @deprecated Use updateMetadataKey mutation */
  addMetadataKey: Scalars['Boolean'];
  /** Add new blockchain */
  addNewBlockchain: Scalars['Boolean'];
  /** Add a new key - and add translations, and possibly for all the custom locales */
  addNewKey: Scalars['Boolean'];
  /** Mutation for adding or editing an investor's bank details */
  addOrEditInvestorBankAccount: Scalars['Boolean'];
  addTopUpSourceId: Scalars['Boolean'];
  /** Create an investor + investor STO record. Returns the investor ID.      Provide STO ID if you want to copy the investor to that STO.      Provide sellSideAgentID if you want to copy the investor to all the STOs of the sell-side entity. */
  adminCreateInvestor: Scalars['Int'];
  /**
   * Creates a dividend with all investor dividends and one invoice per investor dividend.
   * If there is mismatch between dividend currency and channel currency, channel currency is used.
   */
  adminDividendCreate: DividendDistribution;
  adminDividendDelete: Scalars['Boolean'];
  adminDividendEdit: DividendDistribution;
  adminDividendRedistribute: Array<DividendInvestorPayout>;
  /** Set the contract address for samrt contract when the dividend campaing is onchain based. */
  adminDividendSetContractAddress: Scalars['Boolean'];
  adminDividendSetInvestorPaymentStatus: DividendInvestorPayout;
  adminDividendSetStatus: DividendDistribution;
  adminDividendTriggerAllPayments: DividendDistribution;
  /** Mutation for creating Investing Entities. Returns the entity ID */
  adminInvestingEntityCreate: Scalars['Int'];
  /** Mutation for creating or ensuring the existence of an entity's investor representative records in an STO. */
  adminInvestingEntityEnsureInvestorSto: Scalars['Boolean'];
  /**
   * Mutation for creating Investing Entity Member in a given entity ID.
   *
   * Adding a sellAgentID will make it visible only to that sellside plus the other sellsides which have added that member.
   */
  adminInvestingEntityMemberCreate: Scalars['Int'];
  /** Mutation for removing Members from Investing Entities */
  adminInvestingEntityMemberRemove: Scalars['Boolean'];
  /** Mutation for removing a Member's INVESTOR USER ONLY, without deleting the member. Returns the ID of the deleted investor or 0 if there was no user to delete. */
  adminInvestingEntityMemberUserRemove: Scalars['Int'];
  /** Mutation for removing Investing Entities, if this is enabled by the platform configuration */
  adminInvestingEntityRemove: Scalars['Boolean'];
  /**
   * Mutation for creating or updating an entity's STO. Also ensures entity-investor representative's STO relation.
   * @deprecated The logic was unified into adminToggleEntityOfferingOption
   */
  adminInvestingEntityStoUpdate: Scalars['Boolean'];
  /** Mutation for updating Investing Entities */
  adminInvestorInvestingEntityUpdate: Scalars['Boolean'];
  /** Send the member invitation */
  adminSendInvestingEntityMemberInvitation: Scalars['Boolean'];
  /** Mutation for Admin authorization */
  adminSignIn?: Maybe<Scalars['String']>;
  /** Mutation for toggle an entity's offering options */
  adminToggleEntityOfferingOption: Scalars['Boolean'];
  /** Updates existing offering */
  adminUpdateOffering: Scalars['Boolean'];
  /** Set or partially update SMTP settings of a given STO as admin */
  adminUpdateStoSmtp: Scalars['Boolean'];
  /** Mutation for affirming a Polymesh Exchange Swap by BUYER / SELLER */
  affirmPolymeshExchangeSwap: Scalars['Boolean'];
  /** Mutation for apply investor kyc profile */
  applyKyc: Scalars['Boolean'];
  approveMigratedFile: Scalars['Boolean'];
  /**
   * Upload investment history in a very specific way. Can upload investors/entities/investor-entities if they're missing.
   * IMPORTANT: It is strongly recommended you try setting options.dryRun = true first!!!
   */
  basicCsvBulkUploadInvestments: InvestorBasicCsvBulkUploadResults;
  /** Get investor is whitelisted or not status */
  checkInvestorPolymeshAddressWhitelisted: Scalars['Boolean'];
  /** Decrease an investor's available share balance and increase the company's shares in that type. */
  companyTransferShares: Scalars['Boolean'];
  /** Mutation for copying one investor from STO 0 to another via sto title and investor email (this will only copy the investor data and not their investments) */
  copyInvestorToStoTitle: Scalars['Boolean'];
  /** Mutation for copying investors from one project to another (this will only copy the investor data and not their investments) */
  copyInvestorsToOtherProjects: Scalars['Boolean'];
  createACHPayment: Scalars['Boolean'];
  /** Mutation for blockchain transaction transfer */
  createBlockchainTransactionTransfer: Scalars['Float'];
  /** Create an exchange buy order */
  createBuyOrder: Scalars['Boolean'];
  /** Mutation for document comment creation */
  createComment: Scalars['Int'];
  /** Create a new company update post */
  createCompanyNews: Scalars['Boolean'];
  createDiscount: Scalars['Boolean'];
  createDpayAccountAdmin: Scalars['Boolean'];
  createDpayAccountInvestor: Scalars['Boolean'];
  createDpayIdentity: Scalars['String'];
  /** Create a Forum Post */
  createForumPost: Scalars['Boolean'];
  /** Create a Forum Thread */
  createForumThread: Scalars['Boolean'];
  /** Mutation for creation an investor for market space */
  createInvestorMarketSpace: Scalars['Int'];
  /** Creates an investor invoice for the object described by "resolver" and "resolveID" */
  createInvoice_Investor: InvestorInvoices;
  /** Create a kyc file */
  createKycFile: Scalars['Boolean'];
  /** Create a new log activity */
  createLog: Scalars['Boolean'];
  /** This mutation creates metakey relations */
  createMetaStoRelation: Scalars['Boolean'];
  /** Create an exchange offer */
  createOffer: Scalars['Boolean'];
  /** Mutation for creating an investor without market space, but same data type. Optionally, copy the investor to this sell side agent's offerings. Updates membership invitation status if present. */
  createOrCopyInvestorForSellAgent: Scalars['Int'];
  /** Mutation for creating a new property file */
  createPropertyFile: Scalars['Boolean'];
  /** Create an exchange sell order */
  createSellOrder: Scalars['Boolean'];
  /** Creates a new share type. Will return the ID of the newly created share type */
  createShareType: Scalars['Int'];
  /** Mutation for updating some Smtp settings */
  createSmtpSettings: Scalars['Int'];
  /** creates a new STO - returns the ID of the newly created STO */
  createStoAdmin: Scalars['Int'];
  /**
   * Request funds to be added to the investor's internal wallet balance.
   *     Will issue an invoice with the provided amount.
   *     Returns the issued invoice
   */
  createTopUpAlert: InvestorInvoices;
  createTrigger_Admin: Scalars['Boolean'];
  /** Mutation for removing a component customization */
  customizedComponentsDelete: Scalars['Boolean'];
  /** Mutation for inserting a component customization */
  customizedComponentsInsert: Scalars['Boolean'];
  /** Mutation for updating a component customization */
  customizedComponentsUpdate: Scalars['Boolean'];
  /** Mutation for removing Chat History */
  deleteChatHistory: Scalars['Boolean'];
  /** Mutation for removing Chat History Permanently */
  deleteChatHistoryPermanently: Scalars['Boolean'];
  /** Mutation for removing Chat List */
  deleteChatList: Scalars['Boolean'];
  /** Mutation for removing Chat List Permanently */
  deleteChatListPermanently: Scalars['Boolean'];
  /** Mutation for document comment deletion */
  deleteComment: Scalars['Boolean'];
  /** Mutation for removing Customer Support's Chat History */
  deleteCustomerSupportChatHistory: Scalars['Boolean'];
  /** Mutation for removing Customer Support's Chat History Permanently */
  deleteCustomerSupportChatHistoryPermanently: Scalars['Boolean'];
  /** Mutation for removing Customer Support's Chat List */
  deleteCustomerSupportChatList: Scalars['Boolean'];
  /** Mutation for removing Customer Support's Chat List Permanently */
  deleteCustomerSupportChatListPermanently: Scalars['Boolean'];
  /** Delete the discount */
  deleteDiscount: Scalars['Boolean'];
  /** Mutation for deleting a link between an investor and the project. If the Project ID = 0, the investor will be completly delete from the platform. */
  deleteInvestorSto: Scalars['Boolean'];
  /** Delete meta key. */
  deleteMetaKey: Scalars['Boolean'];
  /** This mutation creates metakey relations */
  deleteMetaStoRelation: Scalars['Boolean'];
  /** Remove an exchange offer */
  deleteOffer: Scalars['Boolean'];
  /** Mutation for removing one single Message */
  deleteOneMessage: Scalars['Boolean'];
  /** Mutation for removing one single Message Permanently */
  deleteOneMessagePermanently: Scalars['Boolean'];
  /** Remove an exchange order */
  deleteOrder: Scalars['Boolean'];
  /** Mutation for deleting an uploaded file */
  deletePropertyFile: Scalars['Boolean'];
  deleteSellSideEntity: Scalars['Boolean'];
  /** Mutation for document deleteSharePurchaseRequest */
  deleteSharePurchaseRequest: Scalars['Boolean'];
  /** deletes an  STO this endpoint works only in testing platforms - returns true if successfully removed */
  deleteStoAdmin: Scalars['Int'];
  /** Unlink the trigger from the price changer */
  deleteTrigger_Admin: Scalars['Boolean'];
  dpayLogin: Scalars['Boolean'];
  /** Mutation for removing a Fee Commission */
  feeCommissionDelete: Scalars['Boolean'];
  /** Mutation for removing all Fee Commissions */
  feeCommissionDeleteAll: Scalars['Boolean'];
  /** Mutation for updating a Fee Commission */
  feeCommissionUpdate: Scalars['Boolean'];
  /** Mutation for creating a Fee */
  feeCreate: Scalars['Boolean'];
  /** Mutation for removing a Fee */
  feeDelete: Scalars['Boolean'];
  /** Mutation for removing all Fees */
  feeDeleteAll: Scalars['Boolean'];
  /** Mutation for updating a Fee */
  feeUpdate: Scalars['Boolean'];
  /** Mutation for deleting uploaded files */
  fileRemove: Scalars['Boolean'];
  /** Mutation for uploading investor files */
  fileUpload: FileUploaded;
  /** Mutation for update investor kyc profile */
  fillKyc: Scalars['Boolean'];
  /** Update the investor data with the one provided by the redirect URL from G-Cash */
  gCashUpdateInvestorData: Scalars['Boolean'];
  /** Mutation for Investor authorization */
  generateAPIToken: Scalars['String'];
  /** Get a signed url and fileID for file uploading */
  getUploadUrl: UploadFileUrl;
  /** Mutation for initiating a Polymesh Exchange Swap from BUYER / SELLER */
  initiatePolymeshExchangeSwap: Scalars['Boolean'];
  /**
   * Start the purchase process in Polymesh. This will create a blockchain transaction which the investor must sign. The transaction contains 2 steps, paying for the tokens and affirming the receive of the purchase tokens.
   *       NOTE: used only for the crypto based payments. FIAT payments have a different flow
   */
  initiatePolymeshTokenPurchaseTransaction: InitiatePurchaseResult;
  /** Insert new investor document record */
  insertInvestorDocument: Scalars['Int'];
  insertSellSideEntity: Scalars['Boolean'];
  /** Mutation for removing a custom entity's return percentage. */
  investingEntityReturnsRemove: Scalars['Boolean'];
  /** Mutation for creating or updating a custom return percentage for an entity. */
  investingEntityReturnsUpsert: Scalars['Boolean'];
  /** Mutation for Investor 2FA confirmation */
  investor2FAConfirm: Scalars['String'];
  /** Mutation for update beneficial */
  investorBeneficialUpdate: Scalars['Boolean'];
  /** Create an investors buy alert */
  investorBuyAlert: Scalars['Int'];
  /** Create an investors buy alert as admin or API */
  investorBuyAlertAdmin: Scalars['Int'];
  /** Approves and initiates a share transfer as if invoked from the administration panel */
  investorBuyAlertApprove: Scalars['Boolean'];
  /** Mutation for declining Investor Share transaction as admin or API */
  investorBuyAlertDeclineAdmin: Scalars['Boolean'];
  /** Mutation for hiding Investor Share Purchase Alert when no longer needed */
  investorBuyAlertHide: Scalars['Boolean'];
  /** Create an investors buy alert for market space */
  investorBuyAlertMarketSpace: Scalars['Int'];
  /** Mutation for removing/hiding Investor Share Purchase Alert */
  investorBuyAlertRemove: Scalars['Boolean'];
  /** Mutation for removing Investor Share Purchase Alert as admin or API */
  investorBuyAlertRemoveAdmin: Scalars['Boolean'];
  /** Mutation for setting Investor Share Purchase Alert status (NOTE: Only updates status without any other actions) */
  investorBuyAlertSetStatus: Scalars['Boolean'];
  /** Mutation for update nda signed */
  investorBuyPropertyUpdate: Scalars['Boolean'];
  /** Mutation for change password */
  investorChangePassword: Scalars['Boolean'];
  /** Create an investors deposit or withdraw alert */
  investorDepositWithdrawAlert: Scalars['Boolean'];
  /** Mutation for create inbox */
  investorInboxCreate: Scalars['Int'];
  /** Mutation for creating Investing Entities */
  investorInvestingEntityCreate: Scalars['Boolean'];
  /** Mutation for creating Investing Entity member */
  investorInvestingEntityMemberCreate: Scalars['Boolean'];
  /** Mutation for removing Investing Entity members */
  investorInvestingEntityMemberRemove: Scalars['Boolean'];
  /** Mutation for updating Investing Entity members */
  investorInvestingEntityMemberUpdate: Scalars['Boolean'];
  /** Mutation for updating Investing Entity meta values */
  investorInvestingEntityMetaUpdate: Scalars['Boolean'];
  /** Mutation for removing Investing Entities */
  investorInvestingEntityRemove: Scalars['Boolean'];
  /** Mutation for updating Investing Entities */
  investorInvestingEntityUpdate: Scalars['Boolean'];
  /** Delete an investor invoice alert */
  investorInvoiceAlertDelete: Scalars['Boolean'];
  /** Mutation for updating investors meta values */
  investorMetaValuesUpdate: Scalars['Boolean'];
  /** Mutation for update nda signed */
  investorNDASigned: Scalars['Boolean'];
  /** Mutation for removing a custom investors' preferred return. */
  investorPreferredReturnsRemove: Scalars['Boolean'];
  investorPreferredReturnsUpsert: Scalars['Boolean'];
  /** Mutation for update investor profile */
  investorProfile: Scalars['Boolean'];
  /** Mutation for add public key */
  investorPublicKeyAdd: Scalars['Boolean'];
  /** Mutation for delete public key */
  investorPublicKeyDelete: Scalars['Boolean'];
  /** Mutation for register investor vote */
  investorRegisterVote: Scalars['Boolean'];
  /** Mutation for reset password */
  investorReset: Scalars['Boolean'];
  /** Create an investors sell alert and invoice, returns the invoice ID */
  investorSellAlert: Scalars['Int'];
  /** Mutation for set new password */
  investorSetPassword: Scalars['Boolean'];
  /** Mutation for toggle 2FA authorization */
  investorToggleTwoFA: Scalars['Boolean'];
  /** Increase an investor's share balance and decrease the company's available shares in that type. */
  investorTransferShares: Scalars['Boolean'];
  /** Mutation for update usufructuary */
  investorUsufructuaryUpdate: Scalars['Boolean'];
  /** Mutation for verify account */
  investorVerify: Scalars['Int'];
  /** Mutation for linking the property file to share types */
  linkShareTypesToPropertyFile: Scalars['Boolean'];
  /**
   * Makes an entity **MEMBER** visible to every sell side on the platform by clearing its visibility whitelist.
   *
   * - The visibility works on a whitelist basis which contains sell side IDs.
   *
   * - - When the whitelist is empty, it's not visible to any sellsides.
   *
   *   - When the whitelist is unset, it's visible to all sellsides.
   *
   *   **IMPORTANT NOTE:** If you use this function, regardless of 'doMakeInvisible', you will clear the whitelist data!
   *     You can not undo this function, unless by manually toggling visibility.
   */
  makeInvestingEntityMemberVisibileToAllSellSides: Scalars['Boolean'];
  /** Mutation for creating a message in the chat */
  messageCreate: Scalars['Int'];
  /** Mutation for updating a message */
  messageUpdate: Scalars['Boolean'];
  migrateFiles: Scalars['Boolean'];
  /** Registers a payment sent from MoonPay, after the investor has initiated it. Both admin and investor role possible. */
  moonpayAddTransactionDefault: Scalars['Boolean'];
  /** Modify the platform configuration for Moonpay. Returns a status message on success. */
  moonpayConfigUpdate: Scalars['String'];
  /** Initiates the payment of an invoice using external payment providers. Returns a checkout url where the payment will be made */
  payInvoice: Scalars['String'];
  /** attempts to fulfill the exchange offer with an internal wallet balance payments */
  payOfferWithInternalWalletBalance: Scalars['Boolean'];
  /** Pays a single dividend investor payout. Returns true if the dividend is immediately resolved. */
  paySingleInvestorDividend: DividendInvestorPayout;
  pint_action_beginTransaction_Investor?: Maybe<DpayTransaction>;
  pint_action_uploadBuySideAccount: Scalars['Boolean'];
  pint_beginFlow_Investor: Scalars['JSON'];
  pint_executeAction_Investor: Scalars['JSON'];
  pint_executeAction_Platform: Scalars['JSON'];
  pint_executeAction_Sto0: Scalars['JSON'];
  pint_getSynchronizedAccount_Investor: Scalars['JSON'];
  pint_initWidget_Investor: Scalars['String'];
  pint_updateSingleConfig: Scalars['Boolean'];
  /** @deprecated Use temporarily while WIP. To be removed. Reason: we want at least some input validation, not just any JSON. */
  pint_updateSingleConfigJSON: Scalars['Boolean'];
  /** Mutation for performing a Polymesh Swap */
  polymeshSwap: Scalars['Boolean'];
  /**
   * Gets the balance from the external payment provider and updates our DB with it. The updated balance is returned.
   *       If the processor doesn't support balances, null is returned
   */
  refreshInvestorBalance?: Maybe<Scalars['Float']>;
  /**
   * This mutation triggers a refresh process for the logged in investor. It will re-fetch the data from SumSub servers.
   * Mainly used in order to manually fetch the investor status, in case the webhook never reaches the api,
   * yet the UI element reports the user has been verified
   */
  refreshInvestorData: Scalars['Boolean'];
  /** Reject an unresolved invoice alert by an admin */
  rejectInvoiceAlert: Scalars['Boolean'];
  rejectMigratedFile: Scalars['Boolean'];
  /** Delete investor document record */
  removeInvestorDocument: Scalars['Boolean'];
  /** Removes the kyc file */
  removeKycFile: Scalars['Boolean'];
  /** Removes translations where it matches with locale argument */
  removeLanguage: Scalars['Boolean'];
  removeMetadataKey: Scalars['Boolean'];
  /** Resend the account verification email */
  resendVerificationEmail?: Maybe<Scalars['Boolean']>;
  /** Mutation for change password of an Investor as an Admin */
  resetInvestorPassword: Scalars['Boolean'];
  /**
   * Resets information that populates when interacting with the sell side entity.
   *
   * Examples include:
   *
   * - invited personnel list
   *
   * - 'interested' and other interaction statuses
   *
   * - activity logs
   *
   * - chat history
   */
  resetSellSideEntityUsage: Scalars['Boolean'];
  /** Resolve an unresolved invoice alert by an admin */
  resolveInvoiceAlert: Scalars['Boolean'];
  /** Confirmation that file has been uploaded */
  saveUploadedFile: Scalars['String'];
  /** Mutation for document sendContract */
  sendContract: Scalars['Boolean'];
  /** Mutation for sending an Email Notification to a group of Investors */
  sendEmailNotification: Scalars['Boolean'];
  /** Mutation for sending invitation link to friends */
  sendLinkToFriends: Scalars['Boolean'];
  sendMercuryInstructionalEmail: Scalars['Boolean'];
  /** Mutation for sending tokens to investor */
  sendPolymeshSwapTokensToInvestorAddress: Scalars['Boolean'];
  /** Mutation for document sendSharePurchaseContract */
  sendSharePurchaseContract: Scalars['Boolean'];
  /** Send a test email to any email address */
  sendTestEmail: Scalars['Boolean'];
  /** Mutation for investing memebrs when they first acces the data room. */
  setAccessedDateForDataRoom: Scalars['Boolean'];
  /** Set admin theme config */
  setAdminThemeConfig: Scalars['Boolean'];
  /** Set DocuSign signature for document. Similar to setSharePurchaseDocumentSignature. */
  setDocuSignSignature: Scalars['Boolean'];
  /** Mutation for updating Investing Entities Accreditation status */
  setEntityAccreditation: Scalars['Boolean'];
  /** Change Investor Params */
  setInvestorParam: Scalars['Int'];
  setMercuryRecipient: Scalars['Boolean'];
  /** Change Platform Params */
  setPlatformParam: Scalars['Int'];
  /**
   * Enable or disable buying at Project
   * @deprecated Use unified mutation adminUpdateOffering
   */
  setProjectBuying: Scalars['Boolean'];
  /** Enable or disable PDF presentation display for the project */
  setProjectPDFPresentation: Scalars['Boolean'];
  /** Enable or disable NDA flow for the project */
  setSTONDAFlow: Scalars['Boolean'];
  /**
   * Enable or disable an STO
   * @deprecated Use unified mutation adminUpdateOffering
   */
  setSTOStatus: Scalars['Boolean'];
  /** Mutation for document setSharePurchaseDocumentSignature */
  setSharePurchaseDocumentSignature: Scalars['String'];
  /** Mutation for document setSignature */
  setSignature: Scalars['String'];
  /** Mutation for setting status of Accreditation entity on sto admin */
  setStatusOfAccreditationOnStoAdmin: Scalars['Boolean'];
  /** Mutation for document setSubmittedDocument */
  setSubmittedDocument: Scalars['Boolean'];
  /** Mutation for document setSubmittedSharePurchaseDocument */
  setSubmittedSharePurchaseDocument: Scalars['Boolean'];
  /** Set a theme config */
  setThemeConfig: Scalars['Boolean'];
  /** Mutation for Investor authorization */
  signIn: Scalars['String'];
  /** Mutation for Investor SSO authorization */
  signInSSO: Scalars['String'];
  /** Mutation for Investor register */
  signUp: Scalars['Int'];
  /** Mutation for sign up investor for market space */
  signUpMarketSpace: Scalars['Int'];
  /**
   * Soft deletes a dividend tempalte making it inactive and setting its status to historical.
   *     Removes any future payouts and investor payouts.
   */
  softDeleteDividendTemplate: Scalars['Boolean'];
  /** Mutation for starting atomic swap */
  startSwap: Scalars['Boolean'];
  syncMercuryTransactions: Scalars['Boolean'];
  /**
   * Makes an entity **MEMBER** visible or invisible to the sell side entity specified.
   *
   * - This affects only the single-entity detailed view but **not** listings for registered/unregistered personnel
   *     (that is decoupled and tracked by the invitation system and the investor system).
   *
   * - The visibility works on a whitelist basis which contains sell side IDs.
   *
   *   - When the whitelist is empty, it's not visible to any sellsides.
   *
   *   - When the whitelist is unset, it's visible to all sellsides.
   */
  toggleInvestingEntityMemberVisibilityForSellSideEntity: Scalars['Boolean'];
  /** Makes an entity visible to the sell side agents related to this project */
  toggleInvestingEntityVisibilityForProject: Scalars['Boolean'];
  /**
   * Makes an entity visible or invisible to the sell side entity specified.
   *
   * - Currently we don't support the scenario for toggling OFF sellSide visibility of an entity that is marked as globally visible.
   *
   * - You would have to run this mutation for each sellSideEntityID.
   */
  toggleInvestingEntityVisibilityForSellSideEntity: Scalars['Boolean'];
  topUpInvestorBalance: Scalars['Boolean'];
  /** Transfer shares between two entities. */
  transferSharesBetween: Scalars['Boolean'];
  /** Mutation for unlinking an uploaded file from a share type */
  unlinkPropertyFileFromShareType: Scalars['Boolean'];
  /** Add new blockchain */
  updateBlockchain: Scalars['Boolean'];
  /** Mutation for updating Chats to Seen status */
  updateChatsToSeen: Scalars['Boolean'];
  /** Mutation for document comment updation */
  updateComment: Scalars['Boolean'];
  /** Checks all blockchainSharesTransferTransactions state on their respective blockchain and updates the status field to completed if necessary. */
  updateCompletedBCSTTxsForSto: Scalars['Boolean'];
  updateDiscount: Scalars['Boolean'];
  updateDpayAuthCredentials: Scalars['Boolean'];
  /**
   * Mutation for updating an entity's interest in a project
   * @deprecated The logic was unified into adminToggleEntityOfferingOption
   */
  updateEntityBuySto: Scalars['Boolean'];
  /**
   * Mutation for updating an entity's invited in a project
   * @deprecated The logic was unified into adminToggleEntityOfferingOption
   */
  updateEntityInvited: Scalars['Boolean'];
  /**
   * Mutation for updating an entity's NDA signing status
   * @deprecated The logic was unified into adminToggleEntityOfferingOption
   */
  updateEntitySignedNDA: Scalars['Boolean'];
  /** Updates the investor document title */
  updateInvestorDocument: Scalars['Boolean'];
  /** Mutation for updating Investor's KYC data */
  updateInvestorKyc: Scalars['Boolean'];
  /** Updating investor balance in DB */
  updateInvestorPolymeshBalance: Scalars['Boolean'];
  /** Update an invoice alert status */
  updateInvoiceStatus_Admin: Scalars['Boolean'];
  /** Updates an investor's invoice with the paymentChannelID needed to solve the invoice (mostly used for handling manual deposits) */
  updateInvoice_Investor: Scalars['Boolean'];
  /** This mutation updates a metakey value or creates a new one if it does not exist */
  updateMetaKey: Scalars['Boolean'];
  updateMetadataKey: Scalars['Boolean'];
  /** Mutation updates the meta value or creates if the meta value does not exist */
  updateMetadataValue: Scalars['Boolean'];
  /** update exchange order status */
  updateOrderStatus: Scalars['Boolean'];
  /** Mutation for updating Chats to Seen status in Price Negotiation */
  updatePriceNegotiationChatsToSeen: Scalars['Boolean'];
  /** Mutation for updating uploaded files */
  updatePropertyFile: Scalars['Boolean'];
  /** Update an exchange sell order */
  updateSellOrder: Scalars['Boolean'];
  updateSellSideEntity: Scalars['Boolean'];
  /** Mutation for updating sell side entity meta values */
  updateSellSideEntityMeta: Scalars['Boolean'];
  /** Update a share type, undefined values will be skipped. Will return the ID of the newly created share type */
  updateShareType: Scalars['Int'];
  /** Mutation for updating some Smtp settings */
  updateSmtpSettings: Scalars['Boolean'];
  updateTopUpAlert: Scalars['Int'];
  /** Update an exsisiting translation - possbily update all the translations with the same key */
  updateTranslation: Scalars['Boolean'];
  /** Unlink the trigger from the price changer */
  updateTrigger_Admin: Scalars['Boolean'];
  /** Updates or creates display params for the deposit alert screen used by this payment channel. Returns the ID of the modified entity */
  upsertDepositAlertDisplayParams: Scalars['Int'];
  /**
   * updates or creates a new payment channel. Returns the ID of channel. This ensures that no incompatible combinations can be accidentally selected.
   *     E.g. changing the payment channel type, will modify any incompatible settings to the ones defined in the PaymentChannelSpecs
   *     If no defaults can be selected, will throw an error with the troublesome field
   */
  upsertPaymentChannel: PaymentChannelUpsertResponse;
  /** Check Blockchain Transaction with hash */
  verifyTransactionFromBlockchain?: Maybe<Scalars['String']>;
  /** Mutation for whitelisting an investors wallet */
  whitelistWallet: Scalars['Boolean'];
};

export type MutationAcceptBlockchainSwapArgs = {
  offerID: Scalars['Int'];
  walletAddress: Scalars['String'];
};

export type MutationAcceptInternalSwapArgs = {
  offerID: Scalars['Int'];
};

export type MutationAddDepositsArgs = {
  deposits: Array<InvestorDepositInput>;
  options?: Maybe<InvestorDepositInputOptions>;
};

export type MutationAddLanguageModelArgs = {
  locale: Scalars['String'];
};

export type MutationAddMetadataKeyArgs = {
  key: Scalars['String'];
};

export type MutationAddNewBlockchainArgs = {
  title: Scalars['String'];
};

export type MutationAddNewKeyArgs = {
  data: Array<TranslationInput>;
};

export type MutationAddOrEditInvestorBankAccountArgs = {
  data: InvestorBankAccountInput;
};

export type MutationAddTopUpSourceIdArgs = {
  topUpSourceId: Scalars['String'];
};

export type MutationAdminCreateInvestorArgs = {
  investor: InvestorMarketSpaceInput;
  sellSideAgentID?: Maybe<Scalars['Int']>;
};

export type MutationAdminDividendCreateArgs = {
  data: DividendDistributionInput;
};

export type MutationAdminDividendDeleteArgs = {
  dividendID: Scalars['Float'];
};

export type MutationAdminDividendEditArgs = {
  data: DividendDistributionEditInput;
};

export type MutationAdminDividendRedistributeArgs = {
  dividendID: Scalars['Float'];
};

export type MutationAdminDividendSetContractAddressArgs = {
  contractAddress: Scalars['String'];
  dividendID: Scalars['Int'];
};

export type MutationAdminDividendSetInvestorPaymentStatusArgs = {
  investorPayoutID: Scalars['Float'];
  status: Scalars['String'];
};

export type MutationAdminDividendSetStatusArgs = {
  dividendID: Scalars['Float'];
  status: Scalars['String'];
};

export type MutationAdminDividendTriggerAllPaymentsArgs = {
  dividendID: Scalars['Int'];
};

export type MutationAdminInvestingEntityCreateArgs = {
  data: InvestingEntityInput;
  options?: Maybe<InvestingEntityCreationOptions>;
};

export type MutationAdminInvestingEntityEnsureInvestorStoArgs = {
  entityID: Scalars['Int'];
  stoID: Scalars['Int'];
};

export type MutationAdminInvestingEntityMemberCreateArgs = {
  data: InvestingEntityMemberInput;
  doNotEmail?: Maybe<Scalars['Boolean']>;
  doNotMarkInvited?: Maybe<Scalars['Boolean']>;
  doNotMarkRegistered?: Maybe<Scalars['Boolean']>;
  investorID?: Maybe<Scalars['Int']>;
  sellAgentID?: Maybe<Scalars['Int']>;
  stoID?: Maybe<Scalars['Int']>;
};

export type MutationAdminInvestingEntityMemberRemoveArgs = {
  memberID: Scalars['Int'];
  removeUser?: Maybe<Scalars['Boolean']>;
};

export type MutationAdminInvestingEntityMemberUserRemoveArgs = {
  memberID: Scalars['Int'];
};

export type MutationAdminInvestingEntityRemoveArgs = {
  entityID: Scalars['Int'];
  removeOwner: Scalars['Boolean'];
  removeShares: Scalars['Boolean'];
};

export type MutationAdminInvestingEntityStoUpdateArgs = {
  data: InvestingEntityStoManualInputs;
  entityID: Scalars['Int'];
  stoID: Scalars['Int'];
};

export type MutationAdminInvestorInvestingEntityUpdateArgs = {
  data: InvestingEntityInput;
  entityID: Scalars['Int'];
};

export type MutationAdminSendInvestingEntityMemberInvitationArgs = {
  ID: Scalars['Int'];
  createInvitationInput?: Maybe<CreateInvitationInput>;
};

export type MutationAdminSignInArgs = {
  STO?: Maybe<Scalars['Int']>;
  password: Scalars['String'];
  platform?: Maybe<Scalars['Boolean']>;
  username: Scalars['String'];
};

export type MutationAdminToggleEntityOfferingOptionArgs = {
  entityID: Scalars['Int'];
  offeringID: Scalars['Int'];
  option: Scalars['String'];
};

export type MutationAdminUpdateOfferingArgs = {
  data: UpdateOfferingInput;
  offeringID: Scalars['Int'];
};

export type MutationAdminUpdateStoSmtpArgs = {
  smtpSettings: StoSmtpUpdateInput;
};

export type MutationAffirmPolymeshExchangeSwapArgs = {
  orderID: Scalars['Int'];
  secretKey: Scalars['String'];
};

export type MutationApplyKycArgs = {
  applied: Scalars['Boolean'];
};

export type MutationApproveMigratedFileArgs = {
  ID: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  type: StorageType;
};

export type MutationBasicCsvBulkUploadInvestmentsArgs = {
  columns: Array<Scalars['String']>;
  csv: Array<Scalars['String']>;
  options?: Maybe<InvestorBasicCsvBulkUploadOpts>;
};

export type MutationCheckInvestorPolymeshAddressWhitelistedArgs = {
  investorAddress: Scalars['String'];
  shareTypeRecordID: Scalars['Int'];
};

export type MutationCompanyTransferSharesArgs = {
  data: TransferShareInput;
};

export type MutationCopyInvestorToStoTitleArgs = {
  email: Scalars['String'];
  offeringTitle: Scalars['String'];
};

export type MutationCopyInvestorsToOtherProjectsArgs = {
  copyStoID: Scalars['Int'];
  investorIDs: Array<Scalars['Int']>;
  pasteStoID: Scalars['Int'];
};

export type MutationCreateAchPaymentArgs = {
  amount: Scalars['Float'];
  idempotencyKey: Scalars['String'];
  stoID: Scalars['Int'];
};

export type MutationCreateBlockchainTransactionTransferArgs = {
  data: BlockchainSharesTransferTransactionsInput;
};

export type MutationCreateBuyOrderArgs = {
  order: ExchangeBuyOrderInput;
};

export type MutationCreateCommentArgs = {
  documentID: Scalars['Int'];
  text: Scalars['String'];
};

export type MutationCreateCompanyNewsArgs = {
  companyNews: CompanyNewsCreateInput;
};

export type MutationCreateDiscountArgs = {
  discount: DiscountInput;
};

export type MutationCreateDpayAccountAdminArgs = {
  paymentProcessor: PaymentProcessor;
};

export type MutationCreateDpayAccountInvestorArgs = {
  paymentProcessor: PaymentProcessor;
};

export type MutationCreateDpayIdentityArgs = {
  paymentProcessor: PaymentProcessor;
};

export type MutationCreateForumPostArgs = {
  postData: ForumPostInput;
};

export type MutationCreateForumThreadArgs = {
  threadData: ForumThreadInput;
};

export type MutationCreateInvestorMarketSpaceArgs = {
  data: InvestorMarketSpaceInput;
};

export type MutationCreateInvoiceInvestorArgs = {
  data: InvestorInvoiceInput;
};

export type MutationCreateKycFileArgs = {
  kycFile: KycFileCreateInput;
};

export type MutationCreateLogArgs = {
  activityType: LogAction;
  investorID?: Maybe<Scalars['Int']>;
  stoID: Scalars['Int'];
};

export type MutationCreateMetaStoRelationArgs = {
  metaRelations: Array<MetaStoRelationInput>;
};

export type MutationCreateOfferArgs = {
  offer: ExchangeOfferInput;
};

export type MutationCreateOrCopyInvestorForSellAgentArgs = {
  data: InvestorMarketSpaceInput;
  sellAgentID?: Maybe<Scalars['Int']>;
};

export type MutationCreatePropertyFileArgs = {
  propertyFile: PropertyFileCreateInput;
};

export type MutationCreateSellOrderArgs = {
  order: ExchangeSellOrderInput;
};

export type MutationCreateShareTypeArgs = {
  data: CreateShareTypeInput;
};

export type MutationCreateSmtpSettingsArgs = {
  input: SmtpSettingsCreate;
};

export type MutationCreateStoAdminArgs = {
  data: StoCreateInput;
};

export type MutationCreateTopUpAlertArgs = {
  data: InternalWalletTopUpAlertInput;
};

export type MutationCreateTriggerAdminArgs = {
  trigger: PriceTriggerInput;
};

export type MutationCustomizedComponentsDeleteArgs = {
  componentID?: Maybe<Scalars['Int']>;
};

export type MutationCustomizedComponentsInsertArgs = {
  data: ComponentCustomizationInput;
};

export type MutationCustomizedComponentsUpdateArgs = {
  componentID: Scalars['Int'];
  data: ComponentCustomizationInput;
};

export type MutationDeleteChatHistoryArgs = {
  investorID: Scalars['Int'];
  stoID: Scalars['Int'];
};

export type MutationDeleteChatHistoryPermanentlyArgs = {
  investorID: Scalars['Int'];
  stoID: Scalars['Int'];
};

export type MutationDeleteChatListArgs = {
  stoID: Scalars['Int'];
};

export type MutationDeleteChatListPermanentlyArgs = {
  stoID: Scalars['Int'];
};

export type MutationDeleteCommentArgs = {
  commentID: Scalars['Int'];
};

export type MutationDeleteCustomerSupportChatHistoryArgs = {
  investorID: Scalars['Int'];
  stoID: Scalars['Int'];
};

export type MutationDeleteCustomerSupportChatHistoryPermanentlyArgs = {
  investorID: Scalars['Int'];
  stoID: Scalars['Int'];
};

export type MutationDeleteCustomerSupportChatListArgs = {
  stoID: Scalars['Int'];
};

export type MutationDeleteCustomerSupportChatListPermanentlyArgs = {
  stoID: Scalars['Int'];
};

export type MutationDeleteDiscountArgs = {
  ID: Scalars['Int'];
};

export type MutationDeleteInvestorStoArgs = {
  investorID: Scalars['Int'];
  stoID: Scalars['Int'];
};

export type MutationDeleteMetaKeyArgs = {
  key: Scalars['String'];
};

export type MutationDeleteMetaStoRelationArgs = {
  metaRelations: Array<MetaStoRelationInput>;
};

export type MutationDeleteOfferArgs = {
  orderID: Scalars['Int'];
};

export type MutationDeleteOneMessageArgs = {
  chatID: Scalars['Int'];
};

export type MutationDeleteOneMessagePermanentlyArgs = {
  chatID: Scalars['Int'];
};

export type MutationDeleteOrderArgs = {
  orderID: Scalars['Int'];
};

export type MutationDeletePropertyFileArgs = {
  documentID: Scalars['Int'];
};

export type MutationDeleteSellSideEntityArgs = {
  ID: Scalars['Int'];
};

export type MutationDeleteSharePurchaseRequestArgs = {
  documentID: Scalars['Int'];
};

export type MutationDeleteStoAdminArgs = {
  ID: Scalars['Int'];
};

export type MutationDeleteTriggerAdminArgs = {
  ID: Scalars['Int'];
};

export type MutationDpayLoginArgs = {
  paymentProcessor: PaymentProcessor;
};

export type MutationFeeCommissionDeleteArgs = {
  feeCommissionID: Scalars['Int'];
};

export type MutationFeeCommissionUpdateArgs = {
  data: FeeCommissionInput;
  feeCommissionID: Scalars['Int'];
};

export type MutationFeeCreateArgs = {
  data: FeeInput;
};

export type MutationFeeDeleteArgs = {
  feeID: Scalars['Int'];
};

export type MutationFeeUpdateArgs = {
  data: FeeInput;
  feeID: Scalars['Int'];
};

export type MutationFileRemoveArgs = {
  file: Scalars['String'];
};

export type MutationFileUploadArgs = {
  file: Scalars['Upload'];
};

export type MutationFillKycArgs = {
  data: Scalars['JSON'];
};

export type MutationGCashUpdateInvestorDataArgs = {
  gCashData: EncryptedDataInput;
};

export type MutationGetUploadUrlArgs = {
  filename: Scalars['String'];
  filetype: Scalars['String'];
  type: StorageType;
};

export type MutationInitiatePolymeshExchangeSwapArgs = {
  orderID: Scalars['Int'];
};

export type MutationInitiatePolymeshTokenPurchaseTransactionArgs = {
  input: InitiatePurchaseInput;
};

export type MutationInsertInvestorDocumentArgs = {
  investorDocument: InvestorDocumentInput;
};

export type MutationInsertSellSideEntityArgs = {
  sellSideEntity: SellSideEntityInput;
};

export type MutationInvestingEntityReturnsRemoveArgs = {
  returnRateID: Scalars['Int'];
};

export type MutationInvestingEntityReturnsUpsertArgs = {
  data: InvestingEntityReturnsInput;
  returnRateID?: Maybe<Scalars['Int']>;
};

export type MutationInvestor2FaConfirmArgs = {
  code: Scalars['Int'];
};

export type MutationInvestorBeneficialUpdateArgs = {
  data: InvestorBeneficialInput;
};

export type MutationInvestorBuyAlertArgs = {
  query: InvestorBuyAlertInput;
};

export type MutationInvestorBuyAlertAdminArgs = {
  data: InvestorBuyAlertInputAdmin;
  options?: Maybe<InvestorBuyAlertOptions>;
};

export type MutationInvestorBuyAlertApproveArgs = {
  alertID: Scalars['Int'];
};

export type MutationInvestorBuyAlertDeclineAdminArgs = {
  alertID: Scalars['Int'];
};

export type MutationInvestorBuyAlertHideArgs = {
  alertID: Scalars['Int'];
};

export type MutationInvestorBuyAlertMarketSpaceArgs = {
  data: InvestorBuyAlertInput;
};

export type MutationInvestorBuyAlertRemoveArgs = {
  alertID: Scalars['Int'];
};

export type MutationInvestorBuyAlertRemoveAdminArgs = {
  alertID: Scalars['Int'];
};

export type MutationInvestorBuyAlertSetStatusArgs = {
  alertID: Scalars['Int'];
  status: BuyAlertStatus;
};

export type MutationInvestorBuyPropertyUpdateArgs = {
  investorID?: Maybe<Scalars['Int']>;
  isEnabled: Scalars['Boolean'];
  stoID: Scalars['Int'];
};

export type MutationInvestorChangePasswordArgs = {
  data: ChangePasswordInput;
};

export type MutationInvestorDepositWithdrawAlertArgs = {
  data: InvestorDepositWithdrawAlertInput;
};

export type MutationInvestorInboxCreateArgs = {
  data: InboxInput;
};

export type MutationInvestorInvestingEntityCreateArgs = {
  data: InvestingEntityCreateInput;
  options?: Maybe<InvestingEntityCreationOptions>;
};

export type MutationInvestorInvestingEntityMemberCreateArgs = {
  data: InvestingEntityMemberInput;
};

export type MutationInvestorInvestingEntityMemberRemoveArgs = {
  memberID: Scalars['Int'];
};

export type MutationInvestorInvestingEntityMemberUpdateArgs = {
  data: InvestingEntityMemberInput;
  memberID: Scalars['Int'];
};

export type MutationInvestorInvestingEntityMetaUpdateArgs = {
  entityID: Scalars['Int'];
  values: Array<MetaValuesInput>;
};

export type MutationInvestorInvestingEntityRemoveArgs = {
  entityID: Scalars['Int'];
};

export type MutationInvestorInvestingEntityUpdateArgs = {
  data: InvestingEntityInput;
  entityID: Scalars['Int'];
};

export type MutationInvestorInvoiceAlertDeleteArgs = {
  ID: Scalars['Int'];
};

export type MutationInvestorMetaValuesUpdateArgs = {
  entityID?: Maybe<Scalars['Int']>;
  investorID?: Maybe<Scalars['Int']>;
  values: Array<MetaValuesInput>;
};

export type MutationInvestorNdaSignedArgs = {
  ID: Scalars['Int'];
  isNDASigned?: Maybe<Scalars['Boolean']>;
};

export type MutationInvestorPreferredReturnsRemoveArgs = {
  returnRateID: Scalars['Int'];
};

export type MutationInvestorPreferredReturnsUpsertArgs = {
  data: InvestorPreferredReturnInput;
  returnRateID?: Maybe<Scalars['Int']>;
};

export type MutationInvestorProfileArgs = {
  data: InvestorProfileInput;
};

export type MutationInvestorPublicKeyAddArgs = {
  blockchainID: Scalars['Int'];
  title: Scalars['String'];
};

export type MutationInvestorPublicKeyDeleteArgs = {
  keyID: Scalars['Int'];
};

export type MutationInvestorRegisterVoteArgs = {
  data: RegisterVoteInput;
};

export type MutationInvestorResetArgs = {
  email: Scalars['String'];
  stoID: Scalars['Int'];
};

export type MutationInvestorSellAlertArgs = {
  data: InvestorBuyAlertInput;
};

export type MutationInvestorSetPasswordArgs = {
  data: SetPasswordInput;
};

export type MutationInvestorTransferSharesArgs = {
  data: TransferShareInput;
};

export type MutationInvestorUsufructuaryUpdateArgs = {
  data: InvestorUsufructuaryInput;
};

export type MutationInvestorVerifyArgs = {
  secret: Scalars['String'];
};

export type MutationLinkShareTypesToPropertyFileArgs = {
  propertyFileID: Scalars['Int'];
  shareTypesID: Array<Scalars['Int']>;
};

export type MutationMakeInvestingEntityMemberVisibileToAllSellSidesArgs = {
  doMakeInvisible: Scalars['Boolean'];
  memberID: Scalars['Int'];
};

export type MutationMessageCreateArgs = {
  data: ChatInput;
};

export type MutationMessageUpdateArgs = {
  chatID: Scalars['Int'];
  data: ChatInput;
};

export type MutationMigrateFilesArgs = {
  type: StorageType;
};

export type MutationMoonpayAddTransactionDefaultArgs = {
  moonpayID: Scalars['String'];
  status: Scalars['String'];
};

export type MutationMoonpayConfigUpdateArgs = {
  config: MoonpayConfigInput;
};

export type MutationPayInvoiceArgs = {
  data?: Maybe<XenditPayInvoiceInput>;
  invoiceID: Scalars['Int'];
  isWithdrawal?: Maybe<Scalars['Boolean']>;
  redirectUrl?: Maybe<Scalars['String']>;
};

export type MutationPayOfferWithInternalWalletBalanceArgs = {
  offerID: Scalars['Int'];
};

export type MutationPaySingleInvestorDividendArgs = {
  investorPayoutID: Scalars['Int'];
};

export type MutationPintActionBeginTransactionInvestorArgs = {
  alertID?: Maybe<Scalars['Int']>;
  channelID?: Maybe<Scalars['Int']>;
  invoiceID?: Maybe<Scalars['Int']>;
  isWithdraw?: Maybe<Scalars['Boolean']>;
  processor: PintPreconfiguredProcessors;
};

export type MutationPintActionUploadBuySideAccountArgs = {
  condition?: Maybe<Scalars['String']>;
  processor: PintPreconfiguredProcessors;
};

export type MutationPintBeginFlowInvestorArgs = {
  customData?: Maybe<Scalars['JSON']>;
  flow: PintPreconfFlows;
  processor: Scalars['String'];
};

export type MutationPintExecuteActionInvestorArgs = {
  action: PintApiAction;
  customAction?: Maybe<Scalars['String']>;
  customData?: Maybe<Scalars['JSON']>;
  processor: Scalars['String'];
};

export type MutationPintExecuteActionPlatformArgs = {
  action: PintApiAction;
  customAction?: Maybe<Scalars['String']>;
  customData?: Maybe<Scalars['JSON']>;
  processor: Scalars['String'];
};

export type MutationPintExecuteActionSto0Args = {
  action: PintApiAction;
  customAction?: Maybe<Scalars['String']>;
  customData?: Maybe<Scalars['JSON']>;
  processor: Scalars['String'];
};

export type MutationPintGetSynchronizedAccountInvestorArgs = {
  processor: Scalars['String'];
};

export type MutationPintInitWidgetInvestorArgs = {
  input: PintWidgetInput;
  processor: PintPreconfiguredProcessors;
};

export type MutationPintUpdateSingleConfigArgs = {
  processor: PintConfigInput;
};

export type MutationPintUpdateSingleConfigJsonArgs = {
  processor: Scalars['String'];
};

export type MutationPolymeshSwapArgs = {
  recordID: Scalars['Int'];
  secretKey: Scalars['String'];
};

export type MutationRefreshInvestorBalanceArgs = {
  paymentProcessor?: Maybe<PaymentProcessor>;
};

export type MutationRejectInvoiceAlertArgs = {
  invoiceID: Scalars['Int'];
};

export type MutationRejectMigratedFileArgs = {
  ID: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  type: StorageType;
};

export type MutationRemoveInvestorDocumentArgs = {
  documentID: Scalars['Int'];
};

export type MutationRemoveKycFileArgs = {
  ID: Scalars['Int'];
};

export type MutationRemoveLanguageArgs = {
  locale: Scalars['String'];
};

export type MutationRemoveMetadataKeyArgs = {
  key: Scalars['String'];
};

export type MutationResendVerificationEmailArgs = {
  email: Scalars['String'];
};

export type MutationResetInvestorPasswordArgs = {
  input: AdminChangePasswordInput;
};

export type MutationResetSellSideEntityUsageArgs = {
  ID: Scalars['Int'];
};

export type MutationResolveInvoiceAlertArgs = {
  invoiceID: Scalars['Int'];
};

export type MutationSaveUploadedFileArgs = {
  fileID: Scalars['Int'];
  isPublic?: Maybe<Scalars['Boolean']>;
};

export type MutationSendContractArgs = {
  documentID: Scalars['Int'];
};

export type MutationSendEmailNotificationArgs = {
  investorIDs: Array<Scalars['Int']>;
  message: Scalars['String'];
  senderType: SenderType;
  stoID: Scalars['Int'];
};

export type MutationSendLinkToFriendsArgs = {
  data: ReferralInput;
};

export type MutationSendMercuryInstructionalEmailArgs = {
  accountNumber: Scalars['String'];
  note: Scalars['String'];
  routingNumber: Scalars['String'];
  stoID: Scalars['Int'];
};

export type MutationSendPolymeshSwapTokensToInvestorAddressArgs = {
  investorAddress: Scalars['String'];
  shareTypeRecordID: Scalars['Int'];
  shares: Scalars['Int'];
};

export type MutationSendSharePurchaseContractArgs = {
  documentID: Scalars['Int'];
  sharePurchaseID: Scalars['Int'];
};

export type MutationSendTestEmailArgs = {
  receiverEmail: Scalars['String'];
  smtpID: Scalars['Int'];
};

export type MutationSetAccessedDateForDataRoomArgs = {
  ID: Scalars['Int'];
  date: Scalars['DateTime'];
};

export type MutationSetAdminThemeConfigArgs = {
  theme: Scalars['String'];
};

export type MutationSetDocuSignSignatureArgs = {
  documentID: Scalars['Int'];
  docusignEnvelopeID: Scalars['String'];
  sharePurchaseID: Scalars['Int'];
};

export type MutationSetEntityAccreditationArgs = {
  entityID: Scalars['Int'];
  isAccredited: Scalars['Boolean'];
};

export type MutationSetInvestorParamArgs = {
  intValue?: Maybe<Scalars['Int']>;
  param: InvestorParam;
  stringValue?: Maybe<Scalars['String']>;
};

export type MutationSetMercuryRecipientArgs = {
  accountNumber: Scalars['String'];
  routingNumber: Scalars['String'];
};

export type MutationSetPlatformParamArgs = {
  intValue?: Maybe<Scalars['Int']>;
  param: Scalars['String'];
  stringValue?: Maybe<Scalars['String']>;
};

export type MutationSetProjectBuyingArgs = {
  isEnabled: Scalars['Boolean'];
  stoID: Scalars['Int'];
};

export type MutationSetProjectPdfPresentationArgs = {
  stoID: Scalars['Int'];
};

export type MutationSetStondaFlowArgs = {
  stoID: Scalars['Int'];
};

export type MutationSetStoStatusArgs = {
  isActive: Scalars['Boolean'];
  stoID: Scalars['Int'];
};

export type MutationSetSharePurchaseDocumentSignatureArgs = {
  base64: Scalars['String'];
  documentID: Scalars['Int'];
  investorID?: Maybe<Scalars['Int']>;
  sharePurchaseID: Scalars['Int'];
};

export type MutationSetSignatureArgs = {
  base64: Scalars['String'];
  documentID: Scalars['Int'];
};

export type MutationSetStatusOfAccreditationOnStoAdminArgs = {
  entityID: Scalars['Int'];
  status: AccreditationStatus;
};

export type MutationSetSubmittedDocumentArgs = {
  documentID: Scalars['Int'];
  fieldValues: Array<DocumentFieldValueDto>;
};

export type MutationSetSubmittedSharePurchaseDocumentArgs = {
  documentID: Scalars['Int'];
  fieldValues: Array<DocumentFieldValueDto>;
  sharePurchaseID: Scalars['Int'];
};

export type MutationSetThemeConfigArgs = {
  theme: Scalars['String'];
};

export type MutationSignInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  stoID: Scalars['Int'];
};

export type MutationSignInSsoArgs = {
  data: SignInSsoInput;
};

export type MutationSignUpArgs = {
  data: SignUpInput;
};

export type MutationSignUpMarketSpaceArgs = {
  data: SignUpMarketSpaceInput;
};

export type MutationSoftDeleteDividendTemplateArgs = {
  templateID: Scalars['Int'];
};

export type MutationStartSwapArgs = {
  offerID: Scalars['Int'];
};

export type MutationToggleInvestingEntityMemberVisibilityForSellSideEntityArgs = {
  memberID: Scalars['Int'];
  sellSideEntityID: Scalars['Int'];
  visible: Scalars['Boolean'];
};

export type MutationToggleInvestingEntityVisibilityForProjectArgs = {
  entityID: Scalars['Int'];
  stoID: Scalars['Int'];
  visible: Scalars['Boolean'];
};

export type MutationToggleInvestingEntityVisibilityForSellSideEntityArgs = {
  entityID: Scalars['Int'];
  sellSideEntityID: Scalars['Int'];
  visible: Scalars['Boolean'];
};

export type MutationTopUpInvestorBalanceArgs = {
  amount: Scalars['Float'];
};

export type MutationTransferSharesBetweenArgs = {
  alert: Scalars['Int'];
  data: TransferShareInput;
  from: TransferEntity;
  to: TransferEntity;
};

export type MutationUnlinkPropertyFileFromShareTypeArgs = {
  documentID: Scalars['Int'];
  shareTypeID: Scalars['Int'];
};

export type MutationUpdateBlockchainArgs = {
  ID: Scalars['Int'];
  title: Scalars['String'];
};

export type MutationUpdateChatsToSeenArgs = {
  sender: SenderType;
  stoID: Scalars['Int'];
};

export type MutationUpdateCommentArgs = {
  commentID: Scalars['Int'];
  text: Scalars['String'];
};

export type MutationUpdateCompletedBcstTxsForStoArgs = {
  stoID?: Maybe<Scalars['Int']>;
};

export type MutationUpdateDiscountArgs = {
  discount: DiscountUpdateInput;
};

export type MutationUpdateDpayAuthCredentialsArgs = {
  credentialsInput: DpayAuthCredentialsInput;
  paymentProcessor: PaymentProcessor;
};

export type MutationUpdateEntityBuyStoArgs = {
  entityID: Scalars['Int'];
  isEnabled: Scalars['Boolean'];
  stoID: Scalars['Int'];
};

export type MutationUpdateEntityInvitedArgs = {
  entityID: Scalars['Int'];
  isEnabled: Scalars['Boolean'];
  stoID: Scalars['Int'];
};

export type MutationUpdateEntitySignedNdaArgs = {
  entityID: Scalars['Int'];
  isEnabled: Scalars['Boolean'];
  stoID: Scalars['Int'];
};

export type MutationUpdateInvestorDocumentArgs = {
  documentID: Scalars['Int'];
  title: Scalars['String'];
};

export type MutationUpdateInvestorKycArgs = {
  kycData: InvestorKycInput;
};

export type MutationUpdateInvestorPolymeshBalanceArgs = {
  investorAddress: Scalars['String'];
  shareTypeRecordID: Scalars['Int'];
};

export type MutationUpdateInvoiceStatusAdminArgs = {
  ID: Scalars['Int'];
  status: InvoiceStatusType;
};

export type MutationUpdateInvoiceInvestorArgs = {
  invoiceID: Scalars['Int'];
  paymentChannelID: Scalars['Int'];
};

export type MutationUpdateMetaKeyArgs = {
  data: MetaKeysInput;
};

export type MutationUpdateMetadataKeyArgs = {
  data: MetadataKeyInput;
};

export type MutationUpdateMetadataValueArgs = {
  data: UpdateMetadataValueInput;
};

export type MutationUpdateOrderStatusArgs = {
  atomicSwapCurrentStatus: AtomicSwapStatus;
  orderID: Scalars['Int'];
};

export type MutationUpdatePriceNegotiationChatsToSeenArgs = {
  context: ChatContext;
  contextID: Scalars['Int'];
  counterpartID: Scalars['Int'];
};

export type MutationUpdatePropertyFileArgs = {
  propertyFile: PropertyFileUpdateInput;
};

export type MutationUpdateSellOrderArgs = {
  data: ExchangeUpdateOrderInput;
  orderID: Scalars['Int'];
};

export type MutationUpdateSellSideEntityArgs = {
  sellSideEntity: SellSideEntityInput;
};

export type MutationUpdateSellSideEntityMetaArgs = {
  entityID: Scalars['Int'];
  values: Array<MetaValuesInput>;
};

export type MutationUpdateShareTypeArgs = {
  data: UpdateShareTypeInput;
};

export type MutationUpdateSmtpSettingsArgs = {
  input: SmtpSettingsUpdate;
};

export type MutationUpdateTopUpAlertArgs = {
  data: InternalWalletTopUpAlertInput;
  topUpID: Scalars['Int'];
};

export type MutationUpdateTranslationArgs = {
  data: Array<TranslationInput>;
};

export type MutationUpdateTriggerAdminArgs = {
  trigger: PriceTriggerUpdateInput;
};

export type MutationUpsertDepositAlertDisplayParamsArgs = {
  data: DepositAlertDisplayParamsInput;
};

export type MutationUpsertPaymentChannelArgs = {
  data: PaymentChannelInput;
  paymentChannelID: Scalars['Int'];
};

export type MutationVerifyTransactionFromBlockchainArgs = {
  data: VerifyCryptoReciepeInput;
};

export type MutationWhitelistWalletArgs = {
  shareTypeID: Scalars['Int'];
  walletAddress: Scalars['String'];
};

/** Represents all the data an investor needs in order to sign up via netki */
export type NetkiSignUpData = {
  /** The access code required by the mobile app in order to start the KYC process */
  accessCode: Scalars['String'];
  /** HTML code that nicely represents where the user can get this the Netki App */
  mobileAppPanel: Scalars['String'];
};

export type NonKycInvestor = {
  ID?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  investorType?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updateDate?: Maybe<Scalars['String']>;
};

/** The Status of an Exchange Offer */
export enum OfferStatus {
  Accepted = 'ACCEPTED',
  AwaitingBuyerPayment = 'AWAITING_BUYER_PAYMENT',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Succeeded = 'SUCCEEDED',
}

export type OfferedDocument = {
  ID: Scalars['Int'];
  description: Scalars['String'];
  document?: Maybe<Document>;
  documentID: Scalars['Int'];
  from?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  to?: Maybe<Scalars['DateTime']>;
};

/** All Kinds of Payment Channel Types */
export enum PaymentChannelType {
  /** Using the internal wallet balances */
  Internal = 'Internal',
  /** Using any form of manual deposits. E.g. PayPal, ACH, Wire transfers */
  ManualDeposits = 'ManualDeposits',
  /** Using the provider Mercury. US only */
  Mercury = 'Mercury',
  /** Using the MetaMask crypto wallets */
  Metamask = 'Metamask',
  PintCustom = 'PINTCustom',
  /** Using the provider PayoutOne */
  PayoutOne = 'PayoutOne',
  /** Using the Polymesh crypto wallets */
  Polymesh = 'Polymesh',
  /** Using the provider Stripe */
  Stripe = 'Stripe',
  /** Using the provider Xendit */
  Xendit = 'Xendit',
}

/** Where the payment channel can be used */
export enum PaymentChannelUsage {
  /** to payout the dividends */
  DividendPayouts = 'DIVIDEND_PAYOUTS',
  /** to top-up internal wallet balances */
  InternalWalletTopUp = 'INTERNAL_WALLET_TOP_UP',
  /** to buy shares from the platform */
  SharePurchase = 'SHARE_PURCHASE',
  /** to sell shares back to the platform */
  ShareSellback = 'SHARE_SELLBACK',
  /**
   * to trade shares with other investors.
   *     Marking a payment channel with this means the provider behind the payment channel is an ESCROW service and must support P2P transactions
   */
  Trading = 'TRADING',
}

/** The Status of the Commission Fee Payment */
export enum PaymentStatus {
  Completed = 'Completed',
  Pending = 'Pending',
  Rejected = 'Rejected',
}

/** Between Who Polymesh Swapping Happens */
export enum PolymeshSwapBetween {
  InvestorAdmin = 'InvestorAdmin',
  InvestorInvestor = 'InvestorInvestor',
}

/** The Status of a Polymesh Swap Transaction */
export enum PolymeshSwapStatus {
  Completed = 'Completed',
  Failed = 'Failed',
  Pending = 'Pending',
}

/** All the possible data types of the params */
export enum ParamDataType {
  Json = 'JSON',
  Number = 'Number',
  String = 'String',
}

export type Params = {
  ID: Scalars['Int'];
  dataType?: Maybe<ParamDataType>;
  intValue?: Maybe<Scalars['Int']>;
  isGlobal?: Maybe<Scalars['Int']>;
  param: Scalars['String'];
  stringValue?: Maybe<Scalars['String']>;
};

export type PaymentChannel = {
  ID: Scalars['Int'];
  adminEmailBody?: Maybe<Scalars['String']>;
  adminEmailHeader?: Maybe<Scalars['String']>;
  canWithdrawFunds: Scalars['Boolean'];
  channelType: PaymentChannelType;
  conversionEnabled: Scalars['Boolean'];
  conversionRate: Scalars['String'];
  currency: Currency;
  currencyID: Scalars['Int'];
  currencyToConvert?: Maybe<Currency>;
  currencyToConvertID?: Maybe<Scalars['Int']>;
  depositAlertDisplayParams?: Maybe<DepositAlertDisplayParams>;
  depositInstructionEmailHeader?: Maybe<Scalars['String']>;
  depositInstructionText?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isAutomated: Scalars['Boolean'];
  pintProcessorTitle?: Maybe<Scalars['String']>;
  sendAdminEmail: Scalars['Boolean'];
  sendInstructionalDepositEmail: Scalars['Boolean'];
  stoID: Scalars['Int'];
  title: Scalars['String'];
  usages: Array<PaymentChannelUsage>;
};

export type PaymentChannelInput = {
  adminEmailBody?: Maybe<Scalars['String']>;
  adminEmailHeader?: Maybe<Scalars['String']>;
  canWithdrawFunds?: Maybe<Scalars['Boolean']>;
  channelType?: Maybe<PaymentChannelType>;
  conversionEnabled?: Maybe<Scalars['Boolean']>;
  conversionRate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['Int']>;
  currencyToConvertID?: Maybe<Scalars['Int']>;
  depositInstructionEmailHeader?: Maybe<Scalars['String']>;
  depositInstructionText?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAutomated?: Maybe<Scalars['Boolean']>;
  pintProcessorTitle?: Maybe<Scalars['String']>;
  sendAdminEmail?: Maybe<Scalars['Boolean']>;
  sendInstructionalDepositEmail?: Maybe<Scalars['Boolean']>;
  stoID?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  usages?: Maybe<Array<PaymentChannelUsage>>;
};

export type PaymentChannelSpecAlterations = {
  /** the field which had to be changed */
  fieldName: Scalars['String'];
  /** a string representation of the new value */
  newValue: Scalars['String'];
  /** the specification rule set */
  violatedSpecRule: PaymentChannelSpecs;
};

export type PaymentChannelSpecs = {
  /** if the channel can solve its own deposits / payment requests */
  canBeAutomated: Scalars['Boolean'];
  /** if the channel's payments can be solved manually by an admin */
  canBeManual: Scalars['Boolean'];
  /** if the channel can handle crypto currencies */
  canUseCryptoCurrencies: Scalars['Boolean'];
  /** if the channel can handle fiat currencies */
  canUseFIATCurrencies: Scalars['Boolean'];
  /** if the channel can be used to withdraw funds */
  canWithdrawFunds: Scalars['Boolean'];
  /** number equivalent of the type field */
  intValue: Scalars['Int'];
  /** the payment channel type described by this object */
  type: PaymentChannelType;
  /** allowed usages of this channel */
  usages: Array<PaymentChannelUsage>;
};

export type PaymentChannelUpsertResponse = {
  /** describes which fields had to be modified in order to fit the new specifications. Empty if nothing changed */
  compatibilityCheckAlterations: Array<PaymentChannelSpecAlterations>;
  /** ID of the freshly inserted payment channel. Or if updated, the ID of the updated channel */
  paymentChannelID: Scalars['Int'];
};

/** The payment processor e.g. Kladot, Dwolla */
export enum PaymentProcessor {
  Dwolla = 'Dwolla',
  Fortress = 'Fortress',
  Kladot = 'Kladot',
  Off = 'Off',
  PayoutOne = 'PayoutOne',
  Stripe = 'Stripe',
  Xendit = 'Xendit',
}

/** Data Structure Used To Draw Historical Performance Graph */
export type PerformanceGraphData = {
  data: Array<Scalars['Float']>;
  dates: Array<Scalars['String']>;
  shareType: ShareType;
  sharesSum: Array<Scalars['Float']>;
};

/** An action is a logical step in an API integration. Actions may be triggered depending on a pre-defined  flow, or by modules. Some modules may define their own actions. Some modules may have mandatory endpoints to be  defined for a particular action, like for example an authentication module would require an endpoint with the  "Authenticate" action set. */
export enum PintApiAction {
  AllActions = 'AllActions',
  Authenticate = 'Authenticate',
  BeginAdminToInvestorTransaction = 'BeginAdminToInvestorTransaction',
  BeginInvestorToAdminTransaction = 'BeginInvestorToAdminTransaction',
  BeginMassPayment = 'BeginMassPayment',
  BeginTransaction = 'BeginTransaction',
  CancelTransaction = 'CancelTransaction',
  CustomAction = 'CustomAction',
  ExcludeActions = 'ExcludeActions',
  FindBuySideAccounts = 'FindBuySideAccounts',
  GetAccount = 'GetAccount',
  GetBalance = 'GetBalance',
  GetMassPayments = 'GetMassPayments',
  GetPaymentSource = 'GetPaymentSource',
  GetPaymentSourceBalance = 'GetPaymentSourceBalance',
  GetTransaction = 'GetTransaction',
  GetWidget = 'GetWidget',
  ListMassPayments = 'ListMassPayments',
  ListPaymentSources = 'ListPaymentSources',
  ListTransactions = 'ListTransactions',
  UpdateMassPayment = 'UpdateMassPayment',
  UploadBuySideAccount = 'UploadBuySideAccount',
  UploadPaymentSource = 'UploadPaymentSource',
  VerifyMainAccount = 'VerifyMainAccount',
}

export type PintConfig = {
  configVersion: PintConfigVersion;
  debugMode?: Maybe<Scalars['Boolean']>;
  endpoints: Array<PintEndpoint>;
  flows?: Maybe<Array<PintFlow>>;
  isActive: Scalars['Boolean'];
  liveMode: Scalars['Boolean'];
  metakeysJSON?: Maybe<Scalars['String']>;
  modulesJSON?: Maybe<Scalars['String']>;
  sandboxUrl: Scalars['String'];
  supportsPlatformAccount?: Maybe<Scalars['Boolean']>;
  supportsSTO0Default?: Maybe<Scalars['Boolean']>;
  supportsSTOAccounts?: Maybe<Scalars['Boolean']>;
  supportsSubAccounts?: Maybe<Scalars['Boolean']>;
  syncAccounts?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  transactionType?: Maybe<PintTransactionMethod>;
  uiConfig: PintUiConfig;
  url: Scalars['String'];
  usesEntities: Scalars['Boolean'];
  /** Enforce internal wallet mode(s), i.e. platform config mode. Accepts an array in order to combine multiple modes. By default, any wallet mode is accepted. */
  walletMode?: Maybe<Array<InternalWalletMode>>;
};

export type PintConfigInput = {
  configVersion: PintConfigVersion;
  debugMode?: Maybe<Scalars['Boolean']>;
  endpoints?: Maybe<Array<PintEndpointInput>>;
  flows?: Maybe<Array<PintFlowInput>>;
  isActive: Scalars['Boolean'];
  liveMode?: Maybe<Scalars['Boolean']>;
  metakeysJSON?: Maybe<Scalars['String']>;
  modulesJSON?: Maybe<Scalars['String']>;
  sandboxUrl?: Maybe<Scalars['String']>;
  supportsPlatformAccount?: Maybe<Scalars['Boolean']>;
  supportsSTO0Default?: Maybe<Scalars['Boolean']>;
  supportsSTOAccounts?: Maybe<Scalars['Boolean']>;
  supportsSubAccounts?: Maybe<Scalars['Boolean']>;
  syncAccounts?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  transactionType?: Maybe<PintTransactionMethod>;
  uiConfig?: Maybe<PintUiConfigInput>;
  url?: Maybe<Scalars['String']>;
  usesEntities?: Maybe<Scalars['Boolean']>;
  /** Enforce internal wallet mode(s), i.e. platform config mode. Accepts an array in order to combine multiple modes. By default, any wallet mode is accepted. */
  walletMode?: Maybe<Array<InternalWalletMode>>;
};

export enum PintConfigVersion {
  V1 = 'v1',
  V1_1 = 'v1_1',
}

export type PintEndpoint = {
  action: PintApiAction;
  bodyJSON?: Maybe<Scalars['String']>;
  customAction?: Maybe<Scalars['String']>;
  doNotExecute?: Maybe<Scalars['String']>;
  method: Http;
  modifyBaseUrl?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  queryJSON?: Maybe<Scalars['String']>;
  throwHttpErrors?: Maybe<Scalars['String']>;
};

export type PintEndpointInput = {
  bodyJSON?: Maybe<Scalars['String']>;
  headersJSON?: Maybe<Scalars['String']>;
  queryJSON?: Maybe<Scalars['String']>;
};

export type PintEntityInput = {
  ID?: Maybe<Scalars['Int']>;
  type: DpayUserType;
};

export type PintFlow = {
  customFlow?: Maybe<Scalars['String']>;
  flow: PintPreconfFlows;
  steps: PintFlowStep;
};

export type PintFlowInput = {
  customFlow?: Maybe<Scalars['String']>;
  flow: PintPreconfFlows;
  steps: PintFlowStepInput;
};

export type PintFlowStep = {
  action: PintApiAction;
  addedContext?: Maybe<Scalars['JSON']>;
  completed?: Maybe<Scalars['Boolean']>;
  customAction?: Maybe<Scalars['String']>;
};

export type PintFlowStepInput = {
  action: PintApiAction;
  addedContext?: Maybe<Scalars['JSON']>;
  completed?: Maybe<Scalars['Boolean']>;
  customAction?: Maybe<Scalars['String']>;
};

/** Preconfigured flow for payment integrations to follow */
export enum PintPreconfFlows {
  AdminToInvestorTransaction = 'adminToInvestorTransaction',
  GenericTransaction = 'genericTransaction',
  InvestorToAdminTransaction = 'investorToAdminTransaction',
  PayInvestorDividend = 'payInvestorDividend',
  Purchase = 'purchase',
  Refund = 'refund',
  SearchAndSyncBuySideAccount = 'searchAndSyncBuySideAccount',
  SetUpAccount = 'setUpAccount',
  SyncBuySideAccount = 'syncBuySideAccount',
  UploadPaymentSource = 'uploadPaymentSource',
}

export type PintPreconfiguredCustomActions = {
  Dwolla: Array<DwollaCustomActions>;
};

/** Static processors that are integrated by us with PINT */
export enum PintPreconfiguredProcessors {
  Dwolla = 'Dwolla',
  Lemonway = 'Lemonway',
  NotFound = 'NotFound',
}

export type PintProcessorType = {
  asEnum?: Maybe<PintPreconfiguredProcessors>;
  title: Scalars['String'];
};

/** How to track that a new transaciton has occurred. */
export enum PintTransactionMethod {
  NotSpecified = 'NotSpecified',
  TrackTransactionId = 'TrackTransactionID',
}

export type PintUiConfig = {
  dpayEntity?: Maybe<Array<PintUiFieldConfig>>;
  platform?: Maybe<Array<PintUiFieldConfig>>;
};

export type PintUiConfigInput = {
  dpayEntity?: Maybe<Array<PintUiFieldConfigInput>>;
  platform?: Maybe<Array<PintUiFieldConfigInput>>;
};

export type PintUiFieldConfig = {
  askToConfirm?: Maybe<Scalars['Boolean']>;
  askToConfirmIcon?: Maybe<Scalars['String']>;
  askToConfirmText?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  ghostText?: Maybe<Scalars['String']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  required: Scalars['Boolean'];
  selectOptions?: Maybe<Array<Scalars['String']>>;
  targetProperty: Scalars['String'];
  targetType: PintVarType;
  type: InvestorMetaKeysType;
};

export type PintUiFieldConfigInput = {
  askToConfirm?: Maybe<Scalars['Boolean']>;
  askToConfirmIcon?: Maybe<Scalars['String']>;
  askToConfirmText?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  ghostText?: Maybe<Scalars['String']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  required: Scalars['Boolean'];
  selectOptions?: Maybe<Array<Scalars['String']>>;
  targetProperty: Scalars['String'];
  targetType: PintVarType;
  type: InvestorMetaKeysType;
};

export enum PintVarType {
  Boolean = 'Boolean',
  Json = 'JSON',
  Number = 'Number',
  String = 'String',
}

export type PintWidgetInput = {
  recipient?: Maybe<PintEntityInput>;
  specificWidget?: Maybe<PintWidgetType>;
};

/** Specifies a widget type for when the type of interaction is not the default one. */
export enum PintWidgetType {
  /** KYC widget for investor */
  BuySideKyc = 'BuySideKYC',
  Default = 'Default',
  /** KYC widget for admin or platform */
  SellSideKyc = 'SellSideKYC',
}

export type Poll = {
  notVoted: Scalars['Int'];
  totalInvestment: Scalars['Int'];
  totalInvestors: Scalars['Int'];
  totalShares: Scalars['Int'];
};

export type PolymeshSwapTransaction = {
  ID: Scalars['Int'];
  amount: Scalars['Float'];
  between: PolymeshSwapBetween;
  dateCreated: Scalars['Timestamp'];
  dateModified?: Maybe<Scalars['Timestamp']>;
  receiverID: Scalars['Int'];
  senderID: Scalars['Int'];
  shareTypeID: Scalars['Int'];
  status: PolymeshSwapStatus;
  swapAmount: Scalars['Float'];
  swapTokenTag: Scalars['String'];
  title: Scalars['String'];
  transactionID: Scalars['String'];
};

export type PriceNegotiationListItem = {
  counterpartFullName: Scalars['String'];
  counterpartID: Scalars['Int'];
  dateRead?: Maybe<Scalars['Timestamp']>;
  formattedDateSent: Scalars['String'];
  isRead?: Maybe<Scalars['Boolean']>;
  orderID: Scalars['Int'];
  orderOwnerID: Scalars['Int'];
};

export type PriceTransformerTrigger = {
  ID: Scalars['Int'];
  isAlwaysVisible: Scalars['Boolean'];
  lowerBound: Scalars['Float'];
  transformerID: Scalars['Int'];
  type: TransformerType;
  upperBound: Scalars['Float'];
};

export type PriceTriggerInput = {
  isAlwaysVisible: Scalars['Boolean'];
  lowerBound: Scalars['Float'];
  transformerID: Scalars['Int'];
  type: TransformerType;
  upperBound: Scalars['Float'];
};

export type PriceTriggerUpdateInput = {
  ID: Scalars['Int'];
  isAlwaysVisible: Scalars['Boolean'];
  lowerBound: Scalars['Float'];
  transformerID: Scalars['Int'];
  type: TransformerType;
  upperBound: Scalars['Float'];
};

export type PropertyFile = {
  ID: Scalars['Int'];
  /** @deprecated old field, used as a simple array. Use clearanceLevels instead */
  clearanceLevel: Array<ClearanceLevel>;
  clearanceLevels?: Maybe<Array<ClearanceLevel>>;
  countries: Array<Scalars['String']>;
  /** @deprecated use clearanceLevel field */
  parsedClearanceLevels: Array<ClearanceLevel>;
  shareTypes?: Maybe<Array<ShareType>>;
  stoID: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  type: FileType;
  url: Scalars['String'];
};

export type PropertyFileCreateInput = {
  cloudFileID?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  stoID: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  type: FileType;
};

export type PropertyFileQueryInput = {
  ID?: Maybe<Array<Scalars['Int']>>;
  clearanceLevel?: Maybe<Array<ClearanceLevel>>;
  shareTypeIDs?: Maybe<Array<Scalars['Int']>>;
  stoID?: Maybe<Array<Scalars['Int']>>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Array<FileType>>;
  url?: Maybe<Scalars['String']>;
};

export type PropertyFileUpdateInput = {
  ID: Scalars['Int'];
  clearanceLevel?: Maybe<Array<ClearanceLevel>>;
  countries?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

/** Scanner address for a particular blockchain protocol */
export type ProtocolTxScanner = {
  /** Url of the scanner for testing, e.g. test network scanner urls */
  devScannerUrl?: Maybe<Scalars['String']>;
  /** The ID of the blockchain protocol */
  protocolId: Scalars['Int'];
  /** Url of the scanner */
  scannerUrl?: Maybe<Scalars['String']>;
};

export type PublicKey = {
  ID: Scalars['Int'];
  blockchainID?: Maybe<Scalars['Int']>;
  investorID: Scalars['Int'];
  title: Scalars['String'];
};

export type PublicSto = {
  ID: Scalars['Int'];
  logo: Scalars['String'];
  registrationText?: Maybe<Scalars['String']>;
  settings: Settings;
  stolinkfull: Scalars['String'];
  title: Scalars['String'];
};

export type Query = {
  adminDividendGet: DividendDistribution;
  adminDividendList: Array<DividendDistribution>;
  adminDividendPayments: Array<DividendInvestorPayout>;
  adminDividendPaymentsFull: Array<DividendInvestorPayoutWithDistribution>;
  /** Query for fetching all member invitations */
  adminGetInvestingEntityMemberInvitations: Array<InvestingEntityMemberInvitation>;
  /** Get all investing entity types */
  adminGetInvestingEntityType: Array<InvestingEntityType>;
  /** Get custom kyc field for an investor. Fields populated only when using the internal KYC */
  adminGetInvestorCustomKycFields: Array<TranslatedKycField>;
  /** Get all investors Investing Entities, limited by entity visibility for admins outside STO 0 */
  adminInvestingEntities: Array<InvestingEntity>;
  /**
   * Get all investors Investing Entities, but limited as if you are an agent in sellSideID.
   *
   * - **Attention:** Function is not optimized for speed. Use with caution.
   *
   * - Available only to platform admins and STO 0 admins.
   *
   * - API user must not be member of sell side.
   *
   * - At least one agent must exist for sellSideID.
   */
  adminInvestingEntitiesForSellSide: Array<InvestingEntity>;
  /** Get investors Investing Entity by Admin */
  adminInvestingEntity?: Maybe<InvestingEntity>;
  adminInvestingEntitySto: InvestingEntitySto;
  /** Query for fetching all unregistered members of an entity. If you are a sell-side agent, you only get the members that you have access to. */
  adminInvestingEntityUnregisteredMembers: Array<InvestingEntityMember>;
  /** Get investors deposit history */
  adminInvestorDepositHistory: Array<InvestorDepositAlert>;
  /** Get documents uploaded by the administrators. These documents are related to the investor somehow */
  adminInvestorDocuments: Array<InvestorDocument>;
  /** Get investor sto information */
  adminInvestorSto: InvestorSto;
  /** Mutation for retrieving admin information */
  adminMe?: Maybe<Admin>;
  /** Get admin application parameters */
  adminParameters: AdminParameters;
  /** Get investors Submitted Documents */
  adminSubmittedDocuments: Array<DocumentUser>;
  /** Check the payment status of an invoice. The active payment provider will be used */
  checkInvoiceStatus: DpayTransactionStatus;
  /** Check whether an Investor has paid the Registration Fee or not */
  checkRegistrationFeeStatus: Scalars['Boolean'];
  /** Get documents that are active for investors */
  commentableDocuments: Array<Document>;
  /** Get investors Comments */
  comments: Array<DocumentComment>;
  /** Get all countries */
  countries: Array<Scalars['String']>;
  /** Get single document */
  document?: Maybe<Document>;
  /** Get all Document Fields of a Document */
  documentFields: Array<DocumentField>;
  /** Get Signed DocuSign Document base64 */
  downloadSignedDocuSign: Scalars['String'];
  /** Get Url of Signed HelloSign Document */
  downloadSignedHelloSign: Scalars['String'];
  /** Get all exchange orders */
  exchangeOrders: Array<ExchangeOrder>;
  /** Get component customization */
  fetchCustomizedComponent: ComponentCustomization;
  /** Get all customized components */
  fetchCustomizedComponents: Array<ComponentCustomization>;
  /** Get all Fee Commissions */
  fetchFeeCommissions: Array<FeeCommission>;
  /** Get Fee Commission by ID */
  fetchFeeCommissionsByID: FeeCommission;
  /** Get all Fees related to a specific STO */
  fetchFees: Array<Fee>;
  /** Query for retrieving Admin account by ID */
  findAdmin: User;
  /** Mutation for finding Investor accounts as an admin while respecting the admin rights */
  findAdminInvestor?: Maybe<Investor>;
  /** Query for listing Admin accounts by *one* of the following: email, ID */
  findAdmins: Array<User>;
  /** Get all blockchain protocols available */
  findAllBlockchainProtocols?: Maybe<Array<BlockchainProtocol>>;
  /** Get all blockchains available */
  findAllBlockchains?: Maybe<Array<Blockchains>>;
  /** Get all currencies available */
  findAllCurrencies?: Maybe<Array<Currency>>;
  /** Mutation for listing all Investor accounts */
  findAllInvestors?: Maybe<Array<Investor>>;
  /** Get share types */
  findAllShareTypes: Array<ShareType>;
  /** Get all stos */
  findAllSto: Array<Sto>;
  /** Get all stos public */
  findAllStoPublic: Array<PublicSto>;
  /** Get one blockchain */
  findBlockchain?: Maybe<Blockchains>;
  /** Get one blockchain protocol */
  findBlockchainProtocol?: Maybe<BlockchainProtocol>;
  /** Get specific currency */
  findCurrency?: Maybe<Currency>;
  /** Get specific currency by ID */
  findCurrencyById: Currency;
  /** Get investors deposit history */
  findDepositAlerts: Array<InvestorDepositAlert>;
  /** Mutation for finding Investor accounts by *one* criterion among: ID, email, taxID, govtID. */
  findInvestor?: Maybe<Investor>;
  /** Get dividend payouts for the provided investor, including the ones awarded to related investing entities. */
  findInvestorDividendPayouts: Array<DividendInvestorPayout>;
  /** Mutation for finding multiple Investor accounts by Email array */
  findInvestors?: Maybe<Array<Investor>>;
  /** Get share historical values */
  findShareHistoricalValues: Array<ShareHistoricalData>;
  /** Get share types */
  findShareTypes: Array<ShareType>;
  /** Get share types by ids */
  findShareTypesByIds: Array<ShareType>;
  /** Get sto data */
  findSto?: Maybe<Sto>;
  /** Get all visible offerings for an investor */
  findVisibleOfferings: Array<Sto>;
  generateCardJwt: Scalars['String'];
  generateKycJwt: Scalars['String'];
  /** Get investors accepted exchange offer */
  getAcceptedExchangeOffer?: Maybe<ExchangeOffer>;
  /** Get All Exchange Transactions Data Filtered By The Input For The Platform Admin */
  getAllExchangeTransactions: Array<ExchangeTransactionData>;
  /** Get all Forum Threads */
  getAllForumThreads: Array<ForumThread>;
  /** Get all invoice alerts for an investor. Returns invoices for an investor, if the admin has the proper right permissions */
  getAllInvestorInvoiceAlerts: Array<InvestorInvoices>;
  /** Get all payment channels */
  getAllInvestorPaymentChannels: Array<PaymentChannel>;
  /** Get all details of paramteres */
  getAllParamsDetails: Array<Params>;
  /** Query for fetching all entities with waiting status */
  getAllUnConfirmedEntitiesforStoAdmin: Array<InvestingEntity>;
  /** Get possible values for awardStrategy */
  getAwardStrategies: Array<DividendAwardStrategy>;
  /** Get Block Pass Client ID */
  getBlockPassClientID: Scalars['String'];
  getCategoryMetaKeys: Array<StosMetaKeys>;
  /** Get a chat message */
  getChat: Chat;
  /** Get all chats for the admin */
  getChatOverview: Array<ChatOverview>;
  /** Get all Chats between two sides */
  getChats: Array<Chat>;
  /** Get Sum of Fee Commissions by Beneficiary ID and Beneficiary Type */
  getCommissionsSum: Scalars['Float'];
  /** Get company news by ID */
  getCompanyUpdate?: Maybe<CompanyNews>;
  /** Get a corporate action */
  getCorporateAction: Meeting;
  /** Gets all discounts */
  getDiscounts: Array<Discount>;
  /** Gets all discounts */
  getDiscountsAdmin: Array<Discount>;
  /** Get all active payment channels which is capable of doing dividend distribution */
  getDividendPaymentChannels: Array<PaymentChannel>;
  /** Get DocuSign URL for signing redirect */
  getDocuSignUrl: Scalars['String'];
  getDpayAuths: Array<DpayAuth>;
  /** Gets the DpayEntity belonging to the investor. The balance field can be stale. */
  getDpayEntityInvestor?: Maybe<DpayEntity>;
  /** Get investors exchange offer */
  getExchangeOffer?: Maybe<ExchangeOffer>;
  /** Get investors exchange offers */
  getExchangeOffers: Array<ExchangeOffer>;
  /** Get exchange order by ID */
  getExchangeOrder: ExchangeOrder;
  /** Get exchange orders offers */
  getExchangeOrderOffers: Array<ExchangeOffer>;
  /** Query for Getting the Number of Offers Made on an Order */
  getExchangeOrderOffersCount: Scalars['Int'];
  /** Get all stos exchange orders */
  getExchangeOrders: Array<ExchangeOrder>;
  /** Get All Exchange Transactions Data By Investor ID */
  getExchangeTransactions: Array<ExchangeTransactionData>;
  /** Get a complex object describing the state of your translations. Any incomplete/partial translations will be shown in the returned object */
  getExhaustiveTranslations: Array<ExhaustiveTranslationsState>;
  getFilterMetaKeys: Array<StosMetaKeys>;
  /** Get Full Name by ID */
  getFullNameByID: Scalars['String'];
  /** Get GCash login URL for the investors */
  getGCashLoginUrl: Scalars['String'];
  getInvEntitiesOfSellSideEntity: Array<InvestingEntity>;
  /**
   * Get the meta keys with empty values of type investing entity that the investor can see,
   *     so they can have them displayed in the additional info section while they are creating a new entity.
   *      For Non-Existing entities only. Existing entities already come with their meta keys/values attached to them.
   */
  getInvestingEntityDefaultMeta: Array<MetaValues>;
  /** Get an investor's bank account */
  getInvestorBankAccount?: Maybe<InvestorBanks>;
  /** Query for Getting the Sum of Investor's Collected Commissions */
  getInvestorCommissionsSum: Scalars['Float'];
  /** Get all active payment channels for an investor that support conversion available for the sto provided */
  getInvestorConversionPaymentChannels: Array<PaymentChannel>;
  getInvestorDocument: InvestorDocument;
  /** Get all investor documents for a specific investor */
  getInvestorDocuments: Array<InvestorDocument>;
  /** Get investors exchange orders */
  getInvestorExchangeOrders: Array<ExchangeOrder>;
  getInvestorIdsEntitiesMap: Array<InvestorIdEntitiesTuple>;
  /** Gets all the investors invitations */
  getInvestorInvitations: Array<InvestorInvitation>;
  /** Get investor invoices. Resolved if you use the isHistorical param, unpaid otherwise. Alternatively, show invoices which are in bank transit with isOngoing */
  getInvestorInvoices: Array<InvestorInvoices>;
  /** Get all investor types */
  getInvestorTypes: Array<StoInvestorType>;
  /** Query For Fetching Invitation Link */
  getInvitationLink: Scalars['String'];
  /** Get all locales */
  getLocales: Array<Scalars['String']>;
  /** Get all logs from stos */
  getLogsForSellEntityAgent?: Maybe<Array<Log>>;
  /** Get all logs for an sto */
  getLogsForSto?: Maybe<Array<Log>>;
  getMercuryAccountInfo?: Maybe<MercuryInfo>;
  getMercuryConfigMode: MercuryConfigMode;
  getMercuryRecipient?: Maybe<MercuryRecipient>;
  /**
   * This query retrieves all entity meta keys.
   *     If entityType is provided, only keys for that entity type will be returned.
   *     If sellerEntityID is provided, only keys for that seller will be returned.
   */
  getMetaKeys: Array<MetaKeys>;
  getMetaKeysByCategory: Array<StosMetaKeys>;
  getMetaStoRelations: Array<MetaStoRelation>;
  /** Get all projects meta keys */
  getMetadataKeys: Array<StosMetaKeys>;
  getNetkiSignUpData: NetkiSignUpData;
  /** Gets all the investors that have not finished the KYC process */
  getNonKycInvestors: Array<NonKycInvestor>;
  getNotMigratedFiles: Array<MigrationFile>;
  /** Get a payment channel by id */
  getPaymentChannel: PaymentChannel;
  /** Returns all supported configurations by the payment channels */
  getPaymentChannelSpecs: Array<PaymentChannelSpecs>;
  /** Query for Getting the Number of Investor's Pending Polymesh Swap Transactions */
  getPendingPolymeshTransactionsCount: Scalars['Int'];
  /** Get Historical Performance Graph Data By Share Type ID */
  getPerformanceGraphData: PerformanceGraphData;
  /** Get All Investor's Polymesh Swap Transactions */
  getPolymeshTransactions: Array<PolymeshSwapTransaction>;
  /** Get Submitted Document corresponding to Share Purchase ID */
  getPrefilledDocumentValues: Array<DocumentUserFieldValue>;
  /** Get Price Negotiation Chat List */
  getPriceNegotiationList: Array<PriceNegotiationListItem>;
  /** Query for Getting the Number of Investor's Unread Messages in Price Negotiation */
  getPriceNegotiationUnreadMessagesCount: Scalars['Int'];
  /** Gets all Triggers */
  getPriceTriggers_Admin: Array<PriceTransformerTrigger>;
  getPropertyFile: PropertyFile;
  getPropertyFileByQuery: Array<PropertyFile>;
  /** Get revision number */
  getRevisionNumber: Scalars['String'];
  getSellEntityById: SellSideEntity;
  /** Will fetch descriptive metrics for all of the projects that a sell side entity manages, and that this sell agent has access to */
  getSellEntityMetrics: SellEntityMetrics;
  getSellSideEntities: Array<SellSideEntity>;
  getShareTypeDetails: ShareType;
  /** Get investors shares wallet */
  getSharesWallets: Array<SharesWallet>;
  /**
   * find smtp settings based on input. Fields work under a AND condition.
   *     Passing both the sellingEntityID and the stoID will attempt to find settings that match both parameters
   */
  getSmtpSettings?: Maybe<SmtpSettings>;
  /** Get sum sub login token */
  getSumSubInvestorToken: Scalars['String'];
  /** Get all supported payment channels for this invoice resolver */
  getSupportedPaymentChannels: Array<PaymentChannel>;
  /** Get a Forum Thread by Thread's ID */
  getThreadByID: ForumThread;
  /** Get all Contents of a Forum Thread By Thread's ID */
  getThreadContent: Array<ForumPost>;
  /** Get all translations applicable translations by key */
  getTranslationsByKey: Array<Translation>;
  /** Gets the TxId of a blockchain shares transfer transaction by the buyAlertID */
  getTxIdFromBuyAlertID: Scalars['String'];
  /** Query for Getting the Number of Investor's Unread Messages */
  getUnreadMessagesCount: Scalars['Int'];
  /**
   * Get all unresolved invoice alerts
   * @deprecated Use getAllInvestorInvoiceAlerts and pass the appropriate status instead
   */
  getUnresolvedInvestorInvoiceAlerts: Array<InvestorInvoices>;
  /** Get an update */
  getUpdate: Update;
  /** Get Username by ID */
  getUsernameByID: Scalars['String'];
  /** Get the verifyInvestorCom url set in the database */
  getVerifyInvestorUrl: Scalars['String'];
  /** Get all visible investors for an offering */
  getVisibleInvestingEntityMembers: Array<InvestingEntityMemberInvitation>;
  /** Query For Fetching Web3 Provider Address */
  getWeb3Provider: Web3Providers;
  /** Retrieve entity investor representation */
  investingEntityInvestorRepresentative?: Maybe<Investor>;
  investingEntityStos: Array<InvestingEntitySto>;
  /** Get active properties */
  investorActiveProperties: Array<ActiveProperty>;
  /** Get investor address change requests */
  investorAddressChangeRequests: Array<ChangeAddressRequest>;
  /** Get all meetings */
  investorAllMeeting: AllMeeting;
  /** Get investors application parameters */
  investorAppParameters: AppParameters;
  /** Get an investors balance */
  investorBalance?: Maybe<InvestorBalance>;
  /**
   * Get all investors balances at sto.
   *       Note: if Internal Wallet Mode is set to global, this endpoint will always return values for sto 0
   */
  investorBalances: Array<InvestorBalance>;
  /** Get all investors buy alerts */
  investorBuyAlerts?: Maybe<Array<InvestorBuyAlert>>;
  /** Get all investors buy alerts */
  investorBuyAlertsAdmin?: Maybe<Array<InvestorBuyAlert>>;
  /** Get investor closed accounts */
  investorClosedAccounts: Array<ClosedInvestorAccount>;
  /** Get all current investors data */
  investorCurrentUser: Investor;
  /** Get investors deposit history */
  investorDepositHistory: Array<InvestorDepositAlert>;
  /** Get property details */
  investorDetailProperty: DetailProperty;
  /** Get an investors inbox */
  investorInbox?: Maybe<Inbox>;
  /** Get all investors inboxes */
  investorInboxes?: Maybe<Array<Inbox>>;
  /** Get all investors Investing Entities */
  investorInvestingEntities: Array<InvestingEntity>;
  /** Get investors Investing Entity */
  investorInvestingEntity?: Maybe<InvestingEntity>;
  /** Get investor entity permissions and access date for data room */
  investorInvestingEntityPermissions: InvestingEntityStoPermissions;
  /** Get All Investing Entity Types */
  investorInvestingEntityTypes: Array<InvestingEntityTypes>;
  /** Get all investments of an investor */
  investorInvestments: Array<Investments>;
  /** Get a specific investors invoice alert */
  investorInvoiceAlert: InvestorInvoices;
  /** Get all unpaid investors invoice alerts */
  investorInvoiceAlerts: Array<InvestorInvoices>;
  /** Get investor kyc information */
  investorKyc: Scalars['JSON'];
  /** Get investor logs for a specific project */
  investorLogs: Array<Log>;
  /** Get meeting data */
  investorMeeting?: Maybe<Meeting>;
  /** Get all stos payment channels */
  investorPaymentChannels: Array<PaymentChannel>;
  /** Get poll statistics data */
  investorPoll: Poll;
  /** Get investors preferred returns. */
  investorPreferredReturns: Array<InvestorPreferredReturns>;
  /** Get investors public keys */
  investorPublicKeys?: Maybe<Array<PublicKey>>;
  /** Get investor active STOs */
  investorRelatedSto: Array<ActiveProperty>;
  /** Get an investors share */
  investorShare?: Maybe<Share>;
  /** Get an investor's share balance(s) in a share type(s) */
  investorShareBalance: Array<Share>;
  /** Get all investors shares */
  investorShares: Array<Share>;
  /** Get investor sto information */
  investorSto: InvestorSto;
  /** Get investor sto information, can possibly not exist for current/given STO */
  investorStoNullable?: Maybe<InvestorSto>;
  /**
   * Get a one update
   * @deprecated Use getUpdate query instead
   */
  investorUpdate?: Maybe<Update>;
  /** Get all updates from sto */
  investorUpdates?: Maybe<Array<Update>>;
  /** Get all users data */
  investorUser?: Maybe<InvestorUser>;
  /** Get investor selection */
  investorUserVoting: Array<VotingUser>;
  /** Get voting document */
  investorVotingDocument?: Maybe<VotingDocuments>;
  /** Get voting options */
  investorVotingOptions: Array<VotingOption>;
  /** Get voting user data */
  investorVotingUserData?: Maybe<VotingUserData>;
  /** Check investor's public key to see it is whitelisted */
  isInvestorWhiteListed: Scalars['Boolean'];
  /** Check if a dpay account needs further onboarding */
  isOnboardComplete: Scalars['Boolean'];
  isTopUpSourceExisting: Scalars['Boolean'];
  /** Get Kyc structure */
  kyc: Array<KycPage>;
  /** Get Kyc files */
  kycFiles: Array<KycFile>;
  /** Get all translations or translations for locale */
  locales: Array<Scalars['String']>;
  /** Get all accessible transactions, re-fetch them from MoonPay. Limit for refreshed data is top 50 newest transactions. */
  moonpayAllTransactionsJSON?: Maybe<Array<Scalars['JSON']>>;
  /** Get all accessible transactions as admin or API, re-fetch them from MoonPay. Limit for refreshed data is top 50 newest transactions. */
  moonpayAllTransactionsJSONAdmin?: Maybe<Array<Scalars['JSON']>>;
  /** Get transaction for buy alert as investor (who has interacted with Moonpay, so not just an empty reserved transaction), re-fetch from MoonPay. */
  moonpayBuyAlertTransactionJSON?: Maybe<MoonpayTransactionJson>;
  /** Get transaction for buy alert as admin or API (where investor has interacted with Moonpay, so not just an empty reserved transaction), re-fetch from MoonPay. */
  moonpayBuyAlertTransactionJSONAdmin?: Maybe<MoonpayTransactionJson>;
  /** Get transaction receipt for buy alert, as investor. */
  moonpayBuyAlertTransactionReceiptUrl?: Maybe<Scalars['String']>;
  /** Get the platform configuration for Moonpay. Warning: Contains sensitive information. */
  moonpayConfig: MoonpayConfig;
  /** Get transaction by its local ID (externalTransactionId) if accessible, re-fetch from MoonPay. */
  moonpayGetTransactionJSON?: Maybe<MoonpayTransactionJson>;
  /** Get transaction by its local ID (externalTransactionId on MoonPay) if accessible, re-fetch from MoonPay. */
  moonpayGetTransactionJSONAdmin?: Maybe<MoonpayTransactionJson>;
  /** Get last transaction for investor (where he has interacted with Moonpay, so not just an empty reserved transaction), re-fetch from MoonPay. */
  moonpayLastTransactionJSON?: Maybe<MoonpayTransactionJson>;
  /** NB: If you want to match a buy alert, use another endpoint. Get last transaction for investor as admin or API (where he has interacted with Moonpay, so not just an empty reserved transaction), re-fetch from MoonPay. */
  moonpayLastTransactionJSONAdmin?: Maybe<MoonpayTransactionJson>;
  /** Get URL for Moonpay widget with configuration. Optionally match to buy alert immediately. (Reserves a transaction) */
  moonpayWidgetUrl: Scalars['String'];
  /** Get URL for Moonpay widget with configuration, as admin or API. Optionally match to buy alert immediately. (Reserves a transaction) */
  moonpayWidgetUrlAdmin: Scalars['String'];
  /** Get an investors Offered Document by the documentId */
  offeredDocument: OfferedDocument;
  /** Get investors Offered Documents valid currently */
  offeredDocuments: Array<OfferedDocument>;
  /** Generate an onboarding link for a payment provider */
  onboardAccount: Scalars['String'];
  /** Synchronize/upload and return investor data on the processor. The local data object is returned,    where the remote account data might be found in `storage` field. */
  pint_getAccountData_Investor?: Maybe<DpayEntity>;
  pint_getConfigs: Array<PintConfig>;
  pint_getConfigsJSONArray: Array<Scalars['String']>;
  pint_getLocalAccountData_Platform?: Maybe<DpayEntity>;
  pint_getProcessors: Array<PintProcessorType>;
  pint_listPreconfiguredCustomActions: PintPreconfiguredCustomActions;
  /** Synchronize status of transactions that have anything to do with current investor. Resolve pending actions that require payment, such as BuyAlerts, DepositAlerts, Invoices, Dividends, etc. */
  pint_synchronizeTransactions_Investor?: Maybe<Scalars['Boolean']>;
  /** Get the investor's portfolio value calculations */
  portfolioCalculations: Array<InvestmentsCalculation>;
  /** Get the investor's portfolio value */
  portfolioValue: Scalars['Float'];
  /** Get public sto data */
  publicSto?: Maybe<PublicSto>;
  /** Send a HelloSign contract signing request to the investor */
  sendHelloSignTemplateSignRequest: Scalars['String'];
  /** Get Submitted Document corresponding to Share Purchase ID */
  sharePurchaseDocument?: Maybe<DocumentUser>;
  /** Get documents required for purchasing shares */
  sharePurchaseDocuments: Array<SharePurchaseDocument>;
  /** Get share balances for share type */
  shareTypeShares: Array<Share>;
  /** Get documents which the investor has signed for various reasons */
  signedAndSettledDocuments: Array<DocumentUser>;
  /** Get investors Submitted Document by Id */
  submittedDocument?: Maybe<DocumentUser>;
  /** Get investors Submitted Documents */
  submittedDocuments: Array<DocumentUser>;
  /** Get all translations or translations for locale */
  translations: Array<Translation>;
  /** Get investors Unfinished Documents */
  unfinishedDocument?: Maybe<DocumentUser>;
  /** Query for new, unverified registrations, which will become investor records once verified by email. Only when email verification is required to access the dashbaord. Optionally, can be searched by exact ID, email, or secret */
  unverifiedRegistrations: Array<Register>;
};

export type QueryAdminDividendGetArgs = {
  ID: Scalars['Int'];
};

export type QueryAdminDividendListArgs = {
  stoID?: Maybe<Scalars['Int']>;
};

export type QueryAdminDividendPaymentsArgs = {
  dividendID: Scalars['Int'];
};

export type QueryAdminDividendPaymentsFullArgs = {
  dividendID: Scalars['Float'];
};

export type QueryAdminGetInvestingEntityMemberInvitationsArgs = {
  statuses: Array<InvitationStatus>;
  stoID: Scalars['Int'];
};

export type QueryAdminGetInvestorCustomKycFieldsArgs = {
  queryInput: AdminGetInvestorCustomKycFieldsQuery;
};

export type QueryAdminInvestingEntitiesArgs = {
  offeringID?: Maybe<Scalars['Int']>;
};

export type QueryAdminInvestingEntitiesForSellSideArgs = {
  sellSideID: Scalars['Int'];
};

export type QueryAdminInvestingEntityArgs = {
  entityID?: Maybe<Scalars['Int']>;
};

export type QueryAdminInvestingEntityStoArgs = {
  entityID: Scalars['Int'];
  stoID: Scalars['Int'];
};

export type QueryAdminInvestorDepositHistoryArgs = {
  investorID: Scalars['Int'];
  stoID: Scalars['Int'];
};

export type QueryAdminInvestorDocumentsArgs = {
  investorID: Scalars['Int'];
  stoID: Scalars['Int'];
};

export type QueryAdminInvestorStoArgs = {
  investorID: Scalars['Int'];
  stoID: Scalars['Int'];
};

export type QueryAdminSubmittedDocumentsArgs = {
  investorID: Scalars['Int'];
  minStatus: Scalars['Int'];
};

export type QueryCheckInvoiceStatusArgs = {
  alertID: Scalars['Int'];
  invoiceID: Scalars['Int'];
};

export type QueryCommentsArgs = {
  documentID: Scalars['Int'];
};

export type QueryDocumentArgs = {
  documentID: Scalars['Int'];
};

export type QueryDocumentFieldsArgs = {
  documentID: Scalars['Int'];
};

export type QueryDownloadSignedDocuSignArgs = {
  envelopeID: Scalars['String'];
};

export type QueryDownloadSignedHelloSignArgs = {
  fileID: Scalars['String'];
};

export type QueryFetchCustomizedComponentArgs = {
  componentID?: Maybe<Scalars['Int']>;
  componentTitle?: Maybe<Scalars['String']>;
};

export type QueryFetchFeeCommissionsArgs = {
  beneficiaryID?: Maybe<Scalars['Int']>;
  beneficiaryType?: Maybe<BrokerType>;
  feeID?: Maybe<Scalars['Int']>;
  status?: Maybe<PaymentStatus>;
  transactionID?: Maybe<Scalars['Int']>;
};

export type QueryFetchFeeCommissionsByIdArgs = {
  feeCommissionID: Scalars['Int'];
};

export type QueryFetchFeesArgs = {
  beneficiary?: Maybe<FeeBeneficiary>;
  status?: Maybe<CommissionType>;
  stoID: Scalars['Int'];
  type?: Maybe<FeeType>;
};

export type QueryFindAdminArgs = {
  userID: Scalars['Int'];
};

export type QueryFindAdminInvestorArgs = {
  investorID?: Maybe<Scalars['Int']>;
};

export type QueryFindAdminsArgs = {
  email?: Maybe<Array<Scalars['String']>>;
  userIDs?: Maybe<Array<Scalars['Int']>>;
};

export type QueryFindBlockchainArgs = {
  ID: Scalars['Int'];
};

export type QueryFindBlockchainProtocolArgs = {
  ID: Scalars['Int'];
};

export type QueryFindCurrencyArgs = {
  currencyID: Scalars['Int'];
};

export type QueryFindCurrencyByIdArgs = {
  currencyID: Scalars['Int'];
};

export type QueryFindDepositAlertsArgs = {
  queryInput: InvestorDepositQueryInput;
};

export type QueryFindInvestorArgs = {
  email?: Maybe<Scalars['String']>;
  govtID?: Maybe<Scalars['String']>;
  investorID?: Maybe<Scalars['Int']>;
  passportNumber?: Maybe<Scalars['String']>;
  taxID?: Maybe<Scalars['String']>;
};

export type QueryFindInvestorsArgs = {
  emails: Array<Scalars['String']>;
};

export type QueryFindShareHistoricalValuesArgs = {
  shareTypeID: Scalars['Int'];
};

export type QueryFindShareTypesArgs = {
  ID?: Maybe<Scalars['Int']>;
  stoID?: Maybe<Scalars['Int']>;
};

export type QueryFindShareTypesByIdsArgs = {
  shareTypeIDs: Array<Scalars['Int']>;
};

export type QueryFindStoArgs = {
  ID: Scalars['Int'];
  investorID?: Maybe<Scalars['Int']>;
};

export type QueryGenerateKycJwtArgs = {
  paymentProcessor: PaymentProcessor;
};

export type QueryGetAcceptedExchangeOfferArgs = {
  orderID: Scalars['Int'];
};

export type QueryGetAllExchangeTransactionsArgs = {
  filter: ExchangeTransactionFilterInput;
};

export type QueryGetAllInvestorInvoiceAlertsArgs = {
  queryInput?: Maybe<InvestorInvoiceQueryInput>;
};

export type QueryGetChatArgs = {
  messageID: Scalars['Int'];
};

export type QueryGetChatOverviewArgs = {
  queryInput?: Maybe<AdminChatQueryInput>;
};

export type QueryGetChatsArgs = {
  chatBetween: ChatBetween;
  context?: Maybe<ChatContext>;
  contextID?: Maybe<Scalars['Int']>;
  counterpartID?: Maybe<Scalars['Int']>;
  stoID?: Maybe<Scalars['Int']>;
};

export type QueryGetCommissionsSumArgs = {
  beneficiaryID: Scalars['Int'];
  beneficiaryType?: Maybe<BrokerType>;
  status?: Maybe<PaymentStatus>;
};

export type QueryGetCompanyUpdateArgs = {
  ID: Scalars['Int'];
};

export type QueryGetCorporateActionArgs = {
  ID: Scalars['Int'];
};

export type QueryGetDiscountsArgs = {
  discountAppliesTo: Array<DiscountAppliesTo>;
  stoID: Scalars['Int'];
};

export type QueryGetDiscountsAdminArgs = {
  IDs?: Maybe<Array<Scalars['Int']>>;
  stoIDs?: Maybe<Array<Scalars['Int']>>;
};

export type QueryGetDividendPaymentChannelsArgs = {
  offeringID: Scalars['Int'];
};

export type QueryGetDocuSignUrlArgs = {
  documentID: Scalars['Int'];
  preferredReturnURL: Scalars['String'];
  sharePurchaseID: Scalars['Int'];
};

export type QueryGetDpayEntityInvestorArgs = {
  paymentProcessor?: Maybe<PaymentProcessor>;
};

export type QueryGetExchangeOfferArgs = {
  orderID: Scalars['Int'];
};

export type QueryGetExchangeOffersArgs = {
  stoID: Scalars['Int'];
  type: ExchangeType;
};

export type QueryGetExchangeOrderArgs = {
  orderID: Scalars['Int'];
};

export type QueryGetExchangeOrderOffersArgs = {
  orderID: Scalars['Int'];
};

export type QueryGetExchangeOrderOffersCountArgs = {
  orderID: Scalars['Int'];
};

export type QueryGetExchangeOrdersArgs = {
  stoID: Scalars['Int'];
};

export type QueryGetExhaustiveTranslationsArgs = {
  language?: Maybe<Scalars['String']>;
};

export type QueryGetFullNameByIdArgs = {
  userID: Scalars['Int'];
};

export type QueryGetInvestorCommissionsSumArgs = {
  status?: Maybe<PaymentStatus>;
};

export type QueryGetInvestorConversionPaymentChannelsArgs = {
  stoID: Scalars['Int'];
};

export type QueryGetInvestorDocumentArgs = {
  documentID: Scalars['Int'];
};

export type QueryGetInvestorDocumentsArgs = {
  investorID?: Maybe<Scalars['Int']>;
};

export type QueryGetInvestorExchangeOrdersArgs = {
  stoID?: Maybe<Scalars['Int']>;
  type: ExchangeType;
};

export type QueryGetInvestorIdsEntitiesMapArgs = {
  investorIDs: Array<Scalars['Int']>;
};

export type QueryGetInvestorInvitationsArgs = {
  stoID: Scalars['Int'];
};

export type QueryGetInvestorInvoicesArgs = {
  includeAllTypes?: Maybe<Scalars['Boolean']>;
  isHistorical?: Maybe<Scalars['Boolean']>;
  isOngoing?: Maybe<Scalars['Boolean']>;
};

export type QueryGetLogsForSellEntityAgentArgs = {
  agentUserID: Scalars['Int'];
};

export type QueryGetLogsForStoArgs = {
  data?: Maybe<LogFilterInput>;
};

export type QueryGetMetaKeysArgs = {
  entityType?: Maybe<EntityType>;
  sellerEntityID?: Maybe<Scalars['Int']>;
};

export type QueryGetMetaKeysByCategoryArgs = {
  categories?: Maybe<Array<MetaKeyTypes>>;
};

export type QueryGetMetaStoRelationsArgs = {
  stoID?: Maybe<Scalars['Int']>;
};

export type QueryGetNonKycInvestorsArgs = {
  name?: Maybe<Scalars['String']>;
  stoID: Scalars['Int'];
};

export type QueryGetNotMigratedFilesArgs = {
  type: StorageType;
};

export type QueryGetPaymentChannelArgs = {
  paymentChannelID: Scalars['Int'];
};

export type QueryGetPendingPolymeshTransactionsCountArgs = {
  between: PolymeshSwapBetween;
};

export type QueryGetPerformanceGraphDataArgs = {
  endDate?: Maybe<Scalars['String']>;
  shareTypeID: Scalars['Int'];
  startDate?: Maybe<Scalars['String']>;
};

export type QueryGetPolymeshTransactionsArgs = {
  between: PolymeshSwapBetween;
  status: PolymeshSwapStatus;
};

export type QueryGetPrefilledDocumentValuesArgs = {
  documentID: Scalars['Int'];
  sharePurchaseID: Scalars['Int'];
};

export type QueryGetPriceNegotiationListArgs = {
  orderID: Scalars['Int'];
};

export type QueryGetPriceNegotiationUnreadMessagesCountArgs = {
  context: ChatContext;
  contextID: Scalars['Int'];
  counterpartID?: Maybe<Scalars['Int']>;
};

export type QueryGetPriceTriggersAdminArgs = {
  IDs?: Maybe<Array<Scalars['Int']>>;
};

export type QueryGetPropertyFileArgs = {
  ID: Scalars['Int'];
};

export type QueryGetPropertyFileByQueryArgs = {
  queryInput: PropertyFileQueryInput;
};

export type QueryGetSellEntityByIdArgs = {
  ID: Scalars['Int'];
};

export type QueryGetSellEntityMetricsArgs = {
  agentUserID: Scalars['Int'];
};

export type QueryGetShareTypeDetailsArgs = {
  shareTypeID: Scalars['Int'];
};

export type QueryGetSharesWalletsArgs = {
  investorID?: Maybe<Scalars['Int']>;
  platform?: Maybe<Scalars['Boolean']>;
  shareTypeID?: Maybe<Scalars['Int']>;
  stoID?: Maybe<Scalars['Int']>;
};

export type QueryGetSmtpSettingsArgs = {
  queryInput: SmtpSettingsQuery;
};

export type QueryGetSupportedPaymentChannelsArgs = {
  invoiceID: Scalars['Int'];
  invoiceResolver?: Maybe<DpayResolver>;
};

export type QueryGetThreadByIdArgs = {
  threadID: Scalars['Int'];
};

export type QueryGetThreadContentArgs = {
  threadID: Scalars['Int'];
};

export type QueryGetTranslationsByKeyArgs = {
  key: Scalars['String'];
};

export type QueryGetTxIdFromBuyAlertIdArgs = {
  buyAlertID: Scalars['Int'];
};

export type QueryGetUnreadMessagesCountArgs = {
  sender: SenderType;
};

export type QueryGetUnresolvedInvestorInvoiceAlertsArgs = {
  investorID?: Maybe<Scalars['Int']>;
  isWithdrawal?: Maybe<Scalars['Boolean']>;
  stoID?: Maybe<Scalars['Int']>;
};

export type QueryGetUpdateArgs = {
  updateID: Scalars['Int'];
};

export type QueryGetUsernameByIdArgs = {
  userID: Scalars['Int'];
};

export type QueryGetVisibleInvestingEntityMembersArgs = {
  offeringID: Scalars['Int'];
};

export type QueryGetWeb3ProviderArgs = {
  blockchainProtocolID: Scalars['Int'];
};

export type QueryInvestingEntityInvestorRepresentativeArgs = {
  entityID: Scalars['Int'];
};

export type QueryInvestorActivePropertiesArgs = {
  buyEnabledFilterInput?: Maybe<BuyEnabledFilterInput>;
  showAllProps?: Maybe<Scalars['Boolean']>;
};

export type QueryInvestorAddressChangeRequestsArgs = {
  investorID: Scalars['Int'];
  stoID: Scalars['Int'];
};

export type QueryInvestorAllMeetingArgs = {
  stoID: Scalars['Int'];
};

export type QueryInvestorBalanceArgs = {
  ID?: Maybe<Scalars['Int']>;
  currencyID?: Maybe<Scalars['Int']>;
  stoID?: Maybe<Scalars['Int']>;
};

export type QueryInvestorBalancesArgs = {
  stoID: Scalars['Int'];
};

export type QueryInvestorBuyAlertsArgs = {
  status: Array<BuyAlertStatus>;
};

export type QueryInvestorBuyAlertsAdminArgs = {
  alertID?: Maybe<Scalars['Int']>;
  investorID?: Maybe<Scalars['Int']>;
  isSellRequest?: Maybe<Scalars['Boolean']>;
  status?: Maybe<BuyAlertStatus>;
};

export type QueryInvestorClosedAccountsArgs = {
  investorID: Scalars['Int'];
  isPartial: Scalars['Boolean'];
  stoID: Scalars['Int'];
};

export type QueryInvestorDepositHistoryArgs = {
  stoID: Scalars['Int'];
};

export type QueryInvestorDetailPropertyArgs = {
  investorID?: Maybe<Scalars['Int']>;
  stoID: Scalars['Int'];
};

export type QueryInvestorInboxArgs = {
  ID: Scalars['Int'];
};

export type QueryInvestorInboxesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  stoID: Scalars['Int'];
};

export type QueryInvestorInvestingEntityArgs = {
  entityID: Scalars['Int'];
};

export type QueryInvestorInvestingEntityPermissionsArgs = {
  stoID?: Maybe<Scalars['Int']>;
};

export type QueryInvestorInvestmentsArgs = {
  entityID?: Maybe<Scalars['Int']>;
  investorID?: Maybe<Scalars['Int']>;
  stoID: Scalars['Int'];
};

export type QueryInvestorInvoiceAlertArgs = {
  ID: Scalars['Int'];
};

export type QueryInvestorInvoiceAlertsArgs = {
  includeAllTypes?: Maybe<Scalars['Boolean']>;
};

export type QueryInvestorLogsArgs = {
  investorID: Scalars['Int'];
  stoID: Scalars['Int'];
};

export type QueryInvestorMeetingArgs = {
  meetingID: Scalars['Int'];
};

export type QueryInvestorPaymentChannelsArgs = {
  stoID: Scalars['Int'];
};

export type QueryInvestorPollArgs = {
  meetingID: Scalars['Int'];
};

export type QueryInvestorPreferredReturnsArgs = {
  investorID: Scalars['Int'];
};

export type QueryInvestorRelatedStoArgs = {
  investorID?: Maybe<Scalars['Int']>;
};

export type QueryInvestorShareArgs = {
  ID?: Maybe<Scalars['Int']>;
  shareTypeID?: Maybe<Scalars['Int']>;
  stoID?: Maybe<Scalars['Int']>;
};

export type QueryInvestorShareBalanceArgs = {
  investorID: Scalars['Int'];
  shareTypeIDs?: Maybe<Array<Scalars['Int']>>;
};

export type QueryInvestorSharesArgs = {
  getTrueBalances?: Maybe<Scalars['Boolean']>;
  investorID?: Maybe<Scalars['Int']>;
  isEntity?: Maybe<Scalars['Boolean']>;
  stoID?: Maybe<Scalars['Int']>;
};

export type QueryInvestorStoArgs = {
  stoID?: Maybe<Scalars['Int']>;
};

export type QueryInvestorStoNullableArgs = {
  stoID?: Maybe<Scalars['Int']>;
};

export type QueryInvestorUpdateArgs = {
  ID: Scalars['Int'];
};

export type QueryInvestorUpdatesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  stoID: Scalars['Int'];
};

export type QueryInvestorUserVotingArgs = {
  votingID: Scalars['Int'];
};

export type QueryInvestorVotingDocumentArgs = {
  documentID: Scalars['Int'];
};

export type QueryInvestorVotingOptionsArgs = {
  votingID: Scalars['Int'];
};

export type QueryInvestorVotingUserDataArgs = {
  votingID: Scalars['Int'];
};

export type QueryIsInvestorWhiteListedArgs = {
  walletAddress: Scalars['String'];
};

export type QueryKycFilesArgs = {
  investorID?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type QueryMoonpayAllTransactionsJsonAdminArgs = {
  investorID: Scalars['Int'];
};

export type QueryMoonpayBuyAlertTransactionJsonArgs = {
  alertID: Scalars['Int'];
};

export type QueryMoonpayBuyAlertTransactionJsonAdminArgs = {
  alertID: Scalars['Int'];
};

export type QueryMoonpayBuyAlertTransactionReceiptUrlArgs = {
  alertID: Scalars['Int'];
};

export type QueryMoonpayGetTransactionJsonArgs = {
  transactionID: Scalars['Int'];
};

export type QueryMoonpayGetTransactionJsonAdminArgs = {
  investorID: Scalars['Int'];
  transactionID: Scalars['Int'];
};

export type QueryMoonpayLastTransactionJsonAdminArgs = {
  investorID: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
};

export type QueryMoonpayWidgetUrlArgs = {
  alertID?: Maybe<Scalars['Int']>;
  shareTypeID: Scalars['Int'];
  shares: Scalars['Float'];
};

export type QueryMoonpayWidgetUrlAdminArgs = {
  alertID?: Maybe<Scalars['Int']>;
  investorID: Scalars['Int'];
  shareTypeID: Scalars['Int'];
  shares: Scalars['Float'];
};

export type QueryOfferedDocumentArgs = {
  documentID: Scalars['Int'];
};

export type QueryOnboardAccountArgs = {
  reAuthUrl?: Maybe<Scalars['String']>;
  returnUrl?: Maybe<Scalars['String']>;
};

export type QueryPintGetAccountDataInvestorArgs = {
  processor: PintPreconfiguredProcessors;
};

export type QueryPintGetConfigsJsonArrayArgs = {
  excludeTemplateData?: Maybe<Scalars['Boolean']>;
};

export type QueryPintGetLocalAccountDataPlatformArgs = {
  account: PintEntityInput;
  processor: PintPreconfiguredProcessors;
};

export type QueryPublicStoArgs = {
  stoID: Scalars['Int'];
};

export type QuerySendHelloSignTemplateSignRequestArgs = {
  documentID: Scalars['Int'];
  sharePurchaseID: Scalars['Int'];
};

export type QuerySharePurchaseDocumentArgs = {
  documentID: Scalars['Int'];
  investorID?: Maybe<Scalars['Int']>;
  sharePurchaseID: Scalars['Int'];
};

export type QuerySharePurchaseDocumentsArgs = {
  investorID?: Maybe<Scalars['Int']>;
  sharePurchaseID: Scalars['Int'];
};

export type QueryShareTypeSharesArgs = {
  shareTypeID: Scalars['Int'];
};

export type QuerySignedAndSettledDocumentsArgs = {
  input: GetSignedAndSettledInput;
};

export type QuerySubmittedDocumentArgs = {
  submittedDocumentID: Scalars['Int'];
};

export type QuerySubmittedDocumentsArgs = {
  minStatus: Scalars['Int'];
};

export type QueryTranslationsArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type QueryUnfinishedDocumentArgs = {
  documentID: Scalars['Int'];
};

export type QueryUnverifiedRegistrationsArgs = {
  ID?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['String']>;
};

/** All Types of Message Receiver */
export enum ReceiverType {
  Admin = 'Admin',
  Investor = 'Investor',
  Platform = 'Platform',
}

/** All reserved meta keys */
export enum ReservedMetaKeys {
  /** ID used to track the entity in external services and platforms */
  ExternalId = 'EXTERNAL_ID',
  /** values used to filter offerings based on entity preferences */
  InvestorFilterPreference = 'INVESTOR_FILTER_PREFERENCE',
}

/** Emails of friends being referred and invite content. */
export type ReferralInput = {
  content: Scalars['String'];
  emails: Array<Scalars['String']>;
};

export type Register = {
  ID: Scalars['Int'];
  address?: Maybe<Scalars['String']>;
  brokerID?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  investorType?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  referByInvestorID: Scalars['Int'];
  secret?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stoID: Scalars['Int'];
  zip?: Maybe<Scalars['String']>;
};

/** Register Investor vote data */
export type RegisterVoteInput = {
  meetingID: Scalars['Float'];
  optionID: Scalars['Float'];
  type: Scalars['Float'];
};

/** All Types of Message Sender */
export enum SenderType {
  Admin = 'Admin',
  Investor = 'Investor',
  Platform = 'Platform',
}

export type SellEntityMetrics = {
  entityID: Scalars['Int'];
  managedStos: Array<Sto>;
  metrics: Array<Metric>;
};

export type SellSideEntity = {
  ID: Scalars['Int'];
  /** Determines whether the investors in this entity can see stos outside of it */
  areInvestorsPublic: Scalars['Boolean'];
  details: Scalars['String'];
  getStos?: Maybe<Array<Sto>>;
  /** Determines whether the seller entity is visible to all investors or only investors part of it, in the marketplace */
  isPublic: Scalars['Boolean'];
  /** Determines whether the seller entity is visible to all investors or only investors part of it, in the chat module */
  isPubliclyAvailableToChat: Scalars['Boolean'];
  logoUrl: Scalars['String'];
  meta: Array<MetaValues>;
  stos?: Maybe<Array<Sto>>;
  title: Scalars['String'];
};

export type SellSideEntityMetaArgs = {
  entityID?: Maybe<Scalars['Int']>;
  keys?: Maybe<Array<ReservedMetaKeys>>;
  stoID?: Maybe<Scalars['Int']>;
};

export type SellSideEntityInput = {
  ID?: Maybe<Scalars['Int']>;
  /** Whether the investors can see stos outside this sell-side entity or not */
  areInvestorsPublic?: Maybe<Scalars['Boolean']>;
  cloudFileID?: Maybe<Scalars['Float']>;
  details?: Maybe<Scalars['String']>;
  /** denotes if the offerings managed by this sell side entity are publicly visible to everyone, or only to invited investors */
  isPublic?: Maybe<Scalars['Boolean']>;
  /** denotes if the offerings managed by this sell side entity are publicly visible to everyone, or only to invited investors - in the chat module */
  isPubliclyAvailableToChat?: Maybe<Scalars['Boolean']>;
  logoUrl?: Maybe<Scalars['String']>;
  stoIDs?: Maybe<Array<Scalars['Int']>>;
  title?: Maybe<Scalars['String']>;
};

/** Set password data */
export type SetPasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type Settings = {
  favicon?: Maybe<Scalars['String']>;
  investorCategories: Array<InvestorCategory>;
  isInternalExchangeEnabled: Scalars['Int'];
  tabTitle?: Maybe<Scalars['String']>;
};

export type Share = {
  ID: Scalars['Int'];
  investorID: Scalars['Int'];
  isBlockchainAuthorized: Scalars['Int'];
  isBlockchainFrozen: Scalars['Int'];
  shareType: ShareType;
  shareTypeID: Scalars['Int'];
  shares: Scalars['Float'];
  sharesHistoryID: Scalars['Int'];
  stoID: Scalars['Int'];
};

export type ShareHistoricalData = {
  ID: Scalars['Int'];
  dateOfChange?: Maybe<Scalars['Timestamp']>;
  premiumValue: Scalars['Float'];
  shareTypeID: Scalars['Int'];
  stoID: Scalars['Int'];
};

export type SharePurchaseDocument = {
  document: Document;
  requireOnce: Scalars['Int'];
  status: Scalars['Int'];
};

/** Denotes the provider of documents for share purchases */
export enum SharePurchaseModeEnum {
  Docusign = 'DOCUSIGN',
  Hellosign = 'HELLOSIGN',
  Internal = 'INTERNAL',
}

export type ShareType = {
  ID: Scalars['Int'];
  assetTag?: Maybe<Scalars['String']>;
  availableShare: Scalars['Float'];
  blockchainProtocol?: Maybe<BlockchainProtocol>;
  blockchainProtocolID?: Maybe<Scalars['Int']>;
  blockchaindecimals: Scalars['Int'];
  channelIDForAutoPayments?: Maybe<Scalars['Int']>;
  companyShares: Scalars['Float'];
  currency: Currency;
  currencyID: Scalars['Int'];
  custodianShares: Scalars['Float'];
  ethereumBlockchainPublicAddress?: Maybe<Scalars['String']>;
  ethereumContractAddress?: Maybe<Scalars['String']>;
  /** indicates if these shares are allowed to be purchased directly from the company or not */
  investorCanPurchaseDirectly: Scalars['Boolean'];
  isBlockchain: Scalars['Boolean'];
  isCertificateNosApplicable: Scalars['Boolean'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  isInvestorTradable: Scalars['Boolean'];
  isShareNosApplicable: Scalars['Boolean'];
  keyStoreFileAutoPayments?: Maybe<Scalars['String']>;
  keyStoreFileAutoPaymentsPassword?: Maybe<Scalars['String']>;
  maximumSharesToBuyByInvestor: Scalars['Int'];
  minimumSharesToBuyByInvestor: Scalars['Float'];
  nominalValue: Scalars['Float'];
  polyMeshDistributionVenueID?: Maybe<Scalars['Int']>;
  polymeshAccountID?: Maybe<Scalars['Int']>;
  premiumValue: Scalars['Float'];
  reduceSharesForPurchase: Scalars['Float'];
  sellToCompany: Scalars['Boolean'];
  sellValue: Scalars['Float'];
  /** The Percentage Value Showing The Maximum Shares An Investor Can Own Out Of Total Shares */
  shareOwnershipLimit: Scalars['Float'];
  sto: Sto;
  stoID: Scalars['Int'];
  title: Scalars['String'];
  totalPrice: Scalars['Float'];
  totalShares: Scalars['Float'];
  tradingDetails?: Maybe<ShareTypeTradeDetails>;
  walletCustodyType: Scalars['Int'];
};

export type ShareTypeTradeDetails = {
  /** The latest date at which these shares can be traded */
  closeTradeDate?: Maybe<Scalars['Timestamp']>;
  /** Maximum price at which shares of this kind can be traded. If not defined, no maximum is applied */
  maxTradeValue?: Maybe<Scalars['Float']>;
  /** Minimum price at which shares of this kind can be traded. If not defined, minimum is 0 */
  minTradeValue: Scalars['Float'];
  /** The earliest date at which these shares can be traded */
  openTradeDate?: Maybe<Scalars['Timestamp']>;
  shareType: ShareType;
  shareTypeID: Scalars['Int'];
};

/** Describes trading limitations for this type of share class */
export type ShareTypeTradingDetailsInput = {
  /** The latest date at which these shares can be traded */
  closeTradeDate?: Maybe<Scalars['DateTime']>;
  /** Maximum price at which shares of this kind can be traded. If not defined, no maximum is applied */
  maxTradeValue?: Maybe<Scalars['Float']>;
  /** Minimum price at which shares of this kind can be traded. If not defined, minimum is 0 */
  minTradeValue?: Maybe<Scalars['Float']>;
  /** The earliest date at which these shares can be traded */
  openTradeDate?: Maybe<Scalars['DateTime']>;
};

export type SharesWallet = {
  ID: Scalars['Int'];
  investor?: Maybe<Investor>;
  investorID: Scalars['Int'];
  isBlocked?: Maybe<Scalars['Boolean']>;
  publicKey?: Maybe<Scalars['String']>;
  shareType: ShareType;
  shareTypeID: Scalars['Int'];
  shares: Scalars['Float'];
};

/** SSO investor data */
export type SignInSsoInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

/** New investor data */
export type SignUpInput = {
  address?: Maybe<Scalars['String']>;
  brokerID?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  investorType?: Maybe<Scalars['Int']>;
  lastName: Scalars['String'];
  metaValues?: Maybe<Array<MetaValuesInput>>;
  password: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  referredBy?: Maybe<Scalars['String']>;
  referredByID?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['String']>;
  stoID: Scalars['Int'];
  zip?: Maybe<Scalars['String']>;
};

export type SignUpMarketSpaceInput = {
  address?: Maybe<Scalars['String']>;
  brokerID?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  investorType?: Maybe<Scalars['Int']>;
  lastName: Scalars['String'];
  metaValues?: Maybe<Array<MetaValuesInput>>;
  password: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  referredBy?: Maybe<Scalars['String']>;
  referredByID?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['String']>;
  stoID: Scalars['Int'];
  zip?: Maybe<Scalars['String']>;
};

export type SmtpSettings = {
  ID: Scalars['Int'];
  fromAddress: Scalars['String'];
  fromName: Scalars['String'];
  host: Scalars['String'];
  isSsl3Enabled: Scalars['Boolean'];
  isValid: Scalars['Boolean'];
  port: Scalars['Float'];
  sellSideEntityID?: Maybe<Scalars['Int']>;
  stoID?: Maybe<Scalars['Int']>;
  user: Scalars['String'];
};

export type SmtpSettingsCreate = {
  fromAddress: Scalars['String'];
  fromName: Scalars['String'];
  host: Scalars['String'];
  isSsl3Enabled?: Maybe<Scalars['Boolean']>;
  password: Scalars['String'];
  port: Scalars['Int'];
  sellSideEntityID?: Maybe<Scalars['Int']>;
  stoID?: Maybe<Scalars['Int']>;
  user: Scalars['String'];
};

export type SmtpSettingsQuery = {
  ID?: Maybe<Scalars['Int']>;
  sellSideEntityID?: Maybe<Scalars['Int']>;
  stoID?: Maybe<Scalars['Int']>;
};

export type SmtpSettingsUpdate = {
  ID: Scalars['Int'];
  fromAddress?: Maybe<Scalars['String']>;
  fromName?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  isSsl3Enabled?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['Int']>;
  sellSideEntityID?: Maybe<Scalars['Int']>;
  stoID?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['String']>;
};

export type Sto = {
  ID: Scalars['Int'];
  baseCurrencyID: Scalars['Int'];
  companyType: Scalars['Int'];
  createdAt: Scalars['Timestamp'];
  details: Scalars['String'];
  disclaimer: Scalars['String'];
  documents?: Maybe<Array<PropertyFile>>;
  docusign_sto_contract: Scalars['String'];
  docusign_sto_purchase: Scalars['String'];
  emailFooter: Scalars['String'];
  /** please use the share type */
  ethereumContractAddress: Scalars['String'];
  /** please use the share type */
  ethereumWhitelistAddress: Scalars['String'];
  exchangeOpenDate: Scalars['String'];
  externalSystemID: Scalars['Int'];
  fullDetails: Scalars['String'];
  helloSignClientID?: Maybe<Scalars['String']>;
  images: Array<PropertyFile>;
  investmentReturn: Scalars['Float'];
  inviteFriendEmailText: Scalars['String'];
  isActive: Scalars['Int'];
  isBimountEnabled?: Maybe<Scalars['Int']>;
  isBuyButtonEnabled: Scalars['Boolean'];
  isNDAFlowEnabled: Scalars['Boolean'];
  isProjectPDFPresentationEnabled?: Maybe<Scalars['Boolean']>;
  legalDetails?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  logoUrl: Scalars['String'];
  meta: Array<StoMetaValue>;
  metaFilters: Array<StoMetaValue>;
  parsedSettings: Settings;
  picture: Scalars['String'];
  popularity: Scalars['Int'];
  presentation?: Maybe<PropertyFile>;
  projectAddress?: Maybe<Scalars['String']>;
  projectCost?: Maybe<Scalars['Int']>;
  propertyPicture?: Maybe<Scalars['String']>;
  /** please use the translation keys instead */
  registrationSuccessText: Scalars['String'];
  registrationText?: Maybe<Scalars['String']>;
  sellSideEntity?: Maybe<SellSideEntity>;
  sell_side_entityID?: Maybe<Scalars['Int']>;
  stoDetailsInvestNowLink?: Maybe<Scalars['String']>;
  /** Not used anymore */
  stoInvestorTypes: Array<Scalars['Int']>;
  stoInvestorTypesNotOnShareRegister: Array<Scalars['Int']>;
  stoType: Scalars['Int'];
  stolink: Scalars['String'];
  stolinkfull: Scalars['String'];
  title: Scalars['String'];
  verifyInvestorComHostToken?: Maybe<Scalars['String']>;
  website: Scalars['String'];
};

export type StoMetaArgs = {
  keys?: Maybe<Array<Scalars['String']>>;
};

export type StoCreateInput = {
  companyType: Scalars['Int'];
  /** the blockchain that this offering is supposed to create shares on */
  defaultBlockchainID: Scalars['Int'];
  /** default offering currency */
  defaultCurrencyID: Scalars['Int'];
  /** the token studio used for creating shares in this offering */
  defaultTokenStudioID: Scalars['Int'];
  details: Scalars['String'];
  disclaimer?: Maybe<Scalars['String']>;
  emailFooter?: Maybe<Scalars['String']>;
  fullDetails: Scalars['String'];
  /** determines if the project is active or not. Default value is OFF */
  isActive?: Maybe<Scalars['Boolean']>;
  /** determines if the project is on for buying. Default value is OFF */
  isBuyButtonEnabled?: Maybe<Scalars['Boolean']>;
  /** display the PDF presentation. If not provided, it will take the default value set by the platform admin from the environment parameters */
  isNDAFlowEnabled?: Maybe<Scalars['Boolean']>;
  /** display the PDF presentation. If not provided, it will take the default value set by the platform admin from the environment parameters */
  isProjectPDFPresentationEnabled?: Maybe<Scalars['Boolean']>;
  legalDetails?: Maybe<Scalars['String']>;
  /** URL to the STOs logo */
  logo: Scalars['String'];
  /** if provided, it will attempt to link this offering to the sell side entity. If no such entity is found, skips without any errors */
  managedBySellSideEntityID?: Maybe<Scalars['Int']>;
  projectAddress?: Maybe<Scalars['String']>;
  registrationText?: Maybe<Scalars['String']>;
  /** the link used by investors to navigate to the external deal page */
  stoDetailsInvestNowLink?: Maybe<Scalars['String']>;
  /** this will be taken into consideration only if the areSTOHostnamesEnabled feature is enabled */
  stoLink?: Maybe<Scalars['String']>;
  /** this will be taken into consideration only if the areSTOHostnamesEnabled feature is enabled */
  stoLinkFull?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  website?: Maybe<Scalars['String']>;
};

export type StoInvestorType = {
  ID: Scalars['Float'];
  type: Scalars['String'];
};

export type StoMetaValue = {
  categoryKey?: Maybe<Scalars['String']>;
  categoryKeyTranslation: Scalars['String'];
  display: Scalars['Boolean'];
  displayClosedProp: Scalars['Boolean'];
  isManagedStoTableColumn: Scalars['Boolean'];
  key: Scalars['String'];
  order: Scalars['Float'];
  stoID: Scalars['Float'];
  translation: Scalars['String'];
  type: MetaKeyTypes;
  value: Scalars['String'];
};

/** Partial SMTP settings input for an STO */
export type StoSmtpUpdateInput = {
  fromAddress?: Maybe<Scalars['String']>;
  fromName?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  isSSL3Enabled?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['String']>;
  stoID: Scalars['Int'];
  username?: Maybe<Scalars['String']>;
};

/** Represents an file type in the digishares file storage */
export enum StorageType {
  Chat = 'CHAT',
  InvestorDocument = 'INVESTOR_DOCUMENT',
  KycFile = 'KYC_FILE',
  Offering = 'OFFERING',
  PropertyFile = 'PROPERTY_FILE',
  SellSideEntity = 'SELL_SIDE_ENTITY',
  Update = 'UPDATE',
}

export type StosMetaKeys = {
  areValuesMutuallyExclusive?: Maybe<Scalars['Boolean']>;
  categoryKey?: Maybe<Scalars['String']>;
  categoryKeyTranslation: Scalars['String'];
  display: Scalars['Int'];
  displayClosedProp: Scalars['Int'];
  isManagedStoTableColumn?: Maybe<Scalars['Int']>;
  key: Scalars['String'];
  order: Scalars['Int'];
  requireNDA: Scalars['Int'];
  translation: Scalars['String'];
  type: Scalars['String'];
};

export type Subscription = {
  /** Subscription for getting the last Chat record between two sides */
  getLastChatRecord: Chat;
  /** Subscription for getting the current investor's Invoice that was just resolved */
  getLastResolvedInvoice: InvestorInvoices;
  /** Get the user's KYC data */
  rootKyc: KycData;
};

export enum TopUpAlertStatus {
  ApprovedByAdmin = 'APPROVED_BY_ADMIN',
  DeclinedByAdmin = 'DECLINED_BY_ADMIN',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Successful = 'SUCCESSFUL',
}

/** Denotes the types of price transformers */
export enum TransformerType {
  Discount = 'DISCOUNT',
  Fee = 'FEE',
}

/** Terms and Conditions Config */
export type TermsAndConditionsConfig = {
  /** URL to T&C Page */
  link: Scalars['String'];
  /** Text that appears on the hyperlink to T&C */
  text: Scalars['String'];
};

/** The Type of the Transaction Happening in the Exchange Module */
export enum TransactionType {
  Buy = 'BUY',
  Sell = 'SELL',
}

/** Represents an actor type in a share transfer */
export enum TransferEntity {
  Company = 'Company',
  Custodian = 'Custodian',
  Investor = 'Investor',
}

/** Transfer share data */
export type TransferShareInput = {
  certificateNos?: Maybe<Scalars['String']>;
  investment?: Maybe<Scalars['Float']>;
  investmentDescription?: Maybe<Scalars['String']>;
  investorID: Scalars['Int'];
  /** Manual choice for sending email notification to investor. If unset, will use platform parameter isShareTransferEmailEnabled (default TRUE). */
  sendEmailManualOverride?: Maybe<Scalars['Boolean']>;
  shareNos?: Maybe<Scalars['String']>;
  shareTypeID: Scalars['Int'];
  stoID: Scalars['Int'];
  /** Idempotency token to verify transfer */
  token?: Maybe<Scalars['String']>;
  tokensToTransfer: Scalars['Float'];
};

export type TranslatedKycField = {
  key: Scalars['String'];
  translation?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Translation = {
  ID: Scalars['Int'];
  key: Scalars['String'];
  locale: Scalars['String'];
  translation: Scalars['String'];
};

/** Translations arguments */
export type TranslationInput = {
  key: Scalars['String'];
  locale: Scalars['String'];
  translation?: Maybe<Scalars['String']>;
};

export type Update = {
  ID: Scalars['Int'];
  coverImageUrl: Scalars['String'];
  coverPhoto?: Maybe<Scalars['String']>;
  /** @deprecated Use coverImageUrl instead */
  coverphoto: Scalars['String'];
  date: Scalars['String'];
  details: Scalars['String'];
  stoID: Scalars['Int'];
  title: Scalars['String'];
};

/** Update meta value data */
export type UpdateMetadataValueInput = {
  key: Scalars['String'];
  stoID: Scalars['Int'];
  value: Scalars['String'];
};

/** Update offering data */
export type UpdateOfferingInput = {
  cloudLogoID?: Maybe<Scalars['Int']>;
  cloudPictureID?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isBuyButtonEnabled?: Maybe<Scalars['Boolean']>;
};

/** Update a Share Type with the following data. Leaving fields undefined will skip updating that one in particular */
export type UpdateShareTypeInput = {
  ID: Scalars['Int'];
  assetName?: Maybe<Scalars['String']>;
  assetTag?: Maybe<Scalars['String']>;
  blockchainBuyOrdersAllowed?: Maybe<Scalars['Boolean']>;
  blockchainProtocolID?: Maybe<Scalars['Int']>;
  ethereumBlockchainPublicAddress?: Maybe<Scalars['String']>;
  ethereumContractAddress?: Maybe<Scalars['String']>;
  ethereumWhitelistAddress?: Maybe<Scalars['String']>;
  investorCanPurchaseDirectly?: Maybe<Scalars['Boolean']>;
  isCertificateNosApplicable?: Maybe<Scalars['Boolean']>;
  isInvestorTradable?: Maybe<Scalars['Boolean']>;
  isMeetingRightsApplicable?: Maybe<Scalars['Boolean']>;
  isShareNosApplicable?: Maybe<Scalars['Boolean']>;
  isVotingRightsApplicable?: Maybe<Scalars['Boolean']>;
  maximumSharesToBuyByInvestor?: Maybe<Scalars['Float']>;
  minimumSharesToBuyByInvestor?: Maybe<Scalars['Float']>;
  needAuthorization?: Maybe<Scalars['Boolean']>;
  nominalValue?: Maybe<Scalars['Float']>;
  premiumValue?: Maybe<Scalars['Float']>;
  reduceSharesForPurchase?: Maybe<Scalars['Float']>;
  sellToCompany?: Maybe<Scalars['Boolean']>;
  sellValue?: Maybe<Scalars['Float']>;
  shareOwnershipLimit?: Maybe<Scalars['Float']>;
  tanganyWalletID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tradingDetails?: Maybe<ShareTypeTradingDetailsInput>;
  votingPower?: Maybe<Scalars['Float']>;
  walletCustodyType?: Maybe<Scalars['Int']>;
};

/** Information for uploading file to cloud storage */
export type UploadFileUrl = {
  /** cloud storage file ID */
  fileID: Scalars['Float'];
  /** signed url for uploading */
  url: Scalars['String'];
};

export type User = {
  ID: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  isActive: Scalars['Boolean'];
  isPlatformAdminLogin?: Maybe<Scalars['Boolean']>;
  isTwoFactorEnabled: Scalars['Boolean'];
  lastName: Scalars['String'];
  offering: Sto;
  sellSideEntity?: Maybe<SellSideEntity>;
  sell_side_entityID?: Maybe<Scalars['Int']>;
  stoID: Scalars['Int'];
  username: Scalars['String'];
};

/** Crypto Reciepe data */
export type VerifyCryptoReciepeInput = {
  amount: Scalars['Float'];
  channelID: Scalars['Int'];
  currencyID: Scalars['Int'];
  details: Scalars['String'];
  stoID: Scalars['Int'];
  transactionHash: Scalars['String'];
};

/** Parameter used to describe the keyless integration. This integration does not have any sort of automation enabled. It only redirects investors to a specified URL */
export type VerifyInvestorKeyLessParam = {
  /** Determines if this integration is on */
  isEnabled: Scalars['Boolean'];
  /** Url to which the investors will be redirected to */
  redirectUrl: Scalars['String'];
};

export type VotingDocuments = {
  ID: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  documentLink: Scalars['String'];
  title: Scalars['String'];
  votingID: Scalars['Int'];
  votingOption: VotingOption;
  votingOptionID: Scalars['Int'];
};

export type VotingOption = {
  ID: Scalars['Int'];
  companyComments: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  documents: Array<VotingDocuments>;
  isActiveByAdmin: Scalars['Int'];
  optionTxt: Scalars['String'];
  userVotedOption?: Maybe<VotingUser>;
  votingID: Scalars['Int'];
  votingUserStatistic: VotingUserStatistic;
};

export type VotingUser = {
  ID: Scalars['Int'];
  investmentContributed: Scalars['Int'];
  isCastedByInvestor: Scalars['Int'];
  nominalInvestmentContributed: Scalars['Int'];
  userID: Scalars['Int'];
  votesContributed: Scalars['Int'];
  votingID: Scalars['Int'];
  votingOption: VotingOption;
  votingOptionID: Scalars['Int'];
};

export type VotingUserData = {
  ID: Scalars['Int'];
  attendMeeting: Scalars['Int'];
  investorID: Scalars['Int'];
  unannouncedDecision: Scalars['Int'];
  votingID: Scalars['Int'];
};

export type VotingUserStatistic = {
  thisPercent: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type Web3Providers = {
  blockchainExplorerURL: Scalars['String'];
  blockchainSwapContractAddress: Scalars['String'];
  blockchainURL: Scalars['String'];
};

/** All payment modes that are supported by xendit */
export enum XenditMode {
  CreditCard = 'CreditCard',
  GCash = 'GCash',
}

/** Used only when the active payment provider is XENDIT */
export type XenditPayInvoiceInput = {
  paymentMode: XenditMode;
};

export type Blockchains = {
  ID: Scalars['Int'];
  chainNumber?: Maybe<Scalars['String']>;
  chainNumberTestnet?: Maybe<Scalars['String']>;
  explorerURL?: Maybe<Scalars['String']>;
  explorerURLTestnet?: Maybe<Scalars['String']>;
  isEVMBased: Scalars['Boolean'];
  key?: Maybe<Scalars['String']>;
  keyTestnet?: Maybe<Scalars['String']>;
  /** link that points to the blockchains' main net */
  link?: Maybe<Scalars['String']>;
  /** link that points to the blockchains' test net */
  linkTestnet?: Maybe<Scalars['String']>;
  nodeProviderURL?: Maybe<Scalars['String']>;
  nodeProviderURLTestnet?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  passwordTestnet?: Maybe<Scalars['String']>;
  swapContractAddress?: Maybe<Scalars['String']>;
  swapContractAddressTestnet?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  usernameTestnet?: Maybe<Scalars['String']>;
};

export type VerifyInvestorQueryVariables = Exact<{ [key: string]: never }>;

export type VerifyInvestorQuery = Pick<Query, 'getVerifyInvestorUrl'> & {
  investorUser?: Maybe<{
    sto: Pick<
      Sto,
      | 'ID'
      | 'title'
      | 'details'
      | 'isActive'
      | 'logo'
      | 'ethereumContractAddress'
      | 'ethereumWhitelistAddress'
      | 'disclaimer'
      | 'stolink'
      | 'stolinkfull'
      | 'stoType'
      | 'stoInvestorTypes'
      | 'registrationText'
      | 'stoInvestorTypesNotOnShareRegister'
      | 'companyType'
      | 'registrationSuccessText'
      | 'fullDetails'
      | 'exchangeOpenDate'
      | 'propertyPicture'
      | 'externalSystemID'
      | 'projectAddress'
      | 'legalDetails'
      | 'picture'
      | 'verifyInvestorComHostToken'
    > & {
      parsedSettings: { investorCategories: Array<Pick<InvestorCategory, 'value' | 'label'>> };
      images: Array<Pick<PropertyFile, 'ID' | 'title' | 'url'>>;
      documents?: Maybe<Array<Pick<PropertyFile, 'ID' | 'title' | 'url'>>>;
    };
    investor: Pick<
      Investor,
      | 'ID'
      | 'firstName'
      | 'lastName'
      | 'address'
      | 'country'
      | 'phone'
      | 'cell'
      | 'zip'
      | 'town'
      | 'state'
      | 'email'
      | 'passportNumber'
      | 'nationalID'
      | 'kinname'
      | 'kinphone'
      | 'kinemail'
      | 'investorType'
      | 'companyName'
      | 'titleWithinCompany'
      | 'powerToBindCompany'
      | 'birthDate'
      | 'isTwoFactorEnabled'
      | 'language'
      | 'middleName'
      | 'socialSecurity'
      | 'mailingAddress'
      | 'faxNumber'
      | 'maritalStatus'
      | 'occupation'
      | 'employerName'
      | 'employerAddress'
      | 'retirementAccount'
      | 'trustOrBusinessEntity'
      | 'dateIncorporation'
      | 'taxID'
      | 'govtID'
      | 'isTax'
      | 'secondaryContactName'
      | 'secondaryContactPhone'
      | 'secondaryContactEmail'
      | 'primaryContactName'
      | 'primaryContactPhone'
      | 'primaryContactEmail'
      | 'countryBusiness'
      | 'countryCitizenship'
      | 'taxCountry'
      | 'userName'
    >;
    investorSto: Pick<
      InvestorSto,
      | 'ID'
      | 'isAccountClosed'
      | 'investorID'
      | 'stoID'
      | 'expectedShares'
      | 'expectedInvestment'
      | 'isKYC'
      | 'applied'
      | 'status'
      | 'usufructuaryFirstName'
      | 'usufructuaryLastName'
      | 'usufructuaryAddress'
      | 'usufructuaryCity'
      | 'usufructuaryCountry'
      | 'usufructuaryEmail'
      | 'beneficialFirstName'
      | 'beneficialLastName'
      | 'beneficialAddress'
      | 'beneficialCity'
      | 'beneficialCountry'
      | 'beneficialEmail'
      | 'beneficialBirth'
      | 'beneficialNationality'
      | 'isUsufructuary'
      | 'isActive'
      | 'notes'
    >;
  }>;
};

export type FindBlockchainProtocolQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type FindBlockchainProtocolQuery = {
  findBlockchainProtocol?: Maybe<
    Pick<BlockchainProtocol, 'ID' | 'protocolName' | 'blockchainID'> & {
    blockchain: Pick<
      Blockchains,
      | 'ID'
      | 'title'
      | 'nodeProviderURL'
      | 'nodeProviderURLTestnet'
      | 'chainNumber'
      | 'chainNumberTestnet'
      | 'explorerURL'
      | 'explorerURLTestnet'
      | 'swapContractAddress'
      | 'swapContractAddressTestnet'
      | 'key'
      | 'keyTestnet'
      | 'link'
      | 'linkTestnet'
      | 'username'
      | 'usernameTestnet'
      | 'password'
      | 'passwordTestnet'
    >;
  }
  >;
};

export type FindBlockchainQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type FindBlockchainQuery = {
  findBlockchain?: Maybe<
    Pick<
      Blockchains,
      | 'ID'
      | 'title'
      | 'nodeProviderURL'
      | 'nodeProviderURLTestnet'
      | 'chainNumber'
      | 'chainNumberTestnet'
      | 'explorerURL'
      | 'explorerURLTestnet'
      | 'swapContractAddress'
      | 'swapContractAddressTestnet'
      | 'key'
      | 'keyTestnet'
      | 'link'
      | 'linkTestnet'
      | 'username'
      | 'usernameTestnet'
      | 'password'
      | 'passwordTestnet'
    >
  >;
};

export type FindAllBlockchainProtocolsQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllBlockchainProtocolsQuery = {
  findAllBlockchainProtocols?: Maybe<
    Array<
      Pick<BlockchainProtocol, 'ID' | 'protocolName' | 'blockchainID'> & {
      blockchain: Pick<
        Blockchains,
        | 'ID'
        | 'title'
        | 'nodeProviderURL'
        | 'nodeProviderURLTestnet'
        | 'chainNumber'
        | 'chainNumberTestnet'
        | 'explorerURL'
        | 'explorerURLTestnet'
        | 'swapContractAddress'
        | 'swapContractAddressTestnet'
        | 'key'
        | 'keyTestnet'
        | 'link'
        | 'linkTestnet'
        | 'username'
        | 'usernameTestnet'
        | 'password'
        | 'passwordTestnet'
      >;
    }
    >
  >;
};

export type MessageCreateMutationVariables = Exact<{
  data: ChatInput;
}>;

export type MessageCreateMutation = Pick<Mutation, 'messageCreate'>;

export type UpdateChatsToSeenMutationVariables = Exact<{
  sender: SenderType;
  stoID: Scalars['Int'];
}>;

export type UpdateChatsToSeenMutation = Pick<Mutation, 'updateChatsToSeen'>;

export type UpdatePriceNegotiationChatsToSeenMutationVariables = Exact<{
  context: ChatContext;
  contextID: Scalars['Int'];
  counterpartID: Scalars['Int'];
}>;

export type UpdatePriceNegotiationChatsToSeenMutation = Pick<Mutation, 'updatePriceNegotiationChatsToSeen'>;

export type GetPriceNegotiationListQueryVariables = Exact<{
  orderID: Scalars['Int'];
}>;

export type GetPriceNegotiationListQuery = {
  getPriceNegotiationList: Array<
    Pick<
      PriceNegotiationListItem,
      'counterpartID' | 'orderID' | 'orderOwnerID' | 'isRead' | 'dateRead' | 'formattedDateSent' | 'counterpartFullName'
    >
  >;
};

export type GetChatsQueryVariables = Exact<{
  chatBetween: ChatBetween;
  stoID?: Maybe<Scalars['Int']>;
  context?: Maybe<ChatContext>;
  contextID?: Maybe<Scalars['Int']>;
  counterpartID?: Maybe<Scalars['Int']>;
}>;

export type GetChatsQuery = {
  getChats: Array<
    Pick<
      Chat,
      | 'ID'
      | 'sender'
      | 'receiver'
      | 'investorID'
      | 'adminID'
      | 'stoID'
      | 'message'
      | 'type'
      | 'dateSent'
      | 'isRead'
      | 'dateRead'
      | 'isEdited'
      | 'location'
      | 'isDeleted'
      | 'context'
      | 'contextID'
      | 'contextReceiverID'
    > & {
    cloudFile?: Maybe<Pick<CloudFile, 'name' | 'url'>>;
    admin?: Maybe<
      Pick<User, 'firstName' | 'lastName'> & { sellSideEntity?: Maybe<Pick<SellSideEntity, 'ID' | 'title'>> }
    >;
  }
  >;
};

export type GetStoInfoQueryVariables = Exact<{
  stoID: Scalars['Int'];
}>;

export type GetStoInfoQuery = { findSto?: Maybe<Pick<Sto, 'ID' | 'title' | 'details'>> };

export type GetUnreadMessagesCountQueryVariables = Exact<{
  sender: SenderType;
}>;

export type GetUnreadMessagesCountQuery = Pick<Query, 'getUnreadMessagesCount'>;

export type GetPriceNegotiationUnreadMessagesCountQueryVariables = Exact<{
  context: ChatContext;
  contextID: Scalars['Int'];
  counterpartID?: Maybe<Scalars['Int']>;
}>;

export type GetPriceNegotiationUnreadMessagesCountQuery = Pick<Query, 'getPriceNegotiationUnreadMessagesCount'>;

export type GetLastChatRecordSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GetLastChatRecordSubscription = {
  getLastChatRecord: Pick<
    Chat,
    | 'ID'
    | 'sender'
    | 'receiver'
    | 'investorID'
    | 'adminID'
    | 'stoID'
    | 'message'
    | 'type'
    | 'dateSent'
    | 'isRead'
    | 'dateRead'
    | 'isEdited'
    | 'location'
    | 'isDeleted'
    | 'context'
    | 'contextID'
    | 'contextReceiverID'
  >;
};

export type ConfirmCloudUploadedFileMutationVariables = Exact<{
  fileID: Scalars['Int'];
}>;

export type ConfirmCloudUploadedFileMutation = Pick<Mutation, 'saveUploadedFile'>;

export type GetCloudUploadUrlMutationVariables = Exact<{
  filename: Scalars['String'];
  filetype: Scalars['String'];
  type: StorageType;
}>;

export type GetCloudUploadUrlMutation = { getUploadUrl: Pick<UploadFileUrl, 'fileID' | 'url'> };

export type GetInvestorCommissionsSumQueryVariables = Exact<{
  status?: Maybe<PaymentStatus>;
}>;

export type GetInvestorCommissionsSumQuery = Pick<Query, 'getInvestorCommissionsSum'>;

export type CustomizationAppDataQueryVariables = Exact<{
  componentTitle?: Maybe<Scalars['String']>;
  componentId?: Maybe<Scalars['Int']>;
}>;

export type CustomizationAppDataQuery = {
  fetchCustomizedComponent: Pick<ComponentCustomization, 'ID' | 'component' | 'body'>;
};

export type SignInMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  sto: Scalars['Int'];
}>;

export type SignInMutation = Pick<Mutation, 'signIn'>;

export type SignInSsoMutationVariables = Exact<{
  data: SignInSsoInput;
}>;

export type SignInSsoMutation = Pick<Mutation, 'signInSSO'>;

export type SignUpMarketSpaceMutationVariables = Exact<{
  data: SignUpMarketSpaceInput;
}>;

export type SignUpMarketSpaceMutation = Pick<Mutation, 'signUpMarketSpace'>;

export type UploadMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;

export type UploadMutation = { fileUpload: Pick<FileUploaded, 'link' | 'name'> };

export type FileRemoveMutationVariables = Exact<{
  file: Scalars['String'];
}>;

export type FileRemoveMutation = Pick<Mutation, 'fileRemove'>;

export type SignUpMutationVariables = Exact<{
  data: SignUpInput;
}>;

export type SignUpMutation = Pick<Mutation, 'signUp'>;

export type ChangePasswordMutationVariables = Exact<{
  data: ChangePasswordInput;
}>;

export type ChangePasswordMutation = Pick<Mutation, 'investorChangePassword'>;

export type ToggleTwoFaMutationVariables = Exact<{ [key: string]: never }>;

export type ToggleTwoFaMutation = Pick<Mutation, 'investorToggleTwoFA'>;

export type InvestorPublicKeyAddMutationVariables = Exact<{
  title: Scalars['String'];
  blockchainID: Scalars['Int'];
}>;

export type InvestorPublicKeyAddMutation = Pick<Mutation, 'investorPublicKeyAdd'>;

export type AutomatedWalletWhitelistMutationVariables = Exact<{
  shareTypeID: Scalars['Int'];
  walletAddress: Scalars['String'];
}>;

export type AutomatedWalletWhitelistMutation = Pick<Mutation, 'whitelistWallet'>;

export type InvestorPublicKeyDeleteMutationVariables = Exact<{
  _id: Scalars['Int'];
}>;

export type InvestorPublicKeyDeleteMutation = Pick<Mutation, 'investorPublicKeyDelete'>;

export type InvestorProfileMutationVariables = Exact<{
  data: InvestorProfileInput;
}>;

export type InvestorProfileMutation = Pick<Mutation, 'investorProfile'>;

export type UsufructuaryUpdateMutationVariables = Exact<{
  data: InvestorUsufructuaryInput;
}>;

export type UsufructuaryUpdateMutation = Pick<Mutation, 'investorUsufructuaryUpdate'>;

export type BeneficialUpdateMutationVariables = Exact<{
  data: InvestorBeneficialInput;
}>;

export type BeneficialUpdateMutation = Pick<Mutation, 'investorBeneficialUpdate'>;

export type InvestorVerifyMutationVariables = Exact<{
  secret: Scalars['String'];
}>;

export type InvestorVerifyMutation = Pick<Mutation, 'investorVerify'>;

export type InvestorResetMutationVariables = Exact<{
  email: Scalars['String'];
  STO: Scalars['Int'];
}>;

export type InvestorResetMutation = Pick<Mutation, 'investorReset'>;

export type TwoFaConfirmMutationVariables = Exact<{
  code: Scalars['Int'];
}>;

export type TwoFaConfirmMutation = Pick<Mutation, 'investor2FAConfirm'>;

export type InvestorSetPasswordMutationVariables = Exact<{
  data: SetPasswordInput;
}>;

export type InvestorSetPasswordMutation = Pick<Mutation, 'investorSetPassword'>;

export type SetInvestorParamMutationVariables = Exact<{
  param: InvestorParam;
  intValue?: Maybe<Scalars['Int']>;
  stringValue?: Maybe<Scalars['String']>;
}>;

export type SetInvestorParamMutation = Pick<Mutation, 'setInvestorParam'>;

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  investorUser?: Maybe<{
    sto: Pick<
      Sto,
      | 'ID'
      | 'title'
      | 'details'
      | 'isActive'
      | 'logo'
      | 'ethereumContractAddress'
      | 'ethereumWhitelistAddress'
      | 'disclaimer'
      | 'stolink'
      | 'stolinkfull'
      | 'stoType'
      | 'stoInvestorTypes'
      | 'registrationText'
      | 'stoInvestorTypesNotOnShareRegister'
      | 'companyType'
      | 'registrationSuccessText'
      | 'fullDetails'
      | 'exchangeOpenDate'
      | 'propertyPicture'
      | 'externalSystemID'
      | 'projectAddress'
      | 'legalDetails'
      | 'picture'
      | 'verifyInvestorComHostToken'
    > & {
      parsedSettings: Pick<Settings, 'favicon' | 'tabTitle'> & {
        investorCategories: Array<Pick<InvestorCategory, 'value' | 'label'>>;
      };
      images: Array<Pick<PropertyFile, 'ID' | 'title' | 'url'>>;
      documents?: Maybe<Array<Pick<PropertyFile, 'ID' | 'title' | 'url'>>>;
    };
    investor: Pick<
      Investor,
      | 'ID'
      | 'firstName'
      | 'lastName'
      | 'address'
      | 'country'
      | 'phone'
      | 'cell'
      | 'zip'
      | 'town'
      | 'state'
      | 'email'
      | 'passportNumber'
      | 'nationalID'
      | 'driversLicenseID'
      | 'kinname'
      | 'kinphone'
      | 'kinemail'
      | 'investorType'
      | 'companyName'
      | 'titleWithinCompany'
      | 'powerToBindCompany'
      | 'birthDate'
      | 'isTwoFactorEnabled'
      | 'language'
      | 'middleName'
      | 'socialSecurity'
      | 'mailingAddress'
      | 'faxNumber'
      | 'maritalStatus'
      | 'occupation'
      | 'employerName'
      | 'employerAddress'
      | 'retirementAccount'
      | 'trustOrBusinessEntity'
      | 'dateIncorporation'
      | 'taxID'
      | 'govtID'
      | 'isTax'
      | 'secondaryContactName'
      | 'secondaryContactPhone'
      | 'secondaryContactEmail'
      | 'primaryContactName'
      | 'primaryContactPhone'
      | 'primaryContactEmail'
      | 'countryBusiness'
      | 'countryCitizenship'
      | 'taxCountry'
      | 'userName'
      | 'investmentLimitUpdateDate'
      | 'allowedTotalInvestment'
      | 'yearlyTotalInvestment'
      | 'isEntity'
      | 'isEntityID'
    > & {
      asEntity?: Maybe<
        Pick<
          InvestingEntity,
          | 'ID'
          | 'investorID'
          | 'typeID'
          | 'taxId'
          | 'name'
          | 'nickname'
          | 'accredited'
          | 'paymentMethod'
          | 'address'
          | 'city'
          | 'postalCode'
          | 'country'
          | 'state'
          | 'isApprovedByAdmin'
        > & {
        members: Array<
          Pick<
            InvestingEntityMember,
            'ID' | 'investorID' | 'entityID' | 'firstName' | 'lastName' | 'role' | 'signatory' | 'email'
          >
        >;
        type: Pick<InvestingEntityTypes, 'ID' | 'title' | 'countries'>;
      }
      >;
      meta: Array<Pick<MetaValues, 'type' | 'value' | 'key' | 'order'>>;
    };
    investorSto: Pick<
      InvestorSto,
      | 'ID'
      | 'isAccountClosed'
      | 'investorID'
      | 'stoID'
      | 'expectedShares'
      | 'expectedInvestment'
      | 'isKYC'
      | 'applied'
      | 'status'
      | 'usufructuaryFirstName'
      | 'usufructuaryLastName'
      | 'usufructuaryAddress'
      | 'usufructuaryCity'
      | 'usufructuaryCountry'
      | 'usufructuaryEmail'
      | 'beneficialFirstName'
      | 'beneficialLastName'
      | 'beneficialAddress'
      | 'beneficialCity'
      | 'beneficialCountry'
      | 'beneficialEmail'
      | 'beneficialBirth'
      | 'beneficialNationality'
      | 'isUsufructuary'
      | 'isActive'
      | 'notes'
    >;
  }>;
};

export type InfoQueryVariables = Exact<{
  _id: Scalars['Int'];
}>;

export type InfoQuery = {
  publicSto?: Maybe<
    Pick<PublicSto, 'stolinkfull' | 'logo' | 'registrationText' | 'title'> & {
    settings: Pick<Settings, 'favicon' | 'tabTitle'> & {
      investorCategories: Array<Pick<InvestorCategory, 'value' | 'label'>>;
    };
  }
  >;
};

export type InvestorPublicKeysQueryVariables = Exact<{ [key: string]: never }>;

export type InvestorPublicKeysQuery = { investorPublicKeys?: Maybe<Array<Pick<PublicKey, 'ID' | 'title'>>> };

export type InvestorActiveStoQueryVariables = Exact<{
  _id?: Maybe<Scalars['Int']>;
}>;

export type InvestorActiveStoQuery = {
  investorSto: Pick<
    InvestorSto,
    | 'ID'
    | 'isAccountClosed'
    | 'investorID'
    | 'stoID'
    | 'expectedShares'
    | 'expectedInvestment'
    | 'isKYC'
    | 'applied'
    | 'status'
    | 'usufructuaryFirstName'
    | 'usufructuaryLastName'
    | 'usufructuaryAddress'
    | 'usufructuaryCity'
    | 'usufructuaryCountry'
    | 'usufructuaryEmail'
    | 'beneficialFirstName'
    | 'beneficialLastName'
    | 'beneficialAddress'
    | 'beneficialCity'
    | 'beneficialCountry'
    | 'beneficialEmail'
    | 'beneficialBirth'
    | 'beneficialNationality'
    | 'isUsufructuary'
    | 'isActive'
    | 'notes'
    | 'isNDASigned'
    | 'isBuyStoEnabled'
  >;
};

export type CountriesQueryVariables = Exact<{ [key: string]: never }>;

export type CountriesQuery = Pick<Query, 'countries'>;

export type InvestorAppDataQueryVariables = Exact<{ [key: string]: never }>;

export type InvestorAppDataQuery = {
  investorAppParameters: Pick<
    AppParameters,
    | 'isFooterTextCentered'
    | 'IsMarketSpace'
    | 'IsDocuSignActive'
    | 'IsHelloSignActive'
    | 'areSTOHostnamesEnabled'
    | 'KycProvider'
    | 'IsDarwSignatureActive'
    | 'IsCheckMarkSignatureActive'
    | 'web3Address'
    | 'polygonWeb3Address'
    | 'binanceWeb3Address'
    | 'IsInternalWalletStoSpecific'
    | 'IsInternalWalletGlobal'
    | 'IsInternalWalletDisabled'
    | 'poweredByLabel'
    | 'isSSOModeEnabled'
    | 'clientKYC'
    | 'SSORedirectFrontEnd'
    | 'KycRequirementStep'
    | 'IsMoonpayEnabled'
    | 'is2FAEnabledByDefault'
    | 'doAutomaticBlockchainTransactionChecks'
    | 'doAutomaticPurchase'
    | 'isInvoicingEnabled'
    | 'atomicSwapContractAddress'
    | 'isAccreditationEnabled'
    | 'AccreditationProvider'
    | 'AccreddRedirectLink'
    | 'isCloudStorageEnabled'
    | 'is2FAForcedForAll'
    | 'isPropertySortingEnabled'
    | 'isWalletManagementModuleEnabled'
    | 'isMyPortfolioModuleEnabled'
    | 'isActiveOfferingsModuleEnabled'
    | 'isNewsModuleEnabled'
    | 'isContractsModuleEnabled'
    | 'isCorporateActionsModuleEnabled'
    | 'isTradingModuleEnabled'
    | 'isInvestorPolymeshModuleEnabled'
    | 'isChatModuleEnabled'
    | 'isSupportModuleEnabled'
    | 'isInvestorOwnershipModuleEnabled'
    | 'isSettingsModuleEnabled'
    | 'isTellAFriendModuleEnabled'
    | 'isAccreditationModuleEnabled'
    | 'isContactTheSponsorFontSwitchEnabled'
    | 'isSellBackEnabled'
    | 'isBankDetailsSwitchEnabled'
    | 'isBlockchainAddressSwitchEnabled'
    | 'toggleThemeEditor'
    | 'accreditationRequirementStep'
    | 'accreditationRequiringCountries'
    | 'skipDocumentSignScreen'
    | 'allowInvestorsToRegister'
    | 'hideContractsTilPostPurchase'
    | 'isInvestmentReturnCalculationEnabled'
    | 'isDriversLicenseEnabled'
    | 'isInternalTokenizedPurchaseEnabled'
    | 'isAllDocumentsSignedPopUpEnabled'
    | 'isMergingPaymentsSharesRequestsEnabled'
    | 'platformConfiguration'
    | 'backgroundImageUrl'
    | 'isInvestorDashboardSwitchEnabled'
    | 'isInvestorLanguageSelectorEnabled'
    | 'defaultLoginRoute'
    | 'isCountryFlagsInSelectorEnabled'
    | 'isPublicLanguageSelectorEnabled'
    | 'maxFileUploadLimit'
    | 'platformWalletMode'
    | 'isBuyStoWhitelistPerInvestorEnabled'
    | 'isInvestorDisassociationSwitchEnabled'
    | 'isRegistrationFormStepsInstructionsEnabled'
    | 'wizardCompleteHtml'
    | 'isNewEntityMemberEmailEnabled'
    | 'isNDAFlowEnabled'
    | 'isShareCertificateButtonEnabled'
    | 'isShareCertificatePageEnabled'
    | 'isBlockchainTestnetEnabled'
    | 'isFilterPreferencesEnabled'
    | 'isSaveAndNextAtPersonalInfoEnabled'
    | 'isOfferingProgressTrackingEnabled'
    | 'activePaymentProcessor'
    | 'isFastTrackPaymentEnabled'
    | 'isInvestorNavigationDisabled'
    | 'isMetakeysFilterTagsDisplayEnabled'
    | 'isDiscountsEnabled'
    | 'isFeesEnabled'
    | 'isRegDRegSSeparationEnabled'
    | 'isPortfolioOverviewEnabled'
    | 'isProjectCardTagsDisplayEnabled'
    | 'isProjectCardImageDisplayEnabled'
    | 'isProjectPDFPresentationEnabled'
    | 'isInvestingEntityEnabled'
    | 'isSellSideEntityEnabled'
    | 'isSellSideSecrecyEnabled'
    | 'isSellSideEntityManagedStosTombstoneViewEnabled'
    | 'isEntityAsInvestorEnabled'
    | 'isMarketSpaceRegistrationFormEnabled'
    | 'isClosedOfferingsCardEnabled'
    | 'isActivePropertyBigTombstoneEnabled'
    | 'isPlatformOperatorHidden'
    | 'isInvestorPortfolioDividendsSwitchEnabled'
    | 'isInvestorPortfolioBalanceSwitchEnabled'
    | 'isEntityOptional'
    | 'walletConnectProjectKey'
    | 'isPaymentProcessorSandbox'
    | 'isWalletConnectEnabled'
    | 'showPropertyDetailReturnButton'
    | 'isInvestmentDetailsCardUnfolded'
    | 'isInvestmentDetailsCardEnabled'
    | 'isInvestorDashboardConnectWalletEnabled'
    | 'isLimitedInvestingEntityPreferences'
    | 'isLimitedInvestingEntityInfo'
    | 'isEntityManagementMeetingFieldEnabled'
    | 'isEntityReceivedTermSheetFieldEnabled'
    | 'isEntityReceivedSubscriptionAgreementFieldEnabled'
    | 'isEntityClosingFieldEnabled'
    | 'isStoSpecificPaymentChannelsEnabled'
    | 'isForumRegistrationEnabled'
    | 'isForumModuleEnabled'
    | 'phonePrefillCountryCode'
    | 'leftSideMenuFont'
    | 'areSellSideEntityChatLinksEnabled'
    | 'currencySymbolDisplayMode'
    | 'isInvoiceOverviewEnabled'
    | 'isOfferedContractsEnabled'
    | 'isShareOwnershipLimitEnabled'
    | 'isInvestorOfferingsDashboard'
    | 'isActiveOfferingsFilterCardEnabled'
    | 'isClosedOfferingsFilterCardEnabled'
    | 'isProfileMatchesFilterCardEnabled'
    | 'rightLayoutDesignOfferingsDetailPage'
    | 'isTradingBuyOrdersEnabled'
    | 'isCloudUploadingEnabled'
    | 'isEntityInvitedFieldEnabled'
    | 'isExternalDividendPayoutEnabled'
    | 'defaultInvestorLanguage'
  > & {
    termsAndConditionsConfig: Pick<TermsAndConditionsConfig, 'link' | 'text'>;
    defaultPropertyOrder: Pick<DefaultPropertySortingConfig, 'default'> & {
      options: Array<Pick<DefaultPropertySortingConfigOption, 'label' | 'value'>>;
    };
    txScanner: Array<Pick<ProtocolTxScanner, 'protocolId' | 'scannerUrl' | 'devScannerUrl'>>;
    frontendRedirectConfig: Pick<FrontendRedirectConfig, 'afterPaymentUrl'>;
    investorLogoProperties: Pick<InvestorLogoProperties, 'displayInvestorLogo' | 'height' | 'width'>;
    investorStoSwitchProperties: Pick<CommonInvestorNavbarProperties, 'isEnabled' | 'fontSize'>;
    investorNavbarTitleProperties: Pick<CommonInvestorNavbarProperties, 'isEnabled' | 'fontSize'>;
    verifyInvestorComKeylessIntegration: Pick<VerifyInvestorKeyLessParam, 'redirectUrl' | 'isEnabled'>;
    isExternalModuleEnabled: Pick<ExternalModuleParam, 'redirectUrl' | 'isEnabled' | 'icon'>;
    investorPageScriptProps: Pick<InvestorPageScriptProps, 'src' | 'id'>;
  };
};

export type GetInvestorTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetInvestorTypesQuery = { getInvestorTypes: Array<Pick<StoInvestorType, 'ID' | 'type'>> };

export type FindAllStoQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllStoQuery = { findAllSto: Array<Pick<Sto, 'ID' | 'title' | 'isActive'>> };

export type GetWeb3ProviderQueryVariables = Exact<{
  blockchainProtocolID: Scalars['Int'];
}>;

export type GetWeb3ProviderQuery = {
  getWeb3Provider: Pick<Web3Providers, 'blockchainURL' | 'blockchainSwapContractAddress' | 'blockchainExplorerURL'>;
};

export type FindCurrencyByIdQueryVariables = Exact<{
  currencyID: Scalars['Int'];
}>;

export type FindCurrencyByIdQuery = {
  findCurrencyById: Pick<
    Currency,
    | 'ID'
    | 'currency'
    | 'abbreviation'
    | 'symbol'
    | 'isBlockchainBased'
    | 'blockchainID'
    | 'isNative'
    | 'cryptoReceivingAddress'
    | 'Address'
  >;
};

export type GetDiscountsQueryVariables = Exact<{
  stoID: Scalars['Int'];
  discountAppliesTo: Array<DiscountAppliesTo> | DiscountAppliesTo;
}>;

export type GetDiscountsQuery = {
  getDiscounts: Array<
    Pick<Discount, 'ID' | 'stoIDs' | 'type' | 'appliesTo' | 'amount'> & {
    triggers: Array<
      Pick<PriceTransformerTrigger, 'ID' | 'transformerID' | 'type' | 'lowerBound' | 'upperBound' | 'isAlwaysVisible'>
    >;
  }
  >;
};

export type SaveContractFieldsMutationVariables = Exact<{
  documentID: Scalars['Int'];
  fieldValues: Array<DocumentFieldValueDto> | DocumentFieldValueDto;
}>;

export type SaveContractFieldsMutation = Pick<Mutation, 'setSubmittedDocument'>;

export type SaveContractSignatureMutationVariables = Exact<{
  documentID: Scalars['Int'];
  signature: Scalars['String'];
}>;

export type SaveContractSignatureMutation = Pick<Mutation, 'setSignature'>;

export type SendContractMutationVariables = Exact<{
  documentID: Scalars['Int'];
}>;

export type SendContractMutation = Pick<Mutation, 'sendContract'>;

export type OfferedDocumentQueryVariables = Exact<{
  documentID: Scalars['Int'];
}>;

export type OfferedDocumentQuery = {
  offeredDocument: { document?: Maybe<Pick<Document, 'ID' | 'title' | 'contents'>> };
  unfinishedDocument?: Maybe<
    Pick<DocumentUser, 'status'> & {
    fieldValues?: Maybe<Array<Pick<DocumentUserFieldValue, 'ID' | 'value'>>>;
    signature?: Maybe<Pick<CloudFiles, 'url' | 'modified'>>;
  }
  >;
  documentFields: Array<Pick<DocumentField, 'ID' | 'title' | 'fieldID' | 'type' | 'helperText'>>;
};

export type SubmittedDocumentQueryVariables = Exact<{
  submittedDocumentID: Scalars['Int'];
}>;

export type SubmittedDocumentQuery = {
  submittedDocument?: Maybe<
    Pick<DocumentUser, 'contents' | 'status'> & {
    fieldValues?: Maybe<Array<Pick<DocumentUserFieldValue, 'ID' | 'value'>>>;
    signature?: Maybe<Pick<CloudFiles, 'url' | 'modified'>>;
    document: Pick<Document, 'title'>;
  }
  >;
};

export type ContractsPageQueryVariables = Exact<{ [key: string]: never }>;

export type ContractsPageQuery = {
  offeredDocuments: Array<Pick<OfferedDocument, 'title' | 'to'> & { document?: Maybe<Pick<Document, 'ID' | 'title'>> }>;
  submittedDocuments: Array<
    Pick<DocumentUser, 'ID' | 'status' | 'signatureFilePath' | 'stoID'> & {
    document: Pick<Document, 'ID' | 'title' | 'docusignDocumentID' | 'helloSignDocumentID'>;
    signature?: Maybe<Pick<CloudFiles, 'modified'>>;
  }
  >;
};

export type DownloadSignedHelloSignQueryVariables = Exact<{
  fileID: Scalars['String'];
}>;

export type DownloadSignedHelloSignQuery = Pick<Query, 'downloadSignedHelloSign'>;

export type DownloadSignedDocuSignQueryVariables = Exact<{
  envelopeID: Scalars['String'];
}>;

export type DownloadSignedDocuSignQuery = Pick<Query, 'downloadSignedDocuSign'>;

export type CreateNewBuyOrderMutationVariables = Exact<{
  order: ExchangeBuyOrderInput;
}>;

export type CreateNewBuyOrderMutation = Pick<Mutation, 'createBuyOrder'>;

export type CreateNewSellOrderMutationVariables = Exact<{
  order: ExchangeSellOrderInput;
}>;

export type CreateNewSellOrderMutation = Pick<Mutation, 'createSellOrder'>;

export type UpdateSellOrderMutationVariables = Exact<{
  orderID: Scalars['Int'];
  data: ExchangeUpdateOrderInput;
}>;

export type UpdateSellOrderMutation = Pick<Mutation, 'updateSellOrder'>;

export type DeleteOrderMutationVariables = Exact<{
  orderID: Scalars['Int'];
}>;

export type DeleteOrderMutation = Pick<Mutation, 'deleteOrder'>;

export type DeleteOfferMutationVariables = Exact<{
  orderID: Scalars['Int'];
}>;

export type DeleteOfferMutation = Pick<Mutation, 'deleteOffer'>;

export type StartSwapMutationVariables = Exact<{
  offerID: Scalars['Int'];
}>;

export type StartSwapMutation = Pick<Mutation, 'startSwap'>;

export type CreateNewOfferMutationVariables = Exact<{
  offer: ExchangeOfferInput;
}>;

export type CreateNewOfferMutation = Pick<Mutation, 'createOffer'>;

export type AcceptInternalSwapMutationVariables = Exact<{
  offerID: Scalars['Int'];
}>;

export type AcceptInternalSwapMutation = Pick<Mutation, 'acceptInternalSwap'>;

export type PayOfferWithInternalWalletBalanceMutationVariables = Exact<{
  offerID: Scalars['Int'];
}>;

export type PayOfferWithInternalWalletBalanceMutation = Pick<Mutation, 'payOfferWithInternalWalletBalance'>;

export type AcceptBlockchainSwapMutationVariables = Exact<{
  walletAddress: Scalars['String'];
  offerID: Scalars['Int'];
}>;

export type AcceptBlockchainSwapMutation = Pick<Mutation, 'acceptBlockchainSwap'>;

export type CompanyTransferSharesMutationVariables = Exact<{
  data: TransferShareInput;
}>;

export type CompanyTransferSharesMutation = Pick<Mutation, 'companyTransferShares'>;

export type UpdateOrderStatusMutationVariables = Exact<{
  atomicSwapCurrentStatus: AtomicSwapStatus;
  orderId: Scalars['Int'];
}>;

export type UpdateOrderStatusMutation = Pick<Mutation, 'updateOrderStatus'>;

export type GetInvestorExchangeOrdersQueryVariables = Exact<{
  type: ExchangeType;
  stoID?: Maybe<Scalars['Int']>;
}>;

export type GetInvestorExchangeOrdersQuery = {
  getInvestorExchangeOrders: Array<
    Pick<
      ExchangeOrder,
      | 'ID'
      | 'atomicSwapAcceptable'
      | 'atomicSwapCurrentStatus'
      | 'dateFrom'
      | 'dateTo'
      | 'rateFrom'
      | 'rateTo'
      | 'shares'
      | 'type'
      | 'pricePerShare'
      | 'initialShares'
    > & { shareType: Pick<ShareType, 'title'> & { currency: Pick<Currency, 'ID' | 'symbol' | 'abbreviation'> } }
  >;
};

export type GetExchangeOrdersQueryVariables = Exact<{
  stoID: Scalars['Int'];
}>;

export type GetExchangeOrdersQuery = {
  getExchangeOrders: Array<
    Pick<
      ExchangeOrder,
      'ID' | 'atomicSwapAcceptable' | 'atomicSwapCurrentStatus' | 'dateFrom' | 'rateFrom' | 'rateTo' | 'shares' | 'type'
    > & { shareType: Pick<ShareType, 'title'> & { currency: Pick<Currency, 'ID' | 'symbol' | 'abbreviation'> } }
  >;
};

export type GetMyOffersQueryVariables = Exact<{
  stoID: Scalars['Int'];
  type: ExchangeType;
}>;

export type GetMyOffersQuery = {
  getExchangeOffers: Array<
    Pick<ExchangeOffer, 'ID' | 'rateFrom' | 'sharesPartial' | 'status'> & {
    exchangeOrder: Pick<
      ExchangeOrder,
      'ID' | 'dateFrom' | 'dateTo' | 'rateFrom' | 'atomicSwapAcceptable' | 'atomicSwapCurrentStatus'
    > & { shareType: Pick<ShareType, 'title'> & { currency: Pick<Currency, 'ID' | 'symbol' | 'abbreviation'> } };
    invoices: Array<Pick<InvestorInvoices, 'ID' | 'status'>>;
  }
  >;
};

export type FindShareTypesQueryVariables = Exact<{
  ID?: Maybe<Scalars['Int']>;
  stoID?: Maybe<Scalars['Int']>;
}>;

export type FindShareTypesQuery = {
  findShareTypes: Array<
    Pick<
      ShareType,
      | 'ID'
      | 'stoID'
      | 'title'
      | 'nominalValue'
      | 'premiumValue'
      | 'isBlockchain'
      | 'isInvestorTradable'
      | 'blockchainProtocolID'
      | 'isEnabled'
      | 'totalShares'
      | 'totalPrice'
      | 'availableShare'
    > & {
    currency: Pick<Currency, 'symbol' | 'abbreviation' | 'Address'>;
    sto: Pick<Sto, 'isActive'>;
    tradingDetails?: Maybe<
      Pick<ShareTypeTradeDetails, 'minTradeValue' | 'maxTradeValue' | 'openTradeDate' | 'closeTradeDate'>
    >;
  }
  >;
};

export type GetExchangeOrderDetailQueryVariables = Exact<{
  orderID: Scalars['Int'];
}>;

export type GetExchangeOrderDetailQuery = {
  getExchangeOrderOffers: Array<
    Pick<
      ExchangeOffer,
      | 'sharesPartial'
      | 'rateFrom'
      | 'rateTo'
      | 'pricePerShare'
      | 'status'
      | 'description'
      | 'atomicSwapAccepted'
      | 'atomicSwapSecret'
      | 'atomicBuyerPublicKey'
      | 'atomicSwapExpireDate'
      | 'investorID'
      | 'ID'
    >
  >;
  getExchangeOrder: Pick<
    ExchangeOrder,
    'ID' | 'type' | 'dateFrom' | 'dateTo' | 'shares' | 'rateFrom' | 'atomicSwapAcceptable' | 'investorID' | 'stoID'
  > & {
    shareType: Pick<ShareType, 'ID' | 'title' | 'blockchainProtocolID'> & {
      currency: Pick<Currency, 'symbol' | 'abbreviation'>;
    };
  };
};

export type GetExchangeOfferDetailQueryVariables = Exact<{
  orderID: Scalars['Int'];
}>;

export type GetExchangeOfferDetailQuery = {
  getExchangeOffer?: Maybe<
    Pick<
      ExchangeOffer,
      | 'sharesPartial'
      | 'rateFrom'
      | 'rateTo'
      | 'description'
      | 'atomicSwapAccepted'
      | 'atomicSwapSecret'
      | 'atomicBuyerPublicKey'
      | 'atomicSwapExpireDate'
      | 'investorID'
      | 'ID'
    >
  >;
  getExchangeOrder: Pick<
    ExchangeOrder,
    | 'ID'
    | 'type'
    | 'stoID'
    | 'dateFrom'
    | 'dateTo'
    | 'pricePerShare'
    | 'initialShares'
    | 'shares'
    | 'rateFrom'
    | 'atomicSwapAcceptable'
    | 'atomicSwapCurrentStatus'
    | 'atomicSwapTokenAddressAcceptable'
    | 'investorID'
  > & {
    atomicSwapSharesWallet?: Maybe<Pick<SharesWallet, 'publicKey'>>;
    shareType: Pick<
      ShareType,
      | 'ID'
      | 'title'
      | 'isBlockchain'
      | 'ethereumBlockchainPublicAddress'
      | 'ethereumContractAddress'
      | 'blockchainProtocolID'
    > & {
      currency: Pick<Currency, 'ID' | 'symbol' | 'abbreviation'>;
      tradingDetails?: Maybe<
        Pick<ShareTypeTradeDetails, 'minTradeValue' | 'maxTradeValue' | 'openTradeDate' | 'closeTradeDate'>
      >;
    };
  };
};

export type GetAcceptedExchangeOfferQueryVariables = Exact<{
  orderID: Scalars['Int'];
}>;

export type GetAcceptedExchangeOfferQuery = {
  getAcceptedExchangeOffer?: Maybe<
    Pick<
      ExchangeOffer,
      | 'ID'
      | 'exchangeOrderID'
      | 'investorID'
      | 'stoID'
      | 'sharesPartial'
      | 'rateFrom'
      | 'rateTo'
      | 'description'
      | 'atomicSwapAccepted'
      | 'atomicSwapSecret'
      | 'atomicBuyerPublicKey'
      | 'atomicSwapExpireDate'
    >
  >;
};

export type GetInvestorBalanceQueryVariables = Exact<{
  currencyID: Scalars['Int'];
  stoID: Scalars['Int'];
}>;

export type GetInvestorBalanceQuery = { investorBalance?: Maybe<Pick<InvestorBalance, 'amount'>> };

export type GetInvestorSharesQueryVariables = Exact<{
  shareTypeID: Scalars['Int'];
}>;

export type GetInvestorSharesQuery = { investorShare?: Maybe<Pick<Share, 'shares'>> };

export type GetExchangeOrderOffersCountQueryVariables = Exact<{
  orderID: Scalars['Int'];
}>;

export type GetExchangeOrderOffersCountQuery = Pick<Query, 'getExchangeOrderOffersCount'>;

export type GetPerformanceGraphDataQueryVariables = Exact<{
  shareTypeID: Scalars['Int'];
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;

export type GetPerformanceGraphDataQuery = {
  getPerformanceGraphData: Pick<PerformanceGraphData, 'data' | 'dates' | 'sharesSum'> & {
    shareType: Pick<ShareType, 'ID' | 'stoID' | 'title' | 'nominalValue' | 'premiumValue' | 'isBlockchain'> & {
      currency: Pick<Currency, 'symbol' | 'Address'>;
    };
  };
};

export type GetExchangeTransactionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetExchangeTransactionsQuery = {
  getExchangeTransactions: Array<
    Pick<
      ExchangeTransactionData,
      | 'ID'
      | 'type'
      | 'sellerName'
      | 'buyerName'
      | 'stoTitle'
      | 'shareTypeTitle'
      | 'amount'
      | 'pricePerShare'
      | 'totalPrice'
      | 'dateCompleted'
    > & { currency: Pick<Currency, 'symbol' | 'abbreviation'> }
  >;
};

export type RefreshInvestorDataMutationVariables = Exact<{ [key: string]: never }>;

export type RefreshInvestorDataMutation = Pick<Mutation, 'refreshInvestorData'>;

export type GetSumSubInvestorTokenQueryVariables = Exact<{ [key: string]: never }>;

export type GetSumSubInvestorTokenQuery = Pick<Query, 'getSumSubInvestorToken'>;

export type GetBlockPassClientIdQueryVariables = Exact<{ [key: string]: never }>;

export type GetBlockPassClientIdQuery = Pick<Query, 'getBlockPassClientID'>;

export type GetNetkiSignUpDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetNetkiSignUpDataQuery = { getNetkiSignUpData: Pick<NetkiSignUpData, 'accessCode' | 'mobileAppPanel'> };

export type GetGCashLoginUrlQueryVariables = Exact<{ [key: string]: never }>;

export type GetGCashLoginUrlQuery = Pick<Query, 'getGCashLoginUrl'>;

export type GCashUpdateInvestorDataMutationVariables = Exact<{
  gCashData: EncryptedDataInput;
}>;

export type GCashUpdateInvestorDataMutation = Pick<Mutation, 'gCashUpdateInvestorData'>;

export type RootKycSubscriptionVariables = Exact<{ [key: string]: never }>;

export type RootKycSubscription = { rootKyc: Pick<KycData, 'isKYC' | 'isActive' | 'status' | 'ID'> };

export type FetchFeesQueryVariables = Exact<{
  stoID: Scalars['Int'];
  beneficiary?: Maybe<FeeBeneficiary>;
  type?: Maybe<FeeType>;
  status?: Maybe<CommissionType>;
}>;

export type FetchFeesQuery = { fetchFees: Array<Pick<Fee, 'ID' | 'beneficiary' | 'type' | 'amount' | 'status'>> };

export type GetInvitationLinkQueryVariables = Exact<{ [key: string]: never }>;

export type GetInvitationLinkQuery = Pick<Query, 'getInvitationLink'>;

export type CheckRegistrationFeeStatusQueryVariables = Exact<{ [key: string]: never }>;

export type CheckRegistrationFeeStatusQuery = Pick<Query, 'checkRegistrationFeeStatus'>;

export type CreateForumThreadMutationVariables = Exact<{
  threadData: ForumThreadInput;
}>;

export type CreateForumThreadMutation = Pick<Mutation, 'createForumThread'>;

export type CreateForumPostMutationVariables = Exact<{
  postData: ForumPostInput;
}>;

export type CreateForumPostMutation = Pick<Mutation, 'createForumPost'>;

export type GetAllForumThreadsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllForumThreadsQuery = {
  getAllForumThreads: Array<Pick<ForumThread, 'ID' | 'title' | 'body' | 'createdAt'>>;
};

export type GetThreadByIdQueryVariables = Exact<{
  threadID: Scalars['Int'];
}>;

export type GetThreadByIdQuery = { getThreadByID: Pick<ForumThread, 'ID' | 'title' | 'body' | 'createdAt'> };

export type GetThreadContentQueryVariables = Exact<{
  threadID: Scalars['Int'];
}>;

export type GetThreadContentQuery = {
  getThreadContent: Array<
    Pick<ForumPost, 'ID' | 'body' | 'repliesTo' | 'createdAt'> & {
    admin?: Maybe<Pick<ForumAdmin, 'username'> & { offering: Pick<PublicSto, 'title'> }>;
    thread: Pick<ForumThread, 'ID' | 'title' | 'body' | 'createdAt'>;
  }
  >;
};

export type CreateInboxMutationVariables = Exact<{
  data: InboxInput;
}>;

export type CreateInboxMutation = Pick<Mutation, 'investorInboxCreate'>;

export type InboxDataQueryVariables = Exact<{
  sto: Scalars['Int'];
  offset: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
}>;

export type InboxDataQuery = {
  investorInboxes?: Maybe<
    Array<
      Pick<
        Inbox,
        'ID' | 'stoID' | 'investorID' | 'title' | 'details' | 'date' | 'isResponded' | 'response' | 'responseDate'
      >
    >
  >;
};

export type InboxMessageQueryVariables = Exact<{
  _id: Scalars['Int'];
}>;

export type InboxMessageQuery = {
  investorInbox?: Maybe<
    Pick<
      Inbox,
      'ID' | 'stoID' | 'investorID' | 'title' | 'details' | 'date' | 'response' | 'isResponded' | 'responseDate'
    >
  >;
};

export type GetInvestorDocumentsQueryVariables = Exact<{ [key: string]: never }>;

export type GetInvestorDocumentsQuery = {
  getInvestorDocuments: Array<
    Pick<InvestorDocument, 'ID' | 'stoID' | 'investorID' | 'documentTitle' | 'uploadDate' | 'fileUrl'>
  >;
};

export type InvestorInvestingEntityCreateMutationVariables = Exact<{
  data: InvestingEntityCreateInput;
}>;

export type InvestorInvestingEntityCreateMutation = Pick<Mutation, 'investorInvestingEntityCreate'>;

export type InvestorInvestingEntityUpdateMutationVariables = Exact<{
  data: InvestingEntityInput;
  entityID: Scalars['Int'];
}>;

export type InvestorInvestingEntityUpdateMutation = Pick<Mutation, 'investorInvestingEntityUpdate'>;

export type InvestorInvestingEntityRemoveMutationVariables = Exact<{
  entityID: Scalars['Int'];
}>;

export type InvestorInvestingEntityRemoveMutation = Pick<Mutation, 'investorInvestingEntityRemove'>;

export type InvestorInvestingEntityMemberCreateMutationVariables = Exact<{
  data: InvestingEntityMemberInput;
}>;

export type InvestorInvestingEntityMemberCreateMutation = Pick<Mutation, 'investorInvestingEntityMemberCreate'>;

export type InvestorInvestingEntityMemberUpdateMutationVariables = Exact<{
  data: InvestingEntityMemberInput;
  memberID: Scalars['Int'];
}>;

export type InvestorInvestingEntityMemberUpdateMutation = Pick<Mutation, 'investorInvestingEntityMemberUpdate'>;

export type InvestorInvestingEntityMemberRemoveMutationVariables = Exact<{
  memberID: Scalars['Int'];
}>;

export type InvestorInvestingEntityMemberRemoveMutation = Pick<Mutation, 'investorInvestingEntityMemberRemove'>;

export type InvestorBuyAlertMarketSpaceMutationVariables = Exact<{
  data: InvestorBuyAlertInput;
}>;

export type InvestorBuyAlertMarketSpaceMutation = Pick<Mutation, 'investorBuyAlertMarketSpace'>;

export type InvestorInvestingEntityMetaUpdateMutationVariables = Exact<{
  values: Array<MetaValuesInput> | MetaValuesInput;
  entityID: Scalars['Int'];
}>;

export type InvestorInvestingEntityMetaUpdateMutation = Pick<Mutation, 'investorInvestingEntityMetaUpdate'>;

export type InvestorInvestingEntitiesQueryVariables = Exact<{ [key: string]: never }>;

export type InvestorInvestingEntitiesQuery = {
  investorInvestingEntities: Array<
    Pick<
      InvestingEntity,
      | 'ID'
      | 'investorID'
      | 'typeID'
      | 'taxId'
      | 'name'
      | 'nickname'
      | 'accredited'
      | 'paymentMethod'
      | 'address'
      | 'city'
      | 'postalCode'
      | 'country'
      | 'registrationState'
      | 'state'
      | 'isApprovedByAdmin'
    > & {
    type: Pick<InvestingEntityTypes, 'ID' | 'title' | 'countries'>;
    members: Array<
      Pick<
        InvestingEntityMember,
        'ID' | 'investorID' | 'entityID' | 'firstName' | 'lastName' | 'role' | 'signatory' | 'email'
      >
    >;
    meta: Array<Pick<MetaValues, 'key' | 'value' | 'type' | 'order'>>;
  }
  >;
};

export type InvestorInvestingEntityQueryVariables = Exact<{
  entityId: Scalars['Int'];
}>;

export type InvestorInvestingEntityQuery = {
  investorInvestingEntity?: Maybe<
    Pick<
      InvestingEntity,
      | 'ID'
      | 'investorID'
      | 'typeID'
      | 'taxId'
      | 'name'
      | 'nickname'
      | 'accredited'
      | 'paymentMethod'
      | 'address'
      | 'city'
      | 'postalCode'
      | 'country'
      | 'registrationState'
      | 'state'
      | 'isApprovedByAdmin'
    > & {
    meta: Array<Pick<MetaValues, 'key' | 'value' | 'type' | 'order' | 'display' | 'displayClosedProp'>>;
    members: Array<
      Pick<
        InvestingEntityMember,
        'ID' | 'investorID' | 'entityID' | 'firstName' | 'lastName' | 'role' | 'signatory' | 'email'
      >
    >;
    type: Pick<InvestingEntityTypes, 'ID' | 'title' | 'countries'>;
    returnRates: Array<Pick<InvestingEntityReturns, 'ID' | 'stoID' | 'entityID' | 'returnRate'>>;
  }
  >;
};

export type InvestorEntityTypesQueryVariables = Exact<{ [key: string]: never }>;

export type InvestorEntityTypesQuery = {
  investorInvestingEntityTypes: Array<Pick<InvestingEntityTypes, 'ID' | 'title' | 'countries'>>;
};

export type GetInvestingEntityQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetInvestingEntityQuery = {
  investorInvestingEntity?: Maybe<Pick<InvestingEntity, 'name' | 'address' | 'city' | 'country' | 'nickname'>>;
};

export type GetInvestorInvestingEntityPermissionsQueryVariables = Exact<{
  stoId?: Maybe<Scalars['Int']>;
}>;

export type GetInvestorInvestingEntityPermissionsQuery = {
  investorInvestingEntityPermissions: Pick<
    InvestingEntityStoPermissions,
    | 'ID'
    | 'isBuyStoEnabled'
    | 'isNDASigned'
    | 'managementMeetings'
    | 'receivedTermSheet'
    | 'receivedSubscriptionAgreements'
    | 'closing'
    | 'firstAccessedDataRoom'
  >;
};

export type IsBuyStoEnabledQueryVariables = Exact<{
  stoId?: Maybe<Scalars['Int']>;
}>;

export type IsBuyStoEnabledQuery = {
  investorInvestingEntityPermissions: Pick<InvestingEntityStoPermissions, 'isBuyStoEnabled'>;
};

export type GetInvestingEntityDefaultMetaQueryVariables = Exact<{ [key: string]: never }>;

export type GetInvestingEntityDefaultMetaQuery = {
  getInvestingEntityDefaultMeta: Array<Pick<MetaValues, 'key' | 'value' | 'type' | 'order'>>;
};

export type CreateInvoiceInvestorMutationVariables = Exact<{
  data: InvestorInvoiceInput;
}>;

export type CreateInvoiceInvestorMutation = { createInvoice_Investor: Pick<InvestorInvoices, 'ID'> };

export type UpdateInvoiceInvestorMutationVariables = Exact<{
  invoiceID: Scalars['Int'];
  paymentChannelID: Scalars['Int'];
}>;

export type UpdateInvoiceInvestorMutation = Pick<Mutation, 'updateInvoice_Investor'>;

export type GetLastResolvedInvoiceSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GetLastResolvedInvoiceSubscription = {
  getLastResolvedInvoice: Pick<InvestorInvoices, 'ID' | 'status' | 'resolver' | 'invoiceDescription' | 'dateUpdated'>;
};

export type InvestorMetaValuesUpdateMutationVariables = Exact<{
  values: Array<MetaValuesInput> | MetaValuesInput;
  entityID?: Maybe<Scalars['Int']>;
}>;

export type InvestorMetaValuesUpdateMutation = Pick<Mutation, 'investorMetaValuesUpdate'>;

export type GetFilterOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetFilterOptionsQuery = {
  getFilterMetaKeys: Array<Pick<StosMetaKeys, 'key' | 'order' | 'type' | 'categoryKey'>>;
};

export type GetFilterSelectOptionsQueryVariables = Exact<{
  keys?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetFilterSelectOptionsQuery = {
  findAllSto: Array<Pick<Sto, 'isActive'> & { meta: Array<Pick<StoMetaValue, 'value'>> }>;
};

export type GetCurrentInvestorFilterPreferenceQueryVariables = Exact<{
  entityID?: Maybe<Scalars['Int']>;
}>;

export type GetCurrentInvestorFilterPreferenceQuery = {
  investorCurrentUser: Pick<Investor, 'ID'> & { meta: Array<Pick<MetaValues, 'type' | 'value' | 'key'>> };
};

export type GetCurrentInvestorMetaValuesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentInvestorMetaValuesQuery = {
  investorCurrentUser: { meta: Array<Pick<MetaValues, 'key' | 'value' | 'type'>> };
};

export type GetCategoryOrderMetaKeysQueryVariables = Exact<{ [key: string]: never }>;

export type GetCategoryOrderMetaKeysQuery = { getCategoryMetaKeys: Array<Pick<StosMetaKeys, 'key' | 'order'>> };

export type IsInvestorWhiteListedQueryVariables = Exact<{
  walletAddress: Scalars['String'];
}>;

export type IsInvestorWhiteListedQuery = Pick<Query, 'isInvestorWhiteListed'>;

export type CreateTopUpAlertMutationVariables = Exact<{
  data: InternalWalletTopUpAlertInput;
}>;

export type CreateTopUpAlertMutation = { createTopUpAlert: Pick<InvestorInvoices, 'ID'> };

export type AddOrEditInvestorBankAccountMutationVariables = Exact<{
  data: InvestorBankAccountInput;
}>;

export type AddOrEditInvestorBankAccountMutation = Pick<Mutation, 'addOrEditInvestorBankAccount'>;

export type GetInvestorBankAccountQueryVariables = Exact<{ [key: string]: never }>;

export type GetInvestorBankAccountQuery = {
  getInvestorBankAccount?: Maybe<
    Pick<
      InvestorBanks,
      | 'ID'
      | 'investorID'
      | 'accountTitle'
      | 'accountNo'
      | 'routingNumber'
      | 'iban'
      | 'accountHolderName'
      | 'accountHolderCity'
      | 'accountHolderCountry'
      | 'accountHolderAddress'
      | 'accountPostalCode'
      | 'bankName'
      | 'bankCity'
      | 'bankCountry'
      | 'bankAddress'
      | 'swift'
    >
  >;
};

export type CreateKycFileMutationVariables = Exact<{
  file: KycFileCreateInput;
}>;

export type CreateKycFileMutation = Pick<Mutation, 'createKycFile'>;

export type GetKycFilesQueryVariables = Exact<{
  name: Scalars['String'];
}>;

export type GetKycFilesQuery = { kycFiles: Array<Pick<KycFile, 'ID' | 'url' | 'filename'>> };

export type FillKycMutationVariables = Exact<{
  data: Scalars['JSON'];
}>;

export type FillKycMutation = Pick<Mutation, 'fillKyc'>;

export type ApplyKycMutationVariables = Exact<{
  applied: Scalars['Boolean'];
}>;

export type ApplyKycMutation = Pick<Mutation, 'applyKyc'>;

export type JsonKycQueryVariables = Exact<{ [key: string]: never }>;

export type JsonKycQuery = {
  kyc: Array<
    Pick<KycPage, 'name' | 'title' | 'icon'> & {
    pages: Array<
      Pick<KycPage, 'name' | 'title' | 'icon'> & {
      fields: Array<
        Pick<KycField, 'name' | 'label' | 'placeholder' | 'description' | 'error' | 'required' | 'type'> & {
        values?: Maybe<Array<Pick<KycFiledValue, 'value' | 'label'>>>;
      }
      >;
    }
    >;
  }
  >;
};

export type InvestorKycQueryVariables = Exact<{ [key: string]: never }>;

export type InvestorKycQuery = Pick<Query, 'investorKyc'> & {
  investorAppParameters: Pick<
    AppParameters,
    | 'isInvestorNotesEnabled'
    | 'isOptionKinInfoCollectionEnabled'
    | 'isDriversLicenseEnabled'
    | 'isSaveAndNextAtPersonalInfoEnabled'
    | 'internalKycStateOptions'
    | 'internalKycDocumentIssuingStateOptions'
  >;
};

export type RemoveKycFileMutationVariables = Exact<{
  ID: Scalars['Int'];
}>;

export type RemoveKycFileMutation = Pick<Mutation, 'removeKycFile'>;

export type CreateLogMutationVariables = Exact<{
  activityType: LogAction;
  stoId: Scalars['Int'];
  investorId?: Maybe<Scalars['Int']>;
}>;

export type CreateLogMutation = Pick<Mutation, 'createLog'>;

export type InvestorRegisterVoteMutationVariables = Exact<{
  data: RegisterVoteInput;
}>;

export type InvestorRegisterVoteMutation = Pick<Mutation, 'investorRegisterVote'>;

export type GetMeetingsQueryVariables = Exact<{
  stoId: Scalars['Int'];
}>;

export type GetMeetingsQuery = {
  investorAllMeeting: {
    past: Array<
      Pick<
        Meeting,
        | 'ID'
        | 'stoID'
        | 'title'
        | 'type'
        | 'nameResponsiblePerson'
        | 'phoneResponsiblePerson'
        | 'emailResponsiblePerson'
        | 'nameProxyPerson'
        | 'phoneProxyPerson'
        | 'emailProxyPerson'
        | 'place'
        | 'openDate'
        | 'closeDate'
        | 'voteType'
        | 'timezone'
        | 'timePadding'
      >
    >;
    current: Array<
      Pick<
        Meeting,
        | 'ID'
        | 'stoID'
        | 'title'
        | 'type'
        | 'nameResponsiblePerson'
        | 'phoneResponsiblePerson'
        | 'emailResponsiblePerson'
        | 'nameProxyPerson'
        | 'phoneProxyPerson'
        | 'emailProxyPerson'
        | 'place'
        | 'openDate'
        | 'closeDate'
        | 'voteType'
        | 'timezone'
        | 'timePadding'
      >
    >;
    future: Array<
      Pick<
        Meeting,
        | 'ID'
        | 'stoID'
        | 'title'
        | 'type'
        | 'phoneResponsiblePerson'
        | 'nameResponsiblePerson'
        | 'emailResponsiblePerson'
        | 'phoneProxyPerson'
        | 'nameProxyPerson'
        | 'emailProxyPerson'
        | 'place'
        | 'openDate'
        | 'closeDate'
        | 'voteType'
        | 'timezone'
        | 'timePadding'
      >
    >;
  };
};

export type InvestorMeetingQueryVariables = Exact<{
  meetingId: Scalars['Int'];
  _id: Scalars['Int'];
}>;

export type InvestorMeetingQuery = {
  investorMeeting?: Maybe<
    Pick<
      Meeting,
      | 'ID'
      | 'stoID'
      | 'title'
      | 'content'
      | 'type'
      | 'nameResponsiblePerson'
      | 'phoneResponsiblePerson'
      | 'emailResponsiblePerson'
      | 'nameProxyPerson'
      | 'phoneProxyPerson'
      | 'emailProxyPerson'
      | 'place'
      | 'openDate'
      | 'closeDate'
      | 'voteType'
      | 'timezone'
      | 'timePadding'
    > & {
    documents?: Maybe<
      Array<Pick<VotingDocuments, 'ID' | 'votingID' | 'votingOptionID' | 'documentLink' | 'title' | 'description'>>
    >;
  }
  >;
  findSto?: Maybe<Pick<Sto, 'companyType'>>;
};

export type InvestorVoteOptionsQueryVariables = Exact<{
  meetingId: Scalars['Int'];
}>;

export type InvestorVoteOptionsQuery = {
  investorVotingOptions: Array<
    Pick<VotingOption, 'ID' | 'votingID' | 'optionTxt' | 'description' | 'companyComments' | 'isActiveByAdmin'> & {
    userVotedOption?: Maybe<{ votingOption: Pick<VotingOption, 'ID' | 'optionTxt'> }>;
    documents: Array<
      Pick<VotingDocuments, 'ID' | 'votingID' | 'votingOptionID' | 'documentLink' | 'title' | 'description'>
    >;
  }
  >;
};

export type UserVotingDataQueryVariables = Exact<{
  votingId: Scalars['Int'];
}>;

export type UserVotingDataQuery = {
  investorUserVoting: Array<
    Pick<VotingUser, 'ID' | 'votingID' | 'userID' | 'votingOptionID' | 'investmentContributed' | 'isCastedByInvestor'>
  >;
};

export type OptionsVoteStatisticQueryVariables = Exact<{
  votingId: Scalars['Int'];
}>;

export type OptionsVoteStatisticQuery = {
  investorVotingOptions: Array<
    Pick<VotingOption, 'ID' | 'optionTxt'> & {
    votingUserStatistic: Pick<VotingUserStatistic, 'thisPercent' | 'totalCount'>;
  }
  >;
};

export type GetCorporateActionQueryVariables = Exact<{
  ID: Scalars['Int'];
}>;

export type GetCorporateActionQuery = {
  getCorporateAction: Pick<
    Meeting,
    | 'ID'
    | 'stoID'
    | 'title'
    | 'type'
    | 'phoneResponsiblePerson'
    | 'nameResponsiblePerson'
    | 'emailResponsiblePerson'
    | 'phoneProxyPerson'
    | 'nameProxyPerson'
    | 'emailProxyPerson'
    | 'place'
    | 'openDate'
    | 'closeDate'
    | 'voteType'
    | 'timezone'
    | 'timePadding'
  > & {
    documents?: Maybe<
      Array<Pick<VotingDocuments, 'ID' | 'votingID' | 'votingOptionID' | 'documentLink' | 'title' | 'description'>>
    >;
  };
};

export type SendMercuryInstructionalEmailMutationVariables = Exact<{
  stoId: Scalars['Int'];
  note: Scalars['String'];
  routingNumber: Scalars['String'];
  accountNumber: Scalars['String'];
}>;

export type SendMercuryInstructionalEmailMutation = Pick<Mutation, 'sendMercuryInstructionalEmail'>;

export type GetMercuryAccountInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetMercuryAccountInfoQuery = {
  getMercuryAccountInfo?: Maybe<Pick<MercuryInfo, 'accountNumber' | 'routingNumber'>>;
};

export type GetMercuryRecipientQueryVariables = Exact<{ [key: string]: never }>;

export type GetMercuryRecipientQuery = {
  getMercuryRecipient?: Maybe<
    Pick<MercuryRecipient, 'id' | 'name' | 'emails' | 'paymentMethod'> & {
    electronicRoutingInfo: Pick<MercuryAccount, 'accountNumber' | 'electronicAccountType' | 'routingNumber'> & {
      address: Pick<MercuryAddress, 'address1' | 'city' | 'region' | 'postalCode' | 'country'>;
    };
  }
  >;
};

export type GetMercuryConfigModeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMercuryConfigModeQuery = Pick<Query, 'getMercuryConfigMode'>;

export type MoonpayAddTransactionDefaultMutationVariables = Exact<{
  status: Scalars['String'];
  moonpayId: Scalars['String'];
}>;

export type MoonpayAddTransactionDefaultMutation = Pick<Mutation, 'moonpayAddTransactionDefault'>;

export type MoonpayWidgetUrlQueryVariables = Exact<{
  shares: Scalars['Float'];
  shareTypeId: Scalars['Int'];
  alertId?: Maybe<Scalars['Int']>;
}>;

export type MoonpayWidgetUrlQuery = Pick<Query, 'moonpayWidgetUrl'>;

export type MoonpayBuyAlertTransactionReceiptUrlQueryVariables = Exact<{
  alertId: Scalars['Int'];
}>;

export type MoonpayBuyAlertTransactionReceiptUrlQuery = Pick<Query, 'moonpayBuyAlertTransactionReceiptUrl'>;

export type InvestorDepositWithdrawAlertMutationVariables = Exact<{
  data: InvestorDepositWithdrawAlertInput;
}>;

export type InvestorDepositWithdrawAlertMutation = Pick<Mutation, 'investorDepositWithdrawAlert'>;

export type InvestorBuyAlertMutationVariables = Exact<{
  query: InvestorBuyAlertInput;
}>;

export type InvestorBuyAlertMutation = Pick<Mutation, 'investorBuyAlert'>;

export type InvestorInvoiceAlertDeleteMutationVariables = Exact<{
  ID: Scalars['Int'];
}>;

export type InvestorInvoiceAlertDeleteMutation = Pick<Mutation, 'investorInvoiceAlertDelete'>;

export type VerifyTransactionFromBlockchainMutationVariables = Exact<{
  data: VerifyCryptoReciepeInput;
}>;

export type VerifyTransactionFromBlockchainMutation = Pick<Mutation, 'verifyTransactionFromBlockchain'>;

export type InvestorSellAlertMutationVariables = Exact<{
  data: InvestorBuyAlertInput;
}>;

export type InvestorSellAlertMutation = Pick<Mutation, 'investorSellAlert'>;

export type SetAccessedDateForDataRoomMutationVariables = Exact<{
  date: Scalars['DateTime'];
  ID: Scalars['Int'];
}>;

export type SetAccessedDateForDataRoomMutation = Pick<Mutation, 'setAccessedDateForDataRoom'>;

export type InvestorActivePropertiesQueryVariables = Exact<{
  buyEnabledFilterInput?: Maybe<BuyEnabledFilterInput>;
  showAllProps?: Maybe<Scalars['Boolean']>;
}>;

export type InvestorActivePropertiesQuery = {
  investorActiveProperties: Array<
    Pick<
      ActiveProperty,
      | 'ID'
      | 'details'
      | 'picture'
      | 'title'
      | 'projectCost'
      | 'createdAt'
      | 'popularity'
      | 'isBuyButtonEnabled'
      | 'isNDAFlowEnabled'
      | 'isInvestorBuyEnabled'
      | 'stoDetailsInvestNowLink'
      | 'isProjectPDFPresentationEnabled'
      | 'isInvestorInvited'
    > & {
    meta: Array<Pick<StoMetaValue, 'value' | 'key'>>;
    sellSideEntity?: Maybe<Pick<SellSideEntity, 'ID' | 'title'>>;
  }
  >;
};

export type GetRevisionNumberQueryVariables = Exact<{ [key: string]: never }>;

export type GetRevisionNumberQuery = Pick<Query, 'getRevisionNumber'>;

export type InvestorRelatedStoQueryVariables = Exact<{ [key: string]: never }>;

export type InvestorRelatedStoQuery = {
  investorRelatedSto: Array<Pick<ActiveProperty, 'ID' | 'title' | 'details' | 'picture'>>;
  investorUser?: Maybe<{ sto: Pick<Sto, 'ID' | 'title'> }>;
};

export type InvestorStoHistoryQueryVariables = Exact<{ [key: string]: never }>;

export type InvestorStoHistoryQuery = { investorRelatedSto: Array<Pick<ActiveProperty, 'ID' | 'title'>> };

export type InvestorStoDetailQueryVariables = Exact<{
  _id: Scalars['Int'];
}>;

export type InvestorStoDetailQuery = {
  findSto?: Maybe<
    Pick<
      Sto,
      | 'ID'
      | 'title'
      | 'details'
      | 'picture'
      | 'fullDetails'
      | 'stolinkfull'
      | 'logo'
      | 'registrationText'
      | 'isBuyButtonEnabled'
      | 'isNDAFlowEnabled'
      | 'stoDetailsInvestNowLink'
      | 'projectAddress'
      | 'legalDetails'
      | 'isProjectPDFPresentationEnabled'
    > & {
    images: Array<
      Pick<PropertyFile, 'ID' | 'stoID' | 'title' | 'url' | 'parsedClearanceLevels' | 'type' | 'countries'>
    >;
    documents?: Maybe<
      Array<Pick<PropertyFile, 'ID' | 'stoID' | 'title' | 'url' | 'parsedClearanceLevels' | 'type' | 'countries'>>
    >;
    presentation?: Maybe<Pick<PropertyFile, 'ID' | 'stoID' | 'title' | 'url' | 'parsedClearanceLevels' | 'type'>>;
    parsedSettings: { investorCategories: Array<Pick<InvestorCategory, 'value' | 'label'>> };
    meta: Array<
      Pick<
        StoMetaValue,
        'stoID' | 'key' | 'value' | 'order' | 'display' | 'displayClosedProp' | 'isManagedStoTableColumn' | 'type'
      >
    >;
    sellSideEntity?: Maybe<Pick<SellSideEntity, 'ID' | 'title'>>;
  }
  >;
};

export type InvestorWalletQueryVariables = Exact<{
  _id: Scalars['Int'];
}>;

export type InvestorWalletQuery = {
  investorBalances: Array<
    Pick<InvestorBalance, 'ID' | 'stoID' | 'investorID' | 'currencyID' | 'amount'> & {
    currency: Pick<Currency, 'ID' | 'currency' | 'abbreviation' | 'symbol' | 'isBlockchainBased'>;
  }
  >;
  investorPaymentChannels: Array<
    Pick<
      PaymentChannel,
      | 'ID'
      | 'stoID'
      | 'title'
      | 'details'
      | 'currencyID'
      | 'conversionEnabled'
      | 'conversionRate'
      | 'canWithdrawFunds'
      | 'channelType'
      | 'pintProcessorTitle'
      | 'isActive'
      | 'usages'
    > & {
    currency: Pick<
      Currency,
      | 'ID'
      | 'currency'
      | 'abbreviation'
      | 'symbol'
      | 'isBlockchainBased'
      | 'Address'
      | 'cryptoReceivingAddress'
      | 'blockchainID'
    >;
    currencyToConvert?: Maybe<
      Pick<
        Currency,
        'ID' | 'currency' | 'abbreviation' | 'symbol' | 'isBlockchainBased' | 'Address' | 'cryptoReceivingAddress'
      >
    >;
    depositAlertDisplayParams?: Maybe<
      Pick<
        DepositAlertDisplayParams,
        | 'canDisplaySwift'
        | 'canDisplayBankAccount'
        | 'canDisplayBankName'
        | 'canDisplayDetails'
        | 'canDisplayCardBody'
      >
    >;
  }
  >;
  investorDepositHistory: Array<
    Pick<
      InvestorDepositAlert,
      | 'ID'
      | 'isApproved'
      | 'stoID'
      | 'dateReceived'
      | 'dateApproved'
      | 'amount'
      | 'details'
      | 'currencyID'
      | 'transactionHash'
    > & {
    currency: Pick<Currency, 'ID' | 'currency' | 'abbreviation' | 'symbol' | 'isBlockchainBased'>;
    buyAlert?: Maybe<{ shareType: Pick<ShareType, 'blockchainProtocolID'> }>;
  }
  >;
};

export type InvestorPortfolioQueryVariables = Exact<{
  _id?: Maybe<Scalars['Int']>;
  status: Array<BuyAlertStatus> | BuyAlertStatus;
}>;

export type InvestorPortfolioQuery = {
  investorShares: Array<
    Pick<
      Share,
      | 'sharesHistoryID'
      | 'investorID'
      | 'shares'
      | 'isBlockchainAuthorized'
      | 'isBlockchainFrozen'
      | 'shareTypeID'
      | 'stoID'
      | 'ID'
    > & {
    shareType: Pick<
      ShareType,
      | 'ID'
      | 'title'
      | 'stoID'
      | 'totalShares'
      | 'companyShares'
      | 'nominalValue'
      | 'custodianShares'
      | 'isBlockchain'
      | 'premiumValue'
      | 'currencyID'
      | 'sellToCompany'
      | 'sellValue'
      | 'isShareNosApplicable'
      | 'isCertificateNosApplicable'
      | 'minimumSharesToBuyByInvestor'
      | 'availableShare'
      | 'totalPrice'
      | 'blockchaindecimals'
      | 'blockchainProtocolID'
      | 'reduceSharesForPurchase'
      | 'walletCustodyType'
      | 'isInvestorTradable'
    > & { currency: Pick<Currency, 'ID' | 'currency' | 'abbreviation' | 'symbol' | 'isBlockchainBased'> };
  }
  >;
  investorBuyAlerts?: Maybe<
    Array<
      Pick<
        InvestorBuyAlert,
        | 'ID'
        | 'status'
        | 'stoID'
        | 'shares'
        | 'shareTypeID'
        | 'isHiddenForInvestor'
        | 'date'
        | 'isBuySharesSigned'
        | 'purchasePriceOffered'
        | 'fromCurrencyID'
        | 'isSellRequest'
      > & {
      shareType: Pick<
        ShareType,
        | 'ID'
        | 'title'
        | 'stoID'
        | 'totalShares'
        | 'companyShares'
        | 'nominalValue'
        | 'custodianShares'
        | 'isBlockchain'
        | 'premiumValue'
        | 'currencyID'
        | 'minimumSharesToBuyByInvestor'
        | 'sellToCompany'
        | 'sellValue'
        | 'isShareNosApplicable'
        | 'isCertificateNosApplicable'
        | 'availableShare'
        | 'totalPrice'
        | 'blockchaindecimals'
        | 'blockchainProtocolID'
        | 'reduceSharesForPurchase'
        | 'walletCustodyType'
        | 'maximumSharesToBuyByInvestor'
        | 'isInvestorTradable'
      > & { currency: Pick<Currency, 'ID' | 'currency' | 'abbreviation' | 'symbol' | 'isBlockchainBased'> };
      channel?: Maybe<{
        currency: Pick<Currency, 'ID' | 'abbreviation' | 'symbol' | 'isBlockchainBased' | 'currency'>;
        currencyToConvert?: Maybe<
          Pick<Currency, 'ID' | 'abbreviation' | 'symbol' | 'isBlockchainBased' | 'currency'>
        >;
      }>;
    }
    >
  >;
};

export type InvestorInvoiceAlertsQueryVariables = Exact<{
  includeAllTypes?: Maybe<Scalars['Boolean']>;
}>;

export type InvestorInvoiceAlertsQuery = {
  investorInvoiceAlerts: Array<
    Pick<
      InvestorInvoices,
      | 'ID'
      | 'buyAlertID'
      | 'investorID'
      | 'shareTypeID'
      | 'stoID'
      | 'shares'
      | 'status'
      | 'amountToPay'
      | 'isBlockchain'
      | 'dateCreated'
      | 'invoiceDescription'
      | 'dateUpdated'
      | 'investorWallet'
      | 'paymentChannelID'
      | 'resolver'
      | 'resolveID'
      | 'buyer'
      | 'seller'
    > & {
    buyAlert?: Maybe<Pick<InvestorBuyAlert, 'ID' | 'entityID'>>;
    shareType: Pick<
      ShareType,
      | 'ID'
      | 'title'
      | 'stoID'
      | 'totalShares'
      | 'companyShares'
      | 'ethereumBlockchainPublicAddress'
      | 'custodianShares'
      | 'nominalValue'
      | 'isBlockchain'
      | 'premiumValue'
      | 'currencyID'
      | 'minimumSharesToBuyByInvestor'
      | 'sellToCompany'
      | 'sellValue'
      | 'isShareNosApplicable'
      | 'isCertificateNosApplicable'
      | 'channelIDForAutoPayments'
      | 'availableShare'
      | 'totalPrice'
      | 'blockchaindecimals'
      | 'blockchainProtocolID'
      | 'reduceSharesForPurchase'
      | 'walletCustodyType'
    > & {
      currency: Pick<
        Currency,
        | 'ID'
        | 'currency'
        | 'abbreviation'
        | 'symbol'
        | 'isBlockchainBased'
        | 'blockchainID'
        | 'isNative'
        | 'cryptoReceivingAddress'
        | 'Address'
      >;
    };
    paymentChannel?: Maybe<
      Pick<PaymentChannel, 'title' | 'details' | 'currencyID' | 'canWithdrawFunds'> & {
      currency: Pick<Currency, 'ID' | 'symbol' | 'abbreviation' | 'currency' | 'isBlockchainBased'>;
    }
    >;
  }
  >;
};

export type GetInvestorInvoicesQueryVariables = Exact<{
  isHistorical?: Maybe<Scalars['Boolean']>;
  isOngoing?: Maybe<Scalars['Boolean']>;
  includeAllTypes?: Maybe<Scalars['Boolean']>;
}>;

export type GetInvestorInvoicesQuery = {
  getInvestorInvoices: Array<
    Pick<
      InvestorInvoices,
      | 'ID'
      | 'buyAlertID'
      | 'investorID'
      | 'shareTypeID'
      | 'stoID'
      | 'shares'
      | 'status'
      | 'amountToPay'
      | 'isBlockchain'
      | 'dateCreated'
      | 'invoiceDescription'
      | 'dateUpdated'
      | 'investorWallet'
      | 'paymentChannelID'
      | 'resolver'
      | 'resolveID'
      | 'seller'
      | 'buyer'
    > & {
    buyAlert?: Maybe<Pick<InvestorBuyAlert, 'ID' | 'entityID'>>;
    shareType: Pick<
      ShareType,
      | 'ID'
      | 'title'
      | 'stoID'
      | 'totalShares'
      | 'companyShares'
      | 'ethereumBlockchainPublicAddress'
      | 'custodianShares'
      | 'nominalValue'
      | 'isBlockchain'
      | 'premiumValue'
      | 'currencyID'
      | 'minimumSharesToBuyByInvestor'
      | 'sellToCompany'
      | 'sellValue'
      | 'isShareNosApplicable'
      | 'isCertificateNosApplicable'
      | 'channelIDForAutoPayments'
      | 'availableShare'
      | 'totalPrice'
      | 'blockchaindecimals'
      | 'blockchainProtocolID'
      | 'reduceSharesForPurchase'
      | 'walletCustodyType'
    > & {
      currency: Pick<
        Currency,
        | 'ID'
        | 'currency'
        | 'abbreviation'
        | 'symbol'
        | 'isBlockchainBased'
        | 'blockchainID'
        | 'isNative'
        | 'cryptoReceivingAddress'
        | 'Address'
      >;
    };
    paymentChannel?: Maybe<
      Pick<PaymentChannel, 'title' | 'details' | 'currencyID' | 'canWithdrawFunds'> & {
      currency: Pick<Currency, 'ID' | 'symbol' | 'abbreviation' | 'currency' | 'isBlockchainBased'>;
    }
    >;
  }
  >;
};

export type InvestorBuyAlertsQueryVariables = Exact<{
  status: Array<BuyAlertStatus> | BuyAlertStatus;
}>;

export type InvestorBuyAlertsQuery = {
  investorBuyAlerts?: Maybe<
    Array<
      Pick<
        InvestorBuyAlert,
        | 'ID'
        | 'entityID'
        | 'status'
        | 'stoID'
        | 'shares'
        | 'shareTypeID'
        | 'isHiddenForInvestor'
        | 'date'
        | 'isBuySharesSigned'
        | 'purchasePriceOffered'
        | 'fromCurrencyID'
        | 'isSellRequest'
      > & {
      shareType: Pick<
        ShareType,
        | 'ID'
        | 'title'
        | 'stoID'
        | 'totalShares'
        | 'companyShares'
        | 'nominalValue'
        | 'custodianShares'
        | 'isBlockchain'
        | 'premiumValue'
        | 'currencyID'
        | 'minimumSharesToBuyByInvestor'
        | 'sellToCompany'
        | 'sellValue'
        | 'isShareNosApplicable'
        | 'isCertificateNosApplicable'
        | 'availableShare'
        | 'totalPrice'
        | 'blockchaindecimals'
        | 'blockchainProtocolID'
        | 'reduceSharesForPurchase'
        | 'walletCustodyType'
        | 'maximumSharesToBuyByInvestor'
        | 'isInvestorTradable'
      > & {
        currency: Pick<
          Currency,
          | 'ID'
          | 'currency'
          | 'abbreviation'
          | 'symbol'
          | 'isBlockchainBased'
          | 'blockchainID'
          | 'isNative'
          | 'cryptoReceivingAddress'
        >;
      };
    }
    >
  >;
};

export type InvestorBuyPropertyQueryVariables = Exact<{
  _id: Scalars['Int'];
}>;

export type InvestorBuyPropertyQuery = {
  investorDetailProperty: Pick<DetailProperty, 'ID' | 'title' | 'details' | 'picture' | 'fullDetails'> & {
    images?: Maybe<Array<Pick<PropertyFile, 'ID' | 'title' | 'url'>>>;
    documents?: Maybe<Array<Pick<PropertyFile, 'ID' | 'title' | 'url'>>>;
  };
  investorBalances: Array<
    Pick<InvestorBalance, 'ID' | 'stoID' | 'investorID' | 'currencyID' | 'amount'> & {
    currency: Pick<Currency, 'ID' | 'currency' | 'abbreviation' | 'symbol' | 'isBlockchainBased'>;
  }
  >;
  findShareTypes: Array<
    Pick<
      ShareType,
      | 'ID'
      | 'stoID'
      | 'title'
      | 'totalShares'
      | 'companyShares'
      | 'nominalValue'
      | 'isBlockchain'
      | 'premiumValue'
      | 'custodianShares'
      | 'availableShare'
      | 'totalPrice'
      | 'currencyID'
      | 'sellToCompany'
      | 'sellValue'
      | 'isShareNosApplicable'
      | 'isCertificateNosApplicable'
      | 'isEnabled'
      | 'minimumSharesToBuyByInvestor'
      | 'channelIDForAutoPayments'
      | 'blockchaindecimals'
      | 'blockchainProtocolID'
      | 'reduceSharesForPurchase'
      | 'maximumSharesToBuyByInvestor'
      | 'walletCustodyType'
      | 'assetTag'
      | 'ethereumContractAddress'
      | 'shareOwnershipLimit'
    > & { currency: Pick<Currency, 'ID' | 'abbreviation' | 'currency' | 'symbol' | 'isBlockchainBased'> }
  >;
  getInvestorConversionPaymentChannels: Array<
    Pick<
      PaymentChannel,
      | 'ID'
      | 'stoID'
      | 'title'
      | 'channelType'
      | 'pintProcessorTitle'
      | 'conversionEnabled'
      | 'conversionRate'
      | 'usages'
    > & {
    currency: Pick<Currency, 'ID' | 'abbreviation' | 'symbol' | 'isBlockchainBased' | 'currency'>;
    currencyToConvert?: Maybe<Pick<Currency, 'ID' | 'abbreviation' | 'symbol' | 'isBlockchainBased' | 'currency'>>;
  }
  >;
};

export type FindInvestorDividendPayoutsQueryVariables = Exact<{ [key: string]: never }>;

export type FindInvestorDividendPayoutsQuery = {
  findInvestorDividendPayouts: Array<
    Pick<
      DividendInvestorPayout,
      'ID' | 'investorID' | 'payoutID' | 'amount' | 'investorShares' | 'lastUpdatedAt' | 'status'
    >
  >;
};

export type FindShareHistoricalValuesQueryVariables = Exact<{
  shareTypeID: Scalars['Int'];
}>;

export type FindShareHistoricalValuesQuery = {
  findShareHistoricalValues: Array<
    Pick<ShareHistoricalData, 'ID' | 'shareTypeID' | 'stoID' | 'premiumValue' | 'dateOfChange'>
  >;
};

export type InvestorDetailPropertyQueryVariables = Exact<{
  _id: Scalars['Int'];
}>;

export type InvestorDetailPropertyQuery = {
  investorDetailProperty: Pick<DetailProperty, 'ID' | 'title' | 'details' | 'picture' | 'fullDetails'> & {
    images?: Maybe<Array<Pick<PropertyFile, 'ID' | 'title' | 'url'>>>;
    documents?: Maybe<Array<Pick<PropertyFile, 'ID' | 'title' | 'url'>>>;
  };
};

export type InvestorInvoiceAlertQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type InvestorInvoiceAlertQuery = {
  investorInvoiceAlert: Pick<
    InvestorInvoices,
    | 'ID'
    | 'stoID'
    | 'buyAlertID'
    | 'investorID'
    | 'shareTypeID'
    | 'shares'
    | 'amountToPay'
    | 'status'
    | 'isBlockchain'
    | 'dateCreated'
    | 'dateUpdated'
    | 'invoiceDescription'
    | 'investorWallet'
    | 'paymentChannelID'
    | 'resolver'
    | 'resolveID'
    | 'seller'
    | 'buyer'
  > & {
    shareType: Pick<
      ShareType,
      | 'ethereumBlockchainPublicAddress'
      | 'channelIDForAutoPayments'
      | 'premiumValue'
      | 'currencyID'
      | 'sellValue'
      | 'sellToCompany'
      | 'title'
      | 'blockchaindecimals'
      | 'blockchainProtocolID'
      | 'reduceSharesForPurchase'
      | 'walletCustodyType'
    > & {
      currency: Pick<
        Currency,
        | 'ID'
        | 'currency'
        | 'abbreviation'
        | 'symbol'
        | 'isBlockchainBased'
        | 'blockchainID'
        | 'isNative'
        | 'cryptoReceivingAddress'
        | 'Address'
      >;
    };
    paymentChannel?: Maybe<
      Pick<PaymentChannel, 'title' | 'details' | 'currencyID' | 'conversionEnabled' | 'conversionRate'> & {
      currency: Pick<
        Currency,
        | 'ID'
        | 'currency'
        | 'abbreviation'
        | 'symbol'
        | 'isBlockchainBased'
        | 'blockchainID'
        | 'isNative'
        | 'cryptoReceivingAddress'
        | 'Address'
      >;
      currencyToConvert?: Maybe<
        Pick<
          Currency,
          | 'ID'
          | 'currency'
          | 'abbreviation'
          | 'symbol'
          | 'isBlockchainBased'
          | 'blockchainID'
          | 'isNative'
          | 'cryptoReceivingAddress'
          | 'Address'
        >
      >;
    }
    >;
    buyAlert?: Maybe<Pick<InvestorBuyAlert, 'ID' | 'entityID'>>;
  };
};

export type GetAllInvestorPaymentChannelsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllInvestorPaymentChannelsQuery = {
  getAllInvestorPaymentChannels: Array<
    Pick<
      PaymentChannel,
      | 'ID'
      | 'stoID'
      | 'title'
      | 'details'
      | 'currencyID'
      | 'conversionEnabled'
      | 'conversionRate'
      | 'canWithdrawFunds'
      | 'channelType'
      | 'pintProcessorTitle'
      | 'isActive'
      | 'usages'
    > & {
    currency: Pick<
      Currency,
      | 'ID'
      | 'currency'
      | 'abbreviation'
      | 'symbol'
      | 'isBlockchainBased'
      | 'Address'
      | 'cryptoReceivingAddress'
      | 'blockchainID'
    >;
    currencyToConvert?: Maybe<
      Pick<
        Currency,
        'ID' | 'currency' | 'abbreviation' | 'symbol' | 'isBlockchainBased' | 'Address' | 'cryptoReceivingAddress'
      >
    >;
    depositAlertDisplayParams?: Maybe<
      Pick<
        DepositAlertDisplayParams,
        | 'canDisplaySwift'
        | 'canDisplayBankAccount'
        | 'canDisplayBankName'
        | 'canDisplayDetails'
        | 'canDisplayCardBody'
      >
    >;
  }
  >;
};

export type GetSupportedPaymentChannelsQueryVariables = Exact<{
  invoiceID: Scalars['Int'];
  invoiceResolver?: Maybe<DpayResolver>;
}>;

export type GetSupportedPaymentChannelsQuery = {
  getSupportedPaymentChannels: Array<
    Pick<
      PaymentChannel,
      | 'ID'
      | 'stoID'
      | 'title'
      | 'details'
      | 'currencyID'
      | 'conversionEnabled'
      | 'conversionRate'
      | 'canWithdrawFunds'
      | 'channelType'
      | 'pintProcessorTitle'
      | 'isActive'
    > & {
    currency: Pick<
      Currency,
      | 'ID'
      | 'currency'
      | 'abbreviation'
      | 'symbol'
      | 'isBlockchainBased'
      | 'Address'
      | 'cryptoReceivingAddress'
      | 'blockchainID'
    >;
    currencyToConvert?: Maybe<
      Pick<
        Currency,
        'ID' | 'currency' | 'abbreviation' | 'symbol' | 'isBlockchainBased' | 'Address' | 'cryptoReceivingAddress'
      >
    >;
    depositAlertDisplayParams?: Maybe<
      Pick<
        DepositAlertDisplayParams,
        'canDisplaySwift' | 'canDisplayBankAccount' | 'canDisplayBankName' | 'canDisplayDetails'
      >
    >;
  }
  >;
};

export type CreateDpayAccountInvestorMutationVariables = Exact<{
  paymentProcessor: PaymentProcessor;
}>;

export type CreateDpayAccountInvestorMutation = Pick<Mutation, 'createDpayAccountInvestor'>;

export type RefreshInvestorBalanceMutationVariables = Exact<{
  paymentProcessor?: Maybe<PaymentProcessor>;
}>;

export type RefreshInvestorBalanceMutation = Pick<Mutation, 'refreshInvestorBalance'>;

export type AddTopUpSourceIdMutationVariables = Exact<{
  topUpSourceId: Scalars['String'];
}>;

export type AddTopUpSourceIdMutation = Pick<Mutation, 'addTopUpSourceId'>;

export type TopUpInvestorBalanceMutationVariables = Exact<{
  amount: Scalars['Float'];
}>;

export type TopUpInvestorBalanceMutation = Pick<Mutation, 'topUpInvestorBalance'>;

export type PayInvoiceMutationVariables = Exact<{
  invoiceID: Scalars['Int'];
  data?: Maybe<XenditPayInvoiceInput>;
  redirectUrl?: Maybe<Scalars['String']>;
}>;

export type PayInvoiceMutation = Pick<Mutation, 'payInvoice'>;

export type GetDpayEntityInvestorQueryVariables = Exact<{
  paymentProcessor?: Maybe<PaymentProcessor>;
}>;

export type GetDpayEntityInvestorQuery = {
  getDpayEntityInvestor?: Maybe<
    Pick<
      DpayEntity,
      | 'ID'
      | 'paymentProcessor'
      | 'userType'
      | 'investorID'
      | 'paymentAccountID'
      | 'token'
      | 'refreshToken'
      | 'tokenExpiryDate'
      | 'balance'
      | 'topUpSourceId'
    >
  >;
};

export type GenerateCardJwtQueryVariables = Exact<{ [key: string]: never }>;

export type GenerateCardJwtQuery = Pick<Query, 'generateCardJwt'>;

export type IsTopUpSourceExistingQueryVariables = Exact<{ [key: string]: never }>;

export type IsTopUpSourceExistingQuery = Pick<Query, 'isTopUpSourceExisting'>;

export type CheckInvoiceStatusQueryVariables = Exact<{
  invoiceID: Scalars['Int'];
  alertID: Scalars['Int'];
}>;

export type CheckInvoiceStatusQuery = Pick<Query, 'checkInvoiceStatus'>;

export type OnboardDpayAccountQueryVariables = Exact<{ [key: string]: never }>;

export type OnboardDpayAccountQuery = Pick<Query, 'onboardAccount'>;

export type IsDpayOnboardCompleteQueryVariables = Exact<{ [key: string]: never }>;

export type IsDpayOnboardCompleteQuery = Pick<Query, 'isOnboardComplete'>;

export type PintExecuteActionInvestorMutationVariables = Exact<{
  action: PintApiAction;
  processor: Scalars['String'];
  customAction?: Maybe<Scalars['String']>;
  customData?: Maybe<Scalars['JSON']>;
}>;

export type PintExecuteActionInvestorMutation = Pick<Mutation, 'pint_executeAction_Investor'>;

export type PintBeginFlowInvestorMutationVariables = Exact<{
  flow: PintPreconfFlows;
  processor: Scalars['String'];
  customData?: Maybe<Scalars['JSON']>;
}>;

export type PintBeginFlowInvestorMutation = Pick<Mutation, 'pint_beginFlow_Investor'>;

export type PintActionBeginTransactionInvestorMutationVariables = Exact<{
  processor: PintPreconfiguredProcessors;
  alertId?: Maybe<Scalars['Int']>;
  invoiceId?: Maybe<Scalars['Int']>;
  channelId?: Maybe<Scalars['Int']>;
  isWithdraw?: Maybe<Scalars['Boolean']>;
}>;

export type PintActionBeginTransactionInvestorMutation = {
  pint_action_beginTransaction_Investor?: Maybe<
    Pick<
      DpayTransaction,
      | 'ID'
      | 'investorID'
      | 'externalID'
      | 'externalUrl'
      | 'amount'
      | 'currencyCode'
      | 'localStatus'
      | 'timeStarted'
      | 'timeUpdated'
      | 'timeFinalized'
      | 'storage'
    >
  >;
};

export type PintListPreconfiguredCustomActionsQueryVariables = Exact<{ [key: string]: never }>;

export type PintListPreconfiguredCustomActionsQuery = {
  pint_listPreconfiguredCustomActions: Pick<PintPreconfiguredCustomActions, 'Dwolla'>;
};

export type PintGetAccountDataInvestorQueryVariables = Exact<{
  processor: PintPreconfiguredProcessors;
}>;

export type PintGetAccountDataInvestorQuery = {
  pint_getAccountData_Investor?: Maybe<
    Pick<DpayEntity, 'investorID' | 'userType' | 'paymentAccountID' | 'rsyncStatus' | 'storage' | 'topUpSourceId'>
  >;
};

export type PintSynchronizeTransactionsInvestorQueryVariables = Exact<{ [key: string]: never }>;

export type PintSynchronizeTransactionsInvestorQuery = Pick<Query, 'pint_synchronizeTransactions_Investor'>;

export type PolymeshSwapMutationVariables = Exact<{
  recordID: Scalars['Int'];
  secretKey: Scalars['String'];
}>;

export type PolymeshSwapMutation = Pick<Mutation, 'polymeshSwap'>;

export type InitiatePolymeshExchangeSwapMutationVariables = Exact<{
  orderID: Scalars['Int'];
}>;

export type InitiatePolymeshExchangeSwapMutation = Pick<Mutation, 'initiatePolymeshExchangeSwap'>;

export type AffirmPolymeshExchangeSwapMutationVariables = Exact<{
  orderID: Scalars['Int'];
  secretKey: Scalars['String'];
}>;

export type AffirmPolymeshExchangeSwapMutation = Pick<Mutation, 'affirmPolymeshExchangeSwap'>;

export type InitiatePolymeshTokenPurchaseTransactionMutationVariables = Exact<{
  input: InitiatePurchaseInput;
}>;

export type InitiatePolymeshTokenPurchaseTransactionMutation = {
  initiatePolymeshTokenPurchaseTransaction: Pick<
    InitiatePurchaseResult,
    'blockchainTransactionID' | 'shareTypeVenueID'
  >;
};

export type GetPolymeshTransactionsQueryVariables = Exact<{
  status: PolymeshSwapStatus;
  between: PolymeshSwapBetween;
}>;

export type GetPolymeshTransactionsQuery = {
  getPolymeshTransactions: Array<
    Pick<
      PolymeshSwapTransaction,
      | 'ID'
      | 'title'
      | 'between'
      | 'senderID'
      | 'receiverID'
      | 'transactionID'
      | 'shareTypeID'
      | 'amount'
      | 'swapTokenTag'
      | 'swapAmount'
      | 'status'
      | 'dateCreated'
      | 'dateModified'
    >
  >;
};

export type GetPendingPolymeshTransactionsCountQueryVariables = Exact<{
  between: PolymeshSwapBetween;
}>;

export type GetPendingPolymeshTransactionsCountQuery = Pick<Query, 'getPendingPolymeshTransactionsCount'>;

export type PortfolioValueQueryVariables = Exact<{ [key: string]: never }>;

export type PortfolioValueQuery = Pick<Query, 'portfolioValue'>;

export type PortfolioValueCalculationsQueryVariables = Exact<{ [key: string]: never }>;

export type PortfolioValueCalculationsQuery = {
  portfolioCalculations: Array<
    Pick<
      InvestmentsCalculation,
      | 'originatingProjectName'
      | 'investmentName'
      | 'entityName'
      | 'investmentValue'
      | 'yearlyRate'
      | 'normalDays'
      | 'normalProfit'
      | 'leapProfit'
      | 'leapDays'
      | 'totalProfit'
      | 'shares'
      | 'dividendPayout'
    >
  >;
};

export type GetSellEntityByIdQueryVariables = Exact<{
  ID: Scalars['Int'];
}>;

export type GetSellEntityByIdQuery = {
  getSellEntityById: Pick<SellSideEntity, 'ID' | 'title' | 'details' | 'logoUrl'> & {
    getStos?: Maybe<
      Array<
        Pick<Sto, 'ID' | 'title' | 'isActive' | 'createdAt'> & {
        meta: Array<Pick<StoMetaValue, 'stoID' | 'key' | 'value' | 'isManagedStoTableColumn' | 'type'>>;
      }
      >
    >;
    meta: Array<Pick<MetaValues, 'key' | 'value' | 'type' | 'order' | 'display' | 'displayClosedProp'>>;
  };
};

export type SaveSharePurchaseContractFieldsMutationVariables = Exact<{
  documentID: Scalars['Int'];
  sharePurchaseID: Scalars['Int'];
  fieldValues: Array<DocumentFieldValueDto> | DocumentFieldValueDto;
}>;

export type SaveSharePurchaseContractFieldsMutation = Pick<Mutation, 'setSubmittedSharePurchaseDocument'>;

export type SaveSharePurchaseContractSignatureMutationVariables = Exact<{
  documentID: Scalars['Int'];
  sharePurchaseID: Scalars['Int'];
  signature: Scalars['String'];
}>;

export type SaveSharePurchaseContractSignatureMutation = Pick<Mutation, 'setSharePurchaseDocumentSignature'>;

export type SendSharePurchaseContractMutationVariables = Exact<{
  documentID: Scalars['Int'];
  sharePurchaseID: Scalars['Int'];
}>;

export type SendSharePurchaseContractMutation = Pick<Mutation, 'sendSharePurchaseContract'>;

export type DeleteSharePurchaseRequestMutationVariables = Exact<{
  documentID: Scalars['Int'];
}>;

export type DeleteSharePurchaseRequestMutation = Pick<Mutation, 'deleteSharePurchaseRequest'>;

export type InvestorBuyAlertRemoveMutationVariables = Exact<{
  alertID: Scalars['Int'];
}>;

export type InvestorBuyAlertRemoveMutation = Pick<Mutation, 'investorBuyAlertRemove'>;

export type InvestorBuyAlertHideMutationVariables = Exact<{
  alertID: Scalars['Int'];
}>;

export type InvestorBuyAlertHideMutation = Pick<Mutation, 'investorBuyAlertHide'>;

export type SetDocuSignSignatureMutationVariables = Exact<{
  documentID: Scalars['Int'];
  sharePurchaseID: Scalars['Int'];
  docusignEnvelopeID: Scalars['String'];
}>;

export type SetDocuSignSignatureMutation = Pick<Mutation, 'setDocuSignSignature'>;

export type CreateBlockchainTransactionTransferMutationVariables = Exact<{
  data: BlockchainSharesTransferTransactionsInput;
}>;

export type CreateBlockchainTransactionTransferMutation = Pick<Mutation, 'createBlockchainTransactionTransfer'>;

export type UpdateCompletedBcstTxsForStoMutationVariables = Exact<{
  stoID: Scalars['Int'];
}>;

export type UpdateCompletedBcstTxsForStoMutation = Pick<Mutation, 'updateCompletedBCSTTxsForSto'>;

export type SharePurchaseDocumentsQueryVariables = Exact<{
  sharePurchaseID: Scalars['Int'];
}>;

export type SharePurchaseDocumentsQuery = {
  sharePurchaseDocuments: Array<
    Pick<SharePurchaseDocument, 'requireOnce' | 'status'> & { document: Pick<Document, 'ID' | 'title'> }
  >;
};

export type GetInternalSigningDataQueryVariables = Exact<{
  documentID: Scalars['Int'];
  sharePurchaseID: Scalars['Int'];
}>;

export type GetInternalSigningDataQuery = {
  document?: Maybe<Pick<Document, 'ID' | 'title' | 'contents'>>;
  sharePurchaseDocument?: Maybe<
    Pick<DocumentUser, 'status' | 'signatureFilePath'> & {
    fieldValues?: Maybe<Array<Pick<DocumentUserFieldValue, 'ID' | 'value'>>>;
    signature?: Maybe<Pick<CloudFiles, 'url' | 'modified'>>;
  }
  >;
  documentFields: Array<Pick<DocumentField, 'ID' | 'title' | 'fieldID' | 'type' | 'helperText'>>;
  getPrefilledDocumentValues: Array<Pick<DocumentUserFieldValue, 'ID' | 'value'>>;
  investorAppParameters: Pick<
    AppParameters,
    'IsDarwSignatureActive' | 'IsCheckMarkSignatureActive' | 'drawSignaturePrefillFonts'
  >;
};

export type GetDocuSignUrlQueryVariables = Exact<{
  preferredReturnURL: Scalars['String'];
  documentID: Scalars['Int'];
  sharePurchaseID: Scalars['Int'];
}>;

export type GetDocuSignUrlQuery = Pick<Query, 'getDocuSignUrl'>;

export type SendHelloSignTemplateSignRequestQueryVariables = Exact<{
  documentID: Scalars['Int'];
  sharePurchaseID: Scalars['Int'];
}>;

export type SendHelloSignTemplateSignRequestQuery = Pick<Query, 'sendHelloSignTemplateSignRequest'> & {
  findSto?: Maybe<Pick<Sto, 'helloSignClientID'>>;
};

export type GetTxIdFromBuyAlertIdQueryVariables = Exact<{
  buyAlertID: Scalars['Int'];
}>;

export type GetTxIdFromBuyAlertIdQuery = Pick<Query, 'getTxIdFromBuyAlertID'>;

export type InvestorSharesQueryVariables = Exact<{
  getTrueBalances?: Maybe<Scalars['Boolean']>;
  stoID?: Maybe<Scalars['Int']>;
}>;

export type InvestorSharesQuery = {
  investorShares: Array<
    Pick<
      Share,
      | 'ID'
      | 'stoID'
      | 'sharesHistoryID'
      | 'investorID'
      | 'shares'
      | 'isBlockchainAuthorized'
      | 'isBlockchainFrozen'
      | 'shareTypeID'
    > & {
    shareType: Pick<
      ShareType,
      | 'ID'
      | 'title'
      | 'stoID'
      | 'totalShares'
      | 'companyShares'
      | 'nominalValue'
      | 'custodianShares'
      | 'isBlockchain'
      | 'premiumValue'
      | 'currencyID'
      | 'sellToCompany'
      | 'sellValue'
      | 'isShareNosApplicable'
      | 'isCertificateNosApplicable'
      | 'isInvestorTradable'
      | 'minimumSharesToBuyByInvestor'
      | 'availableShare'
      | 'totalPrice'
      | 'blockchaindecimals'
    > & { currency: Pick<Currency, 'ID' | 'currency' | 'abbreviation' | 'symbol' | 'isBlockchainBased'> };
  }
  >;
};

export type GetInvestorShareQueryVariables = Exact<{
  ID: Scalars['Int'];
}>;

export type GetInvestorShareQuery = {
  investorShare?: Maybe<
    Pick<Share, 'shares'> & {
    shareType: Pick<
      ShareType,
      'ID' | 'title' | 'stoID' | 'nominalValue' | 'premiumValue' | 'isBlockchain' | 'blockchainProtocolID'
    > & {
      currency: Pick<Currency, 'ID' | 'symbol' | 'Address' | 'abbreviation'>;
      tradingDetails?: Maybe<
        Pick<ShareTypeTradeDetails, 'minTradeValue' | 'maxTradeValue' | 'openTradeDate' | 'closeTradeDate'>
      >;
    };
  }
  >;
};

export type GetInvestorSharesByTypeQueryVariables = Exact<{
  shareTypeID: Scalars['Int'];
}>;

export type GetInvestorSharesByTypeQuery = { investorShare?: Maybe<Pick<Share, 'shares'>> };

export type InvestorShareQueryVariables = Exact<{
  stoID: Scalars['Int'];
}>;

export type InvestorShareQuery = {
  investorShare?: Maybe<
    Pick<Share, 'shares'> & {
    shareType: Pick<ShareType, 'ID' | 'title' | 'stoID' | 'nominalValue' | 'premiumValue' | 'isBlockchain'> & {
      currency: Pick<Currency, 'ID' | 'symbol'>;
    };
  }
  >;
};

export type GetSharesWalletsQueryVariables = Exact<{
  shareTypeID: Scalars['Int'];
  platform?: Maybe<Scalars['Boolean']>;
}>;

export type GetSharesWalletsQuery = {
  getSharesWallets: Array<Pick<SharesWallet, 'ID' | 'isBlocked' | 'publicKey' | 'shares'>>;
};

export type GetAllSharesWalletsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllSharesWalletsQuery = {
  getSharesWallets: Array<
    Pick<SharesWallet, 'ID' | 'isBlocked' | 'publicKey' | 'shares'> & {
    shareType: Pick<
      ShareType,
      'ID' | 'title' | 'ethereumContractAddress' | 'blockchaindecimals' | 'assetTag' | 'blockchainProtocolID'
    >;
  }
  >;
};

export type InvestorBuyPropertyUpdateMutationVariables = Exact<{
  stoID: Scalars['Int'];
  isEnabled: Scalars['Boolean'];
  investorID?: Maybe<Scalars['Int']>;
}>;

export type InvestorBuyPropertyUpdateMutation = Pick<Mutation, 'investorBuyPropertyUpdate'>;

export type ResetOfferingProgressMutationVariables = Exact<{
  isEnabled: Scalars['Boolean'];
  stoId: Scalars['Int'];
}>;

export type ResetOfferingProgressMutation = Pick<Mutation, 'investorBuyPropertyUpdate'>;

export type SendLinkToFriendsMutationVariables = Exact<{
  data: ReferralInput;
}>;

export type SendLinkToFriendsMutation = Pick<Mutation, 'sendLinkToFriends'>;

export type SetThemeConfigMutationVariables = Exact<{
  theme: Scalars['String'];
}>;

export type SetThemeConfigMutation = Pick<Mutation, 'setThemeConfig'>;

export type GetThemeConfigQueryVariables = Exact<{ [key: string]: never }>;

export type GetThemeConfigQuery = {
  investorAppParameters: Pick<AppParameters, 'investorDashboardTheme' | 'leftSideMenuFont' | 'poweredByLabel'>;
};

export type TranslationLoadQueryVariables = Exact<{
  locale?: Maybe<Scalars['String']>;
}>;

export type TranslationLoadQuery = Pick<Query, 'locales'> & {
  translations: Array<Pick<Translation, 'key' | 'locale' | 'translation'>>;
};

export type UpdatesQueryVariables = Exact<{
  _id: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;

export type UpdatesQuery = {
  investorUpdates?: Maybe<Array<Pick<Update, 'ID' | 'stoID' | 'title' | 'details' | 'date' | 'coverphoto'>>>;
};

export type UpdateQueryVariables = Exact<{
  _id: Scalars['Int'];
}>;

export type UpdateQuery = { investorUpdate?: Maybe<Pick<Update, 'ID' | 'title' | 'stoID' | 'details' | 'date'>> };

export type GetUsernameByIdQueryVariables = Exact<{
  userID: Scalars['Int'];
}>;

export type GetUsernameByIdQuery = Pick<Query, 'getUsernameByID'>;

export type GetFullNameByIdQueryVariables = Exact<{
  userID: Scalars['Int'];
}>;

export type GetFullNameByIdQuery = Pick<Query, 'getFullNameByID'>;

export const VerifyInvestorDocument = gql`
  query verifyInvestor {
    investorUser {
      sto {
        ID
        title
        details
        isActive
        logo
        ethereumContractAddress
        ethereumWhitelistAddress
        disclaimer
        stolink
        stolinkfull
        stoType
        stoInvestorTypes
        registrationText
        stoInvestorTypesNotOnShareRegister
        companyType
        registrationSuccessText
        fullDetails
        exchangeOpenDate
        propertyPicture
        externalSystemID
        projectAddress
        legalDetails
        picture
        verifyInvestorComHostToken
        parsedSettings {
          investorCategories {
            value
            label
          }
        }
        images {
          ID
          title
          url
        }
        documents {
          ID
          title
          url
        }
      }
      investor {
        ID
        firstName
        lastName
        address
        country
        phone
        cell
        zip
        town
        state
        email
        passportNumber
        nationalID
        kinname
        kinphone
        kinemail
        investorType
        companyName
        titleWithinCompany
        powerToBindCompany
        birthDate
        isTwoFactorEnabled
        language
        middleName
        socialSecurity
        mailingAddress
        faxNumber
        maritalStatus
        occupation
        employerName
        employerAddress
        retirementAccount
        trustOrBusinessEntity
        dateIncorporation
        taxID
        govtID
        isTax
        secondaryContactName
        secondaryContactPhone
        secondaryContactEmail
        primaryContactName
        primaryContactPhone
        primaryContactEmail
        countryBusiness
        countryCitizenship
        taxCountry
        userName
      }
      investorSto {
        ID
        isAccountClosed
        investorID
        stoID
        expectedShares
        expectedInvestment
        isKYC
        applied
        status
        usufructuaryFirstName
        usufructuaryLastName
        usufructuaryAddress
        usufructuaryCity
        usufructuaryCountry
        usufructuaryEmail
        beneficialFirstName
        beneficialLastName
        beneficialAddress
        beneficialCity
        beneficialCountry
        beneficialEmail
        beneficialBirth
        beneficialNationality
        isUsufructuary
        isActive
        notes
      }
    }
    getVerifyInvestorUrl
  }
`;

/**
 * __useVerifyInvestorQuery__
 *
 * To run a query within a React component, call `useVerifyInvestorQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyInvestorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyInvestorQuery({
 *   variables: {
 *   },
 * });
 */
export function useVerifyInvestorQuery(
  baseOptions?: Apollo.QueryHookOptions<VerifyInvestorQuery, VerifyInvestorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VerifyInvestorQuery, VerifyInvestorQueryVariables>(VerifyInvestorDocument, options);
}
export function useVerifyInvestorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VerifyInvestorQuery, VerifyInvestorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VerifyInvestorQuery, VerifyInvestorQueryVariables>(VerifyInvestorDocument, options);
}
export type VerifyInvestorQueryHookResult = ReturnType<typeof useVerifyInvestorQuery>;
export type VerifyInvestorLazyQueryHookResult = ReturnType<typeof useVerifyInvestorLazyQuery>;
export type VerifyInvestorQueryResult = Apollo.QueryResult<VerifyInvestorQuery, VerifyInvestorQueryVariables>;
export const FindBlockchainProtocolDocument = gql`
  query FindBlockchainProtocol($id: Int!) {
    findBlockchainProtocol(ID: $id) {
      ID
      protocolName
      blockchainID
      blockchain {
        ID
        title
        nodeProviderURL
        nodeProviderURLTestnet
        chainNumber
        chainNumberTestnet
        explorerURL
        explorerURLTestnet
        swapContractAddress
        swapContractAddressTestnet
        key
        keyTestnet
        link
        linkTestnet
        username
        usernameTestnet
        password
        passwordTestnet
      }
    }
  }
`;

/**
 * __useFindBlockchainProtocolQuery__
 *
 * To run a query within a React component, call `useFindBlockchainProtocolQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindBlockchainProtocolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindBlockchainProtocolQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindBlockchainProtocolQuery(
  baseOptions: Apollo.QueryHookOptions<FindBlockchainProtocolQuery, FindBlockchainProtocolQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindBlockchainProtocolQuery, FindBlockchainProtocolQueryVariables>(
    FindBlockchainProtocolDocument,
    options,
  );
}
export function useFindBlockchainProtocolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindBlockchainProtocolQuery, FindBlockchainProtocolQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindBlockchainProtocolQuery, FindBlockchainProtocolQueryVariables>(
    FindBlockchainProtocolDocument,
    options,
  );
}
export type FindBlockchainProtocolQueryHookResult = ReturnType<typeof useFindBlockchainProtocolQuery>;
export type FindBlockchainProtocolLazyQueryHookResult = ReturnType<typeof useFindBlockchainProtocolLazyQuery>;
export type FindBlockchainProtocolQueryResult = Apollo.QueryResult<
  FindBlockchainProtocolQuery,
  FindBlockchainProtocolQueryVariables
>;
export const FindBlockchainDocument = gql`
  query FindBlockchain($id: Int!) {
    findBlockchain(ID: $id) {
      ID
      title
      nodeProviderURL
      nodeProviderURLTestnet
      chainNumber
      chainNumberTestnet
      explorerURL
      explorerURLTestnet
      swapContractAddress
      swapContractAddressTestnet
      key
      keyTestnet
      link
      linkTestnet
      username
      usernameTestnet
      password
      passwordTestnet
    }
  }
`;

/**
 * __useFindBlockchainQuery__
 *
 * To run a query within a React component, call `useFindBlockchainQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindBlockchainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindBlockchainQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindBlockchainQuery(
  baseOptions: Apollo.QueryHookOptions<FindBlockchainQuery, FindBlockchainQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindBlockchainQuery, FindBlockchainQueryVariables>(FindBlockchainDocument, options);
}
export function useFindBlockchainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindBlockchainQuery, FindBlockchainQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindBlockchainQuery, FindBlockchainQueryVariables>(FindBlockchainDocument, options);
}
export type FindBlockchainQueryHookResult = ReturnType<typeof useFindBlockchainQuery>;
export type FindBlockchainLazyQueryHookResult = ReturnType<typeof useFindBlockchainLazyQuery>;
export type FindBlockchainQueryResult = Apollo.QueryResult<FindBlockchainQuery, FindBlockchainQueryVariables>;
export const FindAllBlockchainProtocolsDocument = gql`
  query FindAllBlockchainProtocols {
    findAllBlockchainProtocols {
      ID
      protocolName
      blockchainID
      blockchain {
        ID
        title
        nodeProviderURL
        nodeProviderURLTestnet
        chainNumber
        chainNumberTestnet
        explorerURL
        explorerURLTestnet
        swapContractAddress
        swapContractAddressTestnet
        key
        keyTestnet
        link
        linkTestnet
        username
        usernameTestnet
        password
        passwordTestnet
      }
    }
  }
`;

/**
 * __useFindAllBlockchainProtocolsQuery__
 *
 * To run a query within a React component, call `useFindAllBlockchainProtocolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllBlockchainProtocolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllBlockchainProtocolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllBlockchainProtocolsQuery(
  baseOptions?: Apollo.QueryHookOptions<FindAllBlockchainProtocolsQuery, FindAllBlockchainProtocolsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindAllBlockchainProtocolsQuery, FindAllBlockchainProtocolsQueryVariables>(
    FindAllBlockchainProtocolsDocument,
    options,
  );
}
export function useFindAllBlockchainProtocolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindAllBlockchainProtocolsQuery, FindAllBlockchainProtocolsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindAllBlockchainProtocolsQuery, FindAllBlockchainProtocolsQueryVariables>(
    FindAllBlockchainProtocolsDocument,
    options,
  );
}
export type FindAllBlockchainProtocolsQueryHookResult = ReturnType<typeof useFindAllBlockchainProtocolsQuery>;
export type FindAllBlockchainProtocolsLazyQueryHookResult = ReturnType<typeof useFindAllBlockchainProtocolsLazyQuery>;
export type FindAllBlockchainProtocolsQueryResult = Apollo.QueryResult<
  FindAllBlockchainProtocolsQuery,
  FindAllBlockchainProtocolsQueryVariables
>;
export const MessageCreateDocument = gql`
  mutation MessageCreate($data: ChatInput!) {
    messageCreate(data: $data)
  }
`;
export type MessageCreateMutationFn = Apollo.MutationFunction<MessageCreateMutation, MessageCreateMutationVariables>;

/**
 * __useMessageCreateMutation__
 *
 * To run a mutation, you first call `useMessageCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessageCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messageCreateMutation, { data, loading, error }] = useMessageCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMessageCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<MessageCreateMutation, MessageCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MessageCreateMutation, MessageCreateMutationVariables>(MessageCreateDocument, options);
}
export type MessageCreateMutationHookResult = ReturnType<typeof useMessageCreateMutation>;
export type MessageCreateMutationResult = Apollo.MutationResult<MessageCreateMutation>;
export type MessageCreateMutationOptions = Apollo.BaseMutationOptions<
  MessageCreateMutation,
  MessageCreateMutationVariables
>;
export const UpdateChatsToSeenDocument = gql`
  mutation UpdateChatsToSeen($sender: SENDER_TYPE!, $stoID: Int!) {
    updateChatsToSeen(sender: $sender, stoID: $stoID)
  }
`;
export type UpdateChatsToSeenMutationFn = Apollo.MutationFunction<
  UpdateChatsToSeenMutation,
  UpdateChatsToSeenMutationVariables
>;

/**
 * __useUpdateChatsToSeenMutation__
 *
 * To run a mutation, you first call `useUpdateChatsToSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChatsToSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChatsToSeenMutation, { data, loading, error }] = useUpdateChatsToSeenMutation({
 *   variables: {
 *      sender: // value for 'sender'
 *      stoID: // value for 'stoID'
 *   },
 * });
 */
export function useUpdateChatsToSeenMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateChatsToSeenMutation, UpdateChatsToSeenMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateChatsToSeenMutation, UpdateChatsToSeenMutationVariables>(
    UpdateChatsToSeenDocument,
    options,
  );
}
export type UpdateChatsToSeenMutationHookResult = ReturnType<typeof useUpdateChatsToSeenMutation>;
export type UpdateChatsToSeenMutationResult = Apollo.MutationResult<UpdateChatsToSeenMutation>;
export type UpdateChatsToSeenMutationOptions = Apollo.BaseMutationOptions<
  UpdateChatsToSeenMutation,
  UpdateChatsToSeenMutationVariables
>;
export const UpdatePriceNegotiationChatsToSeenDocument = gql`
  mutation UpdatePriceNegotiationChatsToSeen($context: CHAT_CONTEXT!, $contextID: Int!, $counterpartID: Int!) {
    updatePriceNegotiationChatsToSeen(context: $context, contextID: $contextID, counterpartID: $counterpartID)
  }
`;
export type UpdatePriceNegotiationChatsToSeenMutationFn = Apollo.MutationFunction<
  UpdatePriceNegotiationChatsToSeenMutation,
  UpdatePriceNegotiationChatsToSeenMutationVariables
>;

/**
 * __useUpdatePriceNegotiationChatsToSeenMutation__
 *
 * To run a mutation, you first call `useUpdatePriceNegotiationChatsToSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePriceNegotiationChatsToSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePriceNegotiationChatsToSeenMutation, { data, loading, error }] = useUpdatePriceNegotiationChatsToSeenMutation({
 *   variables: {
 *      context: // value for 'context'
 *      contextID: // value for 'contextID'
 *      counterpartID: // value for 'counterpartID'
 *   },
 * });
 */
export function useUpdatePriceNegotiationChatsToSeenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePriceNegotiationChatsToSeenMutation,
    UpdatePriceNegotiationChatsToSeenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePriceNegotiationChatsToSeenMutation,
    UpdatePriceNegotiationChatsToSeenMutationVariables
  >(UpdatePriceNegotiationChatsToSeenDocument, options);
}
export type UpdatePriceNegotiationChatsToSeenMutationHookResult = ReturnType<
  typeof useUpdatePriceNegotiationChatsToSeenMutation
>;
export type UpdatePriceNegotiationChatsToSeenMutationResult =
  Apollo.MutationResult<UpdatePriceNegotiationChatsToSeenMutation>;
export type UpdatePriceNegotiationChatsToSeenMutationOptions = Apollo.BaseMutationOptions<
  UpdatePriceNegotiationChatsToSeenMutation,
  UpdatePriceNegotiationChatsToSeenMutationVariables
>;
export const GetPriceNegotiationListDocument = gql`
  query GetPriceNegotiationList($orderID: Int!) {
    getPriceNegotiationList(orderID: $orderID) {
      counterpartID
      orderID
      orderOwnerID
      isRead
      dateRead
      formattedDateSent
      counterpartFullName
    }
  }
`;

/**
 * __useGetPriceNegotiationListQuery__
 *
 * To run a query within a React component, call `useGetPriceNegotiationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPriceNegotiationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPriceNegotiationListQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useGetPriceNegotiationListQuery(
  baseOptions: Apollo.QueryHookOptions<GetPriceNegotiationListQuery, GetPriceNegotiationListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPriceNegotiationListQuery, GetPriceNegotiationListQueryVariables>(
    GetPriceNegotiationListDocument,
    options,
  );
}
export function useGetPriceNegotiationListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPriceNegotiationListQuery, GetPriceNegotiationListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPriceNegotiationListQuery, GetPriceNegotiationListQueryVariables>(
    GetPriceNegotiationListDocument,
    options,
  );
}
export type GetPriceNegotiationListQueryHookResult = ReturnType<typeof useGetPriceNegotiationListQuery>;
export type GetPriceNegotiationListLazyQueryHookResult = ReturnType<typeof useGetPriceNegotiationListLazyQuery>;
export type GetPriceNegotiationListQueryResult = Apollo.QueryResult<
  GetPriceNegotiationListQuery,
  GetPriceNegotiationListQueryVariables
>;
export const GetChatsDocument = gql`
  query GetChats(
    $chatBetween: CHAT_BETWEEN!
    $stoID: Int
    $context: CHAT_CONTEXT
    $contextID: Int
    $counterpartID: Int
  ) {
    getChats(
      chatBetween: $chatBetween
      stoID: $stoID
      context: $context
      contextID: $contextID
      counterpartID: $counterpartID
    ) {
      ID
      sender
      receiver
      investorID
      adminID
      stoID
      message
      type
      dateSent
      isRead
      dateRead
      isEdited
      location
      isDeleted
      context
      contextID
      contextReceiverID
      cloudFile {
        name
        url
      }
      admin {
        firstName
        lastName
        sellSideEntity {
          ID
          title
        }
      }
    }
  }
`;

/**
 * __useGetChatsQuery__
 *
 * To run a query within a React component, call `useGetChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatsQuery({
 *   variables: {
 *      chatBetween: // value for 'chatBetween'
 *      stoID: // value for 'stoID'
 *      context: // value for 'context'
 *      contextID: // value for 'contextID'
 *      counterpartID: // value for 'counterpartID'
 *   },
 * });
 */
export function useGetChatsQuery(baseOptions: Apollo.QueryHookOptions<GetChatsQuery, GetChatsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChatsQuery, GetChatsQueryVariables>(GetChatsDocument, options);
}
export function useGetChatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatsQuery, GetChatsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChatsQuery, GetChatsQueryVariables>(GetChatsDocument, options);
}
export type GetChatsQueryHookResult = ReturnType<typeof useGetChatsQuery>;
export type GetChatsLazyQueryHookResult = ReturnType<typeof useGetChatsLazyQuery>;
export type GetChatsQueryResult = Apollo.QueryResult<GetChatsQuery, GetChatsQueryVariables>;
export const GetStoInfoDocument = gql`
  query GetStoInfo($stoID: Int!) {
    findSto(ID: $stoID) {
      ID
      title
      details
    }
  }
`;

/**
 * __useGetStoInfoQuery__
 *
 * To run a query within a React component, call `useGetStoInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoInfoQuery({
 *   variables: {
 *      stoID: // value for 'stoID'
 *   },
 * });
 */
export function useGetStoInfoQuery(baseOptions: Apollo.QueryHookOptions<GetStoInfoQuery, GetStoInfoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStoInfoQuery, GetStoInfoQueryVariables>(GetStoInfoDocument, options);
}
export function useGetStoInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStoInfoQuery, GetStoInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStoInfoQuery, GetStoInfoQueryVariables>(GetStoInfoDocument, options);
}
export type GetStoInfoQueryHookResult = ReturnType<typeof useGetStoInfoQuery>;
export type GetStoInfoLazyQueryHookResult = ReturnType<typeof useGetStoInfoLazyQuery>;
export type GetStoInfoQueryResult = Apollo.QueryResult<GetStoInfoQuery, GetStoInfoQueryVariables>;
export const GetUnreadMessagesCountDocument = gql`
  query GetUnreadMessagesCount($sender: SENDER_TYPE!) {
    getUnreadMessagesCount(sender: $sender)
  }
`;

/**
 * __useGetUnreadMessagesCountQuery__
 *
 * To run a query within a React component, call `useGetUnreadMessagesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnreadMessagesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnreadMessagesCountQuery({
 *   variables: {
 *      sender: // value for 'sender'
 *   },
 * });
 */
export function useGetUnreadMessagesCountQuery(
  baseOptions: Apollo.QueryHookOptions<GetUnreadMessagesCountQuery, GetUnreadMessagesCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUnreadMessagesCountQuery, GetUnreadMessagesCountQueryVariables>(
    GetUnreadMessagesCountDocument,
    options,
  );
}
export function useGetUnreadMessagesCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUnreadMessagesCountQuery, GetUnreadMessagesCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUnreadMessagesCountQuery, GetUnreadMessagesCountQueryVariables>(
    GetUnreadMessagesCountDocument,
    options,
  );
}
export type GetUnreadMessagesCountQueryHookResult = ReturnType<typeof useGetUnreadMessagesCountQuery>;
export type GetUnreadMessagesCountLazyQueryHookResult = ReturnType<typeof useGetUnreadMessagesCountLazyQuery>;
export type GetUnreadMessagesCountQueryResult = Apollo.QueryResult<
  GetUnreadMessagesCountQuery,
  GetUnreadMessagesCountQueryVariables
>;
export const GetPriceNegotiationUnreadMessagesCountDocument = gql`
  query GetPriceNegotiationUnreadMessagesCount($context: CHAT_CONTEXT!, $contextID: Int!, $counterpartID: Int) {
    getPriceNegotiationUnreadMessagesCount(context: $context, contextID: $contextID, counterpartID: $counterpartID)
  }
`;

/**
 * __useGetPriceNegotiationUnreadMessagesCountQuery__
 *
 * To run a query within a React component, call `useGetPriceNegotiationUnreadMessagesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPriceNegotiationUnreadMessagesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPriceNegotiationUnreadMessagesCountQuery({
 *   variables: {
 *      context: // value for 'context'
 *      contextID: // value for 'contextID'
 *      counterpartID: // value for 'counterpartID'
 *   },
 * });
 */
export function useGetPriceNegotiationUnreadMessagesCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPriceNegotiationUnreadMessagesCountQuery,
    GetPriceNegotiationUnreadMessagesCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPriceNegotiationUnreadMessagesCountQuery,
    GetPriceNegotiationUnreadMessagesCountQueryVariables
  >(GetPriceNegotiationUnreadMessagesCountDocument, options);
}
export function useGetPriceNegotiationUnreadMessagesCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPriceNegotiationUnreadMessagesCountQuery,
    GetPriceNegotiationUnreadMessagesCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPriceNegotiationUnreadMessagesCountQuery,
    GetPriceNegotiationUnreadMessagesCountQueryVariables
  >(GetPriceNegotiationUnreadMessagesCountDocument, options);
}
export type GetPriceNegotiationUnreadMessagesCountQueryHookResult = ReturnType<
  typeof useGetPriceNegotiationUnreadMessagesCountQuery
>;
export type GetPriceNegotiationUnreadMessagesCountLazyQueryHookResult = ReturnType<
  typeof useGetPriceNegotiationUnreadMessagesCountLazyQuery
>;
export type GetPriceNegotiationUnreadMessagesCountQueryResult = Apollo.QueryResult<
  GetPriceNegotiationUnreadMessagesCountQuery,
  GetPriceNegotiationUnreadMessagesCountQueryVariables
>;
export const GetLastChatRecordDocument = gql`
  subscription GetLastChatRecord {
    getLastChatRecord {
      ID
      sender
      receiver
      investorID
      adminID
      stoID
      message
      type
      dateSent
      isRead
      dateRead
      isEdited
      location
      isDeleted
      context
      contextID
      contextReceiverID
    }
  }
`;

/**
 * __useGetLastChatRecordSubscription__
 *
 * To run a query within a React component, call `useGetLastChatRecordSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetLastChatRecordSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastChatRecordSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetLastChatRecordSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<GetLastChatRecordSubscription, GetLastChatRecordSubscriptionVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<GetLastChatRecordSubscription, GetLastChatRecordSubscriptionVariables>(
    GetLastChatRecordDocument,
    options,
  );
}
export type GetLastChatRecordSubscriptionHookResult = ReturnType<typeof useGetLastChatRecordSubscription>;
export type GetLastChatRecordSubscriptionResult = Apollo.SubscriptionResult<GetLastChatRecordSubscription>;
export const ConfirmCloudUploadedFileDocument = gql`
  mutation ConfirmCloudUploadedFile($fileID: Int!) {
    saveUploadedFile(fileID: $fileID)
  }
`;
export type ConfirmCloudUploadedFileMutationFn = Apollo.MutationFunction<
  ConfirmCloudUploadedFileMutation,
  ConfirmCloudUploadedFileMutationVariables
>;

/**
 * __useConfirmCloudUploadedFileMutation__
 *
 * To run a mutation, you first call `useConfirmCloudUploadedFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmCloudUploadedFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmCloudUploadedFileMutation, { data, loading, error }] = useConfirmCloudUploadedFileMutation({
 *   variables: {
 *      fileID: // value for 'fileID'
 *   },
 * });
 */
export function useConfirmCloudUploadedFileMutation(
  baseOptions?: Apollo.MutationHookOptions<ConfirmCloudUploadedFileMutation, ConfirmCloudUploadedFileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmCloudUploadedFileMutation, ConfirmCloudUploadedFileMutationVariables>(
    ConfirmCloudUploadedFileDocument,
    options,
  );
}
export type ConfirmCloudUploadedFileMutationHookResult = ReturnType<typeof useConfirmCloudUploadedFileMutation>;
export type ConfirmCloudUploadedFileMutationResult = Apollo.MutationResult<ConfirmCloudUploadedFileMutation>;
export type ConfirmCloudUploadedFileMutationOptions = Apollo.BaseMutationOptions<
  ConfirmCloudUploadedFileMutation,
  ConfirmCloudUploadedFileMutationVariables
>;
export const GetCloudUploadUrlDocument = gql`
  mutation GetCloudUploadUrl($filename: String!, $filetype: String!, $type: StorageType!) {
    getUploadUrl(filename: $filename, filetype: $filetype, type: $type) {
      fileID
      url
    }
  }
`;
export type GetCloudUploadUrlMutationFn = Apollo.MutationFunction<
  GetCloudUploadUrlMutation,
  GetCloudUploadUrlMutationVariables
>;

/**
 * __useGetCloudUploadUrlMutation__
 *
 * To run a mutation, you first call `useGetCloudUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetCloudUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getCloudUploadUrlMutation, { data, loading, error }] = useGetCloudUploadUrlMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *      filetype: // value for 'filetype'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetCloudUploadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<GetCloudUploadUrlMutation, GetCloudUploadUrlMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GetCloudUploadUrlMutation, GetCloudUploadUrlMutationVariables>(
    GetCloudUploadUrlDocument,
    options,
  );
}
export type GetCloudUploadUrlMutationHookResult = ReturnType<typeof useGetCloudUploadUrlMutation>;
export type GetCloudUploadUrlMutationResult = Apollo.MutationResult<GetCloudUploadUrlMutation>;
export type GetCloudUploadUrlMutationOptions = Apollo.BaseMutationOptions<
  GetCloudUploadUrlMutation,
  GetCloudUploadUrlMutationVariables
>;
export const GetInvestorCommissionsSumDocument = gql`
  query getInvestorCommissionsSum($status: PAYMENT_STATUS) {
    getInvestorCommissionsSum(status: $status)
  }
`;

/**
 * __useGetInvestorCommissionsSumQuery__
 *
 * To run a query within a React component, call `useGetInvestorCommissionsSumQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestorCommissionsSumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestorCommissionsSumQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetInvestorCommissionsSumQuery(
  baseOptions?: Apollo.QueryHookOptions<GetInvestorCommissionsSumQuery, GetInvestorCommissionsSumQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvestorCommissionsSumQuery, GetInvestorCommissionsSumQueryVariables>(
    GetInvestorCommissionsSumDocument,
    options,
  );
}
export function useGetInvestorCommissionsSumLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInvestorCommissionsSumQuery, GetInvestorCommissionsSumQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvestorCommissionsSumQuery, GetInvestorCommissionsSumQueryVariables>(
    GetInvestorCommissionsSumDocument,
    options,
  );
}
export type GetInvestorCommissionsSumQueryHookResult = ReturnType<typeof useGetInvestorCommissionsSumQuery>;
export type GetInvestorCommissionsSumLazyQueryHookResult = ReturnType<typeof useGetInvestorCommissionsSumLazyQuery>;
export type GetInvestorCommissionsSumQueryResult = Apollo.QueryResult<
  GetInvestorCommissionsSumQuery,
  GetInvestorCommissionsSumQueryVariables
>;
export const CustomizationAppDataDocument = gql`
  query customizationAppData($componentTitle: String, $componentId: Int) {
    fetchCustomizedComponent(componentTitle: $componentTitle, componentID: $componentId) {
      ID
      component
      body
    }
  }
`;

/**
 * __useCustomizationAppDataQuery__
 *
 * To run a query within a React component, call `useCustomizationAppDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomizationAppDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomizationAppDataQuery({
 *   variables: {
 *      componentTitle: // value for 'componentTitle'
 *      componentId: // value for 'componentId'
 *   },
 * });
 */
export function useCustomizationAppDataQuery(
  baseOptions?: Apollo.QueryHookOptions<CustomizationAppDataQuery, CustomizationAppDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomizationAppDataQuery, CustomizationAppDataQueryVariables>(
    CustomizationAppDataDocument,
    options,
  );
}
export function useCustomizationAppDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomizationAppDataQuery, CustomizationAppDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomizationAppDataQuery, CustomizationAppDataQueryVariables>(
    CustomizationAppDataDocument,
    options,
  );
}
export type CustomizationAppDataQueryHookResult = ReturnType<typeof useCustomizationAppDataQuery>;
export type CustomizationAppDataLazyQueryHookResult = ReturnType<typeof useCustomizationAppDataLazyQuery>;
export type CustomizationAppDataQueryResult = Apollo.QueryResult<
  CustomizationAppDataQuery,
  CustomizationAppDataQueryVariables
>;
export const SignInDocument = gql`
  mutation signIn($email: String!, $password: String!, $sto: Int!) {
    signIn(email: $email, password: $password, stoID: $sto)
  }
`;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      sto: // value for 'sto'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const SignInSsoDocument = gql`
  mutation signInSSO($data: SignInSSOInput!) {
    signInSSO(data: $data)
  }
`;
export type SignInSsoMutationFn = Apollo.MutationFunction<SignInSsoMutation, SignInSsoMutationVariables>;

/**
 * __useSignInSsoMutation__
 *
 * To run a mutation, you first call `useSignInSsoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInSsoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInSsoMutation, { data, loading, error }] = useSignInSsoMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignInSsoMutation(
  baseOptions?: Apollo.MutationHookOptions<SignInSsoMutation, SignInSsoMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignInSsoMutation, SignInSsoMutationVariables>(SignInSsoDocument, options);
}
export type SignInSsoMutationHookResult = ReturnType<typeof useSignInSsoMutation>;
export type SignInSsoMutationResult = Apollo.MutationResult<SignInSsoMutation>;
export type SignInSsoMutationOptions = Apollo.BaseMutationOptions<SignInSsoMutation, SignInSsoMutationVariables>;
export const SignUpMarketSpaceDocument = gql`
  mutation SignUpMarketSpace($data: SignUpMarketSpaceInput!) {
    signUpMarketSpace(data: $data)
  }
`;
export type SignUpMarketSpaceMutationFn = Apollo.MutationFunction<
  SignUpMarketSpaceMutation,
  SignUpMarketSpaceMutationVariables
>;

/**
 * __useSignUpMarketSpaceMutation__
 *
 * To run a mutation, you first call `useSignUpMarketSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMarketSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMarketSpaceMutation, { data, loading, error }] = useSignUpMarketSpaceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignUpMarketSpaceMutation(
  baseOptions?: Apollo.MutationHookOptions<SignUpMarketSpaceMutation, SignUpMarketSpaceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignUpMarketSpaceMutation, SignUpMarketSpaceMutationVariables>(
    SignUpMarketSpaceDocument,
    options,
  );
}
export type SignUpMarketSpaceMutationHookResult = ReturnType<typeof useSignUpMarketSpaceMutation>;
export type SignUpMarketSpaceMutationResult = Apollo.MutationResult<SignUpMarketSpaceMutation>;
export type SignUpMarketSpaceMutationOptions = Apollo.BaseMutationOptions<
  SignUpMarketSpaceMutation,
  SignUpMarketSpaceMutationVariables
>;
export const UploadDocument = gql`
  mutation upload($file: Upload!) {
    fileUpload(file: $file) {
      link
      name
    }
  }
`;
export type UploadMutationFn = Apollo.MutationFunction<UploadMutation, UploadMutationVariables>;

/**
 * __useUploadMutation__
 *
 * To run a mutation, you first call `useUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadMutation, { data, loading, error }] = useUploadMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadMutation(baseOptions?: Apollo.MutationHookOptions<UploadMutation, UploadMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadMutation, UploadMutationVariables>(UploadDocument, options);
}
export type UploadMutationHookResult = ReturnType<typeof useUploadMutation>;
export type UploadMutationResult = Apollo.MutationResult<UploadMutation>;
export type UploadMutationOptions = Apollo.BaseMutationOptions<UploadMutation, UploadMutationVariables>;
export const FileRemoveDocument = gql`
  mutation FileRemove($file: String!) {
    fileRemove(file: $file)
  }
`;
export type FileRemoveMutationFn = Apollo.MutationFunction<FileRemoveMutation, FileRemoveMutationVariables>;

/**
 * __useFileRemoveMutation__
 *
 * To run a mutation, you first call `useFileRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileRemoveMutation, { data, loading, error }] = useFileRemoveMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useFileRemoveMutation(
  baseOptions?: Apollo.MutationHookOptions<FileRemoveMutation, FileRemoveMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FileRemoveMutation, FileRemoveMutationVariables>(FileRemoveDocument, options);
}
export type FileRemoveMutationHookResult = ReturnType<typeof useFileRemoveMutation>;
export type FileRemoveMutationResult = Apollo.MutationResult<FileRemoveMutation>;
export type FileRemoveMutationOptions = Apollo.BaseMutationOptions<FileRemoveMutation, FileRemoveMutationVariables>;
export const SignUpDocument = gql`
  mutation signUp($data: SignUpInput!) {
    signUp(data: $data)
  }
`;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const ChangePasswordDocument = gql`
  mutation changePassword($data: ChangePasswordInput!) {
    investorChangePassword(data: $data)
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const ToggleTwoFaDocument = gql`
  mutation toggleTwoFA {
    investorToggleTwoFA
  }
`;
export type ToggleTwoFaMutationFn = Apollo.MutationFunction<ToggleTwoFaMutation, ToggleTwoFaMutationVariables>;

/**
 * __useToggleTwoFaMutation__
 *
 * To run a mutation, you first call `useToggleTwoFaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleTwoFaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleTwoFaMutation, { data, loading, error }] = useToggleTwoFaMutation({
 *   variables: {
 *   },
 * });
 */
export function useToggleTwoFaMutation(
  baseOptions?: Apollo.MutationHookOptions<ToggleTwoFaMutation, ToggleTwoFaMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ToggleTwoFaMutation, ToggleTwoFaMutationVariables>(ToggleTwoFaDocument, options);
}
export type ToggleTwoFaMutationHookResult = ReturnType<typeof useToggleTwoFaMutation>;
export type ToggleTwoFaMutationResult = Apollo.MutationResult<ToggleTwoFaMutation>;
export type ToggleTwoFaMutationOptions = Apollo.BaseMutationOptions<ToggleTwoFaMutation, ToggleTwoFaMutationVariables>;
export const InvestorPublicKeyAddDocument = gql`
  mutation investorPublicKeyAdd($title: String!, $blockchainID: Int!) {
    investorPublicKeyAdd(title: $title, blockchainID: $blockchainID)
  }
`;
export type InvestorPublicKeyAddMutationFn = Apollo.MutationFunction<
  InvestorPublicKeyAddMutation,
  InvestorPublicKeyAddMutationVariables
>;

/**
 * __useInvestorPublicKeyAddMutation__
 *
 * To run a mutation, you first call `useInvestorPublicKeyAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorPublicKeyAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorPublicKeyAddMutation, { data, loading, error }] = useInvestorPublicKeyAddMutation({
 *   variables: {
 *      title: // value for 'title'
 *      blockchainID: // value for 'blockchainID'
 *   },
 * });
 */
export function useInvestorPublicKeyAddMutation(
  baseOptions?: Apollo.MutationHookOptions<InvestorPublicKeyAddMutation, InvestorPublicKeyAddMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InvestorPublicKeyAddMutation, InvestorPublicKeyAddMutationVariables>(
    InvestorPublicKeyAddDocument,
    options,
  );
}
export type InvestorPublicKeyAddMutationHookResult = ReturnType<typeof useInvestorPublicKeyAddMutation>;
export type InvestorPublicKeyAddMutationResult = Apollo.MutationResult<InvestorPublicKeyAddMutation>;
export type InvestorPublicKeyAddMutationOptions = Apollo.BaseMutationOptions<
  InvestorPublicKeyAddMutation,
  InvestorPublicKeyAddMutationVariables
>;
export const AutomatedWalletWhitelistDocument = gql`
  mutation automatedWalletWhitelist($shareTypeID: Int!, $walletAddress: String!) {
    whitelistWallet(shareTypeID: $shareTypeID, walletAddress: $walletAddress)
  }
`;
export type AutomatedWalletWhitelistMutationFn = Apollo.MutationFunction<
  AutomatedWalletWhitelistMutation,
  AutomatedWalletWhitelistMutationVariables
>;

/**
 * __useAutomatedWalletWhitelistMutation__
 *
 * To run a mutation, you first call `useAutomatedWalletWhitelistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAutomatedWalletWhitelistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [automatedWalletWhitelistMutation, { data, loading, error }] = useAutomatedWalletWhitelistMutation({
 *   variables: {
 *      shareTypeID: // value for 'shareTypeID'
 *      walletAddress: // value for 'walletAddress'
 *   },
 * });
 */
export function useAutomatedWalletWhitelistMutation(
  baseOptions?: Apollo.MutationHookOptions<AutomatedWalletWhitelistMutation, AutomatedWalletWhitelistMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AutomatedWalletWhitelistMutation, AutomatedWalletWhitelistMutationVariables>(
    AutomatedWalletWhitelistDocument,
    options,
  );
}
export type AutomatedWalletWhitelistMutationHookResult = ReturnType<typeof useAutomatedWalletWhitelistMutation>;
export type AutomatedWalletWhitelistMutationResult = Apollo.MutationResult<AutomatedWalletWhitelistMutation>;
export type AutomatedWalletWhitelistMutationOptions = Apollo.BaseMutationOptions<
  AutomatedWalletWhitelistMutation,
  AutomatedWalletWhitelistMutationVariables
>;
export const InvestorPublicKeyDeleteDocument = gql`
  mutation investorPublicKeyDelete($_id: Int!) {
    investorPublicKeyDelete(keyID: $_id)
  }
`;
export type InvestorPublicKeyDeleteMutationFn = Apollo.MutationFunction<
  InvestorPublicKeyDeleteMutation,
  InvestorPublicKeyDeleteMutationVariables
>;

/**
 * __useInvestorPublicKeyDeleteMutation__
 *
 * To run a mutation, you first call `useInvestorPublicKeyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorPublicKeyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorPublicKeyDeleteMutation, { data, loading, error }] = useInvestorPublicKeyDeleteMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useInvestorPublicKeyDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<InvestorPublicKeyDeleteMutation, InvestorPublicKeyDeleteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InvestorPublicKeyDeleteMutation, InvestorPublicKeyDeleteMutationVariables>(
    InvestorPublicKeyDeleteDocument,
    options,
  );
}
export type InvestorPublicKeyDeleteMutationHookResult = ReturnType<typeof useInvestorPublicKeyDeleteMutation>;
export type InvestorPublicKeyDeleteMutationResult = Apollo.MutationResult<InvestorPublicKeyDeleteMutation>;
export type InvestorPublicKeyDeleteMutationOptions = Apollo.BaseMutationOptions<
  InvestorPublicKeyDeleteMutation,
  InvestorPublicKeyDeleteMutationVariables
>;
export const InvestorProfileDocument = gql`
  mutation InvestorProfile($data: InvestorProfileInput!) {
    investorProfile(data: $data)
  }
`;
export type InvestorProfileMutationFn = Apollo.MutationFunction<
  InvestorProfileMutation,
  InvestorProfileMutationVariables
>;

/**
 * __useInvestorProfileMutation__
 *
 * To run a mutation, you first call `useInvestorProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorProfileMutation, { data, loading, error }] = useInvestorProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInvestorProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<InvestorProfileMutation, InvestorProfileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InvestorProfileMutation, InvestorProfileMutationVariables>(
    InvestorProfileDocument,
    options,
  );
}
export type InvestorProfileMutationHookResult = ReturnType<typeof useInvestorProfileMutation>;
export type InvestorProfileMutationResult = Apollo.MutationResult<InvestorProfileMutation>;
export type InvestorProfileMutationOptions = Apollo.BaseMutationOptions<
  InvestorProfileMutation,
  InvestorProfileMutationVariables
>;
export const UsufructuaryUpdateDocument = gql`
  mutation usufructuaryUpdate($data: InvestorUsufructuaryInput!) {
    investorUsufructuaryUpdate(data: $data)
  }
`;
export type UsufructuaryUpdateMutationFn = Apollo.MutationFunction<
  UsufructuaryUpdateMutation,
  UsufructuaryUpdateMutationVariables
>;

/**
 * __useUsufructuaryUpdateMutation__
 *
 * To run a mutation, you first call `useUsufructuaryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUsufructuaryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [usufructuaryUpdateMutation, { data, loading, error }] = useUsufructuaryUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUsufructuaryUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<UsufructuaryUpdateMutation, UsufructuaryUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UsufructuaryUpdateMutation, UsufructuaryUpdateMutationVariables>(
    UsufructuaryUpdateDocument,
    options,
  );
}
export type UsufructuaryUpdateMutationHookResult = ReturnType<typeof useUsufructuaryUpdateMutation>;
export type UsufructuaryUpdateMutationResult = Apollo.MutationResult<UsufructuaryUpdateMutation>;
export type UsufructuaryUpdateMutationOptions = Apollo.BaseMutationOptions<
  UsufructuaryUpdateMutation,
  UsufructuaryUpdateMutationVariables
>;
export const BeneficialUpdateDocument = gql`
  mutation beneficialUpdate($data: InvestorBeneficialInput!) {
    investorBeneficialUpdate(data: $data)
  }
`;
export type BeneficialUpdateMutationFn = Apollo.MutationFunction<
  BeneficialUpdateMutation,
  BeneficialUpdateMutationVariables
>;

/**
 * __useBeneficialUpdateMutation__
 *
 * To run a mutation, you first call `useBeneficialUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBeneficialUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [beneficialUpdateMutation, { data, loading, error }] = useBeneficialUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBeneficialUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<BeneficialUpdateMutation, BeneficialUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BeneficialUpdateMutation, BeneficialUpdateMutationVariables>(
    BeneficialUpdateDocument,
    options,
  );
}
export type BeneficialUpdateMutationHookResult = ReturnType<typeof useBeneficialUpdateMutation>;
export type BeneficialUpdateMutationResult = Apollo.MutationResult<BeneficialUpdateMutation>;
export type BeneficialUpdateMutationOptions = Apollo.BaseMutationOptions<
  BeneficialUpdateMutation,
  BeneficialUpdateMutationVariables
>;
export const InvestorVerifyDocument = gql`
  mutation investorVerify($secret: String!) {
    investorVerify(secret: $secret)
  }
`;
export type InvestorVerifyMutationFn = Apollo.MutationFunction<InvestorVerifyMutation, InvestorVerifyMutationVariables>;

/**
 * __useInvestorVerifyMutation__
 *
 * To run a mutation, you first call `useInvestorVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorVerifyMutation, { data, loading, error }] = useInvestorVerifyMutation({
 *   variables: {
 *      secret: // value for 'secret'
 *   },
 * });
 */
export function useInvestorVerifyMutation(
  baseOptions?: Apollo.MutationHookOptions<InvestorVerifyMutation, InvestorVerifyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InvestorVerifyMutation, InvestorVerifyMutationVariables>(InvestorVerifyDocument, options);
}
export type InvestorVerifyMutationHookResult = ReturnType<typeof useInvestorVerifyMutation>;
export type InvestorVerifyMutationResult = Apollo.MutationResult<InvestorVerifyMutation>;
export type InvestorVerifyMutationOptions = Apollo.BaseMutationOptions<
  InvestorVerifyMutation,
  InvestorVerifyMutationVariables
>;
export const InvestorResetDocument = gql`
  mutation investorReset($email: String!, $STO: Int!) {
    investorReset(email: $email, stoID: $STO)
  }
`;
export type InvestorResetMutationFn = Apollo.MutationFunction<InvestorResetMutation, InvestorResetMutationVariables>;

/**
 * __useInvestorResetMutation__
 *
 * To run a mutation, you first call `useInvestorResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorResetMutation, { data, loading, error }] = useInvestorResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *      STO: // value for 'STO'
 *   },
 * });
 */
export function useInvestorResetMutation(
  baseOptions?: Apollo.MutationHookOptions<InvestorResetMutation, InvestorResetMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InvestorResetMutation, InvestorResetMutationVariables>(InvestorResetDocument, options);
}
export type InvestorResetMutationHookResult = ReturnType<typeof useInvestorResetMutation>;
export type InvestorResetMutationResult = Apollo.MutationResult<InvestorResetMutation>;
export type InvestorResetMutationOptions = Apollo.BaseMutationOptions<
  InvestorResetMutation,
  InvestorResetMutationVariables
>;
export const TwoFaConfirmDocument = gql`
  mutation twoFaConfirm($code: Int!) {
    investor2FAConfirm(code: $code)
  }
`;
export type TwoFaConfirmMutationFn = Apollo.MutationFunction<TwoFaConfirmMutation, TwoFaConfirmMutationVariables>;

/**
 * __useTwoFaConfirmMutation__
 *
 * To run a mutation, you first call `useTwoFaConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTwoFaConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [twoFaConfirmMutation, { data, loading, error }] = useTwoFaConfirmMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useTwoFaConfirmMutation(
  baseOptions?: Apollo.MutationHookOptions<TwoFaConfirmMutation, TwoFaConfirmMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TwoFaConfirmMutation, TwoFaConfirmMutationVariables>(TwoFaConfirmDocument, options);
}
export type TwoFaConfirmMutationHookResult = ReturnType<typeof useTwoFaConfirmMutation>;
export type TwoFaConfirmMutationResult = Apollo.MutationResult<TwoFaConfirmMutation>;
export type TwoFaConfirmMutationOptions = Apollo.BaseMutationOptions<
  TwoFaConfirmMutation,
  TwoFaConfirmMutationVariables
>;
export const InvestorSetPasswordDocument = gql`
  mutation investorSetPassword($data: SetPasswordInput!) {
    investorSetPassword(data: $data)
  }
`;
export type InvestorSetPasswordMutationFn = Apollo.MutationFunction<
  InvestorSetPasswordMutation,
  InvestorSetPasswordMutationVariables
>;

/**
 * __useInvestorSetPasswordMutation__
 *
 * To run a mutation, you first call `useInvestorSetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorSetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorSetPasswordMutation, { data, loading, error }] = useInvestorSetPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInvestorSetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<InvestorSetPasswordMutation, InvestorSetPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InvestorSetPasswordMutation, InvestorSetPasswordMutationVariables>(
    InvestorSetPasswordDocument,
    options,
  );
}
export type InvestorSetPasswordMutationHookResult = ReturnType<typeof useInvestorSetPasswordMutation>;
export type InvestorSetPasswordMutationResult = Apollo.MutationResult<InvestorSetPasswordMutation>;
export type InvestorSetPasswordMutationOptions = Apollo.BaseMutationOptions<
  InvestorSetPasswordMutation,
  InvestorSetPasswordMutationVariables
>;
export const SetInvestorParamDocument = gql`
  mutation setInvestorParam($param: INVESTOR_PARAM!, $intValue: Int, $stringValue: String) {
    setInvestorParam(param: $param, intValue: $intValue, stringValue: $stringValue)
  }
`;
export type SetInvestorParamMutationFn = Apollo.MutationFunction<
  SetInvestorParamMutation,
  SetInvestorParamMutationVariables
>;

/**
 * __useSetInvestorParamMutation__
 *
 * To run a mutation, you first call `useSetInvestorParamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetInvestorParamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setInvestorParamMutation, { data, loading, error }] = useSetInvestorParamMutation({
 *   variables: {
 *      param: // value for 'param'
 *      intValue: // value for 'intValue'
 *      stringValue: // value for 'stringValue'
 *   },
 * });
 */
export function useSetInvestorParamMutation(
  baseOptions?: Apollo.MutationHookOptions<SetInvestorParamMutation, SetInvestorParamMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetInvestorParamMutation, SetInvestorParamMutationVariables>(
    SetInvestorParamDocument,
    options,
  );
}
export type SetInvestorParamMutationHookResult = ReturnType<typeof useSetInvestorParamMutation>;
export type SetInvestorParamMutationResult = Apollo.MutationResult<SetInvestorParamMutation>;
export type SetInvestorParamMutationOptions = Apollo.BaseMutationOptions<
  SetInvestorParamMutation,
  SetInvestorParamMutationVariables
>;
export const MeDocument = gql`
  query me {
    investorUser {
      sto {
        ID
        title
        details
        isActive
        logo
        ethereumContractAddress
        ethereumWhitelistAddress
        disclaimer
        stolink
        stolinkfull
        stoType
        stoInvestorTypes
        registrationText
        stoInvestorTypesNotOnShareRegister
        companyType
        registrationSuccessText
        fullDetails
        exchangeOpenDate
        propertyPicture
        externalSystemID
        projectAddress
        legalDetails
        picture
        verifyInvestorComHostToken
        parsedSettings {
          investorCategories {
            value
            label
          }
          favicon
          tabTitle
        }
        images {
          ID
          title
          url
        }
        documents {
          ID
          title
          url
        }
      }
      investor {
        ID
        firstName
        lastName
        address
        country
        phone
        cell
        zip
        town
        state
        email
        passportNumber
        nationalID
        driversLicenseID
        kinname
        kinphone
        kinemail
        investorType
        companyName
        titleWithinCompany
        powerToBindCompany
        birthDate
        isTwoFactorEnabled
        language
        middleName
        socialSecurity
        mailingAddress
        faxNumber
        maritalStatus
        occupation
        employerName
        employerAddress
        retirementAccount
        trustOrBusinessEntity
        dateIncorporation
        taxID
        govtID
        isTax
        secondaryContactName
        secondaryContactPhone
        secondaryContactEmail
        primaryContactName
        primaryContactPhone
        primaryContactEmail
        countryBusiness
        countryCitizenship
        taxCountry
        userName
        investmentLimitUpdateDate
        allowedTotalInvestment
        yearlyTotalInvestment
        asEntity {
          ID
          investorID
          members {
            ID
            investorID
            entityID
            firstName
            lastName
            role
            signatory
            email
          }
          typeID
          type {
            ID
            title
            countries
          }
          taxId
          name
          nickname
          accredited
          paymentMethod
          address
          city
          postalCode
          country
          state
          isApprovedByAdmin
        }
        isEntity
        isEntityID
        meta {
          type
          value
          key
          order
        }
      }
      investorSto {
        ID
        isAccountClosed
        investorID
        stoID
        expectedShares
        expectedInvestment
        isKYC
        applied
        status
        usufructuaryFirstName
        usufructuaryLastName
        usufructuaryAddress
        usufructuaryCity
        usufructuaryCountry
        usufructuaryEmail
        beneficialFirstName
        beneficialLastName
        beneficialAddress
        beneficialCity
        beneficialCountry
        beneficialEmail
        beneficialBirth
        beneficialNationality
        isUsufructuary
        isActive
        notes
      }
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const InfoDocument = gql`
  query info($_id: Int!) {
    publicSto(stoID: $_id) {
      settings {
        investorCategories {
          value
          label
        }
        favicon
        tabTitle
      }
      stolinkfull
      logo
      registrationText
      title
    }
  }
`;

/**
 * __useInfoQuery__
 *
 * To run a query within a React component, call `useInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfoQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useInfoQuery(baseOptions: Apollo.QueryHookOptions<InfoQuery, InfoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InfoQuery, InfoQueryVariables>(InfoDocument, options);
}
export function useInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InfoQuery, InfoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InfoQuery, InfoQueryVariables>(InfoDocument, options);
}
export type InfoQueryHookResult = ReturnType<typeof useInfoQuery>;
export type InfoLazyQueryHookResult = ReturnType<typeof useInfoLazyQuery>;
export type InfoQueryResult = Apollo.QueryResult<InfoQuery, InfoQueryVariables>;
export const InvestorPublicKeysDocument = gql`
  query investorPublicKeys {
    investorPublicKeys {
      ID
      title
    }
  }
`;

/**
 * __useInvestorPublicKeysQuery__
 *
 * To run a query within a React component, call `useInvestorPublicKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorPublicKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorPublicKeysQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvestorPublicKeysQuery(
  baseOptions?: Apollo.QueryHookOptions<InvestorPublicKeysQuery, InvestorPublicKeysQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestorPublicKeysQuery, InvestorPublicKeysQueryVariables>(
    InvestorPublicKeysDocument,
    options,
  );
}
export function useInvestorPublicKeysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestorPublicKeysQuery, InvestorPublicKeysQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestorPublicKeysQuery, InvestorPublicKeysQueryVariables>(
    InvestorPublicKeysDocument,
    options,
  );
}
export type InvestorPublicKeysQueryHookResult = ReturnType<typeof useInvestorPublicKeysQuery>;
export type InvestorPublicKeysLazyQueryHookResult = ReturnType<typeof useInvestorPublicKeysLazyQuery>;
export type InvestorPublicKeysQueryResult = Apollo.QueryResult<
  InvestorPublicKeysQuery,
  InvestorPublicKeysQueryVariables
>;
export const InvestorActiveStoDocument = gql`
  query investorActiveSto($_id: Int) {
    investorSto(stoID: $_id) {
      ID
      isAccountClosed
      investorID
      stoID
      expectedShares
      expectedInvestment
      isKYC
      applied
      status
      usufructuaryFirstName
      usufructuaryLastName
      usufructuaryAddress
      usufructuaryCity
      usufructuaryCountry
      usufructuaryEmail
      beneficialFirstName
      beneficialLastName
      beneficialAddress
      beneficialCity
      beneficialCountry
      beneficialEmail
      beneficialBirth
      beneficialNationality
      isUsufructuary
      isActive
      notes
      isNDASigned
      isBuyStoEnabled
    }
  }
`;

/**
 * __useInvestorActiveStoQuery__
 *
 * To run a query within a React component, call `useInvestorActiveStoQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorActiveStoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorActiveStoQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useInvestorActiveStoQuery(
  baseOptions?: Apollo.QueryHookOptions<InvestorActiveStoQuery, InvestorActiveStoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestorActiveStoQuery, InvestorActiveStoQueryVariables>(InvestorActiveStoDocument, options);
}
export function useInvestorActiveStoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestorActiveStoQuery, InvestorActiveStoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestorActiveStoQuery, InvestorActiveStoQueryVariables>(
    InvestorActiveStoDocument,
    options,
  );
}
export type InvestorActiveStoQueryHookResult = ReturnType<typeof useInvestorActiveStoQuery>;
export type InvestorActiveStoLazyQueryHookResult = ReturnType<typeof useInvestorActiveStoLazyQuery>;
export type InvestorActiveStoQueryResult = Apollo.QueryResult<InvestorActiveStoQuery, InvestorActiveStoQueryVariables>;
export const CountriesDocument = gql`
  query Countries {
    countries
  }
`;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(baseOptions?: Apollo.QueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
}
export function useCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CountriesQuery, CountriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
}
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesQueryResult = Apollo.QueryResult<CountriesQuery, CountriesQueryVariables>;
export const InvestorAppDataDocument = gql`
  query investorAppData {
    investorAppParameters {
      isFooterTextCentered
      IsMarketSpace
      IsDocuSignActive
      IsHelloSignActive
      areSTOHostnamesEnabled
      KycProvider
      IsDarwSignatureActive
      IsCheckMarkSignatureActive
      web3Address
      polygonWeb3Address
      binanceWeb3Address
      IsInternalWalletStoSpecific
      IsInternalWalletGlobal
      IsInternalWalletDisabled
      poweredByLabel
      isSSOModeEnabled
      clientKYC
      SSORedirectFrontEnd
      KycRequirementStep
      IsMoonpayEnabled
      is2FAEnabledByDefault
      doAutomaticBlockchainTransactionChecks
      doAutomaticPurchase
      isInvoicingEnabled
      atomicSwapContractAddress
      isAccreditationEnabled
      AccreditationProvider
      AccreddRedirectLink
      isCloudStorageEnabled
      is2FAForcedForAll
      isPropertySortingEnabled
      isWalletManagementModuleEnabled
      isMyPortfolioModuleEnabled
      isActiveOfferingsModuleEnabled
      isNewsModuleEnabled
      isContractsModuleEnabled
      isCorporateActionsModuleEnabled
      isTradingModuleEnabled
      isInvestorPolymeshModuleEnabled
      isChatModuleEnabled
      isSupportModuleEnabled
      isInvestorOwnershipModuleEnabled
      isSettingsModuleEnabled
      isTellAFriendModuleEnabled
      isAccreditationModuleEnabled
      isContactTheSponsorFontSwitchEnabled
      isSellBackEnabled
      isBankDetailsSwitchEnabled
      isBlockchainAddressSwitchEnabled
      toggleThemeEditor
      accreditationRequirementStep
      accreditationRequiringCountries
      skipDocumentSignScreen
      allowInvestorsToRegister
      hideContractsTilPostPurchase
      isInvestmentReturnCalculationEnabled
      isDriversLicenseEnabled
      isInternalTokenizedPurchaseEnabled
      termsAndConditionsConfig {
        link
        text
      }
      isAllDocumentsSignedPopUpEnabled
      isMergingPaymentsSharesRequestsEnabled
      platformConfiguration
      backgroundImageUrl
      isInvestorDashboardSwitchEnabled
      isInvestorLanguageSelectorEnabled
      defaultPropertyOrder {
        default
        options {
          label
          value
        }
      }
      defaultLoginRoute
      isCountryFlagsInSelectorEnabled
      isPublicLanguageSelectorEnabled
      maxFileUploadLimit
      txScanner {
        protocolId
        scannerUrl
        devScannerUrl
      }
      platformWalletMode
      isBuyStoWhitelistPerInvestorEnabled
      isInvestorDisassociationSwitchEnabled
      isRegistrationFormStepsInstructionsEnabled
      wizardCompleteHtml
      isNewEntityMemberEmailEnabled
      isNDAFlowEnabled
      isShareCertificateButtonEnabled
      isShareCertificatePageEnabled
      isBlockchainTestnetEnabled
      isFilterPreferencesEnabled
      isSaveAndNextAtPersonalInfoEnabled
      isOfferingProgressTrackingEnabled
      activePaymentProcessor
      isFastTrackPaymentEnabled
      isInvestorNavigationDisabled
      frontendRedirectConfig {
        afterPaymentUrl
      }
      isMetakeysFilterTagsDisplayEnabled
      isDiscountsEnabled
      isFeesEnabled
      isRegDRegSSeparationEnabled
      isPortfolioOverviewEnabled
      isProjectCardTagsDisplayEnabled
      isProjectCardImageDisplayEnabled
      isProjectPDFPresentationEnabled
      isInvestingEntityEnabled
      isSellSideEntityEnabled
      isSellSideSecrecyEnabled
      isSellSideEntityManagedStosTombstoneViewEnabled
      investorLogoProperties {
        displayInvestorLogo
        height
        width
      }
      investorStoSwitchProperties {
        isEnabled
        fontSize
      }
      investorNavbarTitleProperties {
        isEnabled
        fontSize
      }
      isEntityAsInvestorEnabled
      isMarketSpaceRegistrationFormEnabled
      isClosedOfferingsCardEnabled
      isActivePropertyBigTombstoneEnabled
      verifyInvestorComKeylessIntegration {
        redirectUrl
        isEnabled
      }
      isPlatformOperatorHidden
      isInvestorPortfolioDividendsSwitchEnabled
      isInvestorPortfolioBalanceSwitchEnabled
      isEntityOptional
      walletConnectProjectKey
      isPaymentProcessorSandbox
      isExternalModuleEnabled {
        redirectUrl
        isEnabled
        icon
      }
      investorPageScriptProps {
        src
        id
      }
      isWalletConnectEnabled
      showPropertyDetailReturnButton
      isInvestmentDetailsCardUnfolded
      isInvestmentDetailsCardEnabled
      isInvestorDashboardConnectWalletEnabled
      isLimitedInvestingEntityPreferences
      isLimitedInvestingEntityInfo
      isEntityManagementMeetingFieldEnabled
      isEntityReceivedTermSheetFieldEnabled
      isEntityReceivedSubscriptionAgreementFieldEnabled
      isEntityClosingFieldEnabled
      isStoSpecificPaymentChannelsEnabled
      isForumRegistrationEnabled
      isForumModuleEnabled
      phonePrefillCountryCode
      leftSideMenuFont
      areSellSideEntityChatLinksEnabled
      currencySymbolDisplayMode
      isInvoiceOverviewEnabled
      isOfferedContractsEnabled
      isShareOwnershipLimitEnabled
      isInvestorOfferingsDashboard
      isActiveOfferingsFilterCardEnabled
      isClosedOfferingsFilterCardEnabled
      isProfileMatchesFilterCardEnabled
      rightLayoutDesignOfferingsDetailPage
      isTradingBuyOrdersEnabled
      isCloudUploadingEnabled
      isEntityInvitedFieldEnabled
      isExternalDividendPayoutEnabled
      defaultInvestorLanguage
    }
  }
`;

/**
 * __useInvestorAppDataQuery__
 *
 * To run a query within a React component, call `useInvestorAppDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorAppDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorAppDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvestorAppDataQuery(
  baseOptions?: Apollo.QueryHookOptions<InvestorAppDataQuery, InvestorAppDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestorAppDataQuery, InvestorAppDataQueryVariables>(InvestorAppDataDocument, options);
}
export function useInvestorAppDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestorAppDataQuery, InvestorAppDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestorAppDataQuery, InvestorAppDataQueryVariables>(InvestorAppDataDocument, options);
}
export type InvestorAppDataQueryHookResult = ReturnType<typeof useInvestorAppDataQuery>;
export type InvestorAppDataLazyQueryHookResult = ReturnType<typeof useInvestorAppDataLazyQuery>;
export type InvestorAppDataQueryResult = Apollo.QueryResult<InvestorAppDataQuery, InvestorAppDataQueryVariables>;
export const GetInvestorTypesDocument = gql`
  query getInvestorTypes {
    getInvestorTypes {
      ID
      type
    }
  }
`;

/**
 * __useGetInvestorTypesQuery__
 *
 * To run a query within a React component, call `useGetInvestorTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestorTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestorTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvestorTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetInvestorTypesQuery, GetInvestorTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvestorTypesQuery, GetInvestorTypesQueryVariables>(GetInvestorTypesDocument, options);
}
export function useGetInvestorTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInvestorTypesQuery, GetInvestorTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvestorTypesQuery, GetInvestorTypesQueryVariables>(GetInvestorTypesDocument, options);
}
export type GetInvestorTypesQueryHookResult = ReturnType<typeof useGetInvestorTypesQuery>;
export type GetInvestorTypesLazyQueryHookResult = ReturnType<typeof useGetInvestorTypesLazyQuery>;
export type GetInvestorTypesQueryResult = Apollo.QueryResult<GetInvestorTypesQuery, GetInvestorTypesQueryVariables>;
export const FindAllStoDocument = gql`
  query findAllSto {
    findAllSto {
      ID
      title
      isActive
    }
  }
`;

/**
 * __useFindAllStoQuery__
 *
 * To run a query within a React component, call `useFindAllStoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllStoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllStoQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllStoQuery(baseOptions?: Apollo.QueryHookOptions<FindAllStoQuery, FindAllStoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindAllStoQuery, FindAllStoQueryVariables>(FindAllStoDocument, options);
}
export function useFindAllStoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindAllStoQuery, FindAllStoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindAllStoQuery, FindAllStoQueryVariables>(FindAllStoDocument, options);
}
export type FindAllStoQueryHookResult = ReturnType<typeof useFindAllStoQuery>;
export type FindAllStoLazyQueryHookResult = ReturnType<typeof useFindAllStoLazyQuery>;
export type FindAllStoQueryResult = Apollo.QueryResult<FindAllStoQuery, FindAllStoQueryVariables>;
export const GetWeb3ProviderDocument = gql`
  query GetWeb3Provider($blockchainProtocolID: Int!) {
    getWeb3Provider(blockchainProtocolID: $blockchainProtocolID) {
      blockchainURL
      blockchainSwapContractAddress
      blockchainExplorerURL
    }
  }
`;

/**
 * __useGetWeb3ProviderQuery__
 *
 * To run a query within a React component, call `useGetWeb3ProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWeb3ProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWeb3ProviderQuery({
 *   variables: {
 *      blockchainProtocolID: // value for 'blockchainProtocolID'
 *   },
 * });
 */
export function useGetWeb3ProviderQuery(
  baseOptions: Apollo.QueryHookOptions<GetWeb3ProviderQuery, GetWeb3ProviderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWeb3ProviderQuery, GetWeb3ProviderQueryVariables>(GetWeb3ProviderDocument, options);
}
export function useGetWeb3ProviderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWeb3ProviderQuery, GetWeb3ProviderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWeb3ProviderQuery, GetWeb3ProviderQueryVariables>(GetWeb3ProviderDocument, options);
}
export type GetWeb3ProviderQueryHookResult = ReturnType<typeof useGetWeb3ProviderQuery>;
export type GetWeb3ProviderLazyQueryHookResult = ReturnType<typeof useGetWeb3ProviderLazyQuery>;
export type GetWeb3ProviderQueryResult = Apollo.QueryResult<GetWeb3ProviderQuery, GetWeb3ProviderQueryVariables>;
export const FindCurrencyByIdDocument = gql`
  query FindCurrencyByID($currencyID: Int!) {
    findCurrencyById(currencyID: $currencyID) {
      ID
      currency
      abbreviation
      symbol
      isBlockchainBased
      blockchainID
      isNative
      cryptoReceivingAddress
      Address
    }
  }
`;

/**
 * __useFindCurrencyByIdQuery__
 *
 * To run a query within a React component, call `useFindCurrencyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCurrencyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCurrencyByIdQuery({
 *   variables: {
 *      currencyID: // value for 'currencyID'
 *   },
 * });
 */
export function useFindCurrencyByIdQuery(
  baseOptions: Apollo.QueryHookOptions<FindCurrencyByIdQuery, FindCurrencyByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindCurrencyByIdQuery, FindCurrencyByIdQueryVariables>(FindCurrencyByIdDocument, options);
}
export function useFindCurrencyByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindCurrencyByIdQuery, FindCurrencyByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindCurrencyByIdQuery, FindCurrencyByIdQueryVariables>(FindCurrencyByIdDocument, options);
}
export type FindCurrencyByIdQueryHookResult = ReturnType<typeof useFindCurrencyByIdQuery>;
export type FindCurrencyByIdLazyQueryHookResult = ReturnType<typeof useFindCurrencyByIdLazyQuery>;
export type FindCurrencyByIdQueryResult = Apollo.QueryResult<FindCurrencyByIdQuery, FindCurrencyByIdQueryVariables>;
export const GetDiscountsDocument = gql`
  query getDiscounts($stoID: Int!, $discountAppliesTo: [DISCOUNT_APPLIES_TO!]!) {
    getDiscounts(stoID: $stoID, discountAppliesTo: $discountAppliesTo) {
      ID
      stoIDs
      type
      appliesTo
      amount
      triggers {
        ID
        transformerID
        type
        lowerBound
        upperBound
        isAlwaysVisible
      }
    }
  }
`;

/**
 * __useGetDiscountsQuery__
 *
 * To run a query within a React component, call `useGetDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscountsQuery({
 *   variables: {
 *      stoID: // value for 'stoID'
 *      discountAppliesTo: // value for 'discountAppliesTo'
 *   },
 * });
 */
export function useGetDiscountsQuery(
  baseOptions: Apollo.QueryHookOptions<GetDiscountsQuery, GetDiscountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDiscountsQuery, GetDiscountsQueryVariables>(GetDiscountsDocument, options);
}
export function useGetDiscountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDiscountsQuery, GetDiscountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDiscountsQuery, GetDiscountsQueryVariables>(GetDiscountsDocument, options);
}
export type GetDiscountsQueryHookResult = ReturnType<typeof useGetDiscountsQuery>;
export type GetDiscountsLazyQueryHookResult = ReturnType<typeof useGetDiscountsLazyQuery>;
export type GetDiscountsQueryResult = Apollo.QueryResult<GetDiscountsQuery, GetDiscountsQueryVariables>;
export const SaveContractFieldsDocument = gql`
  mutation saveContractFields($documentID: Int!, $fieldValues: [DocumentFieldValueDTO!]!) {
    setSubmittedDocument(documentID: $documentID, fieldValues: $fieldValues)
  }
`;
export type SaveContractFieldsMutationFn = Apollo.MutationFunction<
  SaveContractFieldsMutation,
  SaveContractFieldsMutationVariables
>;

/**
 * __useSaveContractFieldsMutation__
 *
 * To run a mutation, you first call `useSaveContractFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveContractFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveContractFieldsMutation, { data, loading, error }] = useSaveContractFieldsMutation({
 *   variables: {
 *      documentID: // value for 'documentID'
 *      fieldValues: // value for 'fieldValues'
 *   },
 * });
 */
export function useSaveContractFieldsMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveContractFieldsMutation, SaveContractFieldsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveContractFieldsMutation, SaveContractFieldsMutationVariables>(
    SaveContractFieldsDocument,
    options,
  );
}
export type SaveContractFieldsMutationHookResult = ReturnType<typeof useSaveContractFieldsMutation>;
export type SaveContractFieldsMutationResult = Apollo.MutationResult<SaveContractFieldsMutation>;
export type SaveContractFieldsMutationOptions = Apollo.BaseMutationOptions<
  SaveContractFieldsMutation,
  SaveContractFieldsMutationVariables
>;
export const SaveContractSignatureDocument = gql`
  mutation saveContractSignature($documentID: Int!, $signature: String!) {
    setSignature(documentID: $documentID, base64: $signature)
  }
`;
export type SaveContractSignatureMutationFn = Apollo.MutationFunction<
  SaveContractSignatureMutation,
  SaveContractSignatureMutationVariables
>;

/**
 * __useSaveContractSignatureMutation__
 *
 * To run a mutation, you first call `useSaveContractSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveContractSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveContractSignatureMutation, { data, loading, error }] = useSaveContractSignatureMutation({
 *   variables: {
 *      documentID: // value for 'documentID'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useSaveContractSignatureMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveContractSignatureMutation, SaveContractSignatureMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveContractSignatureMutation, SaveContractSignatureMutationVariables>(
    SaveContractSignatureDocument,
    options,
  );
}
export type SaveContractSignatureMutationHookResult = ReturnType<typeof useSaveContractSignatureMutation>;
export type SaveContractSignatureMutationResult = Apollo.MutationResult<SaveContractSignatureMutation>;
export type SaveContractSignatureMutationOptions = Apollo.BaseMutationOptions<
  SaveContractSignatureMutation,
  SaveContractSignatureMutationVariables
>;
export const SendContractDocument = gql`
  mutation sendContract($documentID: Int!) {
    sendContract(documentID: $documentID)
  }
`;
export type SendContractMutationFn = Apollo.MutationFunction<SendContractMutation, SendContractMutationVariables>;

/**
 * __useSendContractMutation__
 *
 * To run a mutation, you first call `useSendContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendContractMutation, { data, loading, error }] = useSendContractMutation({
 *   variables: {
 *      documentID: // value for 'documentID'
 *   },
 * });
 */
export function useSendContractMutation(
  baseOptions?: Apollo.MutationHookOptions<SendContractMutation, SendContractMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendContractMutation, SendContractMutationVariables>(SendContractDocument, options);
}
export type SendContractMutationHookResult = ReturnType<typeof useSendContractMutation>;
export type SendContractMutationResult = Apollo.MutationResult<SendContractMutation>;
export type SendContractMutationOptions = Apollo.BaseMutationOptions<
  SendContractMutation,
  SendContractMutationVariables
>;
export const OfferedDocumentDocument = gql`
  query offeredDocument($documentID: Int!) {
    offeredDocument(documentID: $documentID) {
      document {
        ID
        title
        contents
      }
    }
    unfinishedDocument(documentID: $documentID) {
      fieldValues {
        ID
        value
      }
      signature {
        url
        modified
      }
      status
    }
    documentFields(documentID: $documentID) {
      ID
      title
      fieldID
      type
      helperText
    }
  }
`;

/**
 * __useOfferedDocumentQuery__
 *
 * To run a query within a React component, call `useOfferedDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferedDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferedDocumentQuery({
 *   variables: {
 *      documentID: // value for 'documentID'
 *   },
 * });
 */
export function useOfferedDocumentQuery(
  baseOptions: Apollo.QueryHookOptions<OfferedDocumentQuery, OfferedDocumentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OfferedDocumentQuery, OfferedDocumentQueryVariables>(OfferedDocumentDocument, options);
}
export function useOfferedDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OfferedDocumentQuery, OfferedDocumentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OfferedDocumentQuery, OfferedDocumentQueryVariables>(OfferedDocumentDocument, options);
}
export type OfferedDocumentQueryHookResult = ReturnType<typeof useOfferedDocumentQuery>;
export type OfferedDocumentLazyQueryHookResult = ReturnType<typeof useOfferedDocumentLazyQuery>;
export type OfferedDocumentQueryResult = Apollo.QueryResult<OfferedDocumentQuery, OfferedDocumentQueryVariables>;
export const SubmittedDocumentDocument = gql`
  query submittedDocument($submittedDocumentID: Int!) {
    submittedDocument(submittedDocumentID: $submittedDocumentID) {
      fieldValues {
        ID
        value
      }
      contents
      signature {
        url
        modified
      }
      document {
        title
      }
      status
    }
  }
`;

/**
 * __useSubmittedDocumentQuery__
 *
 * To run a query within a React component, call `useSubmittedDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmittedDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmittedDocumentQuery({
 *   variables: {
 *      submittedDocumentID: // value for 'submittedDocumentID'
 *   },
 * });
 */
export function useSubmittedDocumentQuery(
  baseOptions: Apollo.QueryHookOptions<SubmittedDocumentQuery, SubmittedDocumentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubmittedDocumentQuery, SubmittedDocumentQueryVariables>(SubmittedDocumentDocument, options);
}
export function useSubmittedDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubmittedDocumentQuery, SubmittedDocumentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubmittedDocumentQuery, SubmittedDocumentQueryVariables>(
    SubmittedDocumentDocument,
    options,
  );
}
export type SubmittedDocumentQueryHookResult = ReturnType<typeof useSubmittedDocumentQuery>;
export type SubmittedDocumentLazyQueryHookResult = ReturnType<typeof useSubmittedDocumentLazyQuery>;
export type SubmittedDocumentQueryResult = Apollo.QueryResult<SubmittedDocumentQuery, SubmittedDocumentQueryVariables>;
export const ContractsPageDocument = gql`
  query contractsPage {
    offeredDocuments {
      title
      to
      document {
        ID
        title
      }
    }
    submittedDocuments(minStatus: 2) {
      ID
      status
      signatureFilePath
      document {
        ID
        title
        docusignDocumentID
        helloSignDocumentID
      }
      signature {
        modified
      }
      stoID
    }
  }
`;

/**
 * __useContractsPageQuery__
 *
 * To run a query within a React component, call `useContractsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useContractsPageQuery(
  baseOptions?: Apollo.QueryHookOptions<ContractsPageQuery, ContractsPageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContractsPageQuery, ContractsPageQueryVariables>(ContractsPageDocument, options);
}
export function useContractsPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContractsPageQuery, ContractsPageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContractsPageQuery, ContractsPageQueryVariables>(ContractsPageDocument, options);
}
export type ContractsPageQueryHookResult = ReturnType<typeof useContractsPageQuery>;
export type ContractsPageLazyQueryHookResult = ReturnType<typeof useContractsPageLazyQuery>;
export type ContractsPageQueryResult = Apollo.QueryResult<ContractsPageQuery, ContractsPageQueryVariables>;
export const DownloadSignedHelloSignDocument = gql`
  query downloadSignedHelloSign($fileID: String!) {
    downloadSignedHelloSign(fileID: $fileID)
  }
`;

/**
 * __useDownloadSignedHelloSignQuery__
 *
 * To run a query within a React component, call `useDownloadSignedHelloSignQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadSignedHelloSignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadSignedHelloSignQuery({
 *   variables: {
 *      fileID: // value for 'fileID'
 *   },
 * });
 */
export function useDownloadSignedHelloSignQuery(
  baseOptions: Apollo.QueryHookOptions<DownloadSignedHelloSignQuery, DownloadSignedHelloSignQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DownloadSignedHelloSignQuery, DownloadSignedHelloSignQueryVariables>(
    DownloadSignedHelloSignDocument,
    options,
  );
}
export function useDownloadSignedHelloSignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DownloadSignedHelloSignQuery, DownloadSignedHelloSignQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DownloadSignedHelloSignQuery, DownloadSignedHelloSignQueryVariables>(
    DownloadSignedHelloSignDocument,
    options,
  );
}
export type DownloadSignedHelloSignQueryHookResult = ReturnType<typeof useDownloadSignedHelloSignQuery>;
export type DownloadSignedHelloSignLazyQueryHookResult = ReturnType<typeof useDownloadSignedHelloSignLazyQuery>;
export type DownloadSignedHelloSignQueryResult = Apollo.QueryResult<
  DownloadSignedHelloSignQuery,
  DownloadSignedHelloSignQueryVariables
>;
export const DownloadSignedDocuSignDocument = gql`
  query downloadSignedDocuSign($envelopeID: String!) {
    downloadSignedDocuSign(envelopeID: $envelopeID)
  }
`;

/**
 * __useDownloadSignedDocuSignQuery__
 *
 * To run a query within a React component, call `useDownloadSignedDocuSignQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadSignedDocuSignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadSignedDocuSignQuery({
 *   variables: {
 *      envelopeID: // value for 'envelopeID'
 *   },
 * });
 */
export function useDownloadSignedDocuSignQuery(
  baseOptions: Apollo.QueryHookOptions<DownloadSignedDocuSignQuery, DownloadSignedDocuSignQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DownloadSignedDocuSignQuery, DownloadSignedDocuSignQueryVariables>(
    DownloadSignedDocuSignDocument,
    options,
  );
}
export function useDownloadSignedDocuSignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DownloadSignedDocuSignQuery, DownloadSignedDocuSignQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DownloadSignedDocuSignQuery, DownloadSignedDocuSignQueryVariables>(
    DownloadSignedDocuSignDocument,
    options,
  );
}
export type DownloadSignedDocuSignQueryHookResult = ReturnType<typeof useDownloadSignedDocuSignQuery>;
export type DownloadSignedDocuSignLazyQueryHookResult = ReturnType<typeof useDownloadSignedDocuSignLazyQuery>;
export type DownloadSignedDocuSignQueryResult = Apollo.QueryResult<
  DownloadSignedDocuSignQuery,
  DownloadSignedDocuSignQueryVariables
>;
export const CreateNewBuyOrderDocument = gql`
  mutation createNewBuyOrder($order: ExchangeBuyOrderInput!) {
    createBuyOrder(order: $order)
  }
`;
export type CreateNewBuyOrderMutationFn = Apollo.MutationFunction<
  CreateNewBuyOrderMutation,
  CreateNewBuyOrderMutationVariables
>;

/**
 * __useCreateNewBuyOrderMutation__
 *
 * To run a mutation, you first call `useCreateNewBuyOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewBuyOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewBuyOrderMutation, { data, loading, error }] = useCreateNewBuyOrderMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCreateNewBuyOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateNewBuyOrderMutation, CreateNewBuyOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNewBuyOrderMutation, CreateNewBuyOrderMutationVariables>(
    CreateNewBuyOrderDocument,
    options,
  );
}
export type CreateNewBuyOrderMutationHookResult = ReturnType<typeof useCreateNewBuyOrderMutation>;
export type CreateNewBuyOrderMutationResult = Apollo.MutationResult<CreateNewBuyOrderMutation>;
export type CreateNewBuyOrderMutationOptions = Apollo.BaseMutationOptions<
  CreateNewBuyOrderMutation,
  CreateNewBuyOrderMutationVariables
>;
export const CreateNewSellOrderDocument = gql`
  mutation createNewSellOrder($order: ExchangeSellOrderInput!) {
    createSellOrder(order: $order)
  }
`;
export type CreateNewSellOrderMutationFn = Apollo.MutationFunction<
  CreateNewSellOrderMutation,
  CreateNewSellOrderMutationVariables
>;

/**
 * __useCreateNewSellOrderMutation__
 *
 * To run a mutation, you first call `useCreateNewSellOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewSellOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewSellOrderMutation, { data, loading, error }] = useCreateNewSellOrderMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCreateNewSellOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateNewSellOrderMutation, CreateNewSellOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNewSellOrderMutation, CreateNewSellOrderMutationVariables>(
    CreateNewSellOrderDocument,
    options,
  );
}
export type CreateNewSellOrderMutationHookResult = ReturnType<typeof useCreateNewSellOrderMutation>;
export type CreateNewSellOrderMutationResult = Apollo.MutationResult<CreateNewSellOrderMutation>;
export type CreateNewSellOrderMutationOptions = Apollo.BaseMutationOptions<
  CreateNewSellOrderMutation,
  CreateNewSellOrderMutationVariables
>;
export const UpdateSellOrderDocument = gql`
  mutation updateSellOrder($orderID: Int!, $data: ExchangeUpdateOrderInput!) {
    updateSellOrder(orderID: $orderID, data: $data)
  }
`;
export type UpdateSellOrderMutationFn = Apollo.MutationFunction<
  UpdateSellOrderMutation,
  UpdateSellOrderMutationVariables
>;

/**
 * __useUpdateSellOrderMutation__
 *
 * To run a mutation, you first call `useUpdateSellOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSellOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSellOrderMutation, { data, loading, error }] = useUpdateSellOrderMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSellOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSellOrderMutation, UpdateSellOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSellOrderMutation, UpdateSellOrderMutationVariables>(
    UpdateSellOrderDocument,
    options,
  );
}
export type UpdateSellOrderMutationHookResult = ReturnType<typeof useUpdateSellOrderMutation>;
export type UpdateSellOrderMutationResult = Apollo.MutationResult<UpdateSellOrderMutation>;
export type UpdateSellOrderMutationOptions = Apollo.BaseMutationOptions<
  UpdateSellOrderMutation,
  UpdateSellOrderMutationVariables
>;
export const DeleteOrderDocument = gql`
  mutation deleteOrder($orderID: Int!) {
    deleteOrder(orderID: $orderID)
  }
`;
export type DeleteOrderMutationFn = Apollo.MutationFunction<DeleteOrderMutation, DeleteOrderMutationVariables>;

/**
 * __useDeleteOrderMutation__
 *
 * To run a mutation, you first call `useDeleteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderMutation, { data, loading, error }] = useDeleteOrderMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useDeleteOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteOrderMutation, DeleteOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOrderMutation, DeleteOrderMutationVariables>(DeleteOrderDocument, options);
}
export type DeleteOrderMutationHookResult = ReturnType<typeof useDeleteOrderMutation>;
export type DeleteOrderMutationResult = Apollo.MutationResult<DeleteOrderMutation>;
export type DeleteOrderMutationOptions = Apollo.BaseMutationOptions<DeleteOrderMutation, DeleteOrderMutationVariables>;
export const DeleteOfferDocument = gql`
  mutation deleteOffer($orderID: Int!) {
    deleteOffer(orderID: $orderID)
  }
`;
export type DeleteOfferMutationFn = Apollo.MutationFunction<DeleteOfferMutation, DeleteOfferMutationVariables>;

/**
 * __useDeleteOfferMutation__
 *
 * To run a mutation, you first call `useDeleteOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfferMutation, { data, loading, error }] = useDeleteOfferMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useDeleteOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteOfferMutation, DeleteOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOfferMutation, DeleteOfferMutationVariables>(DeleteOfferDocument, options);
}
export type DeleteOfferMutationHookResult = ReturnType<typeof useDeleteOfferMutation>;
export type DeleteOfferMutationResult = Apollo.MutationResult<DeleteOfferMutation>;
export type DeleteOfferMutationOptions = Apollo.BaseMutationOptions<DeleteOfferMutation, DeleteOfferMutationVariables>;
export const StartSwapDocument = gql`
  mutation startSwap($offerID: Int!) {
    startSwap(offerID: $offerID)
  }
`;
export type StartSwapMutationFn = Apollo.MutationFunction<StartSwapMutation, StartSwapMutationVariables>;

/**
 * __useStartSwapMutation__
 *
 * To run a mutation, you first call `useStartSwapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSwapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSwapMutation, { data, loading, error }] = useStartSwapMutation({
 *   variables: {
 *      offerID: // value for 'offerID'
 *   },
 * });
 */
export function useStartSwapMutation(
  baseOptions?: Apollo.MutationHookOptions<StartSwapMutation, StartSwapMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StartSwapMutation, StartSwapMutationVariables>(StartSwapDocument, options);
}
export type StartSwapMutationHookResult = ReturnType<typeof useStartSwapMutation>;
export type StartSwapMutationResult = Apollo.MutationResult<StartSwapMutation>;
export type StartSwapMutationOptions = Apollo.BaseMutationOptions<StartSwapMutation, StartSwapMutationVariables>;
export const CreateNewOfferDocument = gql`
  mutation createNewOffer($offer: ExchangeOfferInput!) {
    createOffer(offer: $offer)
  }
`;
export type CreateNewOfferMutationFn = Apollo.MutationFunction<CreateNewOfferMutation, CreateNewOfferMutationVariables>;

/**
 * __useCreateNewOfferMutation__
 *
 * To run a mutation, you first call `useCreateNewOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewOfferMutation, { data, loading, error }] = useCreateNewOfferMutation({
 *   variables: {
 *      offer: // value for 'offer'
 *   },
 * });
 */
export function useCreateNewOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateNewOfferMutation, CreateNewOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNewOfferMutation, CreateNewOfferMutationVariables>(CreateNewOfferDocument, options);
}
export type CreateNewOfferMutationHookResult = ReturnType<typeof useCreateNewOfferMutation>;
export type CreateNewOfferMutationResult = Apollo.MutationResult<CreateNewOfferMutation>;
export type CreateNewOfferMutationOptions = Apollo.BaseMutationOptions<
  CreateNewOfferMutation,
  CreateNewOfferMutationVariables
>;
export const AcceptInternalSwapDocument = gql`
  mutation acceptInternalSwap($offerID: Int!) {
    acceptInternalSwap(offerID: $offerID)
  }
`;
export type AcceptInternalSwapMutationFn = Apollo.MutationFunction<
  AcceptInternalSwapMutation,
  AcceptInternalSwapMutationVariables
>;

/**
 * __useAcceptInternalSwapMutation__
 *
 * To run a mutation, you first call `useAcceptInternalSwapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInternalSwapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInternalSwapMutation, { data, loading, error }] = useAcceptInternalSwapMutation({
 *   variables: {
 *      offerID: // value for 'offerID'
 *   },
 * });
 */
export function useAcceptInternalSwapMutation(
  baseOptions?: Apollo.MutationHookOptions<AcceptInternalSwapMutation, AcceptInternalSwapMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptInternalSwapMutation, AcceptInternalSwapMutationVariables>(
    AcceptInternalSwapDocument,
    options,
  );
}
export type AcceptInternalSwapMutationHookResult = ReturnType<typeof useAcceptInternalSwapMutation>;
export type AcceptInternalSwapMutationResult = Apollo.MutationResult<AcceptInternalSwapMutation>;
export type AcceptInternalSwapMutationOptions = Apollo.BaseMutationOptions<
  AcceptInternalSwapMutation,
  AcceptInternalSwapMutationVariables
>;
export const PayOfferWithInternalWalletBalanceDocument = gql`
  mutation payOfferWithInternalWalletBalance($offerID: Int!) {
    payOfferWithInternalWalletBalance(offerID: $offerID)
  }
`;
export type PayOfferWithInternalWalletBalanceMutationFn = Apollo.MutationFunction<
  PayOfferWithInternalWalletBalanceMutation,
  PayOfferWithInternalWalletBalanceMutationVariables
>;

/**
 * __usePayOfferWithInternalWalletBalanceMutation__
 *
 * To run a mutation, you first call `usePayOfferWithInternalWalletBalanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayOfferWithInternalWalletBalanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payOfferWithInternalWalletBalanceMutation, { data, loading, error }] = usePayOfferWithInternalWalletBalanceMutation({
 *   variables: {
 *      offerID: // value for 'offerID'
 *   },
 * });
 */
export function usePayOfferWithInternalWalletBalanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayOfferWithInternalWalletBalanceMutation,
    PayOfferWithInternalWalletBalanceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayOfferWithInternalWalletBalanceMutation,
    PayOfferWithInternalWalletBalanceMutationVariables
  >(PayOfferWithInternalWalletBalanceDocument, options);
}
export type PayOfferWithInternalWalletBalanceMutationHookResult = ReturnType<
  typeof usePayOfferWithInternalWalletBalanceMutation
>;
export type PayOfferWithInternalWalletBalanceMutationResult =
  Apollo.MutationResult<PayOfferWithInternalWalletBalanceMutation>;
export type PayOfferWithInternalWalletBalanceMutationOptions = Apollo.BaseMutationOptions<
  PayOfferWithInternalWalletBalanceMutation,
  PayOfferWithInternalWalletBalanceMutationVariables
>;
export const AcceptBlockchainSwapDocument = gql`
  mutation acceptBlockchainSwap($walletAddress: String!, $offerID: Int!) {
    acceptBlockchainSwap(walletAddress: $walletAddress, offerID: $offerID)
  }
`;
export type AcceptBlockchainSwapMutationFn = Apollo.MutationFunction<
  AcceptBlockchainSwapMutation,
  AcceptBlockchainSwapMutationVariables
>;

/**
 * __useAcceptBlockchainSwapMutation__
 *
 * To run a mutation, you first call `useAcceptBlockchainSwapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptBlockchainSwapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptBlockchainSwapMutation, { data, loading, error }] = useAcceptBlockchainSwapMutation({
 *   variables: {
 *      walletAddress: // value for 'walletAddress'
 *      offerID: // value for 'offerID'
 *   },
 * });
 */
export function useAcceptBlockchainSwapMutation(
  baseOptions?: Apollo.MutationHookOptions<AcceptBlockchainSwapMutation, AcceptBlockchainSwapMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptBlockchainSwapMutation, AcceptBlockchainSwapMutationVariables>(
    AcceptBlockchainSwapDocument,
    options,
  );
}
export type AcceptBlockchainSwapMutationHookResult = ReturnType<typeof useAcceptBlockchainSwapMutation>;
export type AcceptBlockchainSwapMutationResult = Apollo.MutationResult<AcceptBlockchainSwapMutation>;
export type AcceptBlockchainSwapMutationOptions = Apollo.BaseMutationOptions<
  AcceptBlockchainSwapMutation,
  AcceptBlockchainSwapMutationVariables
>;
export const CompanyTransferSharesDocument = gql`
  mutation CompanyTransferShares($data: TransferShareInput!) {
    companyTransferShares(data: $data)
  }
`;
export type CompanyTransferSharesMutationFn = Apollo.MutationFunction<
  CompanyTransferSharesMutation,
  CompanyTransferSharesMutationVariables
>;

/**
 * __useCompanyTransferSharesMutation__
 *
 * To run a mutation, you first call `useCompanyTransferSharesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyTransferSharesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyTransferSharesMutation, { data, loading, error }] = useCompanyTransferSharesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCompanyTransferSharesMutation(
  baseOptions?: Apollo.MutationHookOptions<CompanyTransferSharesMutation, CompanyTransferSharesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CompanyTransferSharesMutation, CompanyTransferSharesMutationVariables>(
    CompanyTransferSharesDocument,
    options,
  );
}
export type CompanyTransferSharesMutationHookResult = ReturnType<typeof useCompanyTransferSharesMutation>;
export type CompanyTransferSharesMutationResult = Apollo.MutationResult<CompanyTransferSharesMutation>;
export type CompanyTransferSharesMutationOptions = Apollo.BaseMutationOptions<
  CompanyTransferSharesMutation,
  CompanyTransferSharesMutationVariables
>;
export const UpdateOrderStatusDocument = gql`
  mutation UpdateOrderStatus($atomicSwapCurrentStatus: AtomicSwapStatus!, $orderId: Int!) {
    updateOrderStatus(atomicSwapCurrentStatus: $atomicSwapCurrentStatus, orderID: $orderId)
  }
`;
export type UpdateOrderStatusMutationFn = Apollo.MutationFunction<
  UpdateOrderStatusMutation,
  UpdateOrderStatusMutationVariables
>;

/**
 * __useUpdateOrderStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderStatusMutation, { data, loading, error }] = useUpdateOrderStatusMutation({
 *   variables: {
 *      atomicSwapCurrentStatus: // value for 'atomicSwapCurrentStatus'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useUpdateOrderStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>(
    UpdateOrderStatusDocument,
    options,
  );
}
export type UpdateOrderStatusMutationHookResult = ReturnType<typeof useUpdateOrderStatusMutation>;
export type UpdateOrderStatusMutationResult = Apollo.MutationResult<UpdateOrderStatusMutation>;
export type UpdateOrderStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrderStatusMutation,
  UpdateOrderStatusMutationVariables
>;
export const GetInvestorExchangeOrdersDocument = gql`
  query getInvestorExchangeOrders($type: ExchangeType!, $stoID: Int) {
    getInvestorExchangeOrders(type: $type, stoID: $stoID) {
      shareType {
        title
        currency {
          ID
          symbol
          abbreviation
        }
      }
      ID
      atomicSwapAcceptable
      atomicSwapCurrentStatus
      dateFrom
      dateTo
      rateFrom
      rateTo
      shares
      type
      pricePerShare
      initialShares
    }
  }
`;

/**
 * __useGetInvestorExchangeOrdersQuery__
 *
 * To run a query within a React component, call `useGetInvestorExchangeOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestorExchangeOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestorExchangeOrdersQuery({
 *   variables: {
 *      type: // value for 'type'
 *      stoID: // value for 'stoID'
 *   },
 * });
 */
export function useGetInvestorExchangeOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<GetInvestorExchangeOrdersQuery, GetInvestorExchangeOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvestorExchangeOrdersQuery, GetInvestorExchangeOrdersQueryVariables>(
    GetInvestorExchangeOrdersDocument,
    options,
  );
}
export function useGetInvestorExchangeOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInvestorExchangeOrdersQuery, GetInvestorExchangeOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvestorExchangeOrdersQuery, GetInvestorExchangeOrdersQueryVariables>(
    GetInvestorExchangeOrdersDocument,
    options,
  );
}
export type GetInvestorExchangeOrdersQueryHookResult = ReturnType<typeof useGetInvestorExchangeOrdersQuery>;
export type GetInvestorExchangeOrdersLazyQueryHookResult = ReturnType<typeof useGetInvestorExchangeOrdersLazyQuery>;
export type GetInvestorExchangeOrdersQueryResult = Apollo.QueryResult<
  GetInvestorExchangeOrdersQuery,
  GetInvestorExchangeOrdersQueryVariables
>;
export const GetExchangeOrdersDocument = gql`
  query getExchangeOrders($stoID: Int!) {
    getExchangeOrders(stoID: $stoID) {
      shareType {
        title
        currency {
          ID
          symbol
          abbreviation
        }
      }
      ID
      atomicSwapAcceptable
      atomicSwapCurrentStatus
      dateFrom
      rateFrom
      rateTo
      shares
      type
    }
  }
`;

/**
 * __useGetExchangeOrdersQuery__
 *
 * To run a query within a React component, call `useGetExchangeOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExchangeOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExchangeOrdersQuery({
 *   variables: {
 *      stoID: // value for 'stoID'
 *   },
 * });
 */
export function useGetExchangeOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<GetExchangeOrdersQuery, GetExchangeOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExchangeOrdersQuery, GetExchangeOrdersQueryVariables>(GetExchangeOrdersDocument, options);
}
export function useGetExchangeOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExchangeOrdersQuery, GetExchangeOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExchangeOrdersQuery, GetExchangeOrdersQueryVariables>(
    GetExchangeOrdersDocument,
    options,
  );
}
export type GetExchangeOrdersQueryHookResult = ReturnType<typeof useGetExchangeOrdersQuery>;
export type GetExchangeOrdersLazyQueryHookResult = ReturnType<typeof useGetExchangeOrdersLazyQuery>;
export type GetExchangeOrdersQueryResult = Apollo.QueryResult<GetExchangeOrdersQuery, GetExchangeOrdersQueryVariables>;
export const GetMyOffersDocument = gql`
  query getMyOffers($stoID: Int!, $type: ExchangeType!) {
    getExchangeOffers(stoID: $stoID, type: $type) {
      ID
      exchangeOrder {
        ID
        dateFrom
        dateTo
        rateFrom
        atomicSwapAcceptable
        atomicSwapCurrentStatus
        shareType {
          title
          currency {
            ID
            symbol
            abbreviation
          }
        }
      }
      rateFrom
      sharesPartial
      status
      invoices {
        ID
        status
      }
    }
  }
`;

/**
 * __useGetMyOffersQuery__
 *
 * To run a query within a React component, call `useGetMyOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyOffersQuery({
 *   variables: {
 *      stoID: // value for 'stoID'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetMyOffersQuery(baseOptions: Apollo.QueryHookOptions<GetMyOffersQuery, GetMyOffersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyOffersQuery, GetMyOffersQueryVariables>(GetMyOffersDocument, options);
}
export function useGetMyOffersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyOffersQuery, GetMyOffersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyOffersQuery, GetMyOffersQueryVariables>(GetMyOffersDocument, options);
}
export type GetMyOffersQueryHookResult = ReturnType<typeof useGetMyOffersQuery>;
export type GetMyOffersLazyQueryHookResult = ReturnType<typeof useGetMyOffersLazyQuery>;
export type GetMyOffersQueryResult = Apollo.QueryResult<GetMyOffersQuery, GetMyOffersQueryVariables>;
export const FindShareTypesDocument = gql`
  query findShareTypes($ID: Int, $stoID: Int) {
    findShareTypes(ID: $ID, stoID: $stoID) {
      availableShare
      totalShares
      totalPrice
      currency {
        symbol
        abbreviation
        Address
      }
      sto {
        isActive
      }
      ID
      stoID
      title
      nominalValue
      premiumValue
      isBlockchain
      isInvestorTradable
      blockchainProtocolID
      isEnabled
      companyShares
      totalPrice
      totalShares
      tradingDetails {
        minTradeValue
        maxTradeValue
        openTradeDate
        closeTradeDate
      }
    }
  }
`;

/**
 * __useFindShareTypesQuery__
 *
 * To run a query within a React component, call `useFindShareTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindShareTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindShareTypesQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      stoID: // value for 'stoID'
 *   },
 * });
 */
export function useFindShareTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<FindShareTypesQuery, FindShareTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindShareTypesQuery, FindShareTypesQueryVariables>(FindShareTypesDocument, options);
}
export function useFindShareTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindShareTypesQuery, FindShareTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindShareTypesQuery, FindShareTypesQueryVariables>(FindShareTypesDocument, options);
}
export type FindShareTypesQueryHookResult = ReturnType<typeof useFindShareTypesQuery>;
export type FindShareTypesLazyQueryHookResult = ReturnType<typeof useFindShareTypesLazyQuery>;
export type FindShareTypesQueryResult = Apollo.QueryResult<FindShareTypesQuery, FindShareTypesQueryVariables>;
export const GetExchangeOrderDetailDocument = gql`
  query getExchangeOrderDetail($orderID: Int!) {
    getExchangeOrderOffers(orderID: $orderID) {
      sharesPartial
      rateFrom
      rateTo
      pricePerShare
      status
      description
      atomicSwapAccepted
      atomicSwapSecret
      atomicBuyerPublicKey
      atomicSwapExpireDate
      investorID
      ID
    }
    getExchangeOrder(orderID: $orderID) {
      ID
      type
      dateFrom
      dateTo
      shares
      rateFrom
      atomicSwapAcceptable
      investorID
      stoID
      shareType {
        ID
        title
        blockchainProtocolID
        currency {
          symbol
          abbreviation
        }
      }
    }
  }
`;

/**
 * __useGetExchangeOrderDetailQuery__
 *
 * To run a query within a React component, call `useGetExchangeOrderDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExchangeOrderDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExchangeOrderDetailQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useGetExchangeOrderDetailQuery(
  baseOptions: Apollo.QueryHookOptions<GetExchangeOrderDetailQuery, GetExchangeOrderDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExchangeOrderDetailQuery, GetExchangeOrderDetailQueryVariables>(
    GetExchangeOrderDetailDocument,
    options,
  );
}
export function useGetExchangeOrderDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExchangeOrderDetailQuery, GetExchangeOrderDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExchangeOrderDetailQuery, GetExchangeOrderDetailQueryVariables>(
    GetExchangeOrderDetailDocument,
    options,
  );
}
export type GetExchangeOrderDetailQueryHookResult = ReturnType<typeof useGetExchangeOrderDetailQuery>;
export type GetExchangeOrderDetailLazyQueryHookResult = ReturnType<typeof useGetExchangeOrderDetailLazyQuery>;
export type GetExchangeOrderDetailQueryResult = Apollo.QueryResult<
  GetExchangeOrderDetailQuery,
  GetExchangeOrderDetailQueryVariables
>;
export const GetExchangeOfferDetailDocument = gql`
  query getExchangeOfferDetail($orderID: Int!) {
    getExchangeOffer(orderID: $orderID) {
      sharesPartial
      rateFrom
      rateTo
      description
      atomicSwapAccepted
      atomicSwapSecret
      atomicBuyerPublicKey
      atomicSwapExpireDate
      investorID
      ID
    }
    getExchangeOrder(orderID: $orderID) {
      ID
      type
      stoID
      dateFrom
      dateTo
      pricePerShare
      initialShares
      shares
      rateFrom
      atomicSwapAcceptable
      atomicSwapCurrentStatus
      atomicSwapTokenAddressAcceptable
      investorID
      atomicSwapSharesWallet {
        publicKey
      }
      shareType {
        ID
        title
        isBlockchain
        ethereumBlockchainPublicAddress
        ethereumContractAddress
        blockchainProtocolID
        currency {
          ID
          symbol
          abbreviation
        }
        tradingDetails {
          minTradeValue
          maxTradeValue
          openTradeDate
          closeTradeDate
        }
      }
    }
  }
`;

/**
 * __useGetExchangeOfferDetailQuery__
 *
 * To run a query within a React component, call `useGetExchangeOfferDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExchangeOfferDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExchangeOfferDetailQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useGetExchangeOfferDetailQuery(
  baseOptions: Apollo.QueryHookOptions<GetExchangeOfferDetailQuery, GetExchangeOfferDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExchangeOfferDetailQuery, GetExchangeOfferDetailQueryVariables>(
    GetExchangeOfferDetailDocument,
    options,
  );
}
export function useGetExchangeOfferDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExchangeOfferDetailQuery, GetExchangeOfferDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExchangeOfferDetailQuery, GetExchangeOfferDetailQueryVariables>(
    GetExchangeOfferDetailDocument,
    options,
  );
}
export type GetExchangeOfferDetailQueryHookResult = ReturnType<typeof useGetExchangeOfferDetailQuery>;
export type GetExchangeOfferDetailLazyQueryHookResult = ReturnType<typeof useGetExchangeOfferDetailLazyQuery>;
export type GetExchangeOfferDetailQueryResult = Apollo.QueryResult<
  GetExchangeOfferDetailQuery,
  GetExchangeOfferDetailQueryVariables
>;
export const GetAcceptedExchangeOfferDocument = gql`
  query GetAcceptedExchangeOffer($orderID: Int!) {
    getAcceptedExchangeOffer(orderID: $orderID) {
      ID
      exchangeOrderID
      investorID
      stoID
      sharesPartial
      rateFrom
      rateTo
      description
      atomicSwapAccepted
      atomicSwapSecret
      atomicBuyerPublicKey
      atomicSwapExpireDate
    }
  }
`;

/**
 * __useGetAcceptedExchangeOfferQuery__
 *
 * To run a query within a React component, call `useGetAcceptedExchangeOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAcceptedExchangeOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAcceptedExchangeOfferQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useGetAcceptedExchangeOfferQuery(
  baseOptions: Apollo.QueryHookOptions<GetAcceptedExchangeOfferQuery, GetAcceptedExchangeOfferQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAcceptedExchangeOfferQuery, GetAcceptedExchangeOfferQueryVariables>(
    GetAcceptedExchangeOfferDocument,
    options,
  );
}
export function useGetAcceptedExchangeOfferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAcceptedExchangeOfferQuery, GetAcceptedExchangeOfferQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAcceptedExchangeOfferQuery, GetAcceptedExchangeOfferQueryVariables>(
    GetAcceptedExchangeOfferDocument,
    options,
  );
}
export type GetAcceptedExchangeOfferQueryHookResult = ReturnType<typeof useGetAcceptedExchangeOfferQuery>;
export type GetAcceptedExchangeOfferLazyQueryHookResult = ReturnType<typeof useGetAcceptedExchangeOfferLazyQuery>;
export type GetAcceptedExchangeOfferQueryResult = Apollo.QueryResult<
  GetAcceptedExchangeOfferQuery,
  GetAcceptedExchangeOfferQueryVariables
>;
export const GetInvestorBalanceDocument = gql`
  query getInvestorBalance($currencyID: Int!, $stoID: Int!) {
    investorBalance(currencyID: $currencyID, stoID: $stoID) {
      amount
    }
  }
`;

/**
 * __useGetInvestorBalanceQuery__
 *
 * To run a query within a React component, call `useGetInvestorBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestorBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestorBalanceQuery({
 *   variables: {
 *      currencyID: // value for 'currencyID'
 *      stoID: // value for 'stoID'
 *   },
 * });
 */
export function useGetInvestorBalanceQuery(
  baseOptions: Apollo.QueryHookOptions<GetInvestorBalanceQuery, GetInvestorBalanceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvestorBalanceQuery, GetInvestorBalanceQueryVariables>(
    GetInvestorBalanceDocument,
    options,
  );
}
export function useGetInvestorBalanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInvestorBalanceQuery, GetInvestorBalanceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvestorBalanceQuery, GetInvestorBalanceQueryVariables>(
    GetInvestorBalanceDocument,
    options,
  );
}
export type GetInvestorBalanceQueryHookResult = ReturnType<typeof useGetInvestorBalanceQuery>;
export type GetInvestorBalanceLazyQueryHookResult = ReturnType<typeof useGetInvestorBalanceLazyQuery>;
export type GetInvestorBalanceQueryResult = Apollo.QueryResult<
  GetInvestorBalanceQuery,
  GetInvestorBalanceQueryVariables
>;
export const GetInvestorSharesDocument = gql`
  query getInvestorShares($shareTypeID: Int!) {
    investorShare(shareTypeID: $shareTypeID) {
      shares
    }
  }
`;

/**
 * __useGetInvestorSharesQuery__
 *
 * To run a query within a React component, call `useGetInvestorSharesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestorSharesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestorSharesQuery({
 *   variables: {
 *      shareTypeID: // value for 'shareTypeID'
 *   },
 * });
 */
export function useGetInvestorSharesQuery(
  baseOptions: Apollo.QueryHookOptions<GetInvestorSharesQuery, GetInvestorSharesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvestorSharesQuery, GetInvestorSharesQueryVariables>(GetInvestorSharesDocument, options);
}
export function useGetInvestorSharesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInvestorSharesQuery, GetInvestorSharesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvestorSharesQuery, GetInvestorSharesQueryVariables>(
    GetInvestorSharesDocument,
    options,
  );
}
export type GetInvestorSharesQueryHookResult = ReturnType<typeof useGetInvestorSharesQuery>;
export type GetInvestorSharesLazyQueryHookResult = ReturnType<typeof useGetInvestorSharesLazyQuery>;
export type GetInvestorSharesQueryResult = Apollo.QueryResult<GetInvestorSharesQuery, GetInvestorSharesQueryVariables>;
export const GetExchangeOrderOffersCountDocument = gql`
  query GetExchangeOrderOffersCount($orderID: Int!) {
    getExchangeOrderOffersCount(orderID: $orderID)
  }
`;

/**
 * __useGetExchangeOrderOffersCountQuery__
 *
 * To run a query within a React component, call `useGetExchangeOrderOffersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExchangeOrderOffersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExchangeOrderOffersCountQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useGetExchangeOrderOffersCountQuery(
  baseOptions: Apollo.QueryHookOptions<GetExchangeOrderOffersCountQuery, GetExchangeOrderOffersCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExchangeOrderOffersCountQuery, GetExchangeOrderOffersCountQueryVariables>(
    GetExchangeOrderOffersCountDocument,
    options,
  );
}
export function useGetExchangeOrderOffersCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExchangeOrderOffersCountQuery,
    GetExchangeOrderOffersCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExchangeOrderOffersCountQuery, GetExchangeOrderOffersCountQueryVariables>(
    GetExchangeOrderOffersCountDocument,
    options,
  );
}
export type GetExchangeOrderOffersCountQueryHookResult = ReturnType<typeof useGetExchangeOrderOffersCountQuery>;
export type GetExchangeOrderOffersCountLazyQueryHookResult = ReturnType<typeof useGetExchangeOrderOffersCountLazyQuery>;
export type GetExchangeOrderOffersCountQueryResult = Apollo.QueryResult<
  GetExchangeOrderOffersCountQuery,
  GetExchangeOrderOffersCountQueryVariables
>;
export const GetPerformanceGraphDataDocument = gql`
  query GetPerformanceGraphData($shareTypeID: Int!, $startDate: String, $endDate: String) {
    getPerformanceGraphData(shareTypeID: $shareTypeID, startDate: $startDate, endDate: $endDate) {
      data
      dates
      sharesSum
      shareType {
        currency {
          symbol
          Address
        }
        ID
        stoID
        title
        nominalValue
        premiumValue
        isBlockchain
      }
    }
  }
`;

/**
 * __useGetPerformanceGraphDataQuery__
 *
 * To run a query within a React component, call `useGetPerformanceGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPerformanceGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPerformanceGraphDataQuery({
 *   variables: {
 *      shareTypeID: // value for 'shareTypeID'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetPerformanceGraphDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetPerformanceGraphDataQuery, GetPerformanceGraphDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPerformanceGraphDataQuery, GetPerformanceGraphDataQueryVariables>(
    GetPerformanceGraphDataDocument,
    options,
  );
}
export function useGetPerformanceGraphDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPerformanceGraphDataQuery, GetPerformanceGraphDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPerformanceGraphDataQuery, GetPerformanceGraphDataQueryVariables>(
    GetPerformanceGraphDataDocument,
    options,
  );
}
export type GetPerformanceGraphDataQueryHookResult = ReturnType<typeof useGetPerformanceGraphDataQuery>;
export type GetPerformanceGraphDataLazyQueryHookResult = ReturnType<typeof useGetPerformanceGraphDataLazyQuery>;
export type GetPerformanceGraphDataQueryResult = Apollo.QueryResult<
  GetPerformanceGraphDataQuery,
  GetPerformanceGraphDataQueryVariables
>;
export const GetExchangeTransactionsDocument = gql`
  query GetExchangeTransactions {
    getExchangeTransactions {
      ID
      type
      sellerName
      buyerName
      stoTitle
      shareTypeTitle
      currency {
        symbol
        abbreviation
      }
      amount
      pricePerShare
      totalPrice
      dateCompleted
    }
  }
`;

/**
 * __useGetExchangeTransactionsQuery__
 *
 * To run a query within a React component, call `useGetExchangeTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExchangeTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExchangeTransactionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExchangeTransactionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetExchangeTransactionsQuery, GetExchangeTransactionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExchangeTransactionsQuery, GetExchangeTransactionsQueryVariables>(
    GetExchangeTransactionsDocument,
    options,
  );
}
export function useGetExchangeTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExchangeTransactionsQuery, GetExchangeTransactionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExchangeTransactionsQuery, GetExchangeTransactionsQueryVariables>(
    GetExchangeTransactionsDocument,
    options,
  );
}
export type GetExchangeTransactionsQueryHookResult = ReturnType<typeof useGetExchangeTransactionsQuery>;
export type GetExchangeTransactionsLazyQueryHookResult = ReturnType<typeof useGetExchangeTransactionsLazyQuery>;
export type GetExchangeTransactionsQueryResult = Apollo.QueryResult<
  GetExchangeTransactionsQuery,
  GetExchangeTransactionsQueryVariables
>;
export const RefreshInvestorDataDocument = gql`
  mutation refreshInvestorData {
    refreshInvestorData
  }
`;
export type RefreshInvestorDataMutationFn = Apollo.MutationFunction<
  RefreshInvestorDataMutation,
  RefreshInvestorDataMutationVariables
>;

/**
 * __useRefreshInvestorDataMutation__
 *
 * To run a mutation, you first call `useRefreshInvestorDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshInvestorDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshInvestorDataMutation, { data, loading, error }] = useRefreshInvestorDataMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshInvestorDataMutation(
  baseOptions?: Apollo.MutationHookOptions<RefreshInvestorDataMutation, RefreshInvestorDataMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RefreshInvestorDataMutation, RefreshInvestorDataMutationVariables>(
    RefreshInvestorDataDocument,
    options,
  );
}
export type RefreshInvestorDataMutationHookResult = ReturnType<typeof useRefreshInvestorDataMutation>;
export type RefreshInvestorDataMutationResult = Apollo.MutationResult<RefreshInvestorDataMutation>;
export type RefreshInvestorDataMutationOptions = Apollo.BaseMutationOptions<
  RefreshInvestorDataMutation,
  RefreshInvestorDataMutationVariables
>;
export const GetSumSubInvestorTokenDocument = gql`
  query getSumSubInvestorToken {
    getSumSubInvestorToken
  }
`;

/**
 * __useGetSumSubInvestorTokenQuery__
 *
 * To run a query within a React component, call `useGetSumSubInvestorTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSumSubInvestorTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSumSubInvestorTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSumSubInvestorTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSumSubInvestorTokenQuery, GetSumSubInvestorTokenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSumSubInvestorTokenQuery, GetSumSubInvestorTokenQueryVariables>(
    GetSumSubInvestorTokenDocument,
    options,
  );
}
export function useGetSumSubInvestorTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSumSubInvestorTokenQuery, GetSumSubInvestorTokenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSumSubInvestorTokenQuery, GetSumSubInvestorTokenQueryVariables>(
    GetSumSubInvestorTokenDocument,
    options,
  );
}
export type GetSumSubInvestorTokenQueryHookResult = ReturnType<typeof useGetSumSubInvestorTokenQuery>;
export type GetSumSubInvestorTokenLazyQueryHookResult = ReturnType<typeof useGetSumSubInvestorTokenLazyQuery>;
export type GetSumSubInvestorTokenQueryResult = Apollo.QueryResult<
  GetSumSubInvestorTokenQuery,
  GetSumSubInvestorTokenQueryVariables
>;
export const GetBlockPassClientIdDocument = gql`
  query getBlockPassClientID {
    getBlockPassClientID
  }
`;

/**
 * __useGetBlockPassClientIdQuery__
 *
 * To run a query within a React component, call `useGetBlockPassClientIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlockPassClientIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlockPassClientIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBlockPassClientIdQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBlockPassClientIdQuery, GetBlockPassClientIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBlockPassClientIdQuery, GetBlockPassClientIdQueryVariables>(
    GetBlockPassClientIdDocument,
    options,
  );
}
export function useGetBlockPassClientIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBlockPassClientIdQuery, GetBlockPassClientIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBlockPassClientIdQuery, GetBlockPassClientIdQueryVariables>(
    GetBlockPassClientIdDocument,
    options,
  );
}
export type GetBlockPassClientIdQueryHookResult = ReturnType<typeof useGetBlockPassClientIdQuery>;
export type GetBlockPassClientIdLazyQueryHookResult = ReturnType<typeof useGetBlockPassClientIdLazyQuery>;
export type GetBlockPassClientIdQueryResult = Apollo.QueryResult<
  GetBlockPassClientIdQuery,
  GetBlockPassClientIdQueryVariables
>;
export const GetNetkiSignUpDataDocument = gql`
  query getNetkiSignUpData {
    getNetkiSignUpData {
      accessCode
      mobileAppPanel
    }
  }
`;

/**
 * __useGetNetkiSignUpDataQuery__
 *
 * To run a query within a React component, call `useGetNetkiSignUpDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNetkiSignUpDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNetkiSignUpDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNetkiSignUpDataQuery(
  baseOptions?: Apollo.QueryHookOptions<GetNetkiSignUpDataQuery, GetNetkiSignUpDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNetkiSignUpDataQuery, GetNetkiSignUpDataQueryVariables>(
    GetNetkiSignUpDataDocument,
    options,
  );
}
export function useGetNetkiSignUpDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNetkiSignUpDataQuery, GetNetkiSignUpDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNetkiSignUpDataQuery, GetNetkiSignUpDataQueryVariables>(
    GetNetkiSignUpDataDocument,
    options,
  );
}
export type GetNetkiSignUpDataQueryHookResult = ReturnType<typeof useGetNetkiSignUpDataQuery>;
export type GetNetkiSignUpDataLazyQueryHookResult = ReturnType<typeof useGetNetkiSignUpDataLazyQuery>;
export type GetNetkiSignUpDataQueryResult = Apollo.QueryResult<
  GetNetkiSignUpDataQuery,
  GetNetkiSignUpDataQueryVariables
>;
export const GetGCashLoginUrlDocument = gql`
  query getGCashLoginUrl {
    getGCashLoginUrl
  }
`;

/**
 * __useGetGCashLoginUrlQuery__
 *
 * To run a query within a React component, call `useGetGCashLoginUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGCashLoginUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGCashLoginUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGCashLoginUrlQuery(
  baseOptions?: Apollo.QueryHookOptions<GetGCashLoginUrlQuery, GetGCashLoginUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGCashLoginUrlQuery, GetGCashLoginUrlQueryVariables>(GetGCashLoginUrlDocument, options);
}
export function useGetGCashLoginUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGCashLoginUrlQuery, GetGCashLoginUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGCashLoginUrlQuery, GetGCashLoginUrlQueryVariables>(GetGCashLoginUrlDocument, options);
}
export type GetGCashLoginUrlQueryHookResult = ReturnType<typeof useGetGCashLoginUrlQuery>;
export type GetGCashLoginUrlLazyQueryHookResult = ReturnType<typeof useGetGCashLoginUrlLazyQuery>;
export type GetGCashLoginUrlQueryResult = Apollo.QueryResult<GetGCashLoginUrlQuery, GetGCashLoginUrlQueryVariables>;
export const GCashUpdateInvestorDataDocument = gql`
  mutation gCashUpdateInvestorData($gCashData: EncryptedDataInput!) {
    gCashUpdateInvestorData(gCashData: $gCashData)
  }
`;
export type GCashUpdateInvestorDataMutationFn = Apollo.MutationFunction<
  GCashUpdateInvestorDataMutation,
  GCashUpdateInvestorDataMutationVariables
>;

/**
 * __useGCashUpdateInvestorDataMutation__
 *
 * To run a mutation, you first call `useGCashUpdateInvestorDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGCashUpdateInvestorDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gCashUpdateInvestorDataMutation, { data, loading, error }] = useGCashUpdateInvestorDataMutation({
 *   variables: {
 *      gCashData: // value for 'gCashData'
 *   },
 * });
 */
export function useGCashUpdateInvestorDataMutation(
  baseOptions?: Apollo.MutationHookOptions<GCashUpdateInvestorDataMutation, GCashUpdateInvestorDataMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GCashUpdateInvestorDataMutation, GCashUpdateInvestorDataMutationVariables>(
    GCashUpdateInvestorDataDocument,
    options,
  );
}
export type GCashUpdateInvestorDataMutationHookResult = ReturnType<typeof useGCashUpdateInvestorDataMutation>;
export type GCashUpdateInvestorDataMutationResult = Apollo.MutationResult<GCashUpdateInvestorDataMutation>;
export type GCashUpdateInvestorDataMutationOptions = Apollo.BaseMutationOptions<
  GCashUpdateInvestorDataMutation,
  GCashUpdateInvestorDataMutationVariables
>;
export const RootKycDocument = gql`
  subscription rootKyc {
    rootKyc {
      isKYC
      isActive
      status
      ID
    }
  }
`;

/**
 * __useRootKycSubscription__
 *
 * To run a query within a React component, call `useRootKycSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRootKycSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRootKycSubscription({
 *   variables: {
 *   },
 * });
 */
export function useRootKycSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<RootKycSubscription, RootKycSubscriptionVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<RootKycSubscription, RootKycSubscriptionVariables>(RootKycDocument, options);
}
export type RootKycSubscriptionHookResult = ReturnType<typeof useRootKycSubscription>;
export type RootKycSubscriptionResult = Apollo.SubscriptionResult<RootKycSubscription>;
export const FetchFeesDocument = gql`
  query fetchFees($stoID: Int!, $beneficiary: FEE_BENEFICIARY, $type: FEE_TYPE, $status: COMMISSION_TYPE) {
    fetchFees(stoID: $stoID, beneficiary: $beneficiary, type: $type, status: $status) {
      ID
      beneficiary
      type
      amount
      status
    }
  }
`;

/**
 * __useFetchFeesQuery__
 *
 * To run a query within a React component, call `useFetchFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchFeesQuery({
 *   variables: {
 *      stoID: // value for 'stoID'
 *      beneficiary: // value for 'beneficiary'
 *      type: // value for 'type'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useFetchFeesQuery(baseOptions: Apollo.QueryHookOptions<FetchFeesQuery, FetchFeesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchFeesQuery, FetchFeesQueryVariables>(FetchFeesDocument, options);
}
export function useFetchFeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchFeesQuery, FetchFeesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchFeesQuery, FetchFeesQueryVariables>(FetchFeesDocument, options);
}
export type FetchFeesQueryHookResult = ReturnType<typeof useFetchFeesQuery>;
export type FetchFeesLazyQueryHookResult = ReturnType<typeof useFetchFeesLazyQuery>;
export type FetchFeesQueryResult = Apollo.QueryResult<FetchFeesQuery, FetchFeesQueryVariables>;
export const GetInvitationLinkDocument = gql`
  query getInvitationLink {
    getInvitationLink
  }
`;

/**
 * __useGetInvitationLinkQuery__
 *
 * To run a query within a React component, call `useGetInvitationLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitationLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitationLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvitationLinkQuery(
  baseOptions?: Apollo.QueryHookOptions<GetInvitationLinkQuery, GetInvitationLinkQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvitationLinkQuery, GetInvitationLinkQueryVariables>(GetInvitationLinkDocument, options);
}
export function useGetInvitationLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInvitationLinkQuery, GetInvitationLinkQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvitationLinkQuery, GetInvitationLinkQueryVariables>(
    GetInvitationLinkDocument,
    options,
  );
}
export type GetInvitationLinkQueryHookResult = ReturnType<typeof useGetInvitationLinkQuery>;
export type GetInvitationLinkLazyQueryHookResult = ReturnType<typeof useGetInvitationLinkLazyQuery>;
export type GetInvitationLinkQueryResult = Apollo.QueryResult<GetInvitationLinkQuery, GetInvitationLinkQueryVariables>;
export const CheckRegistrationFeeStatusDocument = gql`
  query CheckRegistrationFeeStatus {
    checkRegistrationFeeStatus
  }
`;

/**
 * __useCheckRegistrationFeeStatusQuery__
 *
 * To run a query within a React component, call `useCheckRegistrationFeeStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckRegistrationFeeStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckRegistrationFeeStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckRegistrationFeeStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<CheckRegistrationFeeStatusQuery, CheckRegistrationFeeStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckRegistrationFeeStatusQuery, CheckRegistrationFeeStatusQueryVariables>(
    CheckRegistrationFeeStatusDocument,
    options,
  );
}
export function useCheckRegistrationFeeStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckRegistrationFeeStatusQuery, CheckRegistrationFeeStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckRegistrationFeeStatusQuery, CheckRegistrationFeeStatusQueryVariables>(
    CheckRegistrationFeeStatusDocument,
    options,
  );
}
export type CheckRegistrationFeeStatusQueryHookResult = ReturnType<typeof useCheckRegistrationFeeStatusQuery>;
export type CheckRegistrationFeeStatusLazyQueryHookResult = ReturnType<typeof useCheckRegistrationFeeStatusLazyQuery>;
export type CheckRegistrationFeeStatusQueryResult = Apollo.QueryResult<
  CheckRegistrationFeeStatusQuery,
  CheckRegistrationFeeStatusQueryVariables
>;
export const CreateForumThreadDocument = gql`
  mutation CreateForumThread($threadData: ForumThreadInput!) {
    createForumThread(threadData: $threadData)
  }
`;
export type CreateForumThreadMutationFn = Apollo.MutationFunction<
  CreateForumThreadMutation,
  CreateForumThreadMutationVariables
>;

/**
 * __useCreateForumThreadMutation__
 *
 * To run a mutation, you first call `useCreateForumThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateForumThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createForumThreadMutation, { data, loading, error }] = useCreateForumThreadMutation({
 *   variables: {
 *      threadData: // value for 'threadData'
 *   },
 * });
 */
export function useCreateForumThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateForumThreadMutation, CreateForumThreadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateForumThreadMutation, CreateForumThreadMutationVariables>(
    CreateForumThreadDocument,
    options,
  );
}
export type CreateForumThreadMutationHookResult = ReturnType<typeof useCreateForumThreadMutation>;
export type CreateForumThreadMutationResult = Apollo.MutationResult<CreateForumThreadMutation>;
export type CreateForumThreadMutationOptions = Apollo.BaseMutationOptions<
  CreateForumThreadMutation,
  CreateForumThreadMutationVariables
>;
export const CreateForumPostDocument = gql`
  mutation CreateForumPost($postData: ForumPostInput!) {
    createForumPost(postData: $postData)
  }
`;
export type CreateForumPostMutationFn = Apollo.MutationFunction<
  CreateForumPostMutation,
  CreateForumPostMutationVariables
>;

/**
 * __useCreateForumPostMutation__
 *
 * To run a mutation, you first call `useCreateForumPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateForumPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createForumPostMutation, { data, loading, error }] = useCreateForumPostMutation({
 *   variables: {
 *      postData: // value for 'postData'
 *   },
 * });
 */
export function useCreateForumPostMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateForumPostMutation, CreateForumPostMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateForumPostMutation, CreateForumPostMutationVariables>(
    CreateForumPostDocument,
    options,
  );
}
export type CreateForumPostMutationHookResult = ReturnType<typeof useCreateForumPostMutation>;
export type CreateForumPostMutationResult = Apollo.MutationResult<CreateForumPostMutation>;
export type CreateForumPostMutationOptions = Apollo.BaseMutationOptions<
  CreateForumPostMutation,
  CreateForumPostMutationVariables
>;
export const GetAllForumThreadsDocument = gql`
  query GetAllForumThreads {
    getAllForumThreads {
      ID
      title
      body
      createdAt
    }
  }
`;

/**
 * __useGetAllForumThreadsQuery__
 *
 * To run a query within a React component, call `useGetAllForumThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllForumThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllForumThreadsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllForumThreadsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllForumThreadsQuery, GetAllForumThreadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllForumThreadsQuery, GetAllForumThreadsQueryVariables>(
    GetAllForumThreadsDocument,
    options,
  );
}
export function useGetAllForumThreadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllForumThreadsQuery, GetAllForumThreadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllForumThreadsQuery, GetAllForumThreadsQueryVariables>(
    GetAllForumThreadsDocument,
    options,
  );
}
export type GetAllForumThreadsQueryHookResult = ReturnType<typeof useGetAllForumThreadsQuery>;
export type GetAllForumThreadsLazyQueryHookResult = ReturnType<typeof useGetAllForumThreadsLazyQuery>;
export type GetAllForumThreadsQueryResult = Apollo.QueryResult<
  GetAllForumThreadsQuery,
  GetAllForumThreadsQueryVariables
>;
export const GetThreadByIdDocument = gql`
  query GetThreadByID($threadID: Int!) {
    getThreadByID(threadID: $threadID) {
      ID
      title
      body
      createdAt
    }
  }
`;

/**
 * __useGetThreadByIdQuery__
 *
 * To run a query within a React component, call `useGetThreadByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThreadByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThreadByIdQuery({
 *   variables: {
 *      threadID: // value for 'threadID'
 *   },
 * });
 */
export function useGetThreadByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetThreadByIdQuery, GetThreadByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetThreadByIdQuery, GetThreadByIdQueryVariables>(GetThreadByIdDocument, options);
}
export function useGetThreadByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetThreadByIdQuery, GetThreadByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetThreadByIdQuery, GetThreadByIdQueryVariables>(GetThreadByIdDocument, options);
}
export type GetThreadByIdQueryHookResult = ReturnType<typeof useGetThreadByIdQuery>;
export type GetThreadByIdLazyQueryHookResult = ReturnType<typeof useGetThreadByIdLazyQuery>;
export type GetThreadByIdQueryResult = Apollo.QueryResult<GetThreadByIdQuery, GetThreadByIdQueryVariables>;
export const GetThreadContentDocument = gql`
  query GetThreadContent($threadID: Int!) {
    getThreadContent(threadID: $threadID) {
      ID
      body
      admin {
        username
        offering {
          title
        }
      }
      thread {
        ID
        title
        body
        createdAt
      }
      repliesTo
      createdAt
    }
  }
`;

/**
 * __useGetThreadContentQuery__
 *
 * To run a query within a React component, call `useGetThreadContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThreadContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThreadContentQuery({
 *   variables: {
 *      threadID: // value for 'threadID'
 *   },
 * });
 */
export function useGetThreadContentQuery(
  baseOptions: Apollo.QueryHookOptions<GetThreadContentQuery, GetThreadContentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetThreadContentQuery, GetThreadContentQueryVariables>(GetThreadContentDocument, options);
}
export function useGetThreadContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetThreadContentQuery, GetThreadContentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetThreadContentQuery, GetThreadContentQueryVariables>(GetThreadContentDocument, options);
}
export type GetThreadContentQueryHookResult = ReturnType<typeof useGetThreadContentQuery>;
export type GetThreadContentLazyQueryHookResult = ReturnType<typeof useGetThreadContentLazyQuery>;
export type GetThreadContentQueryResult = Apollo.QueryResult<GetThreadContentQuery, GetThreadContentQueryVariables>;
export const CreateInboxDocument = gql`
  mutation createInbox($data: InboxInput!) {
    investorInboxCreate(data: $data)
  }
`;
export type CreateInboxMutationFn = Apollo.MutationFunction<CreateInboxMutation, CreateInboxMutationVariables>;

/**
 * __useCreateInboxMutation__
 *
 * To run a mutation, you first call `useCreateInboxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInboxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInboxMutation, { data, loading, error }] = useCreateInboxMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateInboxMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateInboxMutation, CreateInboxMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInboxMutation, CreateInboxMutationVariables>(CreateInboxDocument, options);
}
export type CreateInboxMutationHookResult = ReturnType<typeof useCreateInboxMutation>;
export type CreateInboxMutationResult = Apollo.MutationResult<CreateInboxMutation>;
export type CreateInboxMutationOptions = Apollo.BaseMutationOptions<CreateInboxMutation, CreateInboxMutationVariables>;
export const InboxDataDocument = gql`
  query inboxData($sto: Int!, $offset: Int!, $limit: Int) {
    investorInboxes(stoID: $sto, offset: $offset, limit: $limit) {
      ID
      stoID
      investorID
      title
      details
      date
      isResponded
      response
      responseDate
    }
  }
`;

/**
 * __useInboxDataQuery__
 *
 * To run a query within a React component, call `useInboxDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxDataQuery({
 *   variables: {
 *      sto: // value for 'sto'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useInboxDataQuery(baseOptions: Apollo.QueryHookOptions<InboxDataQuery, InboxDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InboxDataQuery, InboxDataQueryVariables>(InboxDataDocument, options);
}
export function useInboxDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InboxDataQuery, InboxDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InboxDataQuery, InboxDataQueryVariables>(InboxDataDocument, options);
}
export type InboxDataQueryHookResult = ReturnType<typeof useInboxDataQuery>;
export type InboxDataLazyQueryHookResult = ReturnType<typeof useInboxDataLazyQuery>;
export type InboxDataQueryResult = Apollo.QueryResult<InboxDataQuery, InboxDataQueryVariables>;
export const InboxMessageDocument = gql`
  query inboxMessage($_id: Int!) {
    investorInbox(ID: $_id) {
      ID
      stoID
      investorID
      title
      details
      date
      response
      isResponded
      responseDate
    }
  }
`;

/**
 * __useInboxMessageQuery__
 *
 * To run a query within a React component, call `useInboxMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxMessageQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useInboxMessageQuery(
  baseOptions: Apollo.QueryHookOptions<InboxMessageQuery, InboxMessageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InboxMessageQuery, InboxMessageQueryVariables>(InboxMessageDocument, options);
}
export function useInboxMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InboxMessageQuery, InboxMessageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InboxMessageQuery, InboxMessageQueryVariables>(InboxMessageDocument, options);
}
export type InboxMessageQueryHookResult = ReturnType<typeof useInboxMessageQuery>;
export type InboxMessageLazyQueryHookResult = ReturnType<typeof useInboxMessageLazyQuery>;
export type InboxMessageQueryResult = Apollo.QueryResult<InboxMessageQuery, InboxMessageQueryVariables>;
export const GetInvestorDocumentsDocument = gql`
  query GetInvestorDocuments {
    getInvestorDocuments {
      ID
      stoID
      investorID
      documentTitle
      uploadDate
      fileUrl
    }
  }
`;

/**
 * __useGetInvestorDocumentsQuery__
 *
 * To run a query within a React component, call `useGetInvestorDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestorDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestorDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvestorDocumentsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetInvestorDocumentsQuery, GetInvestorDocumentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvestorDocumentsQuery, GetInvestorDocumentsQueryVariables>(
    GetInvestorDocumentsDocument,
    options,
  );
}
export function useGetInvestorDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInvestorDocumentsQuery, GetInvestorDocumentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvestorDocumentsQuery, GetInvestorDocumentsQueryVariables>(
    GetInvestorDocumentsDocument,
    options,
  );
}
export type GetInvestorDocumentsQueryHookResult = ReturnType<typeof useGetInvestorDocumentsQuery>;
export type GetInvestorDocumentsLazyQueryHookResult = ReturnType<typeof useGetInvestorDocumentsLazyQuery>;
export type GetInvestorDocumentsQueryResult = Apollo.QueryResult<
  GetInvestorDocumentsQuery,
  GetInvestorDocumentsQueryVariables
>;
export const InvestorInvestingEntityCreateDocument = gql`
  mutation InvestorInvestingEntityCreate($data: InvestingEntityCreateInput!) {
    investorInvestingEntityCreate(data: $data)
  }
`;
export type InvestorInvestingEntityCreateMutationFn = Apollo.MutationFunction<
  InvestorInvestingEntityCreateMutation,
  InvestorInvestingEntityCreateMutationVariables
>;

/**
 * __useInvestorInvestingEntityCreateMutation__
 *
 * To run a mutation, you first call `useInvestorInvestingEntityCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorInvestingEntityCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorInvestingEntityCreateMutation, { data, loading, error }] = useInvestorInvestingEntityCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInvestorInvestingEntityCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InvestorInvestingEntityCreateMutation,
    InvestorInvestingEntityCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InvestorInvestingEntityCreateMutation, InvestorInvestingEntityCreateMutationVariables>(
    InvestorInvestingEntityCreateDocument,
    options,
  );
}
export type InvestorInvestingEntityCreateMutationHookResult = ReturnType<
  typeof useInvestorInvestingEntityCreateMutation
>;
export type InvestorInvestingEntityCreateMutationResult = Apollo.MutationResult<InvestorInvestingEntityCreateMutation>;
export type InvestorInvestingEntityCreateMutationOptions = Apollo.BaseMutationOptions<
  InvestorInvestingEntityCreateMutation,
  InvestorInvestingEntityCreateMutationVariables
>;
export const InvestorInvestingEntityUpdateDocument = gql`
  mutation InvestorInvestingEntityUpdate($data: InvestingEntityInput!, $entityID: Int!) {
    investorInvestingEntityUpdate(data: $data, entityID: $entityID)
  }
`;
export type InvestorInvestingEntityUpdateMutationFn = Apollo.MutationFunction<
  InvestorInvestingEntityUpdateMutation,
  InvestorInvestingEntityUpdateMutationVariables
>;

/**
 * __useInvestorInvestingEntityUpdateMutation__
 *
 * To run a mutation, you first call `useInvestorInvestingEntityUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorInvestingEntityUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorInvestingEntityUpdateMutation, { data, loading, error }] = useInvestorInvestingEntityUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      entityID: // value for 'entityID'
 *   },
 * });
 */
export function useInvestorInvestingEntityUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InvestorInvestingEntityUpdateMutation,
    InvestorInvestingEntityUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InvestorInvestingEntityUpdateMutation, InvestorInvestingEntityUpdateMutationVariables>(
    InvestorInvestingEntityUpdateDocument,
    options,
  );
}
export type InvestorInvestingEntityUpdateMutationHookResult = ReturnType<
  typeof useInvestorInvestingEntityUpdateMutation
>;
export type InvestorInvestingEntityUpdateMutationResult = Apollo.MutationResult<InvestorInvestingEntityUpdateMutation>;
export type InvestorInvestingEntityUpdateMutationOptions = Apollo.BaseMutationOptions<
  InvestorInvestingEntityUpdateMutation,
  InvestorInvestingEntityUpdateMutationVariables
>;
export const InvestorInvestingEntityRemoveDocument = gql`
  mutation InvestorInvestingEntityRemove($entityID: Int!) {
    investorInvestingEntityRemove(entityID: $entityID)
  }
`;
export type InvestorInvestingEntityRemoveMutationFn = Apollo.MutationFunction<
  InvestorInvestingEntityRemoveMutation,
  InvestorInvestingEntityRemoveMutationVariables
>;

/**
 * __useInvestorInvestingEntityRemoveMutation__
 *
 * To run a mutation, you first call `useInvestorInvestingEntityRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorInvestingEntityRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorInvestingEntityRemoveMutation, { data, loading, error }] = useInvestorInvestingEntityRemoveMutation({
 *   variables: {
 *      entityID: // value for 'entityID'
 *   },
 * });
 */
export function useInvestorInvestingEntityRemoveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InvestorInvestingEntityRemoveMutation,
    InvestorInvestingEntityRemoveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InvestorInvestingEntityRemoveMutation, InvestorInvestingEntityRemoveMutationVariables>(
    InvestorInvestingEntityRemoveDocument,
    options,
  );
}
export type InvestorInvestingEntityRemoveMutationHookResult = ReturnType<
  typeof useInvestorInvestingEntityRemoveMutation
>;
export type InvestorInvestingEntityRemoveMutationResult = Apollo.MutationResult<InvestorInvestingEntityRemoveMutation>;
export type InvestorInvestingEntityRemoveMutationOptions = Apollo.BaseMutationOptions<
  InvestorInvestingEntityRemoveMutation,
  InvestorInvestingEntityRemoveMutationVariables
>;
export const InvestorInvestingEntityMemberCreateDocument = gql`
  mutation InvestorInvestingEntityMemberCreate($data: InvestingEntityMemberInput!) {
    investorInvestingEntityMemberCreate(data: $data)
  }
`;
export type InvestorInvestingEntityMemberCreateMutationFn = Apollo.MutationFunction<
  InvestorInvestingEntityMemberCreateMutation,
  InvestorInvestingEntityMemberCreateMutationVariables
>;

/**
 * __useInvestorInvestingEntityMemberCreateMutation__
 *
 * To run a mutation, you first call `useInvestorInvestingEntityMemberCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorInvestingEntityMemberCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorInvestingEntityMemberCreateMutation, { data, loading, error }] = useInvestorInvestingEntityMemberCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInvestorInvestingEntityMemberCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InvestorInvestingEntityMemberCreateMutation,
    InvestorInvestingEntityMemberCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InvestorInvestingEntityMemberCreateMutation,
    InvestorInvestingEntityMemberCreateMutationVariables
  >(InvestorInvestingEntityMemberCreateDocument, options);
}
export type InvestorInvestingEntityMemberCreateMutationHookResult = ReturnType<
  typeof useInvestorInvestingEntityMemberCreateMutation
>;
export type InvestorInvestingEntityMemberCreateMutationResult =
  Apollo.MutationResult<InvestorInvestingEntityMemberCreateMutation>;
export type InvestorInvestingEntityMemberCreateMutationOptions = Apollo.BaseMutationOptions<
  InvestorInvestingEntityMemberCreateMutation,
  InvestorInvestingEntityMemberCreateMutationVariables
>;
export const InvestorInvestingEntityMemberUpdateDocument = gql`
  mutation InvestorInvestingEntityMemberUpdate($data: InvestingEntityMemberInput!, $memberID: Int!) {
    investorInvestingEntityMemberUpdate(data: $data, memberID: $memberID)
  }
`;
export type InvestorInvestingEntityMemberUpdateMutationFn = Apollo.MutationFunction<
  InvestorInvestingEntityMemberUpdateMutation,
  InvestorInvestingEntityMemberUpdateMutationVariables
>;

/**
 * __useInvestorInvestingEntityMemberUpdateMutation__
 *
 * To run a mutation, you first call `useInvestorInvestingEntityMemberUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorInvestingEntityMemberUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorInvestingEntityMemberUpdateMutation, { data, loading, error }] = useInvestorInvestingEntityMemberUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      memberID: // value for 'memberID'
 *   },
 * });
 */
export function useInvestorInvestingEntityMemberUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InvestorInvestingEntityMemberUpdateMutation,
    InvestorInvestingEntityMemberUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InvestorInvestingEntityMemberUpdateMutation,
    InvestorInvestingEntityMemberUpdateMutationVariables
  >(InvestorInvestingEntityMemberUpdateDocument, options);
}
export type InvestorInvestingEntityMemberUpdateMutationHookResult = ReturnType<
  typeof useInvestorInvestingEntityMemberUpdateMutation
>;
export type InvestorInvestingEntityMemberUpdateMutationResult =
  Apollo.MutationResult<InvestorInvestingEntityMemberUpdateMutation>;
export type InvestorInvestingEntityMemberUpdateMutationOptions = Apollo.BaseMutationOptions<
  InvestorInvestingEntityMemberUpdateMutation,
  InvestorInvestingEntityMemberUpdateMutationVariables
>;
export const InvestorInvestingEntityMemberRemoveDocument = gql`
  mutation InvestorInvestingEntityMemberRemove($memberID: Int!) {
    investorInvestingEntityMemberRemove(memberID: $memberID)
  }
`;
export type InvestorInvestingEntityMemberRemoveMutationFn = Apollo.MutationFunction<
  InvestorInvestingEntityMemberRemoveMutation,
  InvestorInvestingEntityMemberRemoveMutationVariables
>;

/**
 * __useInvestorInvestingEntityMemberRemoveMutation__
 *
 * To run a mutation, you first call `useInvestorInvestingEntityMemberRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorInvestingEntityMemberRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorInvestingEntityMemberRemoveMutation, { data, loading, error }] = useInvestorInvestingEntityMemberRemoveMutation({
 *   variables: {
 *      memberID: // value for 'memberID'
 *   },
 * });
 */
export function useInvestorInvestingEntityMemberRemoveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InvestorInvestingEntityMemberRemoveMutation,
    InvestorInvestingEntityMemberRemoveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InvestorInvestingEntityMemberRemoveMutation,
    InvestorInvestingEntityMemberRemoveMutationVariables
  >(InvestorInvestingEntityMemberRemoveDocument, options);
}
export type InvestorInvestingEntityMemberRemoveMutationHookResult = ReturnType<
  typeof useInvestorInvestingEntityMemberRemoveMutation
>;
export type InvestorInvestingEntityMemberRemoveMutationResult =
  Apollo.MutationResult<InvestorInvestingEntityMemberRemoveMutation>;
export type InvestorInvestingEntityMemberRemoveMutationOptions = Apollo.BaseMutationOptions<
  InvestorInvestingEntityMemberRemoveMutation,
  InvestorInvestingEntityMemberRemoveMutationVariables
>;
export const InvestorBuyAlertMarketSpaceDocument = gql`
  mutation InvestorBuyAlertMarketSpace($data: InvestorBuyAlertInput!) {
    investorBuyAlertMarketSpace(data: $data)
  }
`;
export type InvestorBuyAlertMarketSpaceMutationFn = Apollo.MutationFunction<
  InvestorBuyAlertMarketSpaceMutation,
  InvestorBuyAlertMarketSpaceMutationVariables
>;

/**
 * __useInvestorBuyAlertMarketSpaceMutation__
 *
 * To run a mutation, you first call `useInvestorBuyAlertMarketSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorBuyAlertMarketSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorBuyAlertMarketSpaceMutation, { data, loading, error }] = useInvestorBuyAlertMarketSpaceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInvestorBuyAlertMarketSpaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InvestorBuyAlertMarketSpaceMutation,
    InvestorBuyAlertMarketSpaceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InvestorBuyAlertMarketSpaceMutation, InvestorBuyAlertMarketSpaceMutationVariables>(
    InvestorBuyAlertMarketSpaceDocument,
    options,
  );
}
export type InvestorBuyAlertMarketSpaceMutationHookResult = ReturnType<typeof useInvestorBuyAlertMarketSpaceMutation>;
export type InvestorBuyAlertMarketSpaceMutationResult = Apollo.MutationResult<InvestorBuyAlertMarketSpaceMutation>;
export type InvestorBuyAlertMarketSpaceMutationOptions = Apollo.BaseMutationOptions<
  InvestorBuyAlertMarketSpaceMutation,
  InvestorBuyAlertMarketSpaceMutationVariables
>;
export const InvestorInvestingEntityMetaUpdateDocument = gql`
  mutation InvestorInvestingEntityMetaUpdate($values: [MetaValuesInput!]!, $entityID: Int!) {
    investorInvestingEntityMetaUpdate(values: $values, entityID: $entityID)
  }
`;
export type InvestorInvestingEntityMetaUpdateMutationFn = Apollo.MutationFunction<
  InvestorInvestingEntityMetaUpdateMutation,
  InvestorInvestingEntityMetaUpdateMutationVariables
>;

/**
 * __useInvestorInvestingEntityMetaUpdateMutation__
 *
 * To run a mutation, you first call `useInvestorInvestingEntityMetaUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorInvestingEntityMetaUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorInvestingEntityMetaUpdateMutation, { data, loading, error }] = useInvestorInvestingEntityMetaUpdateMutation({
 *   variables: {
 *      values: // value for 'values'
 *      entityID: // value for 'entityID'
 *   },
 * });
 */
export function useInvestorInvestingEntityMetaUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InvestorInvestingEntityMetaUpdateMutation,
    InvestorInvestingEntityMetaUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InvestorInvestingEntityMetaUpdateMutation,
    InvestorInvestingEntityMetaUpdateMutationVariables
  >(InvestorInvestingEntityMetaUpdateDocument, options);
}
export type InvestorInvestingEntityMetaUpdateMutationHookResult = ReturnType<
  typeof useInvestorInvestingEntityMetaUpdateMutation
>;
export type InvestorInvestingEntityMetaUpdateMutationResult =
  Apollo.MutationResult<InvestorInvestingEntityMetaUpdateMutation>;
export type InvestorInvestingEntityMetaUpdateMutationOptions = Apollo.BaseMutationOptions<
  InvestorInvestingEntityMetaUpdateMutation,
  InvestorInvestingEntityMetaUpdateMutationVariables
>;
export const InvestorInvestingEntitiesDocument = gql`
  query InvestorInvestingEntities {
    investorInvestingEntities {
      ID
      investorID
      typeID
      type {
        ID
        title
        countries
      }
      taxId
      name
      nickname
      accredited
      paymentMethod
      address
      city
      postalCode
      country
      registrationState
      state
      isApprovedByAdmin
      members {
        ID
        investorID
        entityID
        firstName
        lastName
        role
        signatory
        email
      }
      isApprovedByAdmin
      meta {
        key
        value
        type
        order
      }
    }
  }
`;

/**
 * __useInvestorInvestingEntitiesQuery__
 *
 * To run a query within a React component, call `useInvestorInvestingEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorInvestingEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorInvestingEntitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvestorInvestingEntitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<InvestorInvestingEntitiesQuery, InvestorInvestingEntitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestorInvestingEntitiesQuery, InvestorInvestingEntitiesQueryVariables>(
    InvestorInvestingEntitiesDocument,
    options,
  );
}
export function useInvestorInvestingEntitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestorInvestingEntitiesQuery, InvestorInvestingEntitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestorInvestingEntitiesQuery, InvestorInvestingEntitiesQueryVariables>(
    InvestorInvestingEntitiesDocument,
    options,
  );
}
export type InvestorInvestingEntitiesQueryHookResult = ReturnType<typeof useInvestorInvestingEntitiesQuery>;
export type InvestorInvestingEntitiesLazyQueryHookResult = ReturnType<typeof useInvestorInvestingEntitiesLazyQuery>;
export type InvestorInvestingEntitiesQueryResult = Apollo.QueryResult<
  InvestorInvestingEntitiesQuery,
  InvestorInvestingEntitiesQueryVariables
>;
export const InvestorInvestingEntityDocument = gql`
  query InvestorInvestingEntity($entityId: Int!) {
    investorInvestingEntity(entityID: $entityId) {
      ID
      meta {
        key
        value
        type
        order
        display
        displayClosedProp
      }
      investorID
      members {
        ID
        investorID
        entityID
        firstName
        lastName
        role
        signatory
        email
      }
      typeID
      type {
        ID
        title
        countries
      }
      taxId
      name
      nickname
      accredited
      paymentMethod
      address
      city
      postalCode
      country
      registrationState
      state
      isApprovedByAdmin
      returnRates {
        ID
        stoID
        entityID
        returnRate
      }
    }
  }
`;

/**
 * __useInvestorInvestingEntityQuery__
 *
 * To run a query within a React component, call `useInvestorInvestingEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorInvestingEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorInvestingEntityQuery({
 *   variables: {
 *      entityId: // value for 'entityId'
 *   },
 * });
 */
export function useInvestorInvestingEntityQuery(
  baseOptions: Apollo.QueryHookOptions<InvestorInvestingEntityQuery, InvestorInvestingEntityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestorInvestingEntityQuery, InvestorInvestingEntityQueryVariables>(
    InvestorInvestingEntityDocument,
    options,
  );
}
export function useInvestorInvestingEntityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestorInvestingEntityQuery, InvestorInvestingEntityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestorInvestingEntityQuery, InvestorInvestingEntityQueryVariables>(
    InvestorInvestingEntityDocument,
    options,
  );
}
export type InvestorInvestingEntityQueryHookResult = ReturnType<typeof useInvestorInvestingEntityQuery>;
export type InvestorInvestingEntityLazyQueryHookResult = ReturnType<typeof useInvestorInvestingEntityLazyQuery>;
export type InvestorInvestingEntityQueryResult = Apollo.QueryResult<
  InvestorInvestingEntityQuery,
  InvestorInvestingEntityQueryVariables
>;
export const InvestorEntityTypesDocument = gql`
  query InvestorEntityTypes {
    investorInvestingEntityTypes {
      ID
      title
      countries
    }
  }
`;

/**
 * __useInvestorEntityTypesQuery__
 *
 * To run a query within a React component, call `useInvestorEntityTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorEntityTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorEntityTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvestorEntityTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<InvestorEntityTypesQuery, InvestorEntityTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestorEntityTypesQuery, InvestorEntityTypesQueryVariables>(
    InvestorEntityTypesDocument,
    options,
  );
}
export function useInvestorEntityTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestorEntityTypesQuery, InvestorEntityTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestorEntityTypesQuery, InvestorEntityTypesQueryVariables>(
    InvestorEntityTypesDocument,
    options,
  );
}
export type InvestorEntityTypesQueryHookResult = ReturnType<typeof useInvestorEntityTypesQuery>;
export type InvestorEntityTypesLazyQueryHookResult = ReturnType<typeof useInvestorEntityTypesLazyQuery>;
export type InvestorEntityTypesQueryResult = Apollo.QueryResult<
  InvestorEntityTypesQuery,
  InvestorEntityTypesQueryVariables
>;
export const GetInvestingEntityDocument = gql`
  query GetInvestingEntity($id: Int!) {
    investorInvestingEntity(entityID: $id) {
      name
      address
      city
      country
      nickname
    }
  }
`;

/**
 * __useGetInvestingEntityQuery__
 *
 * To run a query within a React component, call `useGetInvestingEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestingEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestingEntityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvestingEntityQuery(
  baseOptions: Apollo.QueryHookOptions<GetInvestingEntityQuery, GetInvestingEntityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvestingEntityQuery, GetInvestingEntityQueryVariables>(
    GetInvestingEntityDocument,
    options,
  );
}
export function useGetInvestingEntityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInvestingEntityQuery, GetInvestingEntityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvestingEntityQuery, GetInvestingEntityQueryVariables>(
    GetInvestingEntityDocument,
    options,
  );
}
export type GetInvestingEntityQueryHookResult = ReturnType<typeof useGetInvestingEntityQuery>;
export type GetInvestingEntityLazyQueryHookResult = ReturnType<typeof useGetInvestingEntityLazyQuery>;
export type GetInvestingEntityQueryResult = Apollo.QueryResult<
  GetInvestingEntityQuery,
  GetInvestingEntityQueryVariables
>;
export const GetInvestorInvestingEntityPermissionsDocument = gql`
  query GetInvestorInvestingEntityPermissions($stoId: Int) {
    investorInvestingEntityPermissions(stoID: $stoId) {
      ID
      isBuyStoEnabled
      isNDASigned
      managementMeetings
      receivedTermSheet
      receivedSubscriptionAgreements
      closing
      firstAccessedDataRoom
    }
  }
`;

/**
 * __useGetInvestorInvestingEntityPermissionsQuery__
 *
 * To run a query within a React component, call `useGetInvestorInvestingEntityPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestorInvestingEntityPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestorInvestingEntityPermissionsQuery({
 *   variables: {
 *      stoId: // value for 'stoId'
 *   },
 * });
 */
export function useGetInvestorInvestingEntityPermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInvestorInvestingEntityPermissionsQuery,
    GetInvestorInvestingEntityPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInvestorInvestingEntityPermissionsQuery,
    GetInvestorInvestingEntityPermissionsQueryVariables
  >(GetInvestorInvestingEntityPermissionsDocument, options);
}
export function useGetInvestorInvestingEntityPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvestorInvestingEntityPermissionsQuery,
    GetInvestorInvestingEntityPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInvestorInvestingEntityPermissionsQuery,
    GetInvestorInvestingEntityPermissionsQueryVariables
  >(GetInvestorInvestingEntityPermissionsDocument, options);
}
export type GetInvestorInvestingEntityPermissionsQueryHookResult = ReturnType<
  typeof useGetInvestorInvestingEntityPermissionsQuery
>;
export type GetInvestorInvestingEntityPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetInvestorInvestingEntityPermissionsLazyQuery
>;
export type GetInvestorInvestingEntityPermissionsQueryResult = Apollo.QueryResult<
  GetInvestorInvestingEntityPermissionsQuery,
  GetInvestorInvestingEntityPermissionsQueryVariables
>;
export const IsBuyStoEnabledDocument = gql`
  query IsBuyStoEnabled($stoId: Int) {
    investorInvestingEntityPermissions(stoID: $stoId) {
      isBuyStoEnabled
    }
  }
`;

/**
 * __useIsBuyStoEnabledQuery__
 *
 * To run a query within a React component, call `useIsBuyStoEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsBuyStoEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsBuyStoEnabledQuery({
 *   variables: {
 *      stoId: // value for 'stoId'
 *   },
 * });
 */
export function useIsBuyStoEnabledQuery(
  baseOptions?: Apollo.QueryHookOptions<IsBuyStoEnabledQuery, IsBuyStoEnabledQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsBuyStoEnabledQuery, IsBuyStoEnabledQueryVariables>(IsBuyStoEnabledDocument, options);
}
export function useIsBuyStoEnabledLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IsBuyStoEnabledQuery, IsBuyStoEnabledQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsBuyStoEnabledQuery, IsBuyStoEnabledQueryVariables>(IsBuyStoEnabledDocument, options);
}
export type IsBuyStoEnabledQueryHookResult = ReturnType<typeof useIsBuyStoEnabledQuery>;
export type IsBuyStoEnabledLazyQueryHookResult = ReturnType<typeof useIsBuyStoEnabledLazyQuery>;
export type IsBuyStoEnabledQueryResult = Apollo.QueryResult<IsBuyStoEnabledQuery, IsBuyStoEnabledQueryVariables>;
export const GetInvestingEntityDefaultMetaDocument = gql`
  query GetInvestingEntityDefaultMeta {
    getInvestingEntityDefaultMeta {
      key
      value
      type
      order
    }
  }
`;

/**
 * __useGetInvestingEntityDefaultMetaQuery__
 *
 * To run a query within a React component, call `useGetInvestingEntityDefaultMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestingEntityDefaultMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestingEntityDefaultMetaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvestingEntityDefaultMetaQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInvestingEntityDefaultMetaQuery,
    GetInvestingEntityDefaultMetaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvestingEntityDefaultMetaQuery, GetInvestingEntityDefaultMetaQueryVariables>(
    GetInvestingEntityDefaultMetaDocument,
    options,
  );
}
export function useGetInvestingEntityDefaultMetaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvestingEntityDefaultMetaQuery,
    GetInvestingEntityDefaultMetaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvestingEntityDefaultMetaQuery, GetInvestingEntityDefaultMetaQueryVariables>(
    GetInvestingEntityDefaultMetaDocument,
    options,
  );
}
export type GetInvestingEntityDefaultMetaQueryHookResult = ReturnType<typeof useGetInvestingEntityDefaultMetaQuery>;
export type GetInvestingEntityDefaultMetaLazyQueryHookResult = ReturnType<
  typeof useGetInvestingEntityDefaultMetaLazyQuery
>;
export type GetInvestingEntityDefaultMetaQueryResult = Apollo.QueryResult<
  GetInvestingEntityDefaultMetaQuery,
  GetInvestingEntityDefaultMetaQueryVariables
>;
export const CreateInvoiceInvestorDocument = gql`
  mutation createInvoice_Investor($data: InvestorInvoiceInput!) {
    createInvoice_Investor(data: $data) {
      ID
    }
  }
`;
export type CreateInvoiceInvestorMutationFn = Apollo.MutationFunction<
  CreateInvoiceInvestorMutation,
  CreateInvoiceInvestorMutationVariables
>;

/**
 * __useCreateInvoiceInvestorMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceInvestorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceInvestorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceInvestorMutation, { data, loading, error }] = useCreateInvoiceInvestorMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateInvoiceInvestorMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateInvoiceInvestorMutation, CreateInvoiceInvestorMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInvoiceInvestorMutation, CreateInvoiceInvestorMutationVariables>(
    CreateInvoiceInvestorDocument,
    options,
  );
}
export type CreateInvoiceInvestorMutationHookResult = ReturnType<typeof useCreateInvoiceInvestorMutation>;
export type CreateInvoiceInvestorMutationResult = Apollo.MutationResult<CreateInvoiceInvestorMutation>;
export type CreateInvoiceInvestorMutationOptions = Apollo.BaseMutationOptions<
  CreateInvoiceInvestorMutation,
  CreateInvoiceInvestorMutationVariables
>;
export const UpdateInvoiceInvestorDocument = gql`
  mutation updateInvoice_Investor($invoiceID: Int!, $paymentChannelID: Int!) {
    updateInvoice_Investor(invoiceID: $invoiceID, paymentChannelID: $paymentChannelID)
  }
`;
export type UpdateInvoiceInvestorMutationFn = Apollo.MutationFunction<
  UpdateInvoiceInvestorMutation,
  UpdateInvoiceInvestorMutationVariables
>;

/**
 * __useUpdateInvoiceInvestorMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceInvestorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceInvestorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceInvestorMutation, { data, loading, error }] = useUpdateInvoiceInvestorMutation({
 *   variables: {
 *      invoiceID: // value for 'invoiceID'
 *      paymentChannelID: // value for 'paymentChannelID'
 *   },
 * });
 */
export function useUpdateInvoiceInvestorMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceInvestorMutation, UpdateInvoiceInvestorMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateInvoiceInvestorMutation, UpdateInvoiceInvestorMutationVariables>(
    UpdateInvoiceInvestorDocument,
    options,
  );
}
export type UpdateInvoiceInvestorMutationHookResult = ReturnType<typeof useUpdateInvoiceInvestorMutation>;
export type UpdateInvoiceInvestorMutationResult = Apollo.MutationResult<UpdateInvoiceInvestorMutation>;
export type UpdateInvoiceInvestorMutationOptions = Apollo.BaseMutationOptions<
  UpdateInvoiceInvestorMutation,
  UpdateInvoiceInvestorMutationVariables
>;
export const GetLastResolvedInvoiceDocument = gql`
  subscription GetLastResolvedInvoice {
    getLastResolvedInvoice {
      ID
      status
      resolver
      invoiceDescription
      dateUpdated
    }
  }
`;

/**
 * __useGetLastResolvedInvoiceSubscription__
 *
 * To run a query within a React component, call `useGetLastResolvedInvoiceSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetLastResolvedInvoiceSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastResolvedInvoiceSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetLastResolvedInvoiceSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetLastResolvedInvoiceSubscription,
    GetLastResolvedInvoiceSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<GetLastResolvedInvoiceSubscription, GetLastResolvedInvoiceSubscriptionVariables>(
    GetLastResolvedInvoiceDocument,
    options,
  );
}
export type GetLastResolvedInvoiceSubscriptionHookResult = ReturnType<typeof useGetLastResolvedInvoiceSubscription>;
export type GetLastResolvedInvoiceSubscriptionResult = Apollo.SubscriptionResult<GetLastResolvedInvoiceSubscription>;
export const InvestorMetaValuesUpdateDocument = gql`
  mutation investorMetaValuesUpdate($values: [MetaValuesInput!]!, $entityID: Int) {
    investorMetaValuesUpdate(values: $values, entityID: $entityID)
  }
`;
export type InvestorMetaValuesUpdateMutationFn = Apollo.MutationFunction<
  InvestorMetaValuesUpdateMutation,
  InvestorMetaValuesUpdateMutationVariables
>;

/**
 * __useInvestorMetaValuesUpdateMutation__
 *
 * To run a mutation, you first call `useInvestorMetaValuesUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorMetaValuesUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorMetaValuesUpdateMutation, { data, loading, error }] = useInvestorMetaValuesUpdateMutation({
 *   variables: {
 *      values: // value for 'values'
 *      entityID: // value for 'entityID'
 *   },
 * });
 */
export function useInvestorMetaValuesUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<InvestorMetaValuesUpdateMutation, InvestorMetaValuesUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InvestorMetaValuesUpdateMutation, InvestorMetaValuesUpdateMutationVariables>(
    InvestorMetaValuesUpdateDocument,
    options,
  );
}
export type InvestorMetaValuesUpdateMutationHookResult = ReturnType<typeof useInvestorMetaValuesUpdateMutation>;
export type InvestorMetaValuesUpdateMutationResult = Apollo.MutationResult<InvestorMetaValuesUpdateMutation>;
export type InvestorMetaValuesUpdateMutationOptions = Apollo.BaseMutationOptions<
  InvestorMetaValuesUpdateMutation,
  InvestorMetaValuesUpdateMutationVariables
>;
export const GetFilterOptionsDocument = gql`
  query getFilterOptions {
    getFilterMetaKeys {
      key
      order
      type
      categoryKey
    }
  }
`;

/**
 * __useGetFilterOptionsQuery__
 *
 * To run a query within a React component, call `useGetFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFilterOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetFilterOptionsQuery, GetFilterOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFilterOptionsQuery, GetFilterOptionsQueryVariables>(GetFilterOptionsDocument, options);
}
export function useGetFilterOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFilterOptionsQuery, GetFilterOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFilterOptionsQuery, GetFilterOptionsQueryVariables>(GetFilterOptionsDocument, options);
}
export type GetFilterOptionsQueryHookResult = ReturnType<typeof useGetFilterOptionsQuery>;
export type GetFilterOptionsLazyQueryHookResult = ReturnType<typeof useGetFilterOptionsLazyQuery>;
export type GetFilterOptionsQueryResult = Apollo.QueryResult<GetFilterOptionsQuery, GetFilterOptionsQueryVariables>;
export const GetFilterSelectOptionsDocument = gql`
  query getFilterSelectOptions($keys: [String!]) {
    findAllSto {
      meta(keys: $keys) {
        value
      }
      isActive
    }
  }
`;

/**
 * __useGetFilterSelectOptionsQuery__
 *
 * To run a query within a React component, call `useGetFilterSelectOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterSelectOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterSelectOptionsQuery({
 *   variables: {
 *      keys: // value for 'keys'
 *   },
 * });
 */
export function useGetFilterSelectOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetFilterSelectOptionsQuery, GetFilterSelectOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFilterSelectOptionsQuery, GetFilterSelectOptionsQueryVariables>(
    GetFilterSelectOptionsDocument,
    options,
  );
}
export function useGetFilterSelectOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFilterSelectOptionsQuery, GetFilterSelectOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFilterSelectOptionsQuery, GetFilterSelectOptionsQueryVariables>(
    GetFilterSelectOptionsDocument,
    options,
  );
}
export type GetFilterSelectOptionsQueryHookResult = ReturnType<typeof useGetFilterSelectOptionsQuery>;
export type GetFilterSelectOptionsLazyQueryHookResult = ReturnType<typeof useGetFilterSelectOptionsLazyQuery>;
export type GetFilterSelectOptionsQueryResult = Apollo.QueryResult<
  GetFilterSelectOptionsQuery,
  GetFilterSelectOptionsQueryVariables
>;
export const GetCurrentInvestorFilterPreferenceDocument = gql`
  query getCurrentInvestorFilterPreference($entityID: Int) {
    investorCurrentUser {
      ID
      meta(keys: [INVESTOR_FILTER_PREFERENCE], entityID: $entityID) {
        type
        value
        key
      }
    }
  }
`;

/**
 * __useGetCurrentInvestorFilterPreferenceQuery__
 *
 * To run a query within a React component, call `useGetCurrentInvestorFilterPreferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentInvestorFilterPreferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentInvestorFilterPreferenceQuery({
 *   variables: {
 *      entityID: // value for 'entityID'
 *   },
 * });
 */
export function useGetCurrentInvestorFilterPreferenceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentInvestorFilterPreferenceQuery,
    GetCurrentInvestorFilterPreferenceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentInvestorFilterPreferenceQuery, GetCurrentInvestorFilterPreferenceQueryVariables>(
    GetCurrentInvestorFilterPreferenceDocument,
    options,
  );
}
export function useGetCurrentInvestorFilterPreferenceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentInvestorFilterPreferenceQuery,
    GetCurrentInvestorFilterPreferenceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentInvestorFilterPreferenceQuery, GetCurrentInvestorFilterPreferenceQueryVariables>(
    GetCurrentInvestorFilterPreferenceDocument,
    options,
  );
}
export type GetCurrentInvestorFilterPreferenceQueryHookResult = ReturnType<
  typeof useGetCurrentInvestorFilterPreferenceQuery
>;
export type GetCurrentInvestorFilterPreferenceLazyQueryHookResult = ReturnType<
  typeof useGetCurrentInvestorFilterPreferenceLazyQuery
>;
export type GetCurrentInvestorFilterPreferenceQueryResult = Apollo.QueryResult<
  GetCurrentInvestorFilterPreferenceQuery,
  GetCurrentInvestorFilterPreferenceQueryVariables
>;
export const GetCurrentInvestorMetaValuesDocument = gql`
  query getCurrentInvestorMetaValues {
    investorCurrentUser {
      meta {
        key
        value
        type
      }
    }
  }
`;

/**
 * __useGetCurrentInvestorMetaValuesQuery__
 *
 * To run a query within a React component, call `useGetCurrentInvestorMetaValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentInvestorMetaValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentInvestorMetaValuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentInvestorMetaValuesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCurrentInvestorMetaValuesQuery, GetCurrentInvestorMetaValuesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentInvestorMetaValuesQuery, GetCurrentInvestorMetaValuesQueryVariables>(
    GetCurrentInvestorMetaValuesDocument,
    options,
  );
}
export function useGetCurrentInvestorMetaValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentInvestorMetaValuesQuery,
    GetCurrentInvestorMetaValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentInvestorMetaValuesQuery, GetCurrentInvestorMetaValuesQueryVariables>(
    GetCurrentInvestorMetaValuesDocument,
    options,
  );
}
export type GetCurrentInvestorMetaValuesQueryHookResult = ReturnType<typeof useGetCurrentInvestorMetaValuesQuery>;
export type GetCurrentInvestorMetaValuesLazyQueryHookResult = ReturnType<
  typeof useGetCurrentInvestorMetaValuesLazyQuery
>;
export type GetCurrentInvestorMetaValuesQueryResult = Apollo.QueryResult<
  GetCurrentInvestorMetaValuesQuery,
  GetCurrentInvestorMetaValuesQueryVariables
>;
export const GetCategoryOrderMetaKeysDocument = gql`
  query getCategoryOrderMetaKeys {
    getCategoryMetaKeys {
      key
      order
    }
  }
`;

/**
 * __useGetCategoryOrderMetaKeysQuery__
 *
 * To run a query within a React component, call `useGetCategoryOrderMetaKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryOrderMetaKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryOrderMetaKeysQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCategoryOrderMetaKeysQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCategoryOrderMetaKeysQuery, GetCategoryOrderMetaKeysQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCategoryOrderMetaKeysQuery, GetCategoryOrderMetaKeysQueryVariables>(
    GetCategoryOrderMetaKeysDocument,
    options,
  );
}
export function useGetCategoryOrderMetaKeysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryOrderMetaKeysQuery, GetCategoryOrderMetaKeysQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCategoryOrderMetaKeysQuery, GetCategoryOrderMetaKeysQueryVariables>(
    GetCategoryOrderMetaKeysDocument,
    options,
  );
}
export type GetCategoryOrderMetaKeysQueryHookResult = ReturnType<typeof useGetCategoryOrderMetaKeysQuery>;
export type GetCategoryOrderMetaKeysLazyQueryHookResult = ReturnType<typeof useGetCategoryOrderMetaKeysLazyQuery>;
export type GetCategoryOrderMetaKeysQueryResult = Apollo.QueryResult<
  GetCategoryOrderMetaKeysQuery,
  GetCategoryOrderMetaKeysQueryVariables
>;
export const IsInvestorWhiteListedDocument = gql`
  query IsInvestorWhiteListed($walletAddress: String!) {
    isInvestorWhiteListed(walletAddress: $walletAddress)
  }
`;

/**
 * __useIsInvestorWhiteListedQuery__
 *
 * To run a query within a React component, call `useIsInvestorWhiteListedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsInvestorWhiteListedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsInvestorWhiteListedQuery({
 *   variables: {
 *      walletAddress: // value for 'walletAddress'
 *   },
 * });
 */
export function useIsInvestorWhiteListedQuery(
  baseOptions: Apollo.QueryHookOptions<IsInvestorWhiteListedQuery, IsInvestorWhiteListedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsInvestorWhiteListedQuery, IsInvestorWhiteListedQueryVariables>(
    IsInvestorWhiteListedDocument,
    options,
  );
}
export function useIsInvestorWhiteListedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IsInvestorWhiteListedQuery, IsInvestorWhiteListedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsInvestorWhiteListedQuery, IsInvestorWhiteListedQueryVariables>(
    IsInvestorWhiteListedDocument,
    options,
  );
}
export type IsInvestorWhiteListedQueryHookResult = ReturnType<typeof useIsInvestorWhiteListedQuery>;
export type IsInvestorWhiteListedLazyQueryHookResult = ReturnType<typeof useIsInvestorWhiteListedLazyQuery>;
export type IsInvestorWhiteListedQueryResult = Apollo.QueryResult<
  IsInvestorWhiteListedQuery,
  IsInvestorWhiteListedQueryVariables
>;
export const CreateTopUpAlertDocument = gql`
  mutation createTopUpAlert($data: InternalWalletTopUpAlertInput!) {
    createTopUpAlert(data: $data) {
      ID
    }
  }
`;
export type CreateTopUpAlertMutationFn = Apollo.MutationFunction<
  CreateTopUpAlertMutation,
  CreateTopUpAlertMutationVariables
>;

/**
 * __useCreateTopUpAlertMutation__
 *
 * To run a mutation, you first call `useCreateTopUpAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTopUpAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTopUpAlertMutation, { data, loading, error }] = useCreateTopUpAlertMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTopUpAlertMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTopUpAlertMutation, CreateTopUpAlertMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTopUpAlertMutation, CreateTopUpAlertMutationVariables>(
    CreateTopUpAlertDocument,
    options,
  );
}
export type CreateTopUpAlertMutationHookResult = ReturnType<typeof useCreateTopUpAlertMutation>;
export type CreateTopUpAlertMutationResult = Apollo.MutationResult<CreateTopUpAlertMutation>;
export type CreateTopUpAlertMutationOptions = Apollo.BaseMutationOptions<
  CreateTopUpAlertMutation,
  CreateTopUpAlertMutationVariables
>;
export const AddOrEditInvestorBankAccountDocument = gql`
  mutation addOrEditInvestorBankAccount($data: InvestorBankAccountInput!) {
    addOrEditInvestorBankAccount(data: $data)
  }
`;
export type AddOrEditInvestorBankAccountMutationFn = Apollo.MutationFunction<
  AddOrEditInvestorBankAccountMutation,
  AddOrEditInvestorBankAccountMutationVariables
>;

/**
 * __useAddOrEditInvestorBankAccountMutation__
 *
 * To run a mutation, you first call `useAddOrEditInvestorBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrEditInvestorBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrEditInvestorBankAccountMutation, { data, loading, error }] = useAddOrEditInvestorBankAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddOrEditInvestorBankAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddOrEditInvestorBankAccountMutation,
    AddOrEditInvestorBankAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddOrEditInvestorBankAccountMutation, AddOrEditInvestorBankAccountMutationVariables>(
    AddOrEditInvestorBankAccountDocument,
    options,
  );
}
export type AddOrEditInvestorBankAccountMutationHookResult = ReturnType<typeof useAddOrEditInvestorBankAccountMutation>;
export type AddOrEditInvestorBankAccountMutationResult = Apollo.MutationResult<AddOrEditInvestorBankAccountMutation>;
export type AddOrEditInvestorBankAccountMutationOptions = Apollo.BaseMutationOptions<
  AddOrEditInvestorBankAccountMutation,
  AddOrEditInvestorBankAccountMutationVariables
>;
export const GetInvestorBankAccountDocument = gql`
  query getInvestorBankAccount {
    getInvestorBankAccount {
      ID
      investorID
      accountTitle
      accountNo
      routingNumber
      iban
      accountHolderName
      accountHolderCity
      accountHolderCountry
      accountHolderAddress
      accountPostalCode
      bankName
      bankCity
      bankCountry
      bankAddress
      swift
    }
  }
`;

/**
 * __useGetInvestorBankAccountQuery__
 *
 * To run a query within a React component, call `useGetInvestorBankAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestorBankAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestorBankAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvestorBankAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<GetInvestorBankAccountQuery, GetInvestorBankAccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvestorBankAccountQuery, GetInvestorBankAccountQueryVariables>(
    GetInvestorBankAccountDocument,
    options,
  );
}
export function useGetInvestorBankAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInvestorBankAccountQuery, GetInvestorBankAccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvestorBankAccountQuery, GetInvestorBankAccountQueryVariables>(
    GetInvestorBankAccountDocument,
    options,
  );
}
export type GetInvestorBankAccountQueryHookResult = ReturnType<typeof useGetInvestorBankAccountQuery>;
export type GetInvestorBankAccountLazyQueryHookResult = ReturnType<typeof useGetInvestorBankAccountLazyQuery>;
export type GetInvestorBankAccountQueryResult = Apollo.QueryResult<
  GetInvestorBankAccountQuery,
  GetInvestorBankAccountQueryVariables
>;
export const CreateKycFileDocument = gql`
  mutation CreateKycFile($file: KycFileCreateInput!) {
    createKycFile(kycFile: $file)
  }
`;
export type CreateKycFileMutationFn = Apollo.MutationFunction<CreateKycFileMutation, CreateKycFileMutationVariables>;

/**
 * __useCreateKycFileMutation__
 *
 * To run a mutation, you first call `useCreateKycFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKycFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKycFileMutation, { data, loading, error }] = useCreateKycFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useCreateKycFileMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateKycFileMutation, CreateKycFileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateKycFileMutation, CreateKycFileMutationVariables>(CreateKycFileDocument, options);
}
export type CreateKycFileMutationHookResult = ReturnType<typeof useCreateKycFileMutation>;
export type CreateKycFileMutationResult = Apollo.MutationResult<CreateKycFileMutation>;
export type CreateKycFileMutationOptions = Apollo.BaseMutationOptions<
  CreateKycFileMutation,
  CreateKycFileMutationVariables
>;
export const GetKycFilesDocument = gql`
  query GetKycFiles($name: String!) {
    kycFiles(name: $name) {
      ID
      url
      filename
    }
  }
`;

/**
 * __useGetKycFilesQuery__
 *
 * To run a query within a React component, call `useGetKycFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKycFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKycFilesQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetKycFilesQuery(baseOptions: Apollo.QueryHookOptions<GetKycFilesQuery, GetKycFilesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetKycFilesQuery, GetKycFilesQueryVariables>(GetKycFilesDocument, options);
}
export function useGetKycFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetKycFilesQuery, GetKycFilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetKycFilesQuery, GetKycFilesQueryVariables>(GetKycFilesDocument, options);
}
export type GetKycFilesQueryHookResult = ReturnType<typeof useGetKycFilesQuery>;
export type GetKycFilesLazyQueryHookResult = ReturnType<typeof useGetKycFilesLazyQuery>;
export type GetKycFilesQueryResult = Apollo.QueryResult<GetKycFilesQuery, GetKycFilesQueryVariables>;
export const FillKycDocument = gql`
  mutation FillKyc($data: JSON!) {
    fillKyc(data: $data)
  }
`;
export type FillKycMutationFn = Apollo.MutationFunction<FillKycMutation, FillKycMutationVariables>;

/**
 * __useFillKycMutation__
 *
 * To run a mutation, you first call `useFillKycMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFillKycMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fillKycMutation, { data, loading, error }] = useFillKycMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useFillKycMutation(
  baseOptions?: Apollo.MutationHookOptions<FillKycMutation, FillKycMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FillKycMutation, FillKycMutationVariables>(FillKycDocument, options);
}
export type FillKycMutationHookResult = ReturnType<typeof useFillKycMutation>;
export type FillKycMutationResult = Apollo.MutationResult<FillKycMutation>;
export type FillKycMutationOptions = Apollo.BaseMutationOptions<FillKycMutation, FillKycMutationVariables>;
export const ApplyKycDocument = gql`
  mutation ApplyKyc($applied: Boolean!) {
    applyKyc(applied: $applied)
  }
`;
export type ApplyKycMutationFn = Apollo.MutationFunction<ApplyKycMutation, ApplyKycMutationVariables>;

/**
 * __useApplyKycMutation__
 *
 * To run a mutation, you first call `useApplyKycMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyKycMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyKycMutation, { data, loading, error }] = useApplyKycMutation({
 *   variables: {
 *      applied: // value for 'applied'
 *   },
 * });
 */
export function useApplyKycMutation(
  baseOptions?: Apollo.MutationHookOptions<ApplyKycMutation, ApplyKycMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ApplyKycMutation, ApplyKycMutationVariables>(ApplyKycDocument, options);
}
export type ApplyKycMutationHookResult = ReturnType<typeof useApplyKycMutation>;
export type ApplyKycMutationResult = Apollo.MutationResult<ApplyKycMutation>;
export type ApplyKycMutationOptions = Apollo.BaseMutationOptions<ApplyKycMutation, ApplyKycMutationVariables>;
export const JsonKycDocument = gql`
  query JsonKYC {
    kyc {
      name
      title
      icon
      pages {
        fields {
          values {
            value
            label
          }
          name
          label
          placeholder
          description
          error
          required
          type
        }
        name
        title
        icon
      }
    }
  }
`;

/**
 * __useJsonKycQuery__
 *
 * To run a query within a React component, call `useJsonKycQuery` and pass it any options that fit your needs.
 * When your component renders, `useJsonKycQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJsonKycQuery({
 *   variables: {
 *   },
 * });
 */
export function useJsonKycQuery(baseOptions?: Apollo.QueryHookOptions<JsonKycQuery, JsonKycQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<JsonKycQuery, JsonKycQueryVariables>(JsonKycDocument, options);
}
export function useJsonKycLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JsonKycQuery, JsonKycQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<JsonKycQuery, JsonKycQueryVariables>(JsonKycDocument, options);
}
export type JsonKycQueryHookResult = ReturnType<typeof useJsonKycQuery>;
export type JsonKycLazyQueryHookResult = ReturnType<typeof useJsonKycLazyQuery>;
export type JsonKycQueryResult = Apollo.QueryResult<JsonKycQuery, JsonKycQueryVariables>;
export const InvestorKycDocument = gql`
  query investorKyc {
    investorKyc
    investorAppParameters {
      isInvestorNotesEnabled
      isOptionKinInfoCollectionEnabled
      isDriversLicenseEnabled
      isSaveAndNextAtPersonalInfoEnabled
      internalKycStateOptions
      internalKycDocumentIssuingStateOptions
    }
  }
`;

/**
 * __useInvestorKycQuery__
 *
 * To run a query within a React component, call `useInvestorKycQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorKycQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorKycQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvestorKycQuery(
  baseOptions?: Apollo.QueryHookOptions<InvestorKycQuery, InvestorKycQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestorKycQuery, InvestorKycQueryVariables>(InvestorKycDocument, options);
}
export function useInvestorKycLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestorKycQuery, InvestorKycQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestorKycQuery, InvestorKycQueryVariables>(InvestorKycDocument, options);
}
export type InvestorKycQueryHookResult = ReturnType<typeof useInvestorKycQuery>;
export type InvestorKycLazyQueryHookResult = ReturnType<typeof useInvestorKycLazyQuery>;
export type InvestorKycQueryResult = Apollo.QueryResult<InvestorKycQuery, InvestorKycQueryVariables>;
export const RemoveKycFileDocument = gql`
  mutation RemoveKycFile($ID: Int!) {
    removeKycFile(ID: $ID)
  }
`;
export type RemoveKycFileMutationFn = Apollo.MutationFunction<RemoveKycFileMutation, RemoveKycFileMutationVariables>;

/**
 * __useRemoveKycFileMutation__
 *
 * To run a mutation, you first call `useRemoveKycFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveKycFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeKycFileMutation, { data, loading, error }] = useRemoveKycFileMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useRemoveKycFileMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveKycFileMutation, RemoveKycFileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveKycFileMutation, RemoveKycFileMutationVariables>(RemoveKycFileDocument, options);
}
export type RemoveKycFileMutationHookResult = ReturnType<typeof useRemoveKycFileMutation>;
export type RemoveKycFileMutationResult = Apollo.MutationResult<RemoveKycFileMutation>;
export type RemoveKycFileMutationOptions = Apollo.BaseMutationOptions<
  RemoveKycFileMutation,
  RemoveKycFileMutationVariables
>;
export const CreateLogDocument = gql`
  mutation CreateLog($activityType: LOG_ACTION!, $stoId: Int!, $investorId: Int) {
    createLog(activityType: $activityType, stoID: $stoId, investorID: $investorId)
  }
`;
export type CreateLogMutationFn = Apollo.MutationFunction<CreateLogMutation, CreateLogMutationVariables>;

/**
 * __useCreateLogMutation__
 *
 * To run a mutation, you first call `useCreateLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLogMutation, { data, loading, error }] = useCreateLogMutation({
 *   variables: {
 *      activityType: // value for 'activityType'
 *      stoId: // value for 'stoId'
 *      investorId: // value for 'investorId'
 *   },
 * });
 */
export function useCreateLogMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateLogMutation, CreateLogMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateLogMutation, CreateLogMutationVariables>(CreateLogDocument, options);
}
export type CreateLogMutationHookResult = ReturnType<typeof useCreateLogMutation>;
export type CreateLogMutationResult = Apollo.MutationResult<CreateLogMutation>;
export type CreateLogMutationOptions = Apollo.BaseMutationOptions<CreateLogMutation, CreateLogMutationVariables>;
export const InvestorRegisterVoteDocument = gql`
  mutation InvestorRegisterVote($data: RegisterVoteInput!) {
    investorRegisterVote(data: $data)
  }
`;
export type InvestorRegisterVoteMutationFn = Apollo.MutationFunction<
  InvestorRegisterVoteMutation,
  InvestorRegisterVoteMutationVariables
>;

/**
 * __useInvestorRegisterVoteMutation__
 *
 * To run a mutation, you first call `useInvestorRegisterVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorRegisterVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorRegisterVoteMutation, { data, loading, error }] = useInvestorRegisterVoteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInvestorRegisterVoteMutation(
  baseOptions?: Apollo.MutationHookOptions<InvestorRegisterVoteMutation, InvestorRegisterVoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InvestorRegisterVoteMutation, InvestorRegisterVoteMutationVariables>(
    InvestorRegisterVoteDocument,
    options,
  );
}
export type InvestorRegisterVoteMutationHookResult = ReturnType<typeof useInvestorRegisterVoteMutation>;
export type InvestorRegisterVoteMutationResult = Apollo.MutationResult<InvestorRegisterVoteMutation>;
export type InvestorRegisterVoteMutationOptions = Apollo.BaseMutationOptions<
  InvestorRegisterVoteMutation,
  InvestorRegisterVoteMutationVariables
>;
export const GetMeetingsDocument = gql`
  query GetMeetings($stoId: Int!) {
    investorAllMeeting(stoID: $stoId) {
      past {
        ID
        stoID
        title
        type
        nameResponsiblePerson
        phoneResponsiblePerson
        emailResponsiblePerson
        nameProxyPerson
        phoneProxyPerson
        emailProxyPerson
        place
        openDate
        closeDate
        voteType
        timezone
        timePadding
      }
      current {
        ID
        stoID
        title
        type
        nameResponsiblePerson
        phoneResponsiblePerson
        emailResponsiblePerson
        nameProxyPerson
        phoneProxyPerson
        emailProxyPerson
        place
        openDate
        closeDate
        voteType
        timezone
        timePadding
      }
      future {
        ID
        stoID
        title
        type
        phoneResponsiblePerson
        nameResponsiblePerson
        emailResponsiblePerson
        phoneProxyPerson
        nameProxyPerson
        emailProxyPerson
        place
        openDate
        closeDate
        voteType
        timezone
        timePadding
      }
    }
  }
`;

/**
 * __useGetMeetingsQuery__
 *
 * To run a query within a React component, call `useGetMeetingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeetingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeetingsQuery({
 *   variables: {
 *      stoId: // value for 'stoId'
 *   },
 * });
 */
export function useGetMeetingsQuery(baseOptions: Apollo.QueryHookOptions<GetMeetingsQuery, GetMeetingsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeetingsQuery, GetMeetingsQueryVariables>(GetMeetingsDocument, options);
}
export function useGetMeetingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMeetingsQuery, GetMeetingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMeetingsQuery, GetMeetingsQueryVariables>(GetMeetingsDocument, options);
}
export type GetMeetingsQueryHookResult = ReturnType<typeof useGetMeetingsQuery>;
export type GetMeetingsLazyQueryHookResult = ReturnType<typeof useGetMeetingsLazyQuery>;
export type GetMeetingsQueryResult = Apollo.QueryResult<GetMeetingsQuery, GetMeetingsQueryVariables>;
export const InvestorMeetingDocument = gql`
  query InvestorMeeting($meetingId: Int!, $_id: Int!) {
    investorMeeting(meetingID: $meetingId) {
      ID
      stoID
      title
      content
      type
      nameResponsiblePerson
      phoneResponsiblePerson
      emailResponsiblePerson
      nameProxyPerson
      phoneProxyPerson
      emailProxyPerson
      place
      openDate
      closeDate
      voteType
      timezone
      timePadding
      documents {
        ID
        votingID
        votingOptionID
        documentLink
        title
        description
      }
    }
    findSto(ID: $_id) {
      companyType
    }
  }
`;

/**
 * __useInvestorMeetingQuery__
 *
 * To run a query within a React component, call `useInvestorMeetingQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorMeetingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorMeetingQuery({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useInvestorMeetingQuery(
  baseOptions: Apollo.QueryHookOptions<InvestorMeetingQuery, InvestorMeetingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestorMeetingQuery, InvestorMeetingQueryVariables>(InvestorMeetingDocument, options);
}
export function useInvestorMeetingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestorMeetingQuery, InvestorMeetingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestorMeetingQuery, InvestorMeetingQueryVariables>(InvestorMeetingDocument, options);
}
export type InvestorMeetingQueryHookResult = ReturnType<typeof useInvestorMeetingQuery>;
export type InvestorMeetingLazyQueryHookResult = ReturnType<typeof useInvestorMeetingLazyQuery>;
export type InvestorMeetingQueryResult = Apollo.QueryResult<InvestorMeetingQuery, InvestorMeetingQueryVariables>;
export const InvestorVoteOptionsDocument = gql`
  query InvestorVoteOptions($meetingId: Int!) {
    investorVotingOptions(votingID: $meetingId) {
      ID
      votingID
      optionTxt
      description
      companyComments
      isActiveByAdmin
      userVotedOption {
        votingOption {
          ID
          optionTxt
        }
      }
      documents {
        ID
        votingID
        votingOptionID
        documentLink
        title
        description
      }
    }
  }
`;

/**
 * __useInvestorVoteOptionsQuery__
 *
 * To run a query within a React component, call `useInvestorVoteOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorVoteOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorVoteOptionsQuery({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *   },
 * });
 */
export function useInvestorVoteOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<InvestorVoteOptionsQuery, InvestorVoteOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestorVoteOptionsQuery, InvestorVoteOptionsQueryVariables>(
    InvestorVoteOptionsDocument,
    options,
  );
}
export function useInvestorVoteOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestorVoteOptionsQuery, InvestorVoteOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestorVoteOptionsQuery, InvestorVoteOptionsQueryVariables>(
    InvestorVoteOptionsDocument,
    options,
  );
}
export type InvestorVoteOptionsQueryHookResult = ReturnType<typeof useInvestorVoteOptionsQuery>;
export type InvestorVoteOptionsLazyQueryHookResult = ReturnType<typeof useInvestorVoteOptionsLazyQuery>;
export type InvestorVoteOptionsQueryResult = Apollo.QueryResult<
  InvestorVoteOptionsQuery,
  InvestorVoteOptionsQueryVariables
>;
export const UserVotingDataDocument = gql`
  query UserVotingData($votingId: Int!) {
    investorUserVoting(votingID: $votingId) {
      ID
      votingID
      userID
      votingOptionID
      investmentContributed
      isCastedByInvestor
    }
  }
`;

/**
 * __useUserVotingDataQuery__
 *
 * To run a query within a React component, call `useUserVotingDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserVotingDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserVotingDataQuery({
 *   variables: {
 *      votingId: // value for 'votingId'
 *   },
 * });
 */
export function useUserVotingDataQuery(
  baseOptions: Apollo.QueryHookOptions<UserVotingDataQuery, UserVotingDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserVotingDataQuery, UserVotingDataQueryVariables>(UserVotingDataDocument, options);
}
export function useUserVotingDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserVotingDataQuery, UserVotingDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserVotingDataQuery, UserVotingDataQueryVariables>(UserVotingDataDocument, options);
}
export type UserVotingDataQueryHookResult = ReturnType<typeof useUserVotingDataQuery>;
export type UserVotingDataLazyQueryHookResult = ReturnType<typeof useUserVotingDataLazyQuery>;
export type UserVotingDataQueryResult = Apollo.QueryResult<UserVotingDataQuery, UserVotingDataQueryVariables>;
export const OptionsVoteStatisticDocument = gql`
  query OptionsVoteStatistic($votingId: Int!) {
    investorVotingOptions(votingID: $votingId) {
      ID
      optionTxt
      votingUserStatistic {
        thisPercent
        totalCount
      }
    }
  }
`;

/**
 * __useOptionsVoteStatisticQuery__
 *
 * To run a query within a React component, call `useOptionsVoteStatisticQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptionsVoteStatisticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptionsVoteStatisticQuery({
 *   variables: {
 *      votingId: // value for 'votingId'
 *   },
 * });
 */
export function useOptionsVoteStatisticQuery(
  baseOptions: Apollo.QueryHookOptions<OptionsVoteStatisticQuery, OptionsVoteStatisticQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OptionsVoteStatisticQuery, OptionsVoteStatisticQueryVariables>(
    OptionsVoteStatisticDocument,
    options,
  );
}
export function useOptionsVoteStatisticLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OptionsVoteStatisticQuery, OptionsVoteStatisticQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OptionsVoteStatisticQuery, OptionsVoteStatisticQueryVariables>(
    OptionsVoteStatisticDocument,
    options,
  );
}
export type OptionsVoteStatisticQueryHookResult = ReturnType<typeof useOptionsVoteStatisticQuery>;
export type OptionsVoteStatisticLazyQueryHookResult = ReturnType<typeof useOptionsVoteStatisticLazyQuery>;
export type OptionsVoteStatisticQueryResult = Apollo.QueryResult<
  OptionsVoteStatisticQuery,
  OptionsVoteStatisticQueryVariables
>;
export const GetCorporateActionDocument = gql`
  query GetCorporateAction($ID: Int!) {
    getCorporateAction(ID: $ID) {
      ID
      stoID
      title
      type
      phoneResponsiblePerson
      nameResponsiblePerson
      emailResponsiblePerson
      phoneProxyPerson
      nameProxyPerson
      emailProxyPerson
      place
      openDate
      closeDate
      voteType
      timezone
      timePadding
      documents {
        ID
        votingID
        votingOptionID
        documentLink
        title
        description
      }
    }
  }
`;

/**
 * __useGetCorporateActionQuery__
 *
 * To run a query within a React component, call `useGetCorporateActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCorporateActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCorporateActionQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetCorporateActionQuery(
  baseOptions: Apollo.QueryHookOptions<GetCorporateActionQuery, GetCorporateActionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCorporateActionQuery, GetCorporateActionQueryVariables>(
    GetCorporateActionDocument,
    options,
  );
}
export function useGetCorporateActionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCorporateActionQuery, GetCorporateActionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCorporateActionQuery, GetCorporateActionQueryVariables>(
    GetCorporateActionDocument,
    options,
  );
}
export type GetCorporateActionQueryHookResult = ReturnType<typeof useGetCorporateActionQuery>;
export type GetCorporateActionLazyQueryHookResult = ReturnType<typeof useGetCorporateActionLazyQuery>;
export type GetCorporateActionQueryResult = Apollo.QueryResult<
  GetCorporateActionQuery,
  GetCorporateActionQueryVariables
>;
export const SendMercuryInstructionalEmailDocument = gql`
  mutation SendMercuryInstructionalEmail(
    $stoId: Int!
    $note: String!
    $routingNumber: String!
    $accountNumber: String!
  ) {
    sendMercuryInstructionalEmail(
      stoID: $stoId
      note: $note
      routingNumber: $routingNumber
      accountNumber: $accountNumber
    )
  }
`;
export type SendMercuryInstructionalEmailMutationFn = Apollo.MutationFunction<
  SendMercuryInstructionalEmailMutation,
  SendMercuryInstructionalEmailMutationVariables
>;

/**
 * __useSendMercuryInstructionalEmailMutation__
 *
 * To run a mutation, you first call `useSendMercuryInstructionalEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMercuryInstructionalEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMercuryInstructionalEmailMutation, { data, loading, error }] = useSendMercuryInstructionalEmailMutation({
 *   variables: {
 *      stoId: // value for 'stoId'
 *      note: // value for 'note'
 *      routingNumber: // value for 'routingNumber'
 *      accountNumber: // value for 'accountNumber'
 *   },
 * });
 */
export function useSendMercuryInstructionalEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendMercuryInstructionalEmailMutation,
    SendMercuryInstructionalEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendMercuryInstructionalEmailMutation, SendMercuryInstructionalEmailMutationVariables>(
    SendMercuryInstructionalEmailDocument,
    options,
  );
}
export type SendMercuryInstructionalEmailMutationHookResult = ReturnType<
  typeof useSendMercuryInstructionalEmailMutation
>;
export type SendMercuryInstructionalEmailMutationResult = Apollo.MutationResult<SendMercuryInstructionalEmailMutation>;
export type SendMercuryInstructionalEmailMutationOptions = Apollo.BaseMutationOptions<
  SendMercuryInstructionalEmailMutation,
  SendMercuryInstructionalEmailMutationVariables
>;
export const GetMercuryAccountInfoDocument = gql`
  query GetMercuryAccountInfo {
    getMercuryAccountInfo {
      accountNumber
      routingNumber
    }
  }
`;

/**
 * __useGetMercuryAccountInfoQuery__
 *
 * To run a query within a React component, call `useGetMercuryAccountInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMercuryAccountInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMercuryAccountInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMercuryAccountInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMercuryAccountInfoQuery, GetMercuryAccountInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMercuryAccountInfoQuery, GetMercuryAccountInfoQueryVariables>(
    GetMercuryAccountInfoDocument,
    options,
  );
}
export function useGetMercuryAccountInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMercuryAccountInfoQuery, GetMercuryAccountInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMercuryAccountInfoQuery, GetMercuryAccountInfoQueryVariables>(
    GetMercuryAccountInfoDocument,
    options,
  );
}
export type GetMercuryAccountInfoQueryHookResult = ReturnType<typeof useGetMercuryAccountInfoQuery>;
export type GetMercuryAccountInfoLazyQueryHookResult = ReturnType<typeof useGetMercuryAccountInfoLazyQuery>;
export type GetMercuryAccountInfoQueryResult = Apollo.QueryResult<
  GetMercuryAccountInfoQuery,
  GetMercuryAccountInfoQueryVariables
>;
export const GetMercuryRecipientDocument = gql`
  query GetMercuryRecipient {
    getMercuryRecipient {
      id
      name
      emails
      paymentMethod
      electronicRoutingInfo {
        accountNumber
        electronicAccountType
        routingNumber
        address {
          address1
          city
          region
          postalCode
          country
        }
      }
    }
  }
`;

/**
 * __useGetMercuryRecipientQuery__
 *
 * To run a query within a React component, call `useGetMercuryRecipientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMercuryRecipientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMercuryRecipientQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMercuryRecipientQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMercuryRecipientQuery, GetMercuryRecipientQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMercuryRecipientQuery, GetMercuryRecipientQueryVariables>(
    GetMercuryRecipientDocument,
    options,
  );
}
export function useGetMercuryRecipientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMercuryRecipientQuery, GetMercuryRecipientQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMercuryRecipientQuery, GetMercuryRecipientQueryVariables>(
    GetMercuryRecipientDocument,
    options,
  );
}
export type GetMercuryRecipientQueryHookResult = ReturnType<typeof useGetMercuryRecipientQuery>;
export type GetMercuryRecipientLazyQueryHookResult = ReturnType<typeof useGetMercuryRecipientLazyQuery>;
export type GetMercuryRecipientQueryResult = Apollo.QueryResult<
  GetMercuryRecipientQuery,
  GetMercuryRecipientQueryVariables
>;
export const GetMercuryConfigModeDocument = gql`
  query GetMercuryConfigMode {
    getMercuryConfigMode
  }
`;

/**
 * __useGetMercuryConfigModeQuery__
 *
 * To run a query within a React component, call `useGetMercuryConfigModeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMercuryConfigModeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMercuryConfigModeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMercuryConfigModeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMercuryConfigModeQuery, GetMercuryConfigModeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMercuryConfigModeQuery, GetMercuryConfigModeQueryVariables>(
    GetMercuryConfigModeDocument,
    options,
  );
}
export function useGetMercuryConfigModeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMercuryConfigModeQuery, GetMercuryConfigModeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMercuryConfigModeQuery, GetMercuryConfigModeQueryVariables>(
    GetMercuryConfigModeDocument,
    options,
  );
}
export type GetMercuryConfigModeQueryHookResult = ReturnType<typeof useGetMercuryConfigModeQuery>;
export type GetMercuryConfigModeLazyQueryHookResult = ReturnType<typeof useGetMercuryConfigModeLazyQuery>;
export type GetMercuryConfigModeQueryResult = Apollo.QueryResult<
  GetMercuryConfigModeQuery,
  GetMercuryConfigModeQueryVariables
>;
export const MoonpayAddTransactionDefaultDocument = gql`
  mutation MoonpayAddTransactionDefault($status: String!, $moonpayId: String!) {
    moonpayAddTransactionDefault(status: $status, moonpayID: $moonpayId)
  }
`;
export type MoonpayAddTransactionDefaultMutationFn = Apollo.MutationFunction<
  MoonpayAddTransactionDefaultMutation,
  MoonpayAddTransactionDefaultMutationVariables
>;

/**
 * __useMoonpayAddTransactionDefaultMutation__
 *
 * To run a mutation, you first call `useMoonpayAddTransactionDefaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoonpayAddTransactionDefaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moonpayAddTransactionDefaultMutation, { data, loading, error }] = useMoonpayAddTransactionDefaultMutation({
 *   variables: {
 *      status: // value for 'status'
 *      moonpayId: // value for 'moonpayId'
 *   },
 * });
 */
export function useMoonpayAddTransactionDefaultMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoonpayAddTransactionDefaultMutation,
    MoonpayAddTransactionDefaultMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MoonpayAddTransactionDefaultMutation, MoonpayAddTransactionDefaultMutationVariables>(
    MoonpayAddTransactionDefaultDocument,
    options,
  );
}
export type MoonpayAddTransactionDefaultMutationHookResult = ReturnType<typeof useMoonpayAddTransactionDefaultMutation>;
export type MoonpayAddTransactionDefaultMutationResult = Apollo.MutationResult<MoonpayAddTransactionDefaultMutation>;
export type MoonpayAddTransactionDefaultMutationOptions = Apollo.BaseMutationOptions<
  MoonpayAddTransactionDefaultMutation,
  MoonpayAddTransactionDefaultMutationVariables
>;
export const MoonpayWidgetUrlDocument = gql`
  query MoonpayWidgetUrl($shares: Float!, $shareTypeId: Int!, $alertId: Int) {
    moonpayWidgetUrl(shares: $shares, shareTypeID: $shareTypeId, alertID: $alertId)
  }
`;

/**
 * __useMoonpayWidgetUrlQuery__
 *
 * To run a query within a React component, call `useMoonpayWidgetUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useMoonpayWidgetUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMoonpayWidgetUrlQuery({
 *   variables: {
 *      shares: // value for 'shares'
 *      shareTypeId: // value for 'shareTypeId'
 *      alertId: // value for 'alertId'
 *   },
 * });
 */
export function useMoonpayWidgetUrlQuery(
  baseOptions: Apollo.QueryHookOptions<MoonpayWidgetUrlQuery, MoonpayWidgetUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MoonpayWidgetUrlQuery, MoonpayWidgetUrlQueryVariables>(MoonpayWidgetUrlDocument, options);
}
export function useMoonpayWidgetUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MoonpayWidgetUrlQuery, MoonpayWidgetUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MoonpayWidgetUrlQuery, MoonpayWidgetUrlQueryVariables>(MoonpayWidgetUrlDocument, options);
}
export type MoonpayWidgetUrlQueryHookResult = ReturnType<typeof useMoonpayWidgetUrlQuery>;
export type MoonpayWidgetUrlLazyQueryHookResult = ReturnType<typeof useMoonpayWidgetUrlLazyQuery>;
export type MoonpayWidgetUrlQueryResult = Apollo.QueryResult<MoonpayWidgetUrlQuery, MoonpayWidgetUrlQueryVariables>;
export const MoonpayBuyAlertTransactionReceiptUrlDocument = gql`
  query MoonpayBuyAlertTransactionReceiptUrl($alertId: Int!) {
    moonpayBuyAlertTransactionReceiptUrl(alertID: $alertId)
  }
`;

/**
 * __useMoonpayBuyAlertTransactionReceiptUrlQuery__
 *
 * To run a query within a React component, call `useMoonpayBuyAlertTransactionReceiptUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useMoonpayBuyAlertTransactionReceiptUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMoonpayBuyAlertTransactionReceiptUrlQuery({
 *   variables: {
 *      alertId: // value for 'alertId'
 *   },
 * });
 */
export function useMoonpayBuyAlertTransactionReceiptUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    MoonpayBuyAlertTransactionReceiptUrlQuery,
    MoonpayBuyAlertTransactionReceiptUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MoonpayBuyAlertTransactionReceiptUrlQuery, MoonpayBuyAlertTransactionReceiptUrlQueryVariables>(
    MoonpayBuyAlertTransactionReceiptUrlDocument,
    options,
  );
}
export function useMoonpayBuyAlertTransactionReceiptUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MoonpayBuyAlertTransactionReceiptUrlQuery,
    MoonpayBuyAlertTransactionReceiptUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MoonpayBuyAlertTransactionReceiptUrlQuery,
    MoonpayBuyAlertTransactionReceiptUrlQueryVariables
  >(MoonpayBuyAlertTransactionReceiptUrlDocument, options);
}
export type MoonpayBuyAlertTransactionReceiptUrlQueryHookResult = ReturnType<
  typeof useMoonpayBuyAlertTransactionReceiptUrlQuery
>;
export type MoonpayBuyAlertTransactionReceiptUrlLazyQueryHookResult = ReturnType<
  typeof useMoonpayBuyAlertTransactionReceiptUrlLazyQuery
>;
export type MoonpayBuyAlertTransactionReceiptUrlQueryResult = Apollo.QueryResult<
  MoonpayBuyAlertTransactionReceiptUrlQuery,
  MoonpayBuyAlertTransactionReceiptUrlQueryVariables
>;
export const InvestorDepositWithdrawAlertDocument = gql`
  mutation investorDepositWithdrawAlert($data: InvestorDepositWithdrawAlertInput!) {
    investorDepositWithdrawAlert(data: $data)
  }
`;
export type InvestorDepositWithdrawAlertMutationFn = Apollo.MutationFunction<
  InvestorDepositWithdrawAlertMutation,
  InvestorDepositWithdrawAlertMutationVariables
>;

/**
 * __useInvestorDepositWithdrawAlertMutation__
 *
 * To run a mutation, you first call `useInvestorDepositWithdrawAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorDepositWithdrawAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorDepositWithdrawAlertMutation, { data, loading, error }] = useInvestorDepositWithdrawAlertMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInvestorDepositWithdrawAlertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InvestorDepositWithdrawAlertMutation,
    InvestorDepositWithdrawAlertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InvestorDepositWithdrawAlertMutation, InvestorDepositWithdrawAlertMutationVariables>(
    InvestorDepositWithdrawAlertDocument,
    options,
  );
}
export type InvestorDepositWithdrawAlertMutationHookResult = ReturnType<typeof useInvestorDepositWithdrawAlertMutation>;
export type InvestorDepositWithdrawAlertMutationResult = Apollo.MutationResult<InvestorDepositWithdrawAlertMutation>;
export type InvestorDepositWithdrawAlertMutationOptions = Apollo.BaseMutationOptions<
  InvestorDepositWithdrawAlertMutation,
  InvestorDepositWithdrawAlertMutationVariables
>;
export const InvestorBuyAlertDocument = gql`
  mutation InvestorBuyAlert($query: InvestorBuyAlertInput!) {
    investorBuyAlert(query: $query)
  }
`;
export type InvestorBuyAlertMutationFn = Apollo.MutationFunction<
  InvestorBuyAlertMutation,
  InvestorBuyAlertMutationVariables
>;

/**
 * __useInvestorBuyAlertMutation__
 *
 * To run a mutation, you first call `useInvestorBuyAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorBuyAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorBuyAlertMutation, { data, loading, error }] = useInvestorBuyAlertMutation({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useInvestorBuyAlertMutation(
  baseOptions?: Apollo.MutationHookOptions<InvestorBuyAlertMutation, InvestorBuyAlertMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InvestorBuyAlertMutation, InvestorBuyAlertMutationVariables>(
    InvestorBuyAlertDocument,
    options,
  );
}
export type InvestorBuyAlertMutationHookResult = ReturnType<typeof useInvestorBuyAlertMutation>;
export type InvestorBuyAlertMutationResult = Apollo.MutationResult<InvestorBuyAlertMutation>;
export type InvestorBuyAlertMutationOptions = Apollo.BaseMutationOptions<
  InvestorBuyAlertMutation,
  InvestorBuyAlertMutationVariables
>;
export const InvestorInvoiceAlertDeleteDocument = gql`
  mutation InvestorInvoiceAlertDelete($ID: Int!) {
    investorInvoiceAlertDelete(ID: $ID)
  }
`;
export type InvestorInvoiceAlertDeleteMutationFn = Apollo.MutationFunction<
  InvestorInvoiceAlertDeleteMutation,
  InvestorInvoiceAlertDeleteMutationVariables
>;

/**
 * __useInvestorInvoiceAlertDeleteMutation__
 *
 * To run a mutation, you first call `useInvestorInvoiceAlertDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorInvoiceAlertDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorInvoiceAlertDeleteMutation, { data, loading, error }] = useInvestorInvoiceAlertDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useInvestorInvoiceAlertDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InvestorInvoiceAlertDeleteMutation,
    InvestorInvoiceAlertDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InvestorInvoiceAlertDeleteMutation, InvestorInvoiceAlertDeleteMutationVariables>(
    InvestorInvoiceAlertDeleteDocument,
    options,
  );
}
export type InvestorInvoiceAlertDeleteMutationHookResult = ReturnType<typeof useInvestorInvoiceAlertDeleteMutation>;
export type InvestorInvoiceAlertDeleteMutationResult = Apollo.MutationResult<InvestorInvoiceAlertDeleteMutation>;
export type InvestorInvoiceAlertDeleteMutationOptions = Apollo.BaseMutationOptions<
  InvestorInvoiceAlertDeleteMutation,
  InvestorInvoiceAlertDeleteMutationVariables
>;
export const VerifyTransactionFromBlockchainDocument = gql`
  mutation VerifyTransactionFromBlockchain($data: VerifyCryptoReciepeInput!) {
    verifyTransactionFromBlockchain(data: $data)
  }
`;
export type VerifyTransactionFromBlockchainMutationFn = Apollo.MutationFunction<
  VerifyTransactionFromBlockchainMutation,
  VerifyTransactionFromBlockchainMutationVariables
>;

/**
 * __useVerifyTransactionFromBlockchainMutation__
 *
 * To run a mutation, you first call `useVerifyTransactionFromBlockchainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyTransactionFromBlockchainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyTransactionFromBlockchainMutation, { data, loading, error }] = useVerifyTransactionFromBlockchainMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useVerifyTransactionFromBlockchainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyTransactionFromBlockchainMutation,
    VerifyTransactionFromBlockchainMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VerifyTransactionFromBlockchainMutation, VerifyTransactionFromBlockchainMutationVariables>(
    VerifyTransactionFromBlockchainDocument,
    options,
  );
}
export type VerifyTransactionFromBlockchainMutationHookResult = ReturnType<
  typeof useVerifyTransactionFromBlockchainMutation
>;
export type VerifyTransactionFromBlockchainMutationResult =
  Apollo.MutationResult<VerifyTransactionFromBlockchainMutation>;
export type VerifyTransactionFromBlockchainMutationOptions = Apollo.BaseMutationOptions<
  VerifyTransactionFromBlockchainMutation,
  VerifyTransactionFromBlockchainMutationVariables
>;
export const InvestorSellAlertDocument = gql`
  mutation InvestorSellAlert($data: InvestorBuyAlertInput!) {
    investorSellAlert(data: $data)
  }
`;
export type InvestorSellAlertMutationFn = Apollo.MutationFunction<
  InvestorSellAlertMutation,
  InvestorSellAlertMutationVariables
>;

/**
 * __useInvestorSellAlertMutation__
 *
 * To run a mutation, you first call `useInvestorSellAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorSellAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorSellAlertMutation, { data, loading, error }] = useInvestorSellAlertMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInvestorSellAlertMutation(
  baseOptions?: Apollo.MutationHookOptions<InvestorSellAlertMutation, InvestorSellAlertMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InvestorSellAlertMutation, InvestorSellAlertMutationVariables>(
    InvestorSellAlertDocument,
    options,
  );
}
export type InvestorSellAlertMutationHookResult = ReturnType<typeof useInvestorSellAlertMutation>;
export type InvestorSellAlertMutationResult = Apollo.MutationResult<InvestorSellAlertMutation>;
export type InvestorSellAlertMutationOptions = Apollo.BaseMutationOptions<
  InvestorSellAlertMutation,
  InvestorSellAlertMutationVariables
>;
export const SetAccessedDateForDataRoomDocument = gql`
  mutation SetAccessedDateForDataRoom($date: DateTime!, $ID: Int!) {
    setAccessedDateForDataRoom(date: $date, ID: $ID)
  }
`;
export type SetAccessedDateForDataRoomMutationFn = Apollo.MutationFunction<
  SetAccessedDateForDataRoomMutation,
  SetAccessedDateForDataRoomMutationVariables
>;

/**
 * __useSetAccessedDateForDataRoomMutation__
 *
 * To run a mutation, you first call `useSetAccessedDateForDataRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAccessedDateForDataRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAccessedDateForDataRoomMutation, { data, loading, error }] = useSetAccessedDateForDataRoomMutation({
 *   variables: {
 *      date: // value for 'date'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useSetAccessedDateForDataRoomMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetAccessedDateForDataRoomMutation,
    SetAccessedDateForDataRoomMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetAccessedDateForDataRoomMutation, SetAccessedDateForDataRoomMutationVariables>(
    SetAccessedDateForDataRoomDocument,
    options,
  );
}
export type SetAccessedDateForDataRoomMutationHookResult = ReturnType<typeof useSetAccessedDateForDataRoomMutation>;
export type SetAccessedDateForDataRoomMutationResult = Apollo.MutationResult<SetAccessedDateForDataRoomMutation>;
export type SetAccessedDateForDataRoomMutationOptions = Apollo.BaseMutationOptions<
  SetAccessedDateForDataRoomMutation,
  SetAccessedDateForDataRoomMutationVariables
>;
export const InvestorActivePropertiesDocument = gql`
  query investorActiveProperties($buyEnabledFilterInput: BuyEnabledFilterInput, $showAllProps: Boolean) {
    investorActiveProperties(buyEnabledFilterInput: $buyEnabledFilterInput, showAllProps: $showAllProps) {
      ID
      details
      picture
      title
      projectCost
      createdAt
      popularity
      isBuyButtonEnabled
      isNDAFlowEnabled
      isInvestorBuyEnabled
      meta {
        value
        key
      }
      stoDetailsInvestNowLink
      isProjectPDFPresentationEnabled
      sellSideEntity {
        ID
        title
      }
      isInvestorInvited
    }
  }
`;

/**
 * __useInvestorActivePropertiesQuery__
 *
 * To run a query within a React component, call `useInvestorActivePropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorActivePropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorActivePropertiesQuery({
 *   variables: {
 *      buyEnabledFilterInput: // value for 'buyEnabledFilterInput'
 *      showAllProps: // value for 'showAllProps'
 *   },
 * });
 */
export function useInvestorActivePropertiesQuery(
  baseOptions?: Apollo.QueryHookOptions<InvestorActivePropertiesQuery, InvestorActivePropertiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestorActivePropertiesQuery, InvestorActivePropertiesQueryVariables>(
    InvestorActivePropertiesDocument,
    options,
  );
}
export function useInvestorActivePropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestorActivePropertiesQuery, InvestorActivePropertiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestorActivePropertiesQuery, InvestorActivePropertiesQueryVariables>(
    InvestorActivePropertiesDocument,
    options,
  );
}
export type InvestorActivePropertiesQueryHookResult = ReturnType<typeof useInvestorActivePropertiesQuery>;
export type InvestorActivePropertiesLazyQueryHookResult = ReturnType<typeof useInvestorActivePropertiesLazyQuery>;
export type InvestorActivePropertiesQueryResult = Apollo.QueryResult<
  InvestorActivePropertiesQuery,
  InvestorActivePropertiesQueryVariables
>;
export const GetRevisionNumberDocument = gql`
  query getRevisionNumber {
    getRevisionNumber
  }
`;

/**
 * __useGetRevisionNumberQuery__
 *
 * To run a query within a React component, call `useGetRevisionNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRevisionNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRevisionNumberQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRevisionNumberQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRevisionNumberQuery, GetRevisionNumberQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRevisionNumberQuery, GetRevisionNumberQueryVariables>(GetRevisionNumberDocument, options);
}
export function useGetRevisionNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRevisionNumberQuery, GetRevisionNumberQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRevisionNumberQuery, GetRevisionNumberQueryVariables>(
    GetRevisionNumberDocument,
    options,
  );
}
export type GetRevisionNumberQueryHookResult = ReturnType<typeof useGetRevisionNumberQuery>;
export type GetRevisionNumberLazyQueryHookResult = ReturnType<typeof useGetRevisionNumberLazyQuery>;
export type GetRevisionNumberQueryResult = Apollo.QueryResult<GetRevisionNumberQuery, GetRevisionNumberQueryVariables>;
export const InvestorRelatedStoDocument = gql`
  query investorRelatedSto {
    investorRelatedSto {
      ID
      title
      details
      picture
    }
    investorUser {
      sto {
        ID
        title
      }
    }
  }
`;

/**
 * __useInvestorRelatedStoQuery__
 *
 * To run a query within a React component, call `useInvestorRelatedStoQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorRelatedStoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorRelatedStoQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvestorRelatedStoQuery(
  baseOptions?: Apollo.QueryHookOptions<InvestorRelatedStoQuery, InvestorRelatedStoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestorRelatedStoQuery, InvestorRelatedStoQueryVariables>(
    InvestorRelatedStoDocument,
    options,
  );
}
export function useInvestorRelatedStoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestorRelatedStoQuery, InvestorRelatedStoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestorRelatedStoQuery, InvestorRelatedStoQueryVariables>(
    InvestorRelatedStoDocument,
    options,
  );
}
export type InvestorRelatedStoQueryHookResult = ReturnType<typeof useInvestorRelatedStoQuery>;
export type InvestorRelatedStoLazyQueryHookResult = ReturnType<typeof useInvestorRelatedStoLazyQuery>;
export type InvestorRelatedStoQueryResult = Apollo.QueryResult<
  InvestorRelatedStoQuery,
  InvestorRelatedStoQueryVariables
>;
export const InvestorStoHistoryDocument = gql`
  query investorStoHistory {
    investorRelatedSto {
      ID
      title
    }
  }
`;

/**
 * __useInvestorStoHistoryQuery__
 *
 * To run a query within a React component, call `useInvestorStoHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorStoHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorStoHistoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvestorStoHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<InvestorStoHistoryQuery, InvestorStoHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestorStoHistoryQuery, InvestorStoHistoryQueryVariables>(
    InvestorStoHistoryDocument,
    options,
  );
}
export function useInvestorStoHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestorStoHistoryQuery, InvestorStoHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestorStoHistoryQuery, InvestorStoHistoryQueryVariables>(
    InvestorStoHistoryDocument,
    options,
  );
}
export type InvestorStoHistoryQueryHookResult = ReturnType<typeof useInvestorStoHistoryQuery>;
export type InvestorStoHistoryLazyQueryHookResult = ReturnType<typeof useInvestorStoHistoryLazyQuery>;
export type InvestorStoHistoryQueryResult = Apollo.QueryResult<
  InvestorStoHistoryQuery,
  InvestorStoHistoryQueryVariables
>;
export const InvestorStoDetailDocument = gql`
  query investorStoDetail($_id: Int!) {
    findSto(ID: $_id) {
      ID
      title
      details
      picture
      fullDetails
      images {
        ID
        stoID
        title
        url
        parsedClearanceLevels
        type
        countries
      }
      documents {
        ID
        stoID
        title
        url
        parsedClearanceLevels
        type
        countries
      }
      presentation {
        ID
        stoID
        title
        url
        parsedClearanceLevels
        type
      }
      parsedSettings {
        investorCategories {
          value
          label
        }
      }
      stolinkfull
      logo
      registrationText
      meta {
        stoID
        key
        value
        order
        display
        displayClosedProp
        isManagedStoTableColumn
        type
      }
      isBuyButtonEnabled
      isNDAFlowEnabled
      stoDetailsInvestNowLink
      projectAddress
      legalDetails
      isProjectPDFPresentationEnabled
      sellSideEntity {
        ID
        title
      }
    }
  }
`;

/**
 * __useInvestorStoDetailQuery__
 *
 * To run a query within a React component, call `useInvestorStoDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorStoDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorStoDetailQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useInvestorStoDetailQuery(
  baseOptions: Apollo.QueryHookOptions<InvestorStoDetailQuery, InvestorStoDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestorStoDetailQuery, InvestorStoDetailQueryVariables>(InvestorStoDetailDocument, options);
}
export function useInvestorStoDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestorStoDetailQuery, InvestorStoDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestorStoDetailQuery, InvestorStoDetailQueryVariables>(
    InvestorStoDetailDocument,
    options,
  );
}
export type InvestorStoDetailQueryHookResult = ReturnType<typeof useInvestorStoDetailQuery>;
export type InvestorStoDetailLazyQueryHookResult = ReturnType<typeof useInvestorStoDetailLazyQuery>;
export type InvestorStoDetailQueryResult = Apollo.QueryResult<InvestorStoDetailQuery, InvestorStoDetailQueryVariables>;
export const InvestorWalletDocument = gql`
  query investorWallet($_id: Int!) {
    investorBalances(stoID: $_id) {
      ID
      stoID
      investorID
      currencyID
      currency {
        ID
        currency
        abbreviation
        symbol
        isBlockchainBased
      }
      amount
    }
    investorPaymentChannels(stoID: $_id) {
      ID
      stoID
      title
      details
      currencyID
      currency {
        ID
        currency
        abbreviation
        symbol
        isBlockchainBased
        Address
        cryptoReceivingAddress
        blockchainID
      }
      currencyToConvert {
        ID
        currency
        abbreviation
        symbol
        isBlockchainBased
        Address
        cryptoReceivingAddress
      }
      conversionEnabled
      conversionRate
      canWithdrawFunds
      channelType
      pintProcessorTitle
      isActive
      depositAlertDisplayParams {
        canDisplaySwift
        canDisplayBankAccount
        canDisplayBankName
        canDisplayDetails
        canDisplayCardBody
      }
      usages
    }
    investorDepositHistory(stoID: $_id) {
      ID
      isApproved
      stoID
      dateReceived
      dateApproved
      amount
      details
      currencyID
      currency {
        ID
        currency
        abbreviation
        symbol
        isBlockchainBased
      }
      transactionHash
      buyAlert {
        shareType {
          blockchainProtocolID
        }
      }
    }
  }
`;

/**
 * __useInvestorWalletQuery__
 *
 * To run a query within a React component, call `useInvestorWalletQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorWalletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorWalletQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useInvestorWalletQuery(
  baseOptions: Apollo.QueryHookOptions<InvestorWalletQuery, InvestorWalletQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestorWalletQuery, InvestorWalletQueryVariables>(InvestorWalletDocument, options);
}
export function useInvestorWalletLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestorWalletQuery, InvestorWalletQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestorWalletQuery, InvestorWalletQueryVariables>(InvestorWalletDocument, options);
}
export type InvestorWalletQueryHookResult = ReturnType<typeof useInvestorWalletQuery>;
export type InvestorWalletLazyQueryHookResult = ReturnType<typeof useInvestorWalletLazyQuery>;
export type InvestorWalletQueryResult = Apollo.QueryResult<InvestorWalletQuery, InvestorWalletQueryVariables>;
export const InvestorPortfolioDocument = gql`
  query investorPortfolio($_id: Int, $status: [BuyAlertStatus!]!) {
    investorShares(investorID: $_id) {
      sharesHistoryID
      investorID
      shares
      isBlockchainAuthorized
      isBlockchainFrozen
      shareType {
        ID
        title
        stoID
        totalShares
        companyShares
        nominalValue
        custodianShares
        isBlockchain
        premiumValue
        currencyID
        sellToCompany
        sellValue
        isShareNosApplicable
        isCertificateNosApplicable
        currency {
          ID
          currency
          abbreviation
          symbol
          isBlockchainBased
        }
        minimumSharesToBuyByInvestor
        availableShare
        totalPrice
        blockchaindecimals
        blockchainProtocolID
        reduceSharesForPurchase
        walletCustodyType
        isInvestorTradable
      }
      shareTypeID
      stoID
      ID
    }
    investorBuyAlerts(status: $status) {
      ID
      status
      stoID
      shares
      shareTypeID
      isHiddenForInvestor
      shareType {
        ID
        title
        stoID
        totalShares
        companyShares
        nominalValue
        custodianShares
        isBlockchain
        premiumValue
        currencyID
        minimumSharesToBuyByInvestor
        sellToCompany
        sellValue
        isShareNosApplicable
        isCertificateNosApplicable
        currency {
          ID
          currency
          abbreviation
          symbol
          isBlockchainBased
        }
        availableShare
        totalPrice
        blockchaindecimals
        blockchainProtocolID
        reduceSharesForPurchase
        walletCustodyType
        maximumSharesToBuyByInvestor
        isInvestorTradable
      }
      channel {
        currency {
          ID
          abbreviation
          symbol
          isBlockchainBased
          currency
        }
        currencyToConvert {
          ID
          abbreviation
          symbol
          isBlockchainBased
          currency
        }
      }
      date
      isBuySharesSigned
      purchasePriceOffered
      fromCurrencyID
      isSellRequest
    }
  }
`;

/**
 * __useInvestorPortfolioQuery__
 *
 * To run a query within a React component, call `useInvestorPortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorPortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorPortfolioQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useInvestorPortfolioQuery(
  baseOptions: Apollo.QueryHookOptions<InvestorPortfolioQuery, InvestorPortfolioQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestorPortfolioQuery, InvestorPortfolioQueryVariables>(InvestorPortfolioDocument, options);
}
export function useInvestorPortfolioLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestorPortfolioQuery, InvestorPortfolioQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestorPortfolioQuery, InvestorPortfolioQueryVariables>(
    InvestorPortfolioDocument,
    options,
  );
}
export type InvestorPortfolioQueryHookResult = ReturnType<typeof useInvestorPortfolioQuery>;
export type InvestorPortfolioLazyQueryHookResult = ReturnType<typeof useInvestorPortfolioLazyQuery>;
export type InvestorPortfolioQueryResult = Apollo.QueryResult<InvestorPortfolioQuery, InvestorPortfolioQueryVariables>;
export const InvestorInvoiceAlertsDocument = gql`
  query InvestorInvoiceAlerts($includeAllTypes: Boolean) {
    investorInvoiceAlerts(includeAllTypes: $includeAllTypes) {
      ID
      buyAlert {
        ID
        entityID
      }
      buyAlertID
      investorID
      shareTypeID
      shareType {
        ID
        title
        stoID
        totalShares
        companyShares
        ethereumBlockchainPublicAddress
        custodianShares
        nominalValue
        isBlockchain
        premiumValue
        currencyID
        currency {
          ID
          currency
          abbreviation
          symbol
          isBlockchainBased
          blockchainID
          isNative
          cryptoReceivingAddress
          Address
        }
        minimumSharesToBuyByInvestor
        sellToCompany
        sellValue
        isShareNosApplicable
        isCertificateNosApplicable
        channelIDForAutoPayments
        availableShare
        totalPrice
        blockchaindecimals
        blockchainProtocolID
        reduceSharesForPurchase
        walletCustodyType
      }
      paymentChannel {
        title
        details
        currencyID
        currency {
          ID
          symbol
          abbreviation
          currency
          isBlockchainBased
        }
        canWithdrawFunds
      }
      stoID
      ID
      shares
      status
      amountToPay
      isBlockchain
      dateCreated
      invoiceDescription
      dateUpdated
      investorWallet
      paymentChannelID
      resolver
      resolveID
      buyer
      seller
    }
  }
`;

/**
 * __useInvestorInvoiceAlertsQuery__
 *
 * To run a query within a React component, call `useInvestorInvoiceAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorInvoiceAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorInvoiceAlertsQuery({
 *   variables: {
 *      includeAllTypes: // value for 'includeAllTypes'
 *   },
 * });
 */
export function useInvestorInvoiceAlertsQuery(
  baseOptions?: Apollo.QueryHookOptions<InvestorInvoiceAlertsQuery, InvestorInvoiceAlertsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestorInvoiceAlertsQuery, InvestorInvoiceAlertsQueryVariables>(
    InvestorInvoiceAlertsDocument,
    options,
  );
}
export function useInvestorInvoiceAlertsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestorInvoiceAlertsQuery, InvestorInvoiceAlertsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestorInvoiceAlertsQuery, InvestorInvoiceAlertsQueryVariables>(
    InvestorInvoiceAlertsDocument,
    options,
  );
}
export type InvestorInvoiceAlertsQueryHookResult = ReturnType<typeof useInvestorInvoiceAlertsQuery>;
export type InvestorInvoiceAlertsLazyQueryHookResult = ReturnType<typeof useInvestorInvoiceAlertsLazyQuery>;
export type InvestorInvoiceAlertsQueryResult = Apollo.QueryResult<
  InvestorInvoiceAlertsQuery,
  InvestorInvoiceAlertsQueryVariables
>;
export const GetInvestorInvoicesDocument = gql`
  query getInvestorInvoices($isHistorical: Boolean, $isOngoing: Boolean, $includeAllTypes: Boolean) {
    getInvestorInvoices(isHistorical: $isHistorical, isOngoing: $isOngoing, includeAllTypes: $includeAllTypes) {
      ID
      buyAlert {
        ID
        entityID
      }
      buyAlertID
      investorID
      shareTypeID
      shareType {
        ID
        title
        stoID
        totalShares
        companyShares
        ethereumBlockchainPublicAddress
        custodianShares
        nominalValue
        isBlockchain
        premiumValue
        currencyID
        currency {
          ID
          currency
          abbreviation
          symbol
          isBlockchainBased
          blockchainID
          isNative
          cryptoReceivingAddress
          Address
        }
        minimumSharesToBuyByInvestor
        sellToCompany
        sellValue
        isShareNosApplicable
        isCertificateNosApplicable
        channelIDForAutoPayments
        availableShare
        totalPrice
        blockchaindecimals
        blockchainProtocolID
        reduceSharesForPurchase
        walletCustodyType
      }
      paymentChannel {
        title
        details
        currencyID
        currency {
          ID
          symbol
          abbreviation
          currency
          isBlockchainBased
        }
        canWithdrawFunds
      }
      stoID
      ID
      shares
      status
      amountToPay
      isBlockchain
      dateCreated
      invoiceDescription
      dateUpdated
      investorWallet
      paymentChannelID
      resolver
      resolveID
      seller
      buyer
    }
  }
`;

/**
 * __useGetInvestorInvoicesQuery__
 *
 * To run a query within a React component, call `useGetInvestorInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestorInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestorInvoicesQuery({
 *   variables: {
 *      isHistorical: // value for 'isHistorical'
 *      isOngoing: // value for 'isOngoing'
 *      includeAllTypes: // value for 'includeAllTypes'
 *   },
 * });
 */
export function useGetInvestorInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetInvestorInvoicesQuery, GetInvestorInvoicesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvestorInvoicesQuery, GetInvestorInvoicesQueryVariables>(
    GetInvestorInvoicesDocument,
    options,
  );
}
export function useGetInvestorInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInvestorInvoicesQuery, GetInvestorInvoicesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvestorInvoicesQuery, GetInvestorInvoicesQueryVariables>(
    GetInvestorInvoicesDocument,
    options,
  );
}
export type GetInvestorInvoicesQueryHookResult = ReturnType<typeof useGetInvestorInvoicesQuery>;
export type GetInvestorInvoicesLazyQueryHookResult = ReturnType<typeof useGetInvestorInvoicesLazyQuery>;
export type GetInvestorInvoicesQueryResult = Apollo.QueryResult<
  GetInvestorInvoicesQuery,
  GetInvestorInvoicesQueryVariables
>;
export const InvestorBuyAlertsDocument = gql`
  query investorBuyAlerts($status: [BuyAlertStatus!]!) {
    investorBuyAlerts(status: $status) {
      ID
      entityID
      status
      stoID
      shares
      shareTypeID
      isHiddenForInvestor
      shareType {
        ID
        title
        stoID
        totalShares
        companyShares
        nominalValue
        custodianShares
        isBlockchain
        premiumValue
        currencyID
        minimumSharesToBuyByInvestor
        sellToCompany
        sellValue
        isShareNosApplicable
        isCertificateNosApplicable
        currency {
          ID
          currency
          abbreviation
          symbol
          isBlockchainBased
          blockchainID
          isNative
          cryptoReceivingAddress
        }
        availableShare
        totalPrice
        blockchaindecimals
        blockchainProtocolID
        reduceSharesForPurchase
        walletCustodyType
        maximumSharesToBuyByInvestor
        isInvestorTradable
      }
      date
      isBuySharesSigned
      purchasePriceOffered
      fromCurrencyID
      isSellRequest
    }
  }
`;

/**
 * __useInvestorBuyAlertsQuery__
 *
 * To run a query within a React component, call `useInvestorBuyAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorBuyAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorBuyAlertsQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useInvestorBuyAlertsQuery(
  baseOptions: Apollo.QueryHookOptions<InvestorBuyAlertsQuery, InvestorBuyAlertsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestorBuyAlertsQuery, InvestorBuyAlertsQueryVariables>(InvestorBuyAlertsDocument, options);
}
export function useInvestorBuyAlertsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestorBuyAlertsQuery, InvestorBuyAlertsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestorBuyAlertsQuery, InvestorBuyAlertsQueryVariables>(
    InvestorBuyAlertsDocument,
    options,
  );
}
export type InvestorBuyAlertsQueryHookResult = ReturnType<typeof useInvestorBuyAlertsQuery>;
export type InvestorBuyAlertsLazyQueryHookResult = ReturnType<typeof useInvestorBuyAlertsLazyQuery>;
export type InvestorBuyAlertsQueryResult = Apollo.QueryResult<InvestorBuyAlertsQuery, InvestorBuyAlertsQueryVariables>;
export const InvestorBuyPropertyDocument = gql`
  query investorBuyProperty($_id: Int!) {
    investorDetailProperty(stoID: $_id) {
      ID
      title
      details
      picture
      fullDetails
      images {
        ID
        title
        url
      }
      documents {
        ID
        title
        url
      }
    }
    investorBalances(stoID: $_id) {
      ID
      stoID
      investorID
      currencyID
      currency {
        ID
        currency
        abbreviation
        symbol
        isBlockchainBased
      }
      amount
    }
    findShareTypes(stoID: $_id) {
      ID
      stoID
      title
      totalShares
      companyShares
      nominalValue
      isBlockchain
      premiumValue
      custodianShares
      availableShare
      totalPrice
      currencyID
      sellToCompany
      sellValue
      isShareNosApplicable
      isCertificateNosApplicable
      isEnabled
      currency {
        ID
        abbreviation
        currency
        symbol
        isBlockchainBased
      }
      minimumSharesToBuyByInvestor
      channelIDForAutoPayments
      blockchaindecimals
      blockchainProtocolID
      reduceSharesForPurchase
      maximumSharesToBuyByInvestor
      walletCustodyType
      assetTag
      ethereumContractAddress
      shareOwnershipLimit
    }
    getInvestorConversionPaymentChannels(stoID: $_id) {
      ID
      stoID
      title
      channelType
      pintProcessorTitle
      conversionEnabled
      conversionRate
      currency {
        ID
        abbreviation
        symbol
        isBlockchainBased
        currency
      }
      currencyToConvert {
        ID
        abbreviation
        symbol
        isBlockchainBased
        currency
      }
      usages
    }
  }
`;

/**
 * __useInvestorBuyPropertyQuery__
 *
 * To run a query within a React component, call `useInvestorBuyPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorBuyPropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorBuyPropertyQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useInvestorBuyPropertyQuery(
  baseOptions: Apollo.QueryHookOptions<InvestorBuyPropertyQuery, InvestorBuyPropertyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestorBuyPropertyQuery, InvestorBuyPropertyQueryVariables>(
    InvestorBuyPropertyDocument,
    options,
  );
}
export function useInvestorBuyPropertyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestorBuyPropertyQuery, InvestorBuyPropertyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestorBuyPropertyQuery, InvestorBuyPropertyQueryVariables>(
    InvestorBuyPropertyDocument,
    options,
  );
}
export type InvestorBuyPropertyQueryHookResult = ReturnType<typeof useInvestorBuyPropertyQuery>;
export type InvestorBuyPropertyLazyQueryHookResult = ReturnType<typeof useInvestorBuyPropertyLazyQuery>;
export type InvestorBuyPropertyQueryResult = Apollo.QueryResult<
  InvestorBuyPropertyQuery,
  InvestorBuyPropertyQueryVariables
>;
export const FindInvestorDividendPayoutsDocument = gql`
  query FindInvestorDividendPayouts {
    findInvestorDividendPayouts {
      ID
      investorID
      payoutID
      amount
      investorShares
      lastUpdatedAt
      status
    }
  }
`;

/**
 * __useFindInvestorDividendPayoutsQuery__
 *
 * To run a query within a React component, call `useFindInvestorDividendPayoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindInvestorDividendPayoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindInvestorDividendPayoutsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindInvestorDividendPayoutsQuery(
  baseOptions?: Apollo.QueryHookOptions<FindInvestorDividendPayoutsQuery, FindInvestorDividendPayoutsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindInvestorDividendPayoutsQuery, FindInvestorDividendPayoutsQueryVariables>(
    FindInvestorDividendPayoutsDocument,
    options,
  );
}
export function useFindInvestorDividendPayoutsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindInvestorDividendPayoutsQuery,
    FindInvestorDividendPayoutsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindInvestorDividendPayoutsQuery, FindInvestorDividendPayoutsQueryVariables>(
    FindInvestorDividendPayoutsDocument,
    options,
  );
}
export type FindInvestorDividendPayoutsQueryHookResult = ReturnType<typeof useFindInvestorDividendPayoutsQuery>;
export type FindInvestorDividendPayoutsLazyQueryHookResult = ReturnType<typeof useFindInvestorDividendPayoutsLazyQuery>;
export type FindInvestorDividendPayoutsQueryResult = Apollo.QueryResult<
  FindInvestorDividendPayoutsQuery,
  FindInvestorDividendPayoutsQueryVariables
>;
export const FindShareHistoricalValuesDocument = gql`
  query FindShareHistoricalValues($shareTypeID: Int!) {
    findShareHistoricalValues(shareTypeID: $shareTypeID) {
      ID
      shareTypeID
      stoID
      premiumValue
      dateOfChange
    }
  }
`;

/**
 * __useFindShareHistoricalValuesQuery__
 *
 * To run a query within a React component, call `useFindShareHistoricalValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindShareHistoricalValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindShareHistoricalValuesQuery({
 *   variables: {
 *      shareTypeID: // value for 'shareTypeID'
 *   },
 * });
 */
export function useFindShareHistoricalValuesQuery(
  baseOptions: Apollo.QueryHookOptions<FindShareHistoricalValuesQuery, FindShareHistoricalValuesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindShareHistoricalValuesQuery, FindShareHistoricalValuesQueryVariables>(
    FindShareHistoricalValuesDocument,
    options,
  );
}
export function useFindShareHistoricalValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindShareHistoricalValuesQuery, FindShareHistoricalValuesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindShareHistoricalValuesQuery, FindShareHistoricalValuesQueryVariables>(
    FindShareHistoricalValuesDocument,
    options,
  );
}
export type FindShareHistoricalValuesQueryHookResult = ReturnType<typeof useFindShareHistoricalValuesQuery>;
export type FindShareHistoricalValuesLazyQueryHookResult = ReturnType<typeof useFindShareHistoricalValuesLazyQuery>;
export type FindShareHistoricalValuesQueryResult = Apollo.QueryResult<
  FindShareHistoricalValuesQuery,
  FindShareHistoricalValuesQueryVariables
>;
export const InvestorDetailPropertyDocument = gql`
  query investorDetailProperty($_id: Int!) {
    investorDetailProperty(stoID: $_id) {
      ID
      title
      details
      picture
      fullDetails
      images {
        ID
        title
        url
      }
      documents {
        ID
        title
        url
      }
    }
  }
`;

/**
 * __useInvestorDetailPropertyQuery__
 *
 * To run a query within a React component, call `useInvestorDetailPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorDetailPropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorDetailPropertyQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useInvestorDetailPropertyQuery(
  baseOptions: Apollo.QueryHookOptions<InvestorDetailPropertyQuery, InvestorDetailPropertyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestorDetailPropertyQuery, InvestorDetailPropertyQueryVariables>(
    InvestorDetailPropertyDocument,
    options,
  );
}
export function useInvestorDetailPropertyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestorDetailPropertyQuery, InvestorDetailPropertyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestorDetailPropertyQuery, InvestorDetailPropertyQueryVariables>(
    InvestorDetailPropertyDocument,
    options,
  );
}
export type InvestorDetailPropertyQueryHookResult = ReturnType<typeof useInvestorDetailPropertyQuery>;
export type InvestorDetailPropertyLazyQueryHookResult = ReturnType<typeof useInvestorDetailPropertyLazyQuery>;
export type InvestorDetailPropertyQueryResult = Apollo.QueryResult<
  InvestorDetailPropertyQuery,
  InvestorDetailPropertyQueryVariables
>;
export const InvestorInvoiceAlertDocument = gql`
  query InvestorInvoiceAlert($id: Int!) {
    investorInvoiceAlert(ID: $id) {
      ID
      stoID
      buyAlertID
      investorID
      shareTypeID
      shareType {
        ethereumBlockchainPublicAddress
        channelIDForAutoPayments
        premiumValue
        currencyID
        currency {
          ID
          currency
          abbreviation
          symbol
          isBlockchainBased
          blockchainID
          isNative
          cryptoReceivingAddress
          Address
        }
        sellValue
        sellToCompany
        title
        blockchaindecimals
        blockchainProtocolID
        reduceSharesForPurchase
        walletCustodyType
      }
      shares
      amountToPay
      status
      isBlockchain
      dateCreated
      dateUpdated
      invoiceDescription
      investorWallet
      paymentChannelID
      paymentChannel {
        title
        details
        currencyID
        currency {
          ID
          currency
          abbreviation
          symbol
          isBlockchainBased
          blockchainID
          isNative
          cryptoReceivingAddress
          Address
        }
        currencyToConvert {
          ID
          currency
          abbreviation
          symbol
          isBlockchainBased
          blockchainID
          isNative
          cryptoReceivingAddress
          Address
        }
        conversionEnabled
        conversionRate
      }
      buyAlert {
        ID
        entityID
      }
      resolver
      resolveID
      seller
      buyer
    }
  }
`;

/**
 * __useInvestorInvoiceAlertQuery__
 *
 * To run a query within a React component, call `useInvestorInvoiceAlertQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorInvoiceAlertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorInvoiceAlertQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvestorInvoiceAlertQuery(
  baseOptions: Apollo.QueryHookOptions<InvestorInvoiceAlertQuery, InvestorInvoiceAlertQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestorInvoiceAlertQuery, InvestorInvoiceAlertQueryVariables>(
    InvestorInvoiceAlertDocument,
    options,
  );
}
export function useInvestorInvoiceAlertLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestorInvoiceAlertQuery, InvestorInvoiceAlertQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestorInvoiceAlertQuery, InvestorInvoiceAlertQueryVariables>(
    InvestorInvoiceAlertDocument,
    options,
  );
}
export type InvestorInvoiceAlertQueryHookResult = ReturnType<typeof useInvestorInvoiceAlertQuery>;
export type InvestorInvoiceAlertLazyQueryHookResult = ReturnType<typeof useInvestorInvoiceAlertLazyQuery>;
export type InvestorInvoiceAlertQueryResult = Apollo.QueryResult<
  InvestorInvoiceAlertQuery,
  InvestorInvoiceAlertQueryVariables
>;
export const GetAllInvestorPaymentChannelsDocument = gql`
  query getAllInvestorPaymentChannels {
    getAllInvestorPaymentChannels {
      ID
      stoID
      title
      details
      currencyID
      currency {
        ID
        currency
        abbreviation
        symbol
        isBlockchainBased
        Address
        cryptoReceivingAddress
        blockchainID
      }
      currencyToConvert {
        ID
        currency
        abbreviation
        symbol
        isBlockchainBased
        Address
        cryptoReceivingAddress
      }
      conversionEnabled
      conversionRate
      canWithdrawFunds
      channelType
      pintProcessorTitle
      isActive
      depositAlertDisplayParams {
        canDisplaySwift
        canDisplayBankAccount
        canDisplayBankName
        canDisplayDetails
        canDisplayCardBody
      }
      usages
    }
  }
`;

/**
 * __useGetAllInvestorPaymentChannelsQuery__
 *
 * To run a query within a React component, call `useGetAllInvestorPaymentChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInvestorPaymentChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInvestorPaymentChannelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllInvestorPaymentChannelsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllInvestorPaymentChannelsQuery,
    GetAllInvestorPaymentChannelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllInvestorPaymentChannelsQuery, GetAllInvestorPaymentChannelsQueryVariables>(
    GetAllInvestorPaymentChannelsDocument,
    options,
  );
}
export function useGetAllInvestorPaymentChannelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllInvestorPaymentChannelsQuery,
    GetAllInvestorPaymentChannelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllInvestorPaymentChannelsQuery, GetAllInvestorPaymentChannelsQueryVariables>(
    GetAllInvestorPaymentChannelsDocument,
    options,
  );
}
export type GetAllInvestorPaymentChannelsQueryHookResult = ReturnType<typeof useGetAllInvestorPaymentChannelsQuery>;
export type GetAllInvestorPaymentChannelsLazyQueryHookResult = ReturnType<
  typeof useGetAllInvestorPaymentChannelsLazyQuery
>;
export type GetAllInvestorPaymentChannelsQueryResult = Apollo.QueryResult<
  GetAllInvestorPaymentChannelsQuery,
  GetAllInvestorPaymentChannelsQueryVariables
>;
export const GetSupportedPaymentChannelsDocument = gql`
  query getSupportedPaymentChannels($invoiceID: Int!, $invoiceResolver: DpayResolver) {
    getSupportedPaymentChannels(invoiceID: $invoiceID, invoiceResolver: $invoiceResolver) {
      ID
      stoID
      title
      details
      currencyID
      currency {
        ID
        currency
        abbreviation
        symbol
        isBlockchainBased
        Address
        cryptoReceivingAddress
        blockchainID
      }
      currencyToConvert {
        ID
        currency
        abbreviation
        symbol
        isBlockchainBased
        Address
        cryptoReceivingAddress
      }
      conversionEnabled
      conversionRate
      canWithdrawFunds
      channelType
      pintProcessorTitle
      isActive
      depositAlertDisplayParams {
        canDisplaySwift
        canDisplayBankAccount
        canDisplayBankName
        canDisplayDetails
      }
    }
  }
`;

/**
 * __useGetSupportedPaymentChannelsQuery__
 *
 * To run a query within a React component, call `useGetSupportedPaymentChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportedPaymentChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportedPaymentChannelsQuery({
 *   variables: {
 *      invoiceID: // value for 'invoiceID'
 *      invoiceResolver: // value for 'invoiceResolver'
 *   },
 * });
 */
export function useGetSupportedPaymentChannelsQuery(
  baseOptions: Apollo.QueryHookOptions<GetSupportedPaymentChannelsQuery, GetSupportedPaymentChannelsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSupportedPaymentChannelsQuery, GetSupportedPaymentChannelsQueryVariables>(
    GetSupportedPaymentChannelsDocument,
    options,
  );
}
export function useGetSupportedPaymentChannelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSupportedPaymentChannelsQuery,
    GetSupportedPaymentChannelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSupportedPaymentChannelsQuery, GetSupportedPaymentChannelsQueryVariables>(
    GetSupportedPaymentChannelsDocument,
    options,
  );
}
export type GetSupportedPaymentChannelsQueryHookResult = ReturnType<typeof useGetSupportedPaymentChannelsQuery>;
export type GetSupportedPaymentChannelsLazyQueryHookResult = ReturnType<typeof useGetSupportedPaymentChannelsLazyQuery>;
export type GetSupportedPaymentChannelsQueryResult = Apollo.QueryResult<
  GetSupportedPaymentChannelsQuery,
  GetSupportedPaymentChannelsQueryVariables
>;
export const CreateDpayAccountInvestorDocument = gql`
  mutation createDpayAccountInvestor($paymentProcessor: PaymentProcessor!) {
    createDpayAccountInvestor(paymentProcessor: $paymentProcessor)
  }
`;
export type CreateDpayAccountInvestorMutationFn = Apollo.MutationFunction<
  CreateDpayAccountInvestorMutation,
  CreateDpayAccountInvestorMutationVariables
>;

/**
 * __useCreateDpayAccountInvestorMutation__
 *
 * To run a mutation, you first call `useCreateDpayAccountInvestorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDpayAccountInvestorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDpayAccountInvestorMutation, { data, loading, error }] = useCreateDpayAccountInvestorMutation({
 *   variables: {
 *      paymentProcessor: // value for 'paymentProcessor'
 *   },
 * });
 */
export function useCreateDpayAccountInvestorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDpayAccountInvestorMutation,
    CreateDpayAccountInvestorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDpayAccountInvestorMutation, CreateDpayAccountInvestorMutationVariables>(
    CreateDpayAccountInvestorDocument,
    options,
  );
}
export type CreateDpayAccountInvestorMutationHookResult = ReturnType<typeof useCreateDpayAccountInvestorMutation>;
export type CreateDpayAccountInvestorMutationResult = Apollo.MutationResult<CreateDpayAccountInvestorMutation>;
export type CreateDpayAccountInvestorMutationOptions = Apollo.BaseMutationOptions<
  CreateDpayAccountInvestorMutation,
  CreateDpayAccountInvestorMutationVariables
>;
export const RefreshInvestorBalanceDocument = gql`
  mutation refreshInvestorBalance($paymentProcessor: PaymentProcessor) {
    refreshInvestorBalance(paymentProcessor: $paymentProcessor)
  }
`;
export type RefreshInvestorBalanceMutationFn = Apollo.MutationFunction<
  RefreshInvestorBalanceMutation,
  RefreshInvestorBalanceMutationVariables
>;

/**
 * __useRefreshInvestorBalanceMutation__
 *
 * To run a mutation, you first call `useRefreshInvestorBalanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshInvestorBalanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshInvestorBalanceMutation, { data, loading, error }] = useRefreshInvestorBalanceMutation({
 *   variables: {
 *      paymentProcessor: // value for 'paymentProcessor'
 *   },
 * });
 */
export function useRefreshInvestorBalanceMutation(
  baseOptions?: Apollo.MutationHookOptions<RefreshInvestorBalanceMutation, RefreshInvestorBalanceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RefreshInvestorBalanceMutation, RefreshInvestorBalanceMutationVariables>(
    RefreshInvestorBalanceDocument,
    options,
  );
}
export type RefreshInvestorBalanceMutationHookResult = ReturnType<typeof useRefreshInvestorBalanceMutation>;
export type RefreshInvestorBalanceMutationResult = Apollo.MutationResult<RefreshInvestorBalanceMutation>;
export type RefreshInvestorBalanceMutationOptions = Apollo.BaseMutationOptions<
  RefreshInvestorBalanceMutation,
  RefreshInvestorBalanceMutationVariables
>;
export const AddTopUpSourceIdDocument = gql`
  mutation addTopUpSourceId($topUpSourceId: String!) {
    addTopUpSourceId(topUpSourceId: $topUpSourceId)
  }
`;
export type AddTopUpSourceIdMutationFn = Apollo.MutationFunction<
  AddTopUpSourceIdMutation,
  AddTopUpSourceIdMutationVariables
>;

/**
 * __useAddTopUpSourceIdMutation__
 *
 * To run a mutation, you first call `useAddTopUpSourceIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTopUpSourceIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTopUpSourceIdMutation, { data, loading, error }] = useAddTopUpSourceIdMutation({
 *   variables: {
 *      topUpSourceId: // value for 'topUpSourceId'
 *   },
 * });
 */
export function useAddTopUpSourceIdMutation(
  baseOptions?: Apollo.MutationHookOptions<AddTopUpSourceIdMutation, AddTopUpSourceIdMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTopUpSourceIdMutation, AddTopUpSourceIdMutationVariables>(
    AddTopUpSourceIdDocument,
    options,
  );
}
export type AddTopUpSourceIdMutationHookResult = ReturnType<typeof useAddTopUpSourceIdMutation>;
export type AddTopUpSourceIdMutationResult = Apollo.MutationResult<AddTopUpSourceIdMutation>;
export type AddTopUpSourceIdMutationOptions = Apollo.BaseMutationOptions<
  AddTopUpSourceIdMutation,
  AddTopUpSourceIdMutationVariables
>;
export const TopUpInvestorBalanceDocument = gql`
  mutation topUpInvestorBalance($amount: Float!) {
    topUpInvestorBalance(amount: $amount)
  }
`;
export type TopUpInvestorBalanceMutationFn = Apollo.MutationFunction<
  TopUpInvestorBalanceMutation,
  TopUpInvestorBalanceMutationVariables
>;

/**
 * __useTopUpInvestorBalanceMutation__
 *
 * To run a mutation, you first call `useTopUpInvestorBalanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTopUpInvestorBalanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [topUpInvestorBalanceMutation, { data, loading, error }] = useTopUpInvestorBalanceMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useTopUpInvestorBalanceMutation(
  baseOptions?: Apollo.MutationHookOptions<TopUpInvestorBalanceMutation, TopUpInvestorBalanceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TopUpInvestorBalanceMutation, TopUpInvestorBalanceMutationVariables>(
    TopUpInvestorBalanceDocument,
    options,
  );
}
export type TopUpInvestorBalanceMutationHookResult = ReturnType<typeof useTopUpInvestorBalanceMutation>;
export type TopUpInvestorBalanceMutationResult = Apollo.MutationResult<TopUpInvestorBalanceMutation>;
export type TopUpInvestorBalanceMutationOptions = Apollo.BaseMutationOptions<
  TopUpInvestorBalanceMutation,
  TopUpInvestorBalanceMutationVariables
>;
export const PayInvoiceDocument = gql`
  mutation payInvoice($invoiceID: Int!, $data: XenditPayInvoiceInput, $redirectUrl: String) {
    payInvoice(invoiceID: $invoiceID, data: $data, redirectUrl: $redirectUrl)
  }
`;
export type PayInvoiceMutationFn = Apollo.MutationFunction<PayInvoiceMutation, PayInvoiceMutationVariables>;

/**
 * __usePayInvoiceMutation__
 *
 * To run a mutation, you first call `usePayInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payInvoiceMutation, { data, loading, error }] = usePayInvoiceMutation({
 *   variables: {
 *      invoiceID: // value for 'invoiceID'
 *      data: // value for 'data'
 *      redirectUrl: // value for 'redirectUrl'
 *   },
 * });
 */
export function usePayInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<PayInvoiceMutation, PayInvoiceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PayInvoiceMutation, PayInvoiceMutationVariables>(PayInvoiceDocument, options);
}
export type PayInvoiceMutationHookResult = ReturnType<typeof usePayInvoiceMutation>;
export type PayInvoiceMutationResult = Apollo.MutationResult<PayInvoiceMutation>;
export type PayInvoiceMutationOptions = Apollo.BaseMutationOptions<PayInvoiceMutation, PayInvoiceMutationVariables>;
export const GetDpayEntityInvestorDocument = gql`
  query getDpayEntityInvestor($paymentProcessor: PaymentProcessor) {
    getDpayEntityInvestor(paymentProcessor: $paymentProcessor) {
      ID
      paymentProcessor
      userType
      investorID
      paymentAccountID
      token
      refreshToken
      tokenExpiryDate
      balance
      topUpSourceId
    }
  }
`;

/**
 * __useGetDpayEntityInvestorQuery__
 *
 * To run a query within a React component, call `useGetDpayEntityInvestorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDpayEntityInvestorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDpayEntityInvestorQuery({
 *   variables: {
 *      paymentProcessor: // value for 'paymentProcessor'
 *   },
 * });
 */
export function useGetDpayEntityInvestorQuery(
  baseOptions?: Apollo.QueryHookOptions<GetDpayEntityInvestorQuery, GetDpayEntityInvestorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDpayEntityInvestorQuery, GetDpayEntityInvestorQueryVariables>(
    GetDpayEntityInvestorDocument,
    options,
  );
}
export function useGetDpayEntityInvestorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDpayEntityInvestorQuery, GetDpayEntityInvestorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDpayEntityInvestorQuery, GetDpayEntityInvestorQueryVariables>(
    GetDpayEntityInvestorDocument,
    options,
  );
}
export type GetDpayEntityInvestorQueryHookResult = ReturnType<typeof useGetDpayEntityInvestorQuery>;
export type GetDpayEntityInvestorLazyQueryHookResult = ReturnType<typeof useGetDpayEntityInvestorLazyQuery>;
export type GetDpayEntityInvestorQueryResult = Apollo.QueryResult<
  GetDpayEntityInvestorQuery,
  GetDpayEntityInvestorQueryVariables
>;
export const GenerateCardJwtDocument = gql`
  query generateCardJwt {
    generateCardJwt
  }
`;

/**
 * __useGenerateCardJwtQuery__
 *
 * To run a query within a React component, call `useGenerateCardJwtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateCardJwtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateCardJwtQuery({
 *   variables: {
 *   },
 * });
 */
export function useGenerateCardJwtQuery(
  baseOptions?: Apollo.QueryHookOptions<GenerateCardJwtQuery, GenerateCardJwtQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GenerateCardJwtQuery, GenerateCardJwtQueryVariables>(GenerateCardJwtDocument, options);
}
export function useGenerateCardJwtLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GenerateCardJwtQuery, GenerateCardJwtQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GenerateCardJwtQuery, GenerateCardJwtQueryVariables>(GenerateCardJwtDocument, options);
}
export type GenerateCardJwtQueryHookResult = ReturnType<typeof useGenerateCardJwtQuery>;
export type GenerateCardJwtLazyQueryHookResult = ReturnType<typeof useGenerateCardJwtLazyQuery>;
export type GenerateCardJwtQueryResult = Apollo.QueryResult<GenerateCardJwtQuery, GenerateCardJwtQueryVariables>;
export const IsTopUpSourceExistingDocument = gql`
  query isTopUpSourceExisting {
    isTopUpSourceExisting
  }
`;

/**
 * __useIsTopUpSourceExistingQuery__
 *
 * To run a query within a React component, call `useIsTopUpSourceExistingQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsTopUpSourceExistingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsTopUpSourceExistingQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsTopUpSourceExistingQuery(
  baseOptions?: Apollo.QueryHookOptions<IsTopUpSourceExistingQuery, IsTopUpSourceExistingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsTopUpSourceExistingQuery, IsTopUpSourceExistingQueryVariables>(
    IsTopUpSourceExistingDocument,
    options,
  );
}
export function useIsTopUpSourceExistingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IsTopUpSourceExistingQuery, IsTopUpSourceExistingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsTopUpSourceExistingQuery, IsTopUpSourceExistingQueryVariables>(
    IsTopUpSourceExistingDocument,
    options,
  );
}
export type IsTopUpSourceExistingQueryHookResult = ReturnType<typeof useIsTopUpSourceExistingQuery>;
export type IsTopUpSourceExistingLazyQueryHookResult = ReturnType<typeof useIsTopUpSourceExistingLazyQuery>;
export type IsTopUpSourceExistingQueryResult = Apollo.QueryResult<
  IsTopUpSourceExistingQuery,
  IsTopUpSourceExistingQueryVariables
>;
export const CheckInvoiceStatusDocument = gql`
  query checkInvoiceStatus($invoiceID: Int!, $alertID: Int!) {
    checkInvoiceStatus(invoiceID: $invoiceID, alertID: $alertID)
  }
`;

/**
 * __useCheckInvoiceStatusQuery__
 *
 * To run a query within a React component, call `useCheckInvoiceStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckInvoiceStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckInvoiceStatusQuery({
 *   variables: {
 *      invoiceID: // value for 'invoiceID'
 *      alertID: // value for 'alertID'
 *   },
 * });
 */
export function useCheckInvoiceStatusQuery(
  baseOptions: Apollo.QueryHookOptions<CheckInvoiceStatusQuery, CheckInvoiceStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckInvoiceStatusQuery, CheckInvoiceStatusQueryVariables>(
    CheckInvoiceStatusDocument,
    options,
  );
}
export function useCheckInvoiceStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckInvoiceStatusQuery, CheckInvoiceStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckInvoiceStatusQuery, CheckInvoiceStatusQueryVariables>(
    CheckInvoiceStatusDocument,
    options,
  );
}
export type CheckInvoiceStatusQueryHookResult = ReturnType<typeof useCheckInvoiceStatusQuery>;
export type CheckInvoiceStatusLazyQueryHookResult = ReturnType<typeof useCheckInvoiceStatusLazyQuery>;
export type CheckInvoiceStatusQueryResult = Apollo.QueryResult<
  CheckInvoiceStatusQuery,
  CheckInvoiceStatusQueryVariables
>;
export const OnboardDpayAccountDocument = gql`
  query onboardDpayAccount {
    onboardAccount
  }
`;

/**
 * __useOnboardDpayAccountQuery__
 *
 * To run a query within a React component, call `useOnboardDpayAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardDpayAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardDpayAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardDpayAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<OnboardDpayAccountQuery, OnboardDpayAccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OnboardDpayAccountQuery, OnboardDpayAccountQueryVariables>(
    OnboardDpayAccountDocument,
    options,
  );
}
export function useOnboardDpayAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OnboardDpayAccountQuery, OnboardDpayAccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OnboardDpayAccountQuery, OnboardDpayAccountQueryVariables>(
    OnboardDpayAccountDocument,
    options,
  );
}
export type OnboardDpayAccountQueryHookResult = ReturnType<typeof useOnboardDpayAccountQuery>;
export type OnboardDpayAccountLazyQueryHookResult = ReturnType<typeof useOnboardDpayAccountLazyQuery>;
export type OnboardDpayAccountQueryResult = Apollo.QueryResult<
  OnboardDpayAccountQuery,
  OnboardDpayAccountQueryVariables
>;
export const IsDpayOnboardCompleteDocument = gql`
  query isDpayOnboardComplete {
    isOnboardComplete
  }
`;

/**
 * __useIsDpayOnboardCompleteQuery__
 *
 * To run a query within a React component, call `useIsDpayOnboardCompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsDpayOnboardCompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsDpayOnboardCompleteQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsDpayOnboardCompleteQuery(
  baseOptions?: Apollo.QueryHookOptions<IsDpayOnboardCompleteQuery, IsDpayOnboardCompleteQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsDpayOnboardCompleteQuery, IsDpayOnboardCompleteQueryVariables>(
    IsDpayOnboardCompleteDocument,
    options,
  );
}
export function useIsDpayOnboardCompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IsDpayOnboardCompleteQuery, IsDpayOnboardCompleteQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsDpayOnboardCompleteQuery, IsDpayOnboardCompleteQueryVariables>(
    IsDpayOnboardCompleteDocument,
    options,
  );
}
export type IsDpayOnboardCompleteQueryHookResult = ReturnType<typeof useIsDpayOnboardCompleteQuery>;
export type IsDpayOnboardCompleteLazyQueryHookResult = ReturnType<typeof useIsDpayOnboardCompleteLazyQuery>;
export type IsDpayOnboardCompleteQueryResult = Apollo.QueryResult<
  IsDpayOnboardCompleteQuery,
  IsDpayOnboardCompleteQueryVariables
>;
export const PintExecuteActionInvestorDocument = gql`
  mutation Pint_executeAction_Investor(
    $action: PintApiAction!
    $processor: String!
    $customAction: String
    $customData: JSON
  ) {
    pint_executeAction_Investor(
      action: $action
      processor: $processor
      customAction: $customAction
      customData: $customData
    )
  }
`;
export type PintExecuteActionInvestorMutationFn = Apollo.MutationFunction<
  PintExecuteActionInvestorMutation,
  PintExecuteActionInvestorMutationVariables
>;

/**
 * __usePintExecuteActionInvestorMutation__
 *
 * To run a mutation, you first call `usePintExecuteActionInvestorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePintExecuteActionInvestorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pintExecuteActionInvestorMutation, { data, loading, error }] = usePintExecuteActionInvestorMutation({
 *   variables: {
 *      action: // value for 'action'
 *      processor: // value for 'processor'
 *      customAction: // value for 'customAction'
 *      customData: // value for 'customData'
 *   },
 * });
 */
export function usePintExecuteActionInvestorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PintExecuteActionInvestorMutation,
    PintExecuteActionInvestorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PintExecuteActionInvestorMutation, PintExecuteActionInvestorMutationVariables>(
    PintExecuteActionInvestorDocument,
    options,
  );
}
export type PintExecuteActionInvestorMutationHookResult = ReturnType<typeof usePintExecuteActionInvestorMutation>;
export type PintExecuteActionInvestorMutationResult = Apollo.MutationResult<PintExecuteActionInvestorMutation>;
export type PintExecuteActionInvestorMutationOptions = Apollo.BaseMutationOptions<
  PintExecuteActionInvestorMutation,
  PintExecuteActionInvestorMutationVariables
>;
export const PintBeginFlowInvestorDocument = gql`
  mutation Pint_beginFlow_Investor($flow: PintPreconfFlows!, $processor: String!, $customData: JSON) {
    pint_beginFlow_Investor(flow: $flow, processor: $processor, customData: $customData)
  }
`;
export type PintBeginFlowInvestorMutationFn = Apollo.MutationFunction<
  PintBeginFlowInvestorMutation,
  PintBeginFlowInvestorMutationVariables
>;

/**
 * __usePintBeginFlowInvestorMutation__
 *
 * To run a mutation, you first call `usePintBeginFlowInvestorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePintBeginFlowInvestorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pintBeginFlowInvestorMutation, { data, loading, error }] = usePintBeginFlowInvestorMutation({
 *   variables: {
 *      flow: // value for 'flow'
 *      processor: // value for 'processor'
 *      customData: // value for 'customData'
 *   },
 * });
 */
export function usePintBeginFlowInvestorMutation(
  baseOptions?: Apollo.MutationHookOptions<PintBeginFlowInvestorMutation, PintBeginFlowInvestorMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PintBeginFlowInvestorMutation, PintBeginFlowInvestorMutationVariables>(
    PintBeginFlowInvestorDocument,
    options,
  );
}
export type PintBeginFlowInvestorMutationHookResult = ReturnType<typeof usePintBeginFlowInvestorMutation>;
export type PintBeginFlowInvestorMutationResult = Apollo.MutationResult<PintBeginFlowInvestorMutation>;
export type PintBeginFlowInvestorMutationOptions = Apollo.BaseMutationOptions<
  PintBeginFlowInvestorMutation,
  PintBeginFlowInvestorMutationVariables
>;
export const PintActionBeginTransactionInvestorDocument = gql`
  mutation Pint_action_beginTransaction_Investor(
    $processor: PintPreconfiguredProcessors!
    $alertId: Int
    $invoiceId: Int
    $channelId: Int
    $isWithdraw: Boolean
  ) {
    pint_action_beginTransaction_Investor(
      processor: $processor
      alertID: $alertId
      invoiceID: $invoiceId
      channelID: $channelId
      isWithdraw: $isWithdraw
    ) {
      ID
      investorID
      externalID
      externalUrl
      amount
      currencyCode
      localStatus
      timeStarted
      timeUpdated
      timeFinalized
      storage
    }
  }
`;
export type PintActionBeginTransactionInvestorMutationFn = Apollo.MutationFunction<
  PintActionBeginTransactionInvestorMutation,
  PintActionBeginTransactionInvestorMutationVariables
>;

/**
 * __usePintActionBeginTransactionInvestorMutation__
 *
 * To run a mutation, you first call `usePintActionBeginTransactionInvestorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePintActionBeginTransactionInvestorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pintActionBeginTransactionInvestorMutation, { data, loading, error }] = usePintActionBeginTransactionInvestorMutation({
 *   variables: {
 *      processor: // value for 'processor'
 *      alertId: // value for 'alertId'
 *      invoiceId: // value for 'invoiceId'
 *      channelId: // value for 'channelId'
 *      isWithdraw: // value for 'isWithdraw'
 *   },
 * });
 */
export function usePintActionBeginTransactionInvestorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PintActionBeginTransactionInvestorMutation,
    PintActionBeginTransactionInvestorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PintActionBeginTransactionInvestorMutation,
    PintActionBeginTransactionInvestorMutationVariables
  >(PintActionBeginTransactionInvestorDocument, options);
}
export type PintActionBeginTransactionInvestorMutationHookResult = ReturnType<
  typeof usePintActionBeginTransactionInvestorMutation
>;
export type PintActionBeginTransactionInvestorMutationResult =
  Apollo.MutationResult<PintActionBeginTransactionInvestorMutation>;
export type PintActionBeginTransactionInvestorMutationOptions = Apollo.BaseMutationOptions<
  PintActionBeginTransactionInvestorMutation,
  PintActionBeginTransactionInvestorMutationVariables
>;
export const PintListPreconfiguredCustomActionsDocument = gql`
  query Pint_listPreconfiguredCustomActions {
    pint_listPreconfiguredCustomActions {
      Dwolla
    }
  }
`;

/**
 * __usePintListPreconfiguredCustomActionsQuery__
 *
 * To run a query within a React component, call `usePintListPreconfiguredCustomActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePintListPreconfiguredCustomActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePintListPreconfiguredCustomActionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePintListPreconfiguredCustomActionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PintListPreconfiguredCustomActionsQuery,
    PintListPreconfiguredCustomActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PintListPreconfiguredCustomActionsQuery, PintListPreconfiguredCustomActionsQueryVariables>(
    PintListPreconfiguredCustomActionsDocument,
    options,
  );
}
export function usePintListPreconfiguredCustomActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PintListPreconfiguredCustomActionsQuery,
    PintListPreconfiguredCustomActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PintListPreconfiguredCustomActionsQuery, PintListPreconfiguredCustomActionsQueryVariables>(
    PintListPreconfiguredCustomActionsDocument,
    options,
  );
}
export type PintListPreconfiguredCustomActionsQueryHookResult = ReturnType<
  typeof usePintListPreconfiguredCustomActionsQuery
>;
export type PintListPreconfiguredCustomActionsLazyQueryHookResult = ReturnType<
  typeof usePintListPreconfiguredCustomActionsLazyQuery
>;
export type PintListPreconfiguredCustomActionsQueryResult = Apollo.QueryResult<
  PintListPreconfiguredCustomActionsQuery,
  PintListPreconfiguredCustomActionsQueryVariables
>;
export const PintGetAccountDataInvestorDocument = gql`
  query Pint_getAccountData_Investor($processor: PintPreconfiguredProcessors!) {
    pint_getAccountData_Investor(processor: $processor) {
      investorID
      userType
      paymentAccountID
      rsyncStatus
      storage
      topUpSourceId
    }
  }
`;

/**
 * __usePintGetAccountDataInvestorQuery__
 *
 * To run a query within a React component, call `usePintGetAccountDataInvestorQuery` and pass it any options that fit your needs.
 * When your component renders, `usePintGetAccountDataInvestorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePintGetAccountDataInvestorQuery({
 *   variables: {
 *      processor: // value for 'processor'
 *   },
 * });
 */
export function usePintGetAccountDataInvestorQuery(
  baseOptions: Apollo.QueryHookOptions<PintGetAccountDataInvestorQuery, PintGetAccountDataInvestorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PintGetAccountDataInvestorQuery, PintGetAccountDataInvestorQueryVariables>(
    PintGetAccountDataInvestorDocument,
    options,
  );
}
export function usePintGetAccountDataInvestorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PintGetAccountDataInvestorQuery, PintGetAccountDataInvestorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PintGetAccountDataInvestorQuery, PintGetAccountDataInvestorQueryVariables>(
    PintGetAccountDataInvestorDocument,
    options,
  );
}
export type PintGetAccountDataInvestorQueryHookResult = ReturnType<typeof usePintGetAccountDataInvestorQuery>;
export type PintGetAccountDataInvestorLazyQueryHookResult = ReturnType<typeof usePintGetAccountDataInvestorLazyQuery>;
export type PintGetAccountDataInvestorQueryResult = Apollo.QueryResult<
  PintGetAccountDataInvestorQuery,
  PintGetAccountDataInvestorQueryVariables
>;
export const PintSynchronizeTransactionsInvestorDocument = gql`
  query Pint_synchronizeTransactions_Investor {
    pint_synchronizeTransactions_Investor
  }
`;

/**
 * __usePintSynchronizeTransactionsInvestorQuery__
 *
 * To run a query within a React component, call `usePintSynchronizeTransactionsInvestorQuery` and pass it any options that fit your needs.
 * When your component renders, `usePintSynchronizeTransactionsInvestorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePintSynchronizeTransactionsInvestorQuery({
 *   variables: {
 *   },
 * });
 */
export function usePintSynchronizeTransactionsInvestorQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PintSynchronizeTransactionsInvestorQuery,
    PintSynchronizeTransactionsInvestorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PintSynchronizeTransactionsInvestorQuery, PintSynchronizeTransactionsInvestorQueryVariables>(
    PintSynchronizeTransactionsInvestorDocument,
    options,
  );
}
export function usePintSynchronizeTransactionsInvestorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PintSynchronizeTransactionsInvestorQuery,
    PintSynchronizeTransactionsInvestorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PintSynchronizeTransactionsInvestorQuery,
    PintSynchronizeTransactionsInvestorQueryVariables
  >(PintSynchronizeTransactionsInvestorDocument, options);
}
export type PintSynchronizeTransactionsInvestorQueryHookResult = ReturnType<
  typeof usePintSynchronizeTransactionsInvestorQuery
>;
export type PintSynchronizeTransactionsInvestorLazyQueryHookResult = ReturnType<
  typeof usePintSynchronizeTransactionsInvestorLazyQuery
>;
export type PintSynchronizeTransactionsInvestorQueryResult = Apollo.QueryResult<
  PintSynchronizeTransactionsInvestorQuery,
  PintSynchronizeTransactionsInvestorQueryVariables
>;
export const PolymeshSwapDocument = gql`
  mutation PolymeshSwap($recordID: Int!, $secretKey: String!) {
    polymeshSwap(recordID: $recordID, secretKey: $secretKey)
  }
`;
export type PolymeshSwapMutationFn = Apollo.MutationFunction<PolymeshSwapMutation, PolymeshSwapMutationVariables>;

/**
 * __usePolymeshSwapMutation__
 *
 * To run a mutation, you first call `usePolymeshSwapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePolymeshSwapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [polymeshSwapMutation, { data, loading, error }] = usePolymeshSwapMutation({
 *   variables: {
 *      recordID: // value for 'recordID'
 *      secretKey: // value for 'secretKey'
 *   },
 * });
 */
export function usePolymeshSwapMutation(
  baseOptions?: Apollo.MutationHookOptions<PolymeshSwapMutation, PolymeshSwapMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PolymeshSwapMutation, PolymeshSwapMutationVariables>(PolymeshSwapDocument, options);
}
export type PolymeshSwapMutationHookResult = ReturnType<typeof usePolymeshSwapMutation>;
export type PolymeshSwapMutationResult = Apollo.MutationResult<PolymeshSwapMutation>;
export type PolymeshSwapMutationOptions = Apollo.BaseMutationOptions<
  PolymeshSwapMutation,
  PolymeshSwapMutationVariables
>;
export const InitiatePolymeshExchangeSwapDocument = gql`
  mutation InitiatePolymeshExchangeSwap($orderID: Int!) {
    initiatePolymeshExchangeSwap(orderID: $orderID)
  }
`;
export type InitiatePolymeshExchangeSwapMutationFn = Apollo.MutationFunction<
  InitiatePolymeshExchangeSwapMutation,
  InitiatePolymeshExchangeSwapMutationVariables
>;

/**
 * __useInitiatePolymeshExchangeSwapMutation__
 *
 * To run a mutation, you first call `useInitiatePolymeshExchangeSwapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiatePolymeshExchangeSwapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiatePolymeshExchangeSwapMutation, { data, loading, error }] = useInitiatePolymeshExchangeSwapMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useInitiatePolymeshExchangeSwapMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitiatePolymeshExchangeSwapMutation,
    InitiatePolymeshExchangeSwapMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InitiatePolymeshExchangeSwapMutation, InitiatePolymeshExchangeSwapMutationVariables>(
    InitiatePolymeshExchangeSwapDocument,
    options,
  );
}
export type InitiatePolymeshExchangeSwapMutationHookResult = ReturnType<typeof useInitiatePolymeshExchangeSwapMutation>;
export type InitiatePolymeshExchangeSwapMutationResult = Apollo.MutationResult<InitiatePolymeshExchangeSwapMutation>;
export type InitiatePolymeshExchangeSwapMutationOptions = Apollo.BaseMutationOptions<
  InitiatePolymeshExchangeSwapMutation,
  InitiatePolymeshExchangeSwapMutationVariables
>;
export const AffirmPolymeshExchangeSwapDocument = gql`
  mutation AffirmPolymeshExchangeSwap($orderID: Int!, $secretKey: String!) {
    affirmPolymeshExchangeSwap(orderID: $orderID, secretKey: $secretKey)
  }
`;
export type AffirmPolymeshExchangeSwapMutationFn = Apollo.MutationFunction<
  AffirmPolymeshExchangeSwapMutation,
  AffirmPolymeshExchangeSwapMutationVariables
>;

/**
 * __useAffirmPolymeshExchangeSwapMutation__
 *
 * To run a mutation, you first call `useAffirmPolymeshExchangeSwapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAffirmPolymeshExchangeSwapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [affirmPolymeshExchangeSwapMutation, { data, loading, error }] = useAffirmPolymeshExchangeSwapMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      secretKey: // value for 'secretKey'
 *   },
 * });
 */
export function useAffirmPolymeshExchangeSwapMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AffirmPolymeshExchangeSwapMutation,
    AffirmPolymeshExchangeSwapMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AffirmPolymeshExchangeSwapMutation, AffirmPolymeshExchangeSwapMutationVariables>(
    AffirmPolymeshExchangeSwapDocument,
    options,
  );
}
export type AffirmPolymeshExchangeSwapMutationHookResult = ReturnType<typeof useAffirmPolymeshExchangeSwapMutation>;
export type AffirmPolymeshExchangeSwapMutationResult = Apollo.MutationResult<AffirmPolymeshExchangeSwapMutation>;
export type AffirmPolymeshExchangeSwapMutationOptions = Apollo.BaseMutationOptions<
  AffirmPolymeshExchangeSwapMutation,
  AffirmPolymeshExchangeSwapMutationVariables
>;
export const InitiatePolymeshTokenPurchaseTransactionDocument = gql`
  mutation InitiatePolymeshTokenPurchaseTransaction($input: InitiatePurchaseInput!) {
    initiatePolymeshTokenPurchaseTransaction(input: $input) {
      blockchainTransactionID
      shareTypeVenueID
    }
  }
`;
export type InitiatePolymeshTokenPurchaseTransactionMutationFn = Apollo.MutationFunction<
  InitiatePolymeshTokenPurchaseTransactionMutation,
  InitiatePolymeshTokenPurchaseTransactionMutationVariables
>;

/**
 * __useInitiatePolymeshTokenPurchaseTransactionMutation__
 *
 * To run a mutation, you first call `useInitiatePolymeshTokenPurchaseTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiatePolymeshTokenPurchaseTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiatePolymeshTokenPurchaseTransactionMutation, { data, loading, error }] = useInitiatePolymeshTokenPurchaseTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitiatePolymeshTokenPurchaseTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitiatePolymeshTokenPurchaseTransactionMutation,
    InitiatePolymeshTokenPurchaseTransactionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InitiatePolymeshTokenPurchaseTransactionMutation,
    InitiatePolymeshTokenPurchaseTransactionMutationVariables
  >(InitiatePolymeshTokenPurchaseTransactionDocument, options);
}
export type InitiatePolymeshTokenPurchaseTransactionMutationHookResult = ReturnType<
  typeof useInitiatePolymeshTokenPurchaseTransactionMutation
>;
export type InitiatePolymeshTokenPurchaseTransactionMutationResult =
  Apollo.MutationResult<InitiatePolymeshTokenPurchaseTransactionMutation>;
export type InitiatePolymeshTokenPurchaseTransactionMutationOptions = Apollo.BaseMutationOptions<
  InitiatePolymeshTokenPurchaseTransactionMutation,
  InitiatePolymeshTokenPurchaseTransactionMutationVariables
>;
export const GetPolymeshTransactionsDocument = gql`
  query GetPolymeshTransactions($status: POLYMESH_SWAP_STATUS!, $between: POLYMESH_SWAP_BETWEEN!) {
    getPolymeshTransactions(status: $status, between: $between) {
      ID
      title
      between
      senderID
      receiverID
      transactionID
      shareTypeID
      amount
      swapTokenTag
      swapAmount
      status
      dateCreated
      dateModified
    }
  }
`;

/**
 * __useGetPolymeshTransactionsQuery__
 *
 * To run a query within a React component, call `useGetPolymeshTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolymeshTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolymeshTransactionsQuery({
 *   variables: {
 *      status: // value for 'status'
 *      between: // value for 'between'
 *   },
 * });
 */
export function useGetPolymeshTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<GetPolymeshTransactionsQuery, GetPolymeshTransactionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPolymeshTransactionsQuery, GetPolymeshTransactionsQueryVariables>(
    GetPolymeshTransactionsDocument,
    options,
  );
}
export function useGetPolymeshTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPolymeshTransactionsQuery, GetPolymeshTransactionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPolymeshTransactionsQuery, GetPolymeshTransactionsQueryVariables>(
    GetPolymeshTransactionsDocument,
    options,
  );
}
export type GetPolymeshTransactionsQueryHookResult = ReturnType<typeof useGetPolymeshTransactionsQuery>;
export type GetPolymeshTransactionsLazyQueryHookResult = ReturnType<typeof useGetPolymeshTransactionsLazyQuery>;
export type GetPolymeshTransactionsQueryResult = Apollo.QueryResult<
  GetPolymeshTransactionsQuery,
  GetPolymeshTransactionsQueryVariables
>;
export const GetPendingPolymeshTransactionsCountDocument = gql`
  query GetPendingPolymeshTransactionsCount($between: POLYMESH_SWAP_BETWEEN!) {
    getPendingPolymeshTransactionsCount(between: $between)
  }
`;

/**
 * __useGetPendingPolymeshTransactionsCountQuery__
 *
 * To run a query within a React component, call `useGetPendingPolymeshTransactionsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPendingPolymeshTransactionsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPendingPolymeshTransactionsCountQuery({
 *   variables: {
 *      between: // value for 'between'
 *   },
 * });
 */
export function useGetPendingPolymeshTransactionsCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPendingPolymeshTransactionsCountQuery,
    GetPendingPolymeshTransactionsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPendingPolymeshTransactionsCountQuery, GetPendingPolymeshTransactionsCountQueryVariables>(
    GetPendingPolymeshTransactionsCountDocument,
    options,
  );
}
export function useGetPendingPolymeshTransactionsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPendingPolymeshTransactionsCountQuery,
    GetPendingPolymeshTransactionsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPendingPolymeshTransactionsCountQuery,
    GetPendingPolymeshTransactionsCountQueryVariables
  >(GetPendingPolymeshTransactionsCountDocument, options);
}
export type GetPendingPolymeshTransactionsCountQueryHookResult = ReturnType<
  typeof useGetPendingPolymeshTransactionsCountQuery
>;
export type GetPendingPolymeshTransactionsCountLazyQueryHookResult = ReturnType<
  typeof useGetPendingPolymeshTransactionsCountLazyQuery
>;
export type GetPendingPolymeshTransactionsCountQueryResult = Apollo.QueryResult<
  GetPendingPolymeshTransactionsCountQuery,
  GetPendingPolymeshTransactionsCountQueryVariables
>;
export const PortfolioValueDocument = gql`
  query portfolioValue {
    portfolioValue
  }
`;

/**
 * __usePortfolioValueQuery__
 *
 * To run a query within a React component, call `usePortfolioValueQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioValueQuery({
 *   variables: {
 *   },
 * });
 */
export function usePortfolioValueQuery(
  baseOptions?: Apollo.QueryHookOptions<PortfolioValueQuery, PortfolioValueQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PortfolioValueQuery, PortfolioValueQueryVariables>(PortfolioValueDocument, options);
}
export function usePortfolioValueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PortfolioValueQuery, PortfolioValueQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PortfolioValueQuery, PortfolioValueQueryVariables>(PortfolioValueDocument, options);
}
export type PortfolioValueQueryHookResult = ReturnType<typeof usePortfolioValueQuery>;
export type PortfolioValueLazyQueryHookResult = ReturnType<typeof usePortfolioValueLazyQuery>;
export type PortfolioValueQueryResult = Apollo.QueryResult<PortfolioValueQuery, PortfolioValueQueryVariables>;
export const PortfolioValueCalculationsDocument = gql`
  query portfolioValueCalculations {
    portfolioCalculations {
      originatingProjectName
      investmentName
      entityName
      investmentValue
      yearlyRate
      normalDays
      normalProfit
      leapProfit
      leapDays
      totalProfit
      shares
      dividendPayout
    }
  }
`;

/**
 * __usePortfolioValueCalculationsQuery__
 *
 * To run a query within a React component, call `usePortfolioValueCalculationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioValueCalculationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioValueCalculationsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePortfolioValueCalculationsQuery(
  baseOptions?: Apollo.QueryHookOptions<PortfolioValueCalculationsQuery, PortfolioValueCalculationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PortfolioValueCalculationsQuery, PortfolioValueCalculationsQueryVariables>(
    PortfolioValueCalculationsDocument,
    options,
  );
}
export function usePortfolioValueCalculationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PortfolioValueCalculationsQuery, PortfolioValueCalculationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PortfolioValueCalculationsQuery, PortfolioValueCalculationsQueryVariables>(
    PortfolioValueCalculationsDocument,
    options,
  );
}
export type PortfolioValueCalculationsQueryHookResult = ReturnType<typeof usePortfolioValueCalculationsQuery>;
export type PortfolioValueCalculationsLazyQueryHookResult = ReturnType<typeof usePortfolioValueCalculationsLazyQuery>;
export type PortfolioValueCalculationsQueryResult = Apollo.QueryResult<
  PortfolioValueCalculationsQuery,
  PortfolioValueCalculationsQueryVariables
>;
export const GetSellEntityByIdDocument = gql`
  query getSellEntityById($ID: Int!) {
    getSellEntityById(ID: $ID) {
      ID
      title
      details
      logoUrl
      getStos {
        ID
        title
        isActive
        createdAt
        meta {
          stoID
          key
          value
          isManagedStoTableColumn
          type
        }
      }
      meta {
        key
        value
        type
        order
        display
        displayClosedProp
      }
    }
  }
`;

/**
 * __useGetSellEntityByIdQuery__
 *
 * To run a query within a React component, call `useGetSellEntityByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSellEntityByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSellEntityByIdQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetSellEntityByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetSellEntityByIdQuery, GetSellEntityByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSellEntityByIdQuery, GetSellEntityByIdQueryVariables>(GetSellEntityByIdDocument, options);
}
export function useGetSellEntityByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSellEntityByIdQuery, GetSellEntityByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSellEntityByIdQuery, GetSellEntityByIdQueryVariables>(
    GetSellEntityByIdDocument,
    options,
  );
}
export type GetSellEntityByIdQueryHookResult = ReturnType<typeof useGetSellEntityByIdQuery>;
export type GetSellEntityByIdLazyQueryHookResult = ReturnType<typeof useGetSellEntityByIdLazyQuery>;
export type GetSellEntityByIdQueryResult = Apollo.QueryResult<GetSellEntityByIdQuery, GetSellEntityByIdQueryVariables>;
export const SaveSharePurchaseContractFieldsDocument = gql`
  mutation saveSharePurchaseContractFields(
    $documentID: Int!
    $sharePurchaseID: Int!
    $fieldValues: [DocumentFieldValueDTO!]!
  ) {
    setSubmittedSharePurchaseDocument(
      documentID: $documentID
      sharePurchaseID: $sharePurchaseID
      fieldValues: $fieldValues
    )
  }
`;
export type SaveSharePurchaseContractFieldsMutationFn = Apollo.MutationFunction<
  SaveSharePurchaseContractFieldsMutation,
  SaveSharePurchaseContractFieldsMutationVariables
>;

/**
 * __useSaveSharePurchaseContractFieldsMutation__
 *
 * To run a mutation, you first call `useSaveSharePurchaseContractFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSharePurchaseContractFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSharePurchaseContractFieldsMutation, { data, loading, error }] = useSaveSharePurchaseContractFieldsMutation({
 *   variables: {
 *      documentID: // value for 'documentID'
 *      sharePurchaseID: // value for 'sharePurchaseID'
 *      fieldValues: // value for 'fieldValues'
 *   },
 * });
 */
export function useSaveSharePurchaseContractFieldsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveSharePurchaseContractFieldsMutation,
    SaveSharePurchaseContractFieldsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveSharePurchaseContractFieldsMutation, SaveSharePurchaseContractFieldsMutationVariables>(
    SaveSharePurchaseContractFieldsDocument,
    options,
  );
}
export type SaveSharePurchaseContractFieldsMutationHookResult = ReturnType<
  typeof useSaveSharePurchaseContractFieldsMutation
>;
export type SaveSharePurchaseContractFieldsMutationResult =
  Apollo.MutationResult<SaveSharePurchaseContractFieldsMutation>;
export type SaveSharePurchaseContractFieldsMutationOptions = Apollo.BaseMutationOptions<
  SaveSharePurchaseContractFieldsMutation,
  SaveSharePurchaseContractFieldsMutationVariables
>;
export const SaveSharePurchaseContractSignatureDocument = gql`
  mutation saveSharePurchaseContractSignature($documentID: Int!, $sharePurchaseID: Int!, $signature: String!) {
    setSharePurchaseDocumentSignature(documentID: $documentID, sharePurchaseID: $sharePurchaseID, base64: $signature)
  }
`;
export type SaveSharePurchaseContractSignatureMutationFn = Apollo.MutationFunction<
  SaveSharePurchaseContractSignatureMutation,
  SaveSharePurchaseContractSignatureMutationVariables
>;

/**
 * __useSaveSharePurchaseContractSignatureMutation__
 *
 * To run a mutation, you first call `useSaveSharePurchaseContractSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSharePurchaseContractSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSharePurchaseContractSignatureMutation, { data, loading, error }] = useSaveSharePurchaseContractSignatureMutation({
 *   variables: {
 *      documentID: // value for 'documentID'
 *      sharePurchaseID: // value for 'sharePurchaseID'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useSaveSharePurchaseContractSignatureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveSharePurchaseContractSignatureMutation,
    SaveSharePurchaseContractSignatureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveSharePurchaseContractSignatureMutation,
    SaveSharePurchaseContractSignatureMutationVariables
  >(SaveSharePurchaseContractSignatureDocument, options);
}
export type SaveSharePurchaseContractSignatureMutationHookResult = ReturnType<
  typeof useSaveSharePurchaseContractSignatureMutation
>;
export type SaveSharePurchaseContractSignatureMutationResult =
  Apollo.MutationResult<SaveSharePurchaseContractSignatureMutation>;
export type SaveSharePurchaseContractSignatureMutationOptions = Apollo.BaseMutationOptions<
  SaveSharePurchaseContractSignatureMutation,
  SaveSharePurchaseContractSignatureMutationVariables
>;
export const SendSharePurchaseContractDocument = gql`
  mutation sendSharePurchaseContract($documentID: Int!, $sharePurchaseID: Int!) {
    sendSharePurchaseContract(documentID: $documentID, sharePurchaseID: $sharePurchaseID)
  }
`;
export type SendSharePurchaseContractMutationFn = Apollo.MutationFunction<
  SendSharePurchaseContractMutation,
  SendSharePurchaseContractMutationVariables
>;

/**
 * __useSendSharePurchaseContractMutation__
 *
 * To run a mutation, you first call `useSendSharePurchaseContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSharePurchaseContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSharePurchaseContractMutation, { data, loading, error }] = useSendSharePurchaseContractMutation({
 *   variables: {
 *      documentID: // value for 'documentID'
 *      sharePurchaseID: // value for 'sharePurchaseID'
 *   },
 * });
 */
export function useSendSharePurchaseContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendSharePurchaseContractMutation,
    SendSharePurchaseContractMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendSharePurchaseContractMutation, SendSharePurchaseContractMutationVariables>(
    SendSharePurchaseContractDocument,
    options,
  );
}
export type SendSharePurchaseContractMutationHookResult = ReturnType<typeof useSendSharePurchaseContractMutation>;
export type SendSharePurchaseContractMutationResult = Apollo.MutationResult<SendSharePurchaseContractMutation>;
export type SendSharePurchaseContractMutationOptions = Apollo.BaseMutationOptions<
  SendSharePurchaseContractMutation,
  SendSharePurchaseContractMutationVariables
>;
export const DeleteSharePurchaseRequestDocument = gql`
  mutation deleteSharePurchaseRequest($documentID: Int!) {
    deleteSharePurchaseRequest(documentID: $documentID)
  }
`;
export type DeleteSharePurchaseRequestMutationFn = Apollo.MutationFunction<
  DeleteSharePurchaseRequestMutation,
  DeleteSharePurchaseRequestMutationVariables
>;

/**
 * __useDeleteSharePurchaseRequestMutation__
 *
 * To run a mutation, you first call `useDeleteSharePurchaseRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSharePurchaseRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSharePurchaseRequestMutation, { data, loading, error }] = useDeleteSharePurchaseRequestMutation({
 *   variables: {
 *      documentID: // value for 'documentID'
 *   },
 * });
 */
export function useDeleteSharePurchaseRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSharePurchaseRequestMutation,
    DeleteSharePurchaseRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSharePurchaseRequestMutation, DeleteSharePurchaseRequestMutationVariables>(
    DeleteSharePurchaseRequestDocument,
    options,
  );
}
export type DeleteSharePurchaseRequestMutationHookResult = ReturnType<typeof useDeleteSharePurchaseRequestMutation>;
export type DeleteSharePurchaseRequestMutationResult = Apollo.MutationResult<DeleteSharePurchaseRequestMutation>;
export type DeleteSharePurchaseRequestMutationOptions = Apollo.BaseMutationOptions<
  DeleteSharePurchaseRequestMutation,
  DeleteSharePurchaseRequestMutationVariables
>;
export const InvestorBuyAlertRemoveDocument = gql`
  mutation InvestorBuyAlertRemove($alertID: Int!) {
    investorBuyAlertRemove(alertID: $alertID)
  }
`;
export type InvestorBuyAlertRemoveMutationFn = Apollo.MutationFunction<
  InvestorBuyAlertRemoveMutation,
  InvestorBuyAlertRemoveMutationVariables
>;

/**
 * __useInvestorBuyAlertRemoveMutation__
 *
 * To run a mutation, you first call `useInvestorBuyAlertRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorBuyAlertRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorBuyAlertRemoveMutation, { data, loading, error }] = useInvestorBuyAlertRemoveMutation({
 *   variables: {
 *      alertID: // value for 'alertID'
 *   },
 * });
 */
export function useInvestorBuyAlertRemoveMutation(
  baseOptions?: Apollo.MutationHookOptions<InvestorBuyAlertRemoveMutation, InvestorBuyAlertRemoveMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InvestorBuyAlertRemoveMutation, InvestorBuyAlertRemoveMutationVariables>(
    InvestorBuyAlertRemoveDocument,
    options,
  );
}
export type InvestorBuyAlertRemoveMutationHookResult = ReturnType<typeof useInvestorBuyAlertRemoveMutation>;
export type InvestorBuyAlertRemoveMutationResult = Apollo.MutationResult<InvestorBuyAlertRemoveMutation>;
export type InvestorBuyAlertRemoveMutationOptions = Apollo.BaseMutationOptions<
  InvestorBuyAlertRemoveMutation,
  InvestorBuyAlertRemoveMutationVariables
>;
export const InvestorBuyAlertHideDocument = gql`
  mutation InvestorBuyAlertHide($alertID: Int!) {
    investorBuyAlertHide(alertID: $alertID)
  }
`;
export type InvestorBuyAlertHideMutationFn = Apollo.MutationFunction<
  InvestorBuyAlertHideMutation,
  InvestorBuyAlertHideMutationVariables
>;

/**
 * __useInvestorBuyAlertHideMutation__
 *
 * To run a mutation, you first call `useInvestorBuyAlertHideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorBuyAlertHideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorBuyAlertHideMutation, { data, loading, error }] = useInvestorBuyAlertHideMutation({
 *   variables: {
 *      alertID: // value for 'alertID'
 *   },
 * });
 */
export function useInvestorBuyAlertHideMutation(
  baseOptions?: Apollo.MutationHookOptions<InvestorBuyAlertHideMutation, InvestorBuyAlertHideMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InvestorBuyAlertHideMutation, InvestorBuyAlertHideMutationVariables>(
    InvestorBuyAlertHideDocument,
    options,
  );
}
export type InvestorBuyAlertHideMutationHookResult = ReturnType<typeof useInvestorBuyAlertHideMutation>;
export type InvestorBuyAlertHideMutationResult = Apollo.MutationResult<InvestorBuyAlertHideMutation>;
export type InvestorBuyAlertHideMutationOptions = Apollo.BaseMutationOptions<
  InvestorBuyAlertHideMutation,
  InvestorBuyAlertHideMutationVariables
>;
export const SetDocuSignSignatureDocument = gql`
  mutation SetDocuSignSignature($documentID: Int!, $sharePurchaseID: Int!, $docusignEnvelopeID: String!) {
    setDocuSignSignature(
      documentID: $documentID
      sharePurchaseID: $sharePurchaseID
      docusignEnvelopeID: $docusignEnvelopeID
    )
  }
`;
export type SetDocuSignSignatureMutationFn = Apollo.MutationFunction<
  SetDocuSignSignatureMutation,
  SetDocuSignSignatureMutationVariables
>;

/**
 * __useSetDocuSignSignatureMutation__
 *
 * To run a mutation, you first call `useSetDocuSignSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDocuSignSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDocuSignSignatureMutation, { data, loading, error }] = useSetDocuSignSignatureMutation({
 *   variables: {
 *      documentID: // value for 'documentID'
 *      sharePurchaseID: // value for 'sharePurchaseID'
 *      docusignEnvelopeID: // value for 'docusignEnvelopeID'
 *   },
 * });
 */
export function useSetDocuSignSignatureMutation(
  baseOptions?: Apollo.MutationHookOptions<SetDocuSignSignatureMutation, SetDocuSignSignatureMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetDocuSignSignatureMutation, SetDocuSignSignatureMutationVariables>(
    SetDocuSignSignatureDocument,
    options,
  );
}
export type SetDocuSignSignatureMutationHookResult = ReturnType<typeof useSetDocuSignSignatureMutation>;
export type SetDocuSignSignatureMutationResult = Apollo.MutationResult<SetDocuSignSignatureMutation>;
export type SetDocuSignSignatureMutationOptions = Apollo.BaseMutationOptions<
  SetDocuSignSignatureMutation,
  SetDocuSignSignatureMutationVariables
>;
export const CreateBlockchainTransactionTransferDocument = gql`
  mutation CreateBlockchainTransactionTransfer($data: BlockchainSharesTransferTransactionsInput!) {
    createBlockchainTransactionTransfer(data: $data)
  }
`;
export type CreateBlockchainTransactionTransferMutationFn = Apollo.MutationFunction<
  CreateBlockchainTransactionTransferMutation,
  CreateBlockchainTransactionTransferMutationVariables
>;

/**
 * __useCreateBlockchainTransactionTransferMutation__
 *
 * To run a mutation, you first call `useCreateBlockchainTransactionTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlockchainTransactionTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlockchainTransactionTransferMutation, { data, loading, error }] = useCreateBlockchainTransactionTransferMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBlockchainTransactionTransferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBlockchainTransactionTransferMutation,
    CreateBlockchainTransactionTransferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateBlockchainTransactionTransferMutation,
    CreateBlockchainTransactionTransferMutationVariables
  >(CreateBlockchainTransactionTransferDocument, options);
}
export type CreateBlockchainTransactionTransferMutationHookResult = ReturnType<
  typeof useCreateBlockchainTransactionTransferMutation
>;
export type CreateBlockchainTransactionTransferMutationResult =
  Apollo.MutationResult<CreateBlockchainTransactionTransferMutation>;
export type CreateBlockchainTransactionTransferMutationOptions = Apollo.BaseMutationOptions<
  CreateBlockchainTransactionTransferMutation,
  CreateBlockchainTransactionTransferMutationVariables
>;
export const UpdateCompletedBcstTxsForStoDocument = gql`
  mutation UpdateCompletedBCSTTxsForSto($stoID: Int!) {
    updateCompletedBCSTTxsForSto(stoID: $stoID)
  }
`;
export type UpdateCompletedBcstTxsForStoMutationFn = Apollo.MutationFunction<
  UpdateCompletedBcstTxsForStoMutation,
  UpdateCompletedBcstTxsForStoMutationVariables
>;

/**
 * __useUpdateCompletedBcstTxsForStoMutation__
 *
 * To run a mutation, you first call `useUpdateCompletedBcstTxsForStoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompletedBcstTxsForStoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompletedBcstTxsForStoMutation, { data, loading, error }] = useUpdateCompletedBcstTxsForStoMutation({
 *   variables: {
 *      stoID: // value for 'stoID'
 *   },
 * });
 */
export function useUpdateCompletedBcstTxsForStoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompletedBcstTxsForStoMutation,
    UpdateCompletedBcstTxsForStoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCompletedBcstTxsForStoMutation, UpdateCompletedBcstTxsForStoMutationVariables>(
    UpdateCompletedBcstTxsForStoDocument,
    options,
  );
}
export type UpdateCompletedBcstTxsForStoMutationHookResult = ReturnType<typeof useUpdateCompletedBcstTxsForStoMutation>;
export type UpdateCompletedBcstTxsForStoMutationResult = Apollo.MutationResult<UpdateCompletedBcstTxsForStoMutation>;
export type UpdateCompletedBcstTxsForStoMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompletedBcstTxsForStoMutation,
  UpdateCompletedBcstTxsForStoMutationVariables
>;
export const SharePurchaseDocumentsDocument = gql`
  query sharePurchaseDocuments($sharePurchaseID: Int!) {
    sharePurchaseDocuments(sharePurchaseID: $sharePurchaseID) {
      requireOnce
      document {
        ID
        title
      }
      status
    }
  }
`;

/**
 * __useSharePurchaseDocumentsQuery__
 *
 * To run a query within a React component, call `useSharePurchaseDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharePurchaseDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharePurchaseDocumentsQuery({
 *   variables: {
 *      sharePurchaseID: // value for 'sharePurchaseID'
 *   },
 * });
 */
export function useSharePurchaseDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<SharePurchaseDocumentsQuery, SharePurchaseDocumentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SharePurchaseDocumentsQuery, SharePurchaseDocumentsQueryVariables>(
    SharePurchaseDocumentsDocument,
    options,
  );
}
export function useSharePurchaseDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SharePurchaseDocumentsQuery, SharePurchaseDocumentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SharePurchaseDocumentsQuery, SharePurchaseDocumentsQueryVariables>(
    SharePurchaseDocumentsDocument,
    options,
  );
}
export type SharePurchaseDocumentsQueryHookResult = ReturnType<typeof useSharePurchaseDocumentsQuery>;
export type SharePurchaseDocumentsLazyQueryHookResult = ReturnType<typeof useSharePurchaseDocumentsLazyQuery>;
export type SharePurchaseDocumentsQueryResult = Apollo.QueryResult<
  SharePurchaseDocumentsQuery,
  SharePurchaseDocumentsQueryVariables
>;
export const GetInternalSigningDataDocument = gql`
  query getInternalSigningData($documentID: Int!, $sharePurchaseID: Int!) {
    document(documentID: $documentID) {
      ID
      title
      contents
    }
    sharePurchaseDocument(sharePurchaseID: $sharePurchaseID, documentID: $documentID) {
      fieldValues {
        ID
        value
      }
      signature {
        url
        modified
      }
      status
      signatureFilePath
    }
    documentFields(documentID: $documentID) {
      ID
      title
      fieldID
      type
      helperText
    }
    getPrefilledDocumentValues(sharePurchaseID: $sharePurchaseID, documentID: $documentID) {
      ID
      value
    }
    investorAppParameters {
      IsDarwSignatureActive
      IsCheckMarkSignatureActive
      drawSignaturePrefillFonts
    }
  }
`;

/**
 * __useGetInternalSigningDataQuery__
 *
 * To run a query within a React component, call `useGetInternalSigningDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInternalSigningDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInternalSigningDataQuery({
 *   variables: {
 *      documentID: // value for 'documentID'
 *      sharePurchaseID: // value for 'sharePurchaseID'
 *   },
 * });
 */
export function useGetInternalSigningDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetInternalSigningDataQuery, GetInternalSigningDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInternalSigningDataQuery, GetInternalSigningDataQueryVariables>(
    GetInternalSigningDataDocument,
    options,
  );
}
export function useGetInternalSigningDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInternalSigningDataQuery, GetInternalSigningDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInternalSigningDataQuery, GetInternalSigningDataQueryVariables>(
    GetInternalSigningDataDocument,
    options,
  );
}
export type GetInternalSigningDataQueryHookResult = ReturnType<typeof useGetInternalSigningDataQuery>;
export type GetInternalSigningDataLazyQueryHookResult = ReturnType<typeof useGetInternalSigningDataLazyQuery>;
export type GetInternalSigningDataQueryResult = Apollo.QueryResult<
  GetInternalSigningDataQuery,
  GetInternalSigningDataQueryVariables
>;
export const GetDocuSignUrlDocument = gql`
  query getDocuSignUrl($preferredReturnURL: String!, $documentID: Int!, $sharePurchaseID: Int!) {
    getDocuSignUrl(preferredReturnURL: $preferredReturnURL, documentID: $documentID, sharePurchaseID: $sharePurchaseID)
  }
`;

/**
 * __useGetDocuSignUrlQuery__
 *
 * To run a query within a React component, call `useGetDocuSignUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocuSignUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocuSignUrlQuery({
 *   variables: {
 *      preferredReturnURL: // value for 'preferredReturnURL'
 *      documentID: // value for 'documentID'
 *      sharePurchaseID: // value for 'sharePurchaseID'
 *   },
 * });
 */
export function useGetDocuSignUrlQuery(
  baseOptions: Apollo.QueryHookOptions<GetDocuSignUrlQuery, GetDocuSignUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDocuSignUrlQuery, GetDocuSignUrlQueryVariables>(GetDocuSignUrlDocument, options);
}
export function useGetDocuSignUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDocuSignUrlQuery, GetDocuSignUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDocuSignUrlQuery, GetDocuSignUrlQueryVariables>(GetDocuSignUrlDocument, options);
}
export type GetDocuSignUrlQueryHookResult = ReturnType<typeof useGetDocuSignUrlQuery>;
export type GetDocuSignUrlLazyQueryHookResult = ReturnType<typeof useGetDocuSignUrlLazyQuery>;
export type GetDocuSignUrlQueryResult = Apollo.QueryResult<GetDocuSignUrlQuery, GetDocuSignUrlQueryVariables>;
export const SendHelloSignTemplateSignRequestDocument = gql`
  query sendHelloSignTemplateSignRequest($documentID: Int!, $sharePurchaseID: Int!) {
    sendHelloSignTemplateSignRequest(documentID: $documentID, sharePurchaseID: $sharePurchaseID)
    findSto(ID: 0) {
      helloSignClientID
    }
  }
`;

/**
 * __useSendHelloSignTemplateSignRequestQuery__
 *
 * To run a query within a React component, call `useSendHelloSignTemplateSignRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendHelloSignTemplateSignRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendHelloSignTemplateSignRequestQuery({
 *   variables: {
 *      documentID: // value for 'documentID'
 *      sharePurchaseID: // value for 'sharePurchaseID'
 *   },
 * });
 */
export function useSendHelloSignTemplateSignRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    SendHelloSignTemplateSignRequestQuery,
    SendHelloSignTemplateSignRequestQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SendHelloSignTemplateSignRequestQuery, SendHelloSignTemplateSignRequestQueryVariables>(
    SendHelloSignTemplateSignRequestDocument,
    options,
  );
}
export function useSendHelloSignTemplateSignRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SendHelloSignTemplateSignRequestQuery,
    SendHelloSignTemplateSignRequestQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SendHelloSignTemplateSignRequestQuery, SendHelloSignTemplateSignRequestQueryVariables>(
    SendHelloSignTemplateSignRequestDocument,
    options,
  );
}
export type SendHelloSignTemplateSignRequestQueryHookResult = ReturnType<
  typeof useSendHelloSignTemplateSignRequestQuery
>;
export type SendHelloSignTemplateSignRequestLazyQueryHookResult = ReturnType<
  typeof useSendHelloSignTemplateSignRequestLazyQuery
>;
export type SendHelloSignTemplateSignRequestQueryResult = Apollo.QueryResult<
  SendHelloSignTemplateSignRequestQuery,
  SendHelloSignTemplateSignRequestQueryVariables
>;
export const GetTxIdFromBuyAlertIdDocument = gql`
  query getTxIdFromBuyAlertID($buyAlertID: Int!) {
    getTxIdFromBuyAlertID(buyAlertID: $buyAlertID)
  }
`;

/**
 * __useGetTxIdFromBuyAlertIdQuery__
 *
 * To run a query within a React component, call `useGetTxIdFromBuyAlertIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTxIdFromBuyAlertIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTxIdFromBuyAlertIdQuery({
 *   variables: {
 *      buyAlertID: // value for 'buyAlertID'
 *   },
 * });
 */
export function useGetTxIdFromBuyAlertIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetTxIdFromBuyAlertIdQuery, GetTxIdFromBuyAlertIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTxIdFromBuyAlertIdQuery, GetTxIdFromBuyAlertIdQueryVariables>(
    GetTxIdFromBuyAlertIdDocument,
    options,
  );
}
export function useGetTxIdFromBuyAlertIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTxIdFromBuyAlertIdQuery, GetTxIdFromBuyAlertIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTxIdFromBuyAlertIdQuery, GetTxIdFromBuyAlertIdQueryVariables>(
    GetTxIdFromBuyAlertIdDocument,
    options,
  );
}
export type GetTxIdFromBuyAlertIdQueryHookResult = ReturnType<typeof useGetTxIdFromBuyAlertIdQuery>;
export type GetTxIdFromBuyAlertIdLazyQueryHookResult = ReturnType<typeof useGetTxIdFromBuyAlertIdLazyQuery>;
export type GetTxIdFromBuyAlertIdQueryResult = Apollo.QueryResult<
  GetTxIdFromBuyAlertIdQuery,
  GetTxIdFromBuyAlertIdQueryVariables
>;
export const InvestorSharesDocument = gql`
  query investorShares($getTrueBalances: Boolean, $stoID: Int) {
    investorShares(getTrueBalances: $getTrueBalances, stoID: $stoID) {
      ID
      stoID
      sharesHistoryID
      investorID
      shares
      isBlockchainAuthorized
      isBlockchainFrozen
      shareTypeID
      shareType {
        ID
        title
        stoID
        totalShares
        companyShares
        nominalValue
        custodianShares
        isBlockchain
        premiumValue
        currencyID
        sellToCompany
        sellValue
        isShareNosApplicable
        isCertificateNosApplicable
        isInvestorTradable
        currency {
          ID
          currency
          abbreviation
          symbol
          isBlockchainBased
        }
        minimumSharesToBuyByInvestor
        availableShare
        totalPrice
        blockchaindecimals
      }
    }
  }
`;

/**
 * __useInvestorSharesQuery__
 *
 * To run a query within a React component, call `useInvestorSharesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorSharesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorSharesQuery({
 *   variables: {
 *      getTrueBalances: // value for 'getTrueBalances'
 *      stoID: // value for 'stoID'
 *   },
 * });
 */
export function useInvestorSharesQuery(
  baseOptions?: Apollo.QueryHookOptions<InvestorSharesQuery, InvestorSharesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestorSharesQuery, InvestorSharesQueryVariables>(InvestorSharesDocument, options);
}
export function useInvestorSharesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestorSharesQuery, InvestorSharesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestorSharesQuery, InvestorSharesQueryVariables>(InvestorSharesDocument, options);
}
export type InvestorSharesQueryHookResult = ReturnType<typeof useInvestorSharesQuery>;
export type InvestorSharesLazyQueryHookResult = ReturnType<typeof useInvestorSharesLazyQuery>;
export type InvestorSharesQueryResult = Apollo.QueryResult<InvestorSharesQuery, InvestorSharesQueryVariables>;
export const GetInvestorShareDocument = gql`
  query getInvestorShare($ID: Int!) {
    investorShare(ID: $ID) {
      shareType {
        ID
        title
        stoID
        nominalValue
        premiumValue
        isBlockchain
        blockchainProtocolID
        currency {
          ID
          symbol
          Address
          abbreviation
        }
        tradingDetails {
          minTradeValue
          maxTradeValue
          openTradeDate
          closeTradeDate
        }
      }
      shares
    }
  }
`;

/**
 * __useGetInvestorShareQuery__
 *
 * To run a query within a React component, call `useGetInvestorShareQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestorShareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestorShareQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetInvestorShareQuery(
  baseOptions: Apollo.QueryHookOptions<GetInvestorShareQuery, GetInvestorShareQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvestorShareQuery, GetInvestorShareQueryVariables>(GetInvestorShareDocument, options);
}
export function useGetInvestorShareLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInvestorShareQuery, GetInvestorShareQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvestorShareQuery, GetInvestorShareQueryVariables>(GetInvestorShareDocument, options);
}
export type GetInvestorShareQueryHookResult = ReturnType<typeof useGetInvestorShareQuery>;
export type GetInvestorShareLazyQueryHookResult = ReturnType<typeof useGetInvestorShareLazyQuery>;
export type GetInvestorShareQueryResult = Apollo.QueryResult<GetInvestorShareQuery, GetInvestorShareQueryVariables>;
export const GetInvestorSharesByTypeDocument = gql`
  query getInvestorSharesByType($shareTypeID: Int!) {
    investorShare(shareTypeID: $shareTypeID) {
      shares
    }
  }
`;

/**
 * __useGetInvestorSharesByTypeQuery__
 *
 * To run a query within a React component, call `useGetInvestorSharesByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestorSharesByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestorSharesByTypeQuery({
 *   variables: {
 *      shareTypeID: // value for 'shareTypeID'
 *   },
 * });
 */
export function useGetInvestorSharesByTypeQuery(
  baseOptions: Apollo.QueryHookOptions<GetInvestorSharesByTypeQuery, GetInvestorSharesByTypeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvestorSharesByTypeQuery, GetInvestorSharesByTypeQueryVariables>(
    GetInvestorSharesByTypeDocument,
    options,
  );
}
export function useGetInvestorSharesByTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInvestorSharesByTypeQuery, GetInvestorSharesByTypeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvestorSharesByTypeQuery, GetInvestorSharesByTypeQueryVariables>(
    GetInvestorSharesByTypeDocument,
    options,
  );
}
export type GetInvestorSharesByTypeQueryHookResult = ReturnType<typeof useGetInvestorSharesByTypeQuery>;
export type GetInvestorSharesByTypeLazyQueryHookResult = ReturnType<typeof useGetInvestorSharesByTypeLazyQuery>;
export type GetInvestorSharesByTypeQueryResult = Apollo.QueryResult<
  GetInvestorSharesByTypeQuery,
  GetInvestorSharesByTypeQueryVariables
>;
export const InvestorShareDocument = gql`
  query investorShare($stoID: Int!) {
    investorShare(stoID: $stoID) {
      shareType {
        ID
        title
        stoID
        nominalValue
        premiumValue
        isBlockchain
        currency {
          ID
          symbol
        }
      }
      shares
    }
  }
`;

/**
 * __useInvestorShareQuery__
 *
 * To run a query within a React component, call `useInvestorShareQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorShareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorShareQuery({
 *   variables: {
 *      stoID: // value for 'stoID'
 *   },
 * });
 */
export function useInvestorShareQuery(
  baseOptions: Apollo.QueryHookOptions<InvestorShareQuery, InvestorShareQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvestorShareQuery, InvestorShareQueryVariables>(InvestorShareDocument, options);
}
export function useInvestorShareLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvestorShareQuery, InvestorShareQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvestorShareQuery, InvestorShareQueryVariables>(InvestorShareDocument, options);
}
export type InvestorShareQueryHookResult = ReturnType<typeof useInvestorShareQuery>;
export type InvestorShareLazyQueryHookResult = ReturnType<typeof useInvestorShareLazyQuery>;
export type InvestorShareQueryResult = Apollo.QueryResult<InvestorShareQuery, InvestorShareQueryVariables>;
export const GetSharesWalletsDocument = gql`
  query getSharesWallets($shareTypeID: Int!, $platform: Boolean) {
    getSharesWallets(shareTypeID: $shareTypeID, platform: $platform) {
      ID
      isBlocked
      publicKey
      shares
    }
  }
`;

/**
 * __useGetSharesWalletsQuery__
 *
 * To run a query within a React component, call `useGetSharesWalletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSharesWalletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSharesWalletsQuery({
 *   variables: {
 *      shareTypeID: // value for 'shareTypeID'
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useGetSharesWalletsQuery(
  baseOptions: Apollo.QueryHookOptions<GetSharesWalletsQuery, GetSharesWalletsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSharesWalletsQuery, GetSharesWalletsQueryVariables>(GetSharesWalletsDocument, options);
}
export function useGetSharesWalletsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSharesWalletsQuery, GetSharesWalletsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSharesWalletsQuery, GetSharesWalletsQueryVariables>(GetSharesWalletsDocument, options);
}
export type GetSharesWalletsQueryHookResult = ReturnType<typeof useGetSharesWalletsQuery>;
export type GetSharesWalletsLazyQueryHookResult = ReturnType<typeof useGetSharesWalletsLazyQuery>;
export type GetSharesWalletsQueryResult = Apollo.QueryResult<GetSharesWalletsQuery, GetSharesWalletsQueryVariables>;
export const GetAllSharesWalletsDocument = gql`
  query getAllSharesWallets {
    getSharesWallets(platform: false) {
      ID
      isBlocked
      publicKey
      shares
      shareType {
        ID
        title
        ethereumContractAddress
        blockchaindecimals
        assetTag
        blockchainProtocolID
      }
    }
  }
`;

/**
 * __useGetAllSharesWalletsQuery__
 *
 * To run a query within a React component, call `useGetAllSharesWalletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSharesWalletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSharesWalletsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllSharesWalletsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllSharesWalletsQuery, GetAllSharesWalletsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllSharesWalletsQuery, GetAllSharesWalletsQueryVariables>(
    GetAllSharesWalletsDocument,
    options,
  );
}
export function useGetAllSharesWalletsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllSharesWalletsQuery, GetAllSharesWalletsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllSharesWalletsQuery, GetAllSharesWalletsQueryVariables>(
    GetAllSharesWalletsDocument,
    options,
  );
}
export type GetAllSharesWalletsQueryHookResult = ReturnType<typeof useGetAllSharesWalletsQuery>;
export type GetAllSharesWalletsLazyQueryHookResult = ReturnType<typeof useGetAllSharesWalletsLazyQuery>;
export type GetAllSharesWalletsQueryResult = Apollo.QueryResult<
  GetAllSharesWalletsQuery,
  GetAllSharesWalletsQueryVariables
>;
export const InvestorBuyPropertyUpdateDocument = gql`
  mutation investorBuyPropertyUpdate($stoID: Int!, $isEnabled: Boolean!, $investorID: Int) {
    investorBuyPropertyUpdate(stoID: $stoID, isEnabled: $isEnabled, investorID: $investorID)
  }
`;
export type InvestorBuyPropertyUpdateMutationFn = Apollo.MutationFunction<
  InvestorBuyPropertyUpdateMutation,
  InvestorBuyPropertyUpdateMutationVariables
>;

/**
 * __useInvestorBuyPropertyUpdateMutation__
 *
 * To run a mutation, you first call `useInvestorBuyPropertyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestorBuyPropertyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investorBuyPropertyUpdateMutation, { data, loading, error }] = useInvestorBuyPropertyUpdateMutation({
 *   variables: {
 *      stoID: // value for 'stoID'
 *      isEnabled: // value for 'isEnabled'
 *      investorID: // value for 'investorID'
 *   },
 * });
 */
export function useInvestorBuyPropertyUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InvestorBuyPropertyUpdateMutation,
    InvestorBuyPropertyUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InvestorBuyPropertyUpdateMutation, InvestorBuyPropertyUpdateMutationVariables>(
    InvestorBuyPropertyUpdateDocument,
    options,
  );
}
export type InvestorBuyPropertyUpdateMutationHookResult = ReturnType<typeof useInvestorBuyPropertyUpdateMutation>;
export type InvestorBuyPropertyUpdateMutationResult = Apollo.MutationResult<InvestorBuyPropertyUpdateMutation>;
export type InvestorBuyPropertyUpdateMutationOptions = Apollo.BaseMutationOptions<
  InvestorBuyPropertyUpdateMutation,
  InvestorBuyPropertyUpdateMutationVariables
>;
export const ResetOfferingProgressDocument = gql`
  mutation resetOfferingProgress($isEnabled: Boolean!, $stoId: Int!) {
    investorBuyPropertyUpdate(isEnabled: $isEnabled, stoID: $stoId)
  }
`;
export type ResetOfferingProgressMutationFn = Apollo.MutationFunction<
  ResetOfferingProgressMutation,
  ResetOfferingProgressMutationVariables
>;

/**
 * __useResetOfferingProgressMutation__
 *
 * To run a mutation, you first call `useResetOfferingProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetOfferingProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetOfferingProgressMutation, { data, loading, error }] = useResetOfferingProgressMutation({
 *   variables: {
 *      isEnabled: // value for 'isEnabled'
 *      stoId: // value for 'stoId'
 *   },
 * });
 */
export function useResetOfferingProgressMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetOfferingProgressMutation, ResetOfferingProgressMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetOfferingProgressMutation, ResetOfferingProgressMutationVariables>(
    ResetOfferingProgressDocument,
    options,
  );
}
export type ResetOfferingProgressMutationHookResult = ReturnType<typeof useResetOfferingProgressMutation>;
export type ResetOfferingProgressMutationResult = Apollo.MutationResult<ResetOfferingProgressMutation>;
export type ResetOfferingProgressMutationOptions = Apollo.BaseMutationOptions<
  ResetOfferingProgressMutation,
  ResetOfferingProgressMutationVariables
>;
export const SendLinkToFriendsDocument = gql`
  mutation sendLinkToFriends($data: ReferralInput!) {
    sendLinkToFriends(data: $data)
  }
`;
export type SendLinkToFriendsMutationFn = Apollo.MutationFunction<
  SendLinkToFriendsMutation,
  SendLinkToFriendsMutationVariables
>;

/**
 * __useSendLinkToFriendsMutation__
 *
 * To run a mutation, you first call `useSendLinkToFriendsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendLinkToFriendsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendLinkToFriendsMutation, { data, loading, error }] = useSendLinkToFriendsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendLinkToFriendsMutation(
  baseOptions?: Apollo.MutationHookOptions<SendLinkToFriendsMutation, SendLinkToFriendsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendLinkToFriendsMutation, SendLinkToFriendsMutationVariables>(
    SendLinkToFriendsDocument,
    options,
  );
}
export type SendLinkToFriendsMutationHookResult = ReturnType<typeof useSendLinkToFriendsMutation>;
export type SendLinkToFriendsMutationResult = Apollo.MutationResult<SendLinkToFriendsMutation>;
export type SendLinkToFriendsMutationOptions = Apollo.BaseMutationOptions<
  SendLinkToFriendsMutation,
  SendLinkToFriendsMutationVariables
>;
export const SetThemeConfigDocument = gql`
  mutation setThemeConfig($theme: String!) {
    setThemeConfig(theme: $theme)
  }
`;
export type SetThemeConfigMutationFn = Apollo.MutationFunction<SetThemeConfigMutation, SetThemeConfigMutationVariables>;

/**
 * __useSetThemeConfigMutation__
 *
 * To run a mutation, you first call `useSetThemeConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetThemeConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setThemeConfigMutation, { data, loading, error }] = useSetThemeConfigMutation({
 *   variables: {
 *      theme: // value for 'theme'
 *   },
 * });
 */
export function useSetThemeConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<SetThemeConfigMutation, SetThemeConfigMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetThemeConfigMutation, SetThemeConfigMutationVariables>(SetThemeConfigDocument, options);
}
export type SetThemeConfigMutationHookResult = ReturnType<typeof useSetThemeConfigMutation>;
export type SetThemeConfigMutationResult = Apollo.MutationResult<SetThemeConfigMutation>;
export type SetThemeConfigMutationOptions = Apollo.BaseMutationOptions<
  SetThemeConfigMutation,
  SetThemeConfigMutationVariables
>;
export const GetThemeConfigDocument = gql`
  query getThemeConfig {
    investorAppParameters {
      investorDashboardTheme
      leftSideMenuFont
      poweredByLabel
    }
  }
`;

/**
 * __useGetThemeConfigQuery__
 *
 * To run a query within a React component, call `useGetThemeConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThemeConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThemeConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetThemeConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<GetThemeConfigQuery, GetThemeConfigQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetThemeConfigQuery, GetThemeConfigQueryVariables>(GetThemeConfigDocument, options);
}
export function useGetThemeConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetThemeConfigQuery, GetThemeConfigQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetThemeConfigQuery, GetThemeConfigQueryVariables>(GetThemeConfigDocument, options);
}
export type GetThemeConfigQueryHookResult = ReturnType<typeof useGetThemeConfigQuery>;
export type GetThemeConfigLazyQueryHookResult = ReturnType<typeof useGetThemeConfigLazyQuery>;
export type GetThemeConfigQueryResult = Apollo.QueryResult<GetThemeConfigQuery, GetThemeConfigQueryVariables>;
export const TranslationLoadDocument = gql`
  query translationLoad($locale: String) {
    translations(locale: $locale) {
      key
      locale
      translation
    }
    locales
  }
`;

/**
 * __useTranslationLoadQuery__
 *
 * To run a query within a React component, call `useTranslationLoadQuery` and pass it any options that fit your needs.
 * When your component renders, `useTranslationLoadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTranslationLoadQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useTranslationLoadQuery(
  baseOptions?: Apollo.QueryHookOptions<TranslationLoadQuery, TranslationLoadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TranslationLoadQuery, TranslationLoadQueryVariables>(TranslationLoadDocument, options);
}
export function useTranslationLoadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TranslationLoadQuery, TranslationLoadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TranslationLoadQuery, TranslationLoadQueryVariables>(TranslationLoadDocument, options);
}
export type TranslationLoadQueryHookResult = ReturnType<typeof useTranslationLoadQuery>;
export type TranslationLoadLazyQueryHookResult = ReturnType<typeof useTranslationLoadLazyQuery>;
export type TranslationLoadQueryResult = Apollo.QueryResult<TranslationLoadQuery, TranslationLoadQueryVariables>;
export const UpdatesDocument = gql`
  query updates($_id: Int!, $offset: Int, $limit: Int) {
    investorUpdates(stoID: $_id, offset: $offset, limit: $limit) {
      ID
      stoID
      title
      details
      date
      coverphoto
    }
  }
`;

/**
 * __useUpdatesQuery__
 *
 * To run a query within a React component, call `useUpdatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatesQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useUpdatesQuery(baseOptions: Apollo.QueryHookOptions<UpdatesQuery, UpdatesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UpdatesQuery, UpdatesQueryVariables>(UpdatesDocument, options);
}
export function useUpdatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdatesQuery, UpdatesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UpdatesQuery, UpdatesQueryVariables>(UpdatesDocument, options);
}
export type UpdatesQueryHookResult = ReturnType<typeof useUpdatesQuery>;
export type UpdatesLazyQueryHookResult = ReturnType<typeof useUpdatesLazyQuery>;
export type UpdatesQueryResult = Apollo.QueryResult<UpdatesQuery, UpdatesQueryVariables>;
export const UpdateDocument = gql`
  query update($_id: Int!) {
    investorUpdate(ID: $_id) {
      ID
      title
      stoID
      details
      date
    }
  }
`;

/**
 * __useUpdateQuery__
 *
 * To run a query within a React component, call `useUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useUpdateQuery(baseOptions: Apollo.QueryHookOptions<UpdateQuery, UpdateQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UpdateQuery, UpdateQueryVariables>(UpdateDocument, options);
}
export function useUpdateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateQuery, UpdateQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UpdateQuery, UpdateQueryVariables>(UpdateDocument, options);
}
export type UpdateQueryHookResult = ReturnType<typeof useUpdateQuery>;
export type UpdateLazyQueryHookResult = ReturnType<typeof useUpdateLazyQuery>;
export type UpdateQueryResult = Apollo.QueryResult<UpdateQuery, UpdateQueryVariables>;
export const GetUsernameByIdDocument = gql`
  query GetUsernameByID($userID: Int!) {
    getUsernameByID(userID: $userID)
  }
`;

/**
 * __useGetUsernameByIdQuery__
 *
 * To run a query within a React component, call `useGetUsernameByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsernameByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsernameByIdQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useGetUsernameByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetUsernameByIdQuery, GetUsernameByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsernameByIdQuery, GetUsernameByIdQueryVariables>(GetUsernameByIdDocument, options);
}
export function useGetUsernameByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUsernameByIdQuery, GetUsernameByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsernameByIdQuery, GetUsernameByIdQueryVariables>(GetUsernameByIdDocument, options);
}
export type GetUsernameByIdQueryHookResult = ReturnType<typeof useGetUsernameByIdQuery>;
export type GetUsernameByIdLazyQueryHookResult = ReturnType<typeof useGetUsernameByIdLazyQuery>;
export type GetUsernameByIdQueryResult = Apollo.QueryResult<GetUsernameByIdQuery, GetUsernameByIdQueryVariables>;
export const GetFullNameByIdDocument = gql`
  query GetFullNameByID($userID: Int!) {
    getFullNameByID(userID: $userID)
  }
`;

/**
 * __useGetFullNameByIdQuery__
 *
 * To run a query within a React component, call `useGetFullNameByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFullNameByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFullNameByIdQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useGetFullNameByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetFullNameByIdQuery, GetFullNameByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFullNameByIdQuery, GetFullNameByIdQueryVariables>(GetFullNameByIdDocument, options);
}
export function useGetFullNameByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFullNameByIdQuery, GetFullNameByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFullNameByIdQuery, GetFullNameByIdQueryVariables>(GetFullNameByIdDocument, options);
}
export type GetFullNameByIdQueryHookResult = ReturnType<typeof useGetFullNameByIdQuery>;
export type GetFullNameByIdLazyQueryHookResult = ReturnType<typeof useGetFullNameByIdLazyQuery>;
export type GetFullNameByIdQueryResult = Apollo.QueryResult<GetFullNameByIdQuery, GetFullNameByIdQueryVariables>;
