import { type Chain } from 'viem';

/*
Since wagmi which is part of the WalletConnect SDK does not support the Libex blockchain by default,
 it needed to be implemented as a custom chain utilizing the Chain object from the wagmi library
*/

export const libexTestnet = {
  id: 2702,
  name: 'Libex Testnet',
  network: 'libexTestnet',
  nativeCurrency: {
    decimals: 18,
    name: 'LIBEX',
    symbol: 'LBX',
  },
  rpcUrls: {
    public: { http: ['https://test-rpc.libex.ai'] },
    default: { http: ['https://test-rpc.libex.ai'] },
  },
  blockExplorers: {
    etherscan: { name: 'LibexExplorer', url: 'https://test-explorer.libex.ai' },
    default: { name: 'LibexExplorer', url: 'https://test-explorer.libex.ai' },
  },
} as Chain;

export const libex = {
  id: 2701,
  name: 'Libex',
  network: 'libex',
  nativeCurrency: {
    decimals: 18,
    name: 'LIBEX',
    symbol: 'LBX',
  },
  rpcUrls: {
    public: { http: ['https://rpc.libex.ai'] },
    default: { http: ['https://rpc.libex.ai'] },
  },
  blockExplorers: {
    etherscan: { name: 'LibexExplorer', url: 'https://explorer.libex.ai' },
    default: { name: 'LibexExplorer', url: 'https://explorer.libex.ai' },
  },
} as Chain;

export const sepolia = {
  id: 11155111,
  network: 'sepolia',
  name: 'Sepolia',
  nativeCurrency: {
    name: 'Sepolia Ether',
    symbol: 'SEP',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://eth-sepolia.g.alchemy.com/v2/5yiTa2hWRefUdIki3OGt4buh8wyW4MJc'],
    },
    public: {
      http: ['https://rpc.sepolia.org'],
    },
  },
  blockExplorers: {
    etherscan: {
      name: 'Etherscan',
      url: 'https://sepolia.etherscan.io',
    },
    default: {
      name: 'Etherscan',
      url: 'https://sepolia.etherscan.io',
    },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 6507670,
    },
  },
  testnet: true,
} as Chain;
