import { Button, Typography } from "antd"
import { FC } from "react"

import notAvailableplaceholder from "../../../assets/images/service-unavailable.webp"
import "./NotAvailableSection.less"

const NotAvailableSection: FC = () => {
  const handleClick = () => {
    window.location.reload()
  }

  return (
    <section>
      <div className={"container not-available"}>
        {notAvailableplaceholder && <img src={notAvailableplaceholder} alt={"Service Unavailable"} className={"not-available-img"} />}
        <Typography.Title level={1} className={"heading"}>
          Temporarily unavailable
        </Typography.Title>
        <Typography.Paragraph className={"desc"}>We are sorry. Something went wrong on our end.
          Our engineers are working to resolve the issue. </Typography.Paragraph>
        <Button type={"primary"} onClick={handleClick} className={"btn"}>Refresh the page</Button>
      </div>
    </section>
  )
}

export { NotAvailableSection }
