import { Row, Typography } from "antd"
import { FC } from "react"
import { NavLink } from "react-router-dom"

import { ReactComponent as AboutUsIcon } from "../../assets/icons/AboutUs.svg"
import { ReactComponent as FaqsIcon } from "../../assets/icons/Faq.svg"
import { ReactComponent as HowItWorksIcon } from "../../assets/icons/HowItWorks.svg"
import { ReactComponent as WhySmartBlocksIcon } from "../../assets/icons/WhySmartBlocks.svg"

import Navigation from "./Navigation"

import "./index.less"

const getAboutUsSubmenuLink = (link: string, icon: React.ReactNode, label: string, text: string) => {
  return (
    <NavLink to={link} className={"submenu-link"}>
      {icon}
      <Row className={"submenu-text-wrapper"}>
        <Typography.Text className={"submenu-link-label"}>{label}</Typography.Text>
        <Typography.Text className={"submenu-link-text"}>{text}</Typography.Text>
      </Row>
    </NavLink>
  )
}

const aboutUsItems = [
  { key: "/about-us", label: getAboutUsSubmenuLink("/about-us", <AboutUsIcon />, "About us", "Our company overview") },
  { key: "/faq", label: getAboutUsSubmenuLink("/faq", <FaqsIcon />, "FAQs", "Common questions answered") },
  { key: "/how-smartblocks-works", label: getAboutUsSubmenuLink("/how-smartblocks-works", <HowItWorksIcon />, "How it Works", "Platform explanation") },
  { key: "/why-use-smartblocks", label: getAboutUsSubmenuLink("/why-use-smartblocks", <WhySmartBlocksIcon />, "Why SmartBlocks", "Platform advantages highlighted") },
]

const homeMenuItems = [
  { key: "/property-listings", label: <NavLink to={"https://www.newsletter.smartblocks.xyz/"}>Newsletter</NavLink> },  
  { key: "about-us", label: "About us", children: aboutUsItems, popupOffset: [-343, 27] } 
]

const investorMenuItems = [
  { key: "/property-listings", label: <NavLink to={"/property-listings"}>Property Listings</NavLink> },
  { key: "/dashboard", label: <NavLink to={"/dashboard"}>Dashboard</NavLink> },
  { key: "about-us", label: "About us", children: aboutUsItems, popupOffset: [-511, 27] } 
]

const HeaderMenu: FC<{ isHomeMenuItems: boolean, pathname: string }> = ({ isHomeMenuItems, pathname }) => {
  const token = localStorage.getItem("token")
  
  const menuItems = isHomeMenuItems && !token ? homeMenuItems : investorMenuItems
  
  return (
    <Navigation
      mode={"horizontal"}
      activeKey={pathname}
      defaultActiveFirst
      items={menuItems}
      triggerSubMenuAction={"click"}
    />
  )
}

export { HeaderMenu }
