import { Layout as BaseLayout, Row } from "antd"
import { FC, Suspense, useEffect } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

import { Header, Footer, Loader } from "."
import { Logo } from "../logo"

import { CookieBar } from "../ui/CookieBar/CookieBar"

const authPages = ["/login", "/sign-up", "/auth-action-choice", "/forgot-password", "/new-password/"]
const pagesWithFooter = ["/", "/property-listings", "/property/", "/cookie-policy", "/privacy-policy", "/terms-of-service", "/about-us", "/faq", "/how-smartblocks-works", "/why-use-smartblocks"]
const availableForAll = ["/", "/cookie-policy", "/privacy-policy", "/terms-of-service", "/verify/", "/about-us", "/faq", "/how-smartblocks-works", "/why-use-smartblocks"]

const Layout: FC = () => {  
  const path = useLocation().pathname
  const navigate = useNavigate()
  
  const width = window.visualViewport?.width  

  const checkPath = (array: string[]) => {
    return array.find(page => page === "/" ? path === page : (path === page || path.includes(page)))
  }

  const isDesktop = width && width >= 1280 
  const isAuthPage = checkPath(authPages)
  const hasPageFooter = checkPath(pagesWithFooter)
  const isAvailableForAll = checkPath(availableForAll) || isAuthPage   
  
  useEffect(() => {
    const token = localStorage.getItem("token")    
    !isAvailableForAll && !token && navigate("/login")
  }, [path, isAvailableForAll, navigate])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }, [path])
  
  return (
    <BaseLayout>
      <BaseLayout.Header style={{ position: "fixed", zIndex: 10, width: "100%", padding: 0, height: "96px", overflowX: "auto"}}>
        {!isAuthPage ? <Header /> : <div className={`container ${isDesktop ? "auth" : "limited"}`} ><Logo /></div>}
      </BaseLayout.Header>
      <main>
        <Suspense fallback={<Row align={"middle"} justify={"center"} style={{minHeight: "80vh"}} className={"layout-loader-wrapper"}><Loader /></Row>}>
          <Outlet />
          <CookieBar desktopOnMobile={!isDesktop} />
        </Suspense>
      </main>
      {hasPageFooter && (
        <BaseLayout.Footer style={{padding: "40px 0 40px 0"}}>
          <Footer />
        </BaseLayout.Footer>
      )}
    </BaseLayout>
  )
}

export { Layout }
