import { ApolloClient, ApolloLink, ApolloProvider, concat, InMemoryCache } from "@apollo/client"
import { ConfigProvider } from "antd"
import createUploadLink from "apollo-upload-client/createUploadLink.mjs"
import { memo, type FC } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import routes from "../../pages"
import theme from "../../themes"
import { ContextProvider, WagmiContextProvider } from "../utils"
import { v4 as uuidv4 } from 'uuid';

import "./index.less"

const httpLink = createUploadLink({
  uri: "https://api-smartblocks-dev.digishares.tech/gql",
  credentials: "same-origin",
})

const apolloMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("token")
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      idempotencyKey: uuidv4(),
    },
  }))

  return forward(operation)
})

export const client = new ApolloClient({
  link: concat(apolloMiddleware, httpLink as unknown as ApolloLink),
  connectToDevTools: import.meta.env.DEV,
  queryDeduplication: true,
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
  }),
})

const router = createBrowserRouter(routes)

const App: FC = memo(() => (
  <ApolloProvider client={client}>
    <ContextProvider>
        <ConfigProvider theme={theme}>
          <WagmiContextProvider>
            <RouterProvider router={router} />
          </WagmiContextProvider>
        </ConfigProvider>
      </ContextProvider>
  </ApolloProvider>
))

export default App

