// import { createContext } from 'react';

import { client } from "../components/app/index"

import {
  AdminMeDocument,
  AdminMeQuery,
  InvestorVerifyDocument,
  InvestorVerifyMutation,
  InvestorVerifyMutationVariables, 
  MeDocument,
  MeQuery,
  MeQueryVariables,
  OwnerSignInDocument,
  OwnerSignInMutation,
  OwnerSignInMutationVariables,
  SignInDocument,
  SignInMutation,
  SignInMutationVariables,
  SignUpDocument,
  SignUpMutation,
  SignUpMutationVariables,
} from "../graphql"

import { GqlError } from "../helpers"

export enum JwtRole {
  investor,
  admin,
  platformAdmin,
  api,
  // role used to verify 2FA
  twoFA,
}

export interface User {
  ID: number;
  stoID: number;
  role: JwtRole;
}
// export const StoProvider = StoContext.Provider;

const authService = {
	async signIn(email: string, password: string): Promise<void | string> {		
    try {
      const { data } = await client.mutate<SignInMutation, SignInMutationVariables>({
        mutation: SignInDocument,
        variables: {
          email,
          password,
          sto: 0,
        },
        fetchPolicy: "no-cache"
      });

      this.token = data?.signIn || '';

      const { data: investorData } = await client.query<MeQuery, MeQueryVariables>({
        query: MeDocument,
        fetchPolicy: "no-cache"
      });

      const investor = investorData.investorUser?.investor

      const user = {
        id: investor?.ID,
        firstName: investor?.firstName,
        lastName: investor?.lastName, 
      }

      this.sto = Number(investorData?.investorUser?.investorSto?.stoID) || 0;
      
      localStorage.setItem("user", JSON.stringify(user))

      await client.resetStore();
    } catch (e) {
      const gqlError = e as GqlError;
      return gqlError.message;
    }
  },

  async ownerSignIn(username: string, password: string): Promise<void | string> {		
    try {
      const { data } = await client.mutate<OwnerSignInMutation, OwnerSignInMutationVariables>({
        mutation: OwnerSignInDocument,
        variables: {
          username,
          password,
          sto: 2,
        },
        fetchPolicy: "no-cache"
      });

      this.token = data?.adminSignIn || '';

      const { data: dataOwner } = await client.query<AdminMeQuery>({
        query: AdminMeDocument,
        fetchPolicy: "no-cache"
      });

      localStorage.setItem("user", JSON.stringify(dataOwner.adminMe.user))
      
      this.sto = 2
      
      await client.resetStore();
    } catch (e) {		
      const gqlError = e as GqlError;
      return gqlError.message;
    }
  },

  async signUp(signUpInput: SignUpMutationVariables): Promise<number | string | undefined> {		
    try {
      const { data } = await client.mutate<SignUpMutation, SignUpMutationVariables>({
        mutation: SignUpDocument,
        variables: {
          ...signUpInput
        },
        fetchPolicy: "no-cache"
      }); 
      return data?.signUp
    } catch (e) {
      const gqlError = e as GqlError;
      return gqlError.message;
    }
  },

  async verify(secret: string): Promise<void> {
    try {
      const { errors } = await client.mutate<InvestorVerifyMutation, InvestorVerifyMutationVariables>({
        mutation: InvestorVerifyDocument,
        variables: {
          secret,
        },
        fetchPolicy: "no-cache"
      });

      if (errors?.length) {
        throw new Error(errors[0].message);
      }
    } catch (e) {
      const gqlError = e as GqlError;
      throw new Error(gqlError.message) ;
    }
  },

  async signOut(): Promise<void> {
    localStorage.removeItem('token');
    localStorage.removeItem('sto');
    localStorage.removeItem('user');
    localStorage.removeItem('kycProvider');
    localStorage.removeItem('kycStep');
    return client.cache.reset();
  },

//   async signInSSO(token: string) {
//     this.token = token;
//     this.sto = 0;

//     try {
//       const { data } = await client.query<MeQuery>({ query: MeDocument });
//       const { data: appParams } = await client.query<InvestorAppDataQuery>({ query: InvestorAppDataDocument });
//       await this.checkSSOMode(appParams);
//       await client.resetStore();
//       return data.investorUser?.investor.isTwoFactorEnabled;
//     } catch (e) {
//       localStorage.removeItem('token');
//       localStorage.removeItem('sto');
//       await client.cache.reset();
//       throw new Error('Invalid token');
//     }
//   },

//   async checkSSOMode(user: InvestorAppDataQuery) {
//     if (!user.investorAppParameters.isSSOModeEnabled) {
//       localStorage.removeItem('token');
//       localStorage.removeItem('sto');
//       await client.cache.reset();
//     }
// 	},
  
  get token(): string {
    return localStorage.getItem('token') || '';
  },

  set token(token: string) {
    localStorage.setItem('token', token);
  },

  get isLoggedIn(): boolean {
    return !!this.token;
  },

  get payload(): User {
    const { token } = this;
    return JSON.parse(atob(token.split('.')[1])) as User;
  },

  need2FA(): boolean {
    const { role } = this.payload;
    return role === JwtRole.twoFA;
  },

  get sto(): number {
    return Number(localStorage.getItem('sto')) || 0;
  },

  set sto(sto: number) {
    localStorage.setItem('sto', String(sto));
  }
}

export default authService;