import { FC, lazy } from "react"
import { Outlet, RouteObject } from "react-router-dom"

import DefaultLayout, { Loader } from "../components/layout"

const Home = lazy<FC>(() => import("./home"))
const PropertyListings = lazy<FC>(() => import("./property-listings"))
const Property = lazy<FC>(() => import("./property"))
const Investment = lazy<FC>(() => import("./investment"))
const InvestmentConfirmation = lazy<FC>(() => import("./investment-confirmation"))
const InvestmentSuccess = lazy<FC>(() => import("./success-payment"))
const LogIn = lazy<FC>(() => import("./login"))
const SignUp = lazy<FC>(() => import("./sign-up"))
const MyListings = lazy<FC>(() => import("./my-listings"))
const InvestorProfile = lazy<FC>(() => import("./investor-profile"))
const ActiveInvestments = lazy<FC>(() => import("./active-investments"))
const Dashboard = lazy<FC>(() => import("./dashboard"))
const Documents = lazy<FC>(() => import("./documents"))
const PrivacyPolicy = lazy<FC>(() => import("./privacy-policy"))
const TermsOfService = lazy<FC>(() => import("./terms-of-service"))
const Messages = lazy<FC>(() => import("./messages"))
const ForgotPassword = lazy<FC>(() => import("./forgot-password"))
const ResetPassword = lazy<FC>(() => import("./reset-password"))
const CookiePolicy = lazy<FC>(() => import("./cookie-policy"))
const DocumentSigning = lazy<FC>(() => import("./document-signing"))
const DocumentsSigningList = lazy<FC>(() => import("./documents-signing-list"))
const VerifyEmail = lazy<FC>(() => import("./verify-email"))
const Transactions = lazy<FC>(() => import("./transactions"))
const DocumentSigned = lazy<FC>(() => import("./document-signed"))
const AuthActionChoice = lazy<FC>(() => import("./auth-action-choice"))
const HowSmartBlocksWorks = lazy<FC>(() => import("./how-smartblocks-works"))
const WhyUseSmartblocks = lazy<FC>(() => import("./why-use-smartblocks"))
const AboutUs = lazy<FC>(() => import("./about-us"))
const Faq = lazy<FC>(() => import("./faq"))

const routes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Home,
        path: "",
        index: true,
      },
       {
        Component: SignUp,
        path: "/sign-up",
      },
      {
        Component: LogIn,
        path: "/login",
      },
      {
        Component: PropertyListings,
        path: "/property-listings",
      },
      {
        Component: Property,
        path: "/property/:id",
      },
      {
        Component: Investment,
        path: "/investment/:id",
      },
      {
        Component: Investment,
        path: "/investment/:id",
      },
      {
        Component: InvestmentConfirmation,
        path: "/investment/:id/confirmation",
      },
      {
        Component: InvestmentSuccess,
        path: "/investment/:id/success",
      },
      {
        Component: MyListings,
        path: "/my-listings",
      },
      {
        Component: InvestorProfile,
        path: "/profile/:id",
      },
      {
        Component: ActiveInvestments,
        path: "/active-investments",
      },
      {
        Component: Dashboard,
        path: "/dashboard",
      },
      {
        Component: Documents,
        path: "/documents",
      },
      {
        Component: PrivacyPolicy,
        path: "/privacy-policy",
      },
      {
        Component: HowSmartBlocksWorks,
        path: "/how-smartblocks-works",
      },
      {
        Component: WhyUseSmartblocks,
        path: "/why-use-smartblocks",
      },
      {
        Component: TermsOfService,
        path: "/terms-of-service",
      },
      {
        Component: CookiePolicy,
        path: "/cookie-policy",
      },
      {
        Component: Messages,
        path: "/messages",
      },
      {
        Component: ForgotPassword,
        path: "/forgot-password",
      },
      {
        Component: ResetPassword,
        path: "/new-password/:token",
      },
      {
        Component: DocumentSigning,
        path: "/document-signing/:buildingId/:invoice/:docId",
      },
      {
        Component: DocumentsSigningList,
        path: "/documents-signing/:id",
      },
      {
        Component: VerifyEmail,
        path: "/verify/:id",
      },
      {
        Component: Transactions,
        path: "/transactions",
      },
      {
        Component: DocumentSigned,
        path: "/document-signed/:buildingId/:invoice/:docId",
      },
      {
        Component: AuthActionChoice,
        path: "/auth-action-choice",
      },
      {
        Component: AboutUs,
        path: "/about-us",
      },
      {
        Component: Faq,
        path: "/faq",
      },
      {
        Component: Outlet,
        path: "*",
      },
    ],
  },
]

export default routes
