import { Badge, Button, Dropdown, Space, Tag, TagProps, Typography, notification } from "antd"
import { FC, useEffect, useMemo, useState } from "react"
import { NavLink } from "react-router-dom"

import { ReactComponent as NotificationIcon } from "../../assets/icons/NotificationsIcon.svg"

import { SenderType, useGetUnreadMessagesCountQuery } from "../../graphql"

export interface Notification {
  source: string;
  type: TagProps["color"];
  text: string;
  title: string;
}

const NotificationButton: FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [api, contextHolder] = notification.useNotification();

  const { data } = useGetUnreadMessagesCountQuery({
    variables: {
      sender: SenderType.Admin,
    },
    pollInterval: 4000,
    fetchPolicy: 'network-only',
  });

  const items = useMemo(
    () =>
      notifications?.map(({ source, type, title, text }, index) => ({
        key: index,
        label: (
          <NavLink to={source} className={"notification-wrapper"}>
            <Space direction={"vertical"} size={4} className={"notification"}>
              <Tag color={type}>{title}</Tag>
              <Typography.Text>{text}</Typography.Text>
            </Space>
          </NavLink>
        ),
      })),
    [notifications]
  );

  useEffect(() => {
    if (data?.getUnreadMessagesCount && data.getUnreadMessagesCount > 0) {
      const unreadMessagesNotificationIndex = notifications.findIndex(notification => notification.source === "/messages");

      if (unreadMessagesNotificationIndex !== -1) {
        const updatedNotifications = [...notifications];
        updatedNotifications[unreadMessagesNotificationIndex] = {
          ...updatedNotifications[unreadMessagesNotificationIndex],
          text: `You have ${data.getUnreadMessagesCount} unread messages`,
        };
        setNotifications(updatedNotifications);
      } else {
        setNotifications(prev => [
          ...prev,
          {
            source: "/messages",
            type: "blue-inverse",
            title: "New Message",
            text: `You have ${data.getUnreadMessagesCount} unread messages`,
          },
        ]);
      }
    } else {
      setNotifications([])
    }
  }, [data]);

  useEffect(() => {
    if (notifications && notifications.length > 0) {
      api.destroy()
      notifications[notifications.length - 1].source === "/messages" && api.info({
        message: "New message",
        description: "You received a new message",
        duration: 2,
      })
    }
  }, [items, api]);

  return (
  <div className={"notifications"}>
    {items && <Dropdown destroyPopupOnHide disabled={items.length === 0} rootClassName={`notifications-grid`} placement={"bottom"} menu={{ items }} trigger={["click"]}>
        <Button className={"icon-btn"} icon={notifications ? <Badge offset={[-6,6]} dot={notifications && notifications?.length > 0}> <NotificationIcon className={notifications && notifications?.length > 0 ? "active" : ""} /></Badge> : null} />
    </Dropdown>}
    {contextHolder}
  </div>
)
}

export { NotificationButton }
