import { useWeb3Modal } from "@web3modal/react"
import { Badge, Button, Dropdown, notification, Typography } from "antd"
import { FC, useEffect, useState } from "react"
import { useAccount, useConnect, useDisconnect } from "wagmi"

type ConnectWalletButtonProps = {
  isWalletConnectEnabled?: boolean
  className: string
}

const ConnectWalletButton: FC<ConnectWalletButtonProps> = ({ isWalletConnectEnabled, className }) => {
  const { address, isConnected } = useAccount()
  const { open } = useWeb3Modal()
  const { disconnect } = useDisconnect()
  const { connect, connectors } = useConnect()
  const [publicKey, setPublicKey] = useState("")

  useEffect(() => {
    if (isConnected) {
      setPublicKey(address as string)
      localStorage.setItem("publicWalletKey", address)
    } else {
      setPublicKey("")
    }
  }, [address, isConnected])

  const handleWalletConnect = async () => {
    if (!isConnected) {
      await open()
    } else {
      disconnect()
      localStorage.setItem("publicWalletKey", "")
    }
  }

  const handleInjectedWalletConnect = async () => {
    if (!isConnected) {
      await connect({ connector: connectors[1] })
    } else {
      disconnect()
    }
  }


  const items = [
    {
      key: "1",
      label: "Copy Wallet Address",
      onClick: () => {
        navigator.clipboard.writeText(publicKey).then(() => {
          notification.success({
            message: "Wallet Address Copied",
            description: publicKey,
          })
        }).catch(() => {
          notification.error({
            message: "Failed to copy wallet address",
          })
        })
      },
    },
    {
      key: "2",
      label: "Disconnect Wallet",
      onClick: () => {
        disconnect()
        localStorage.setItem("publicWalletKey", "")
      },
    },
  ]

  const connectedDropdown = (
    <Dropdown arrow destroyPopupOnHide rootClassName={`wallet-dropdown`} placement={"bottom"} menu={{ items }} trigger={["click"]}>
      <Button className={`${className} wallet`}>
        <Badge color="#305CE1"/>
        <Typography.Text>{"Connected"}</Typography.Text>
      </Button>
    </Dropdown>
  )

  return isConnected ? connectedDropdown : (
    <Button className={`${className} wallet`} onClick={isWalletConnectEnabled ? handleWalletConnect : handleInjectedWalletConnect}>
      <Typography.Text>{"Connect Wallet"}</Typography.Text>
    </Button>
  )
}

export { ConnectWalletButton }
