import { Select } from "antd"
import { FC, useState } from "react"

import { ReactComponent as ArrowIcon } from "../../../assets/icons/Arrow.svg"

import "./Select.less"

export type Option = {
  value: string
  label: string
}

type Props = {
  placeholder?: string
  options: Option[] 
  value?: string | null
  size: "small" | "big"
  bigPdLeft?: boolean
  width?: number 
  onChange?: (value: string ) => void
}

const SelectInput: FC<Props> = ({ placeholder, options, value, width, size, bigPdLeft, onChange }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const handleClick = () => { setIsDropdownVisible(prevState => !prevState) }
  
  return (
    <Select
      placeholder={placeholder}
      value={value || null}
      suffixIcon={<ArrowIcon className={`${size} ${isDropdownVisible && "rotated"}`} />}
      style={{
        width: `${width}px`        
      }}
      className={`${size} ${bigPdLeft && "big-padding-left"}`}      
      onChange={(value) => onChange && onChange(value)}
      onDropdownVisibleChange={handleClick}
    >
      {options &&
        !!options.length &&
        options.map(option => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
    </Select>
  )
}

export { SelectInput }
