import { Button, DatePicker, Form, Input, Row, Typography } from "antd"
import dayjs, { type Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { FC, useEffect, useMemo, useState } from "react"

import { ReactComponent as ResizeInputCornerIcon } from "../../../assets/icons/ResizeInputCorner.svg"

import { PhoneNumberInput } from "../../ui/PhoneNumberInput/PhoneNumberInput"
import { Option, SelectInput } from "../../ui/Select/Select"

import { useGeneralContext } from "../../utils"

import { isPhoneValid, getCountriesSelectList, parseJSON, User } from "../../../helpers"

import { Investor, MeDocument, InvestorProfileInput, useCountriesQuery, useInvestorProfileMutation } from "../../../graphql"

import "./PersonalInfoForm.less"

dayjs.extend(customParseFormat);

type FormValues = {
	address: string
	birthDate: Dayjs | null
  country: string
  driversLicenseID: string
	firstName: string
	lastName: string
	nationalID: string
	passportNumber: string
	phone: string
	state: string
	town: string
  zip: string
  kinname: string
  kinphone: string
  kinemail: string
  notes: string
}

const validateMessages = {
  required: "This field is required",
}

const PersonalInfoForm: FC<Partial<{ investor: Investor | undefined, notes: Maybe<string> }>> = ({ investor, notes }) => {
  const [isSubmitDisable, setIsSubmitDisable] = useState(true)
  const [docsRequiredMessage, setDocsRequiredMessage] = useState<string | null>(null)
  const [update] = useInvestorProfileMutation({ refetchQueries: [{ query: MeDocument }], fetchPolicy: "no-cache" });
  const { data: countries } = useCountriesQuery({fetchPolicy: "no-cache"})
  
  const { setUser } = useGeneralContext()
  
  const [form] = Form.useForm()  
  
  let user = parseJSON("user") as User
  
  const initialValues: FormValues = {
    address: investor?.address || "",
    birthDate: investor?.birthDate ? dayjs(investor?.birthDate) : null,
    country: investor?.country || "",
    driversLicenseID: investor?.driversLicenseID || "",
    firstName: investor?.firstName || "",
    lastName: investor?.lastName || "",
    nationalID: investor?.nationalID || "",
    passportNumber: investor?.passportNumber || "",
    phone: investor?.phone || "",
    state: investor?.state || "",
    town: investor?.town || "",
    zip: investor?.zip || "",
    kinname: investor?.kinname || "",
    kinphone: investor?.kinphone || "",
    kinemail: investor?.kinemail || "",
    notes: notes || "",
  }
 
  const countriesOptions = getCountriesSelectList(countries?.countries)
  
  const handlePhoneNumberChange = (value: string | undefined) => {
    form.setFieldValue("phone", value)
  } 

  const handleCountryChange = (value: string) => {    
    form.setFieldValue("country", value)
  }
	
  const handleSubmit = async (values: FormValues) => {
    if (values.passportNumber.trim() === "" && values.nationalID.trim() === "" && values.driversLicenseID.trim() === "") {
      setDocsRequiredMessage("Please fill at least one of fields: Passport Number, National ID or Drivers License ID")
      return
    }
    else {
      const data = {
        ...values,
        birthDate: values.birthDate?.format("YYYY-MM-DD") || ""        
      } as InvestorProfileInput
      
      try {
        await update({ variables: { data } })

        if (values.firstName !== user.firstName || values.lastName !== user.lastName) {
          user = {
            ...user,
            firstName: values.firstName,
            lastName: values.lastName
          }
          localStorage.setItem("user", JSON.stringify(user))
          setUser(user)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
	
  form.setFieldsValue(initialValues)  
	
  return (
    <Form      
      form={form}
      requiredMark={false}
      layout={"vertical"}
      validateMessages={validateMessages}
      onFieldsChange={() => setIsSubmitDisable(false)}
      onFinish={handleSubmit}
      className={"personal-info-form profile-content"}
    >
      <Row align={"middle"} justify={"space-between"} className={"form-title-wrapper"}>
        <Typography.Title level={2} className={"form-title"}>Investor Information</Typography.Title>
        <Button
          type={"primary"}
          htmlType={"submit"}
          className={"submit-btn"}
          disabled={isSubmitDisable}
        >
          Save Changes
        </Button>
      </Row>
      <Form.Item className={"form-block-wrapper"}>
        <Typography.Title level={3} className={"form-block-title"}>1. Personal Information</Typography.Title>
        <Row justify={"space-between"} className={"row-wrapper"}>
          <Form.Item
            label={(
              <Typography.Text className={"label"}>First Name
                <Typography.Text className={"required-mark"}>*</Typography.Text>
              </Typography.Text>
            )}
            name={"firstName"}
            rules={[
              { required: true },
              { max: 60, message: "Value can't be longer than 60 characters" },
              { min: 3, message: "Value can't be less than 3 characters" }
            ]}
          >
            <Input placeholder={"First Name"} className={"input"} />
          </Form.Item>
          <Form.Item
            label={(
              <Typography.Text className={"label"}>Last Name
                <Typography.Text className={"required-mark"}>*</Typography.Text>
              </Typography.Text>
            )}
            name={"lastName"}
            rules={[
              { required: true },
              { max: 60, message: "Value can't be longer than 60 characters" },
              { min: 3, message: "Value can't be less than 3 characters" }
            ]}
          >
            <Input placeholder={"Last Name"} className={"input"} />
          </Form.Item>
        </Row>
        <Form.Item
          label={(
            <Typography.Text className={"label"}>Phone Number
              <Typography.Text className={"required-mark"}>*</Typography.Text>
            </Typography.Text>
          )}
          name={"phone"}
          validateTrigger={"onFinish"}
          rules={[
            { required: true },
            {
              validator: () => {
                const isValid = isPhoneValid(form.getFieldValue("phone"))
                if (!isValid) {
                  return Promise.reject("Phone is not valid")
                } else return Promise.resolve()
              },
            }
          ]}
        >
          <PhoneNumberInput
            value={form.getFieldValue("phone")}
            userCountry={form.getFieldValue("country")}
            onChange={handlePhoneNumberChange}
          />
        </Form.Item>
        <Form.Item
          label={(
            <Typography.Text className={"label"}>Date of Birth
              <Typography.Text className={"required-mark"}>*</Typography.Text>
            </Typography.Text>
          )}
          name={"birthDate"}
          rules={[{ required: true }]}
        >
          <DatePicker placeholder={"DD.MM.YYYY"} format={"DD.MM.YYYY"} />
        </Form.Item>
        <Row justify={"space-between"} className={"row-wrapper"}>
          <Form.Item
            label={(
              <Typography.Text className={"label"}>Passport Number
                <Typography.Text className={"required-mark"}>*</Typography.Text>
              </Typography.Text>
            )}
            name={"passportNumber"}            
            rules={[{ max: 45, message: "Value can't be longer than 45 characters"}]}
          >
            <Input placeholder={"Passport Number"} className={"input"} />
          </Form.Item>
          <Form.Item
            label={(
              <Typography.Text className={"label"}>National ID
                <Typography.Text className={"required-mark"}>*</Typography.Text>
              </Typography.Text>
            )}
            name={"nationalID"}
            rules={[{ max: 60, message: "Value can't be longer than 60 characters" }]}
          >
            <Input placeholder={"National ID"} className={"input"} />
          </Form.Item>          
        </Row>
        <Form.Item className={"docs"}>
          <Form.Item
            label={(
              <Typography.Text className={"label"}>Drivers License ID
                <Typography.Text className={"required-mark"}>*</Typography.Text>
              </Typography.Text>
            )}
            name={"driversLicenseID"}
            help={"At least one of fields is required: Passport Number, National ID or Drivers License ID"}
            rules={[{ max: 60, message: "Value can't be longer than 60 characters" }]}
          >
            <Input placeholder={"Drivers License ID"} className={"input"} />
          </Form.Item>
          {docsRequiredMessage && (
            <Typography.Text className={"error"}>
              {docsRequiredMessage}
            </Typography.Text>
          )}
        </Form.Item>
        <Row justify={"space-between"} className={"row-wrapper"}>
          <Form.Item
            label={(
              <Typography.Text className={"label"}>Country
                <Typography.Text className={"required-mark"}>*</Typography.Text>
              </Typography.Text>
            )}
            name={"country"}
            rules={[{ required: true }]}
          >
            <SelectInput
              options={countriesOptions as Option[]}
              value={form.getFieldValue("country")}
              onChange={handleCountryChange}
              placeholder={"Country"}
              size={"big"}
            />
          </Form.Item>
          <Form.Item
            label={(
              <Typography.Text className={"label"}>State
                <Typography.Text className={"required-mark"}>*</Typography.Text>
              </Typography.Text>
            )}
            name={"state"}
            rules={[
              { required: true },
              { max: 50, message: "Value can't be longer than 50 characters" }
            ]}
          >
            <Input placeholder={"State"} className={"input"} />
          </Form.Item>
        </Row>
        <Row justify={"space-between"} className={"row-wrapper"}>
          <Form.Item
            label={(
              <Typography.Text className={"label"}>City
                <Typography.Text className={"required-mark"}>*</Typography.Text>
              </Typography.Text>
            )}
            name={"town"}
            rules={[
              { required: true },
              { max: 50, message: "Value can't be longer than 50 characters" }
            ]}
          >
            <Input placeholder={"City"} className={"input"} />
          </Form.Item> 
          <Form.Item
            label={(
              <Typography.Text className={"label"}>Zip
                <Typography.Text className={"required-mark"}>*</Typography.Text>
              </Typography.Text>
            )}
            name={"zip"}
            rules={[
              { required: true },
              { max: 28, message: "Value can't be longer than 28 characters" }
            ]}
          >
            <Input placeholder={"Zip"} className={"input"} />
          </Form.Item>
        </Row>
        <Form.Item
          label={(
            <Typography.Text className={"label"}>Street Address
              <Typography.Text className={"required-mark"}>*</Typography.Text>
            </Typography.Text>
          )}
          name={"address"}
          rules={[
            { required: true },
            { max: 150, message: "Value can't be longer than 150 characters" }
          ]}
          className={"last-block-row"}
        >
          <Input placeholder={"Street Address"} className={"input"} />
        </Form.Item>
      </Form.Item>
      <Form.Item className={"form-block-wrapper"}>
        <Typography.Title level={3} className={"form-block-title"}>2. Backup Admin</Typography.Title>
        <Form.Item
          label={"Full Name"}
          name={"kinname"}
          rules={[
            { max: 70, message: "Value can't be longer than 70 characters" }
          ]}
        >
          <Input placeholder={"Full Name"} className={"input"} />
        </Form.Item>
        <Row justify={"space-between"} className={"row-wrapper"}>
          <Form.Item
            label={"Phone Number"}
            name={"kinphone"}
            validateTrigger={"onFinish"}
            rules={[{
              validator: () => {
                const isValid = isPhoneValid(form.getFieldValue("phone"))
                if (!isValid) {
                  return Promise.reject("Phone is not valid")
                } else return Promise.resolve()
              },
            }]}
            className={"last-block-row"}
          >
            <PhoneNumberInput
              value={form.getFieldValue("kinphone")}
              userCountry={form.getFieldValue("country")}
              onChange={handlePhoneNumberChange}
            />
          </Form.Item>
          <Form.Item
            label={"Email"}
            name={"kinemail"}
            rules={[
              { max: 30, message: "Value can't be longer than 30 characters" },
              {
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Value must be a valid email"
              }
            ]}
            className={"last-block-row"}
          >
            <Input type={"email"} placeholder={"Email"} className={"input"} />
          </Form.Item>
        </Row>
      </Form.Item>
      <Form.Item className={"form-block-wrapper"}>
        <Typography.Title level={3} className={"form-block-title"}>3. Investor Notes</Typography.Title>
        <Typography.Paragraph className={"help-text"}>Enter any notes, additional information you may want to share with the administration. The administration can also modify or add to these notes.</Typography.Paragraph>
        <Form.Item name={"notes"} className={"notes-wrapper"}>
          <Input.TextArea placeholder={"Enter Investor Notes"} className={"text-area"} onChange={(e) => form.setFieldValue("notes", e.target.value)} />          
        </Form.Item>
        <ResizeInputCornerIcon />
      </Form.Item>      
    </Form>
  )
}

export { PersonalInfoForm }
