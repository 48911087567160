import { FC } from "react"
import { PhoneInput, defaultCountries } from "react-international-phone"

import "react-international-phone/style.css"

import fsFlag from "../../../assets/icons/FsFlag.svg"

import "./PhoneNumberInput.less"

defaultCountries.splice(3, 0, ["American Samoa", "as", "1684"])
defaultCountries.splice(6, 0, ["Anguilla", "ai", "1264"], ["Antarctica", "aq", "672"])
defaultCountries.splice(23, 0, ["Bermuda", "bm", "1441"])
defaultCountries.splice(39, 0, ["Cayman Islands", "ky", "1345"])
defaultCountries.splice(44, 0, ["Christmas Island", "cx", "61"], ["Cocos Islands", "cc", "61"])
defaultCountries.splice(50, 0, ["Cook Islands", "ck", "682"])
defaultCountries.splice(69, 0, ["Falkland Islands", "fk", "500"], ["Faroe Islands", "fo", "298"])
defaultCountries.splice(76, 0, ["French Southern Territories", "fs", "262"])
defaultCountries.splice(82, 0, ["Gibraltar", "gi", "350"])
defaultCountries.splice(84, 0, ["Greenland", "gl", "299"])
defaultCountries.splice(93, 0, ["Heard and McDonald Islands", "hm", "672"])
defaultCountries.splice(111, 1)
defaultCountries.splice(134, 0, ["Mayotte", "yt", "262"])
defaultCountries.splice(140, 1, ["Montserrat", "ms", "1664"])
defaultCountries.splice(153, 1, ["Niue", "nu", "683"], ["Norfolk Island", "nf", "672"], ["Northern Mariana Islands", "mp", "1670"])
defaultCountries.splice(160, 1)
defaultCountries.splice(165, 0, ["Pitcairn", "pn", "64"])
defaultCountries.splice(192, 1, ["South Georgia and the South Sandwich Islands", "gs", "500"])
defaultCountries.splice(195, 0, ["Saint Helena", "sh", "290"], ["Saint Pierre and Miquelon", "pm", "508"])
defaultCountries.splice(199, 0, ["Svalbard and Jan Mayen", "sj", "47"])
defaultCountries.splice(210, 0, ["Tokelau", "tk", "690"])
defaultCountries.splice(216, 0, ["Turks and Caicos Islands", "tc", "1649"])
defaultCountries.splice(223, 0, ["United States Minor Outlying Islands", "um", "1"])
defaultCountries.splice(230, 0, ["Virgin Islands (British)", "vg", "1284"], ["Virgin Islands (U.S.)", "vi", "1340"], ["Wallis and Futuna Islands", "wf", "681"], ["Western Sahara", "eh", "212"])

const PhoneNumberInput: FC<{ value: string | undefined, userCountry?: string | undefined, onChange: (value: string | undefined) => void }> = ({ value, userCountry, onChange }) => {
  
  const defaultCountry = defaultCountries.find(country => country[0] === userCountry)?.[1]
  
  const customFlags = [{
    iso2: 'fs',
    src: fsFlag,
  }]  

  return (
    <PhoneInput
      defaultCountry={defaultCountry || "us"}
      value={value}
      onChange={(value) => onChange(value)}
      className={"phone-input-wrapper"}
      inputClassName={"phone-input"}
      inputProps={{
        type: "tel",
        id: "phone",
      }}
      countrySelectorStyleProps={{
        className: "phone-country-selector",
      }}
      countries={defaultCountries}
      flags={customFlags}
    />
  )
}

export { PhoneNumberInput }
