import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useContext, useState } from "react"

import { parseJSON, User } from "../../helpers"

import { InvestorWalletQuery, ShareType } from "../../graphql"


type InvestorWalletChannel = NonNullable<InvestorWalletQuery['investorPaymentChannels']>[0];

type ContextProps = {  
  user: User | undefined
  setUser: Dispatch<SetStateAction<User | undefined>> 
  investmentAmount: number | undefined
  setInvestmentAmount: Dispatch<SetStateAction<number | undefined>> 
  shares: number | undefined
  setShares: Dispatch<SetStateAction<number | undefined>>
  invoiceId?: number
  setInvoiceId: Dispatch<SetStateAction<number | undefined>>
  selectedShareType?: Partial<ShareType> | undefined
  setSelectedShareType: Dispatch<SetStateAction<Partial<ShareType> | undefined>>
  paymentChannel: InvestorWalletChannel | null
  setPaymentChannel: Dispatch<SetStateAction<InvestorWalletChannel | null>>
  currencyAddress: string
  setCurrencyAddress: Dispatch<SetStateAction<string>>
}

export const defaultValues: ContextProps = {
  user: parseJSON("user") as User,
  setUser: () => null, 
  investmentAmount: undefined,
  setInvestmentAmount: () => null,  
  shares: undefined,
  setShares: () => null,
  setInvoiceId: () => null,
  invoiceId: undefined,
  selectedShareType: undefined,
  setSelectedShareType: () => null,
  paymentChannel: null,
  setPaymentChannel: () => null,
  setCurrencyAddress: () => null,
  currencyAddress: "",
}

const Context = createContext(defaultValues)

const ContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState(defaultValues.user)
  const [investmentAmount, setInvestmentAmount] = useState(defaultValues.investmentAmount)  
  const [shares, setShares] = useState(defaultValues.shares)
  const [invoiceId, setInvoiceId] = useState<number | undefined>(undefined)
  const [selectedShareType, setSelectedShareType] = useState<Partial<ShareType> | undefined>(undefined)
  const [paymentChannel, setPaymentChannel] = useState<InvestorWalletChannel | null>(null)
  const [currencyAddress, setCurrencyAddress] = useState("")
  return (
    <Context.Provider
      value={{
        currencyAddress,
        setCurrencyAddress,
        user,
        setUser,
        investmentAmount,
        setInvestmentAmount,        
        shares,
        setShares,
        invoiceId,
        setInvoiceId,
        selectedShareType,
        setSelectedShareType,
        paymentChannel,
        setPaymentChannel
      }}
    >
      {children}
    </Context.Provider>
  )
}

const useGeneralContext = (): ContextProps => useContext(Context)

export { ContextProvider, useGeneralContext }
