import { EthereumClient, w3mConnectors, w3mProvider } from "@web3modal/ethereum"
import { Web3Modal } from "@web3modal/react"
import { FC, Fragment, PropsWithChildren } from "react"
import { WagmiConfig, configureChains, createConfig } from "wagmi"
import { bsc, bscTestnet, fantom, fantomTestnet, goerli, mainnet, polygon, polygonMumbai } from "wagmi/chains"
import { useInvestorAppDataQuery } from "../../graphql"
import { libex, libexTestnet, sepolia } from "./wagmiHelpers"
import { NotAvailableSection } from "../section"

const WagmiContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data: appData, loading: appDataLoad } = useInvestorAppDataQuery()
  const chains = [
    sepolia,
    goerli,
    mainnet,
    polygon,
    polygonMumbai,
    fantom,
    fantomTestnet,
    bsc,
    bscTestnet,
    libex,
    libexTestnet,
  ]
  const chainsMainnet = [mainnet, polygon, fantom, bsc, libex]
  const chainsTestnet = [sepolia, goerli, polygonMumbai, fantomTestnet, bscTestnet, libexTestnet]

  if (appDataLoad || !appData) {
    return <NotAvailableSection />
  }

  const { walletConnectProjectKey, isBlockchainTestnetEnabled } = appData.investorAppParameters
  const { publicClient } = configureChains(chains, [w3mProvider({ projectId: walletConnectProjectKey })])

  const wagmiClient = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({
      projectId: walletConnectProjectKey,
      chains,
    }),
    publicClient,
  })

  const ethereumClient = new EthereumClient(wagmiClient, isBlockchainTestnetEnabled ? chainsTestnet : chainsMainnet)

  return (
    <Fragment>
      <WagmiConfig config={wagmiClient}>{children}</WagmiConfig>
      <Web3Modal
        projectId={walletConnectProjectKey}
        ethereumClient={ethereumClient}
        enableNetworkView
        themeVariables={{
          "--w3m-z-index": "9999",
        }}
      />
    </Fragment>
  )
}
export { WagmiContextProvider }
