import { Badge, Button, Dropdown, Row, Space, Tag, Typography, notification } from "antd"
import { FC, Fragment } from "react"
import { useNavigate } from "react-router"

import { ReactComponent as AddIcon } from "../../assets/icons/Add.svg"

import { NotificationButton } from "./NotficationButton"
import { UserMenuDropdownBtn } from "./UserMenuDropdownBtn"

import { ConnectWalletButton } from "../ui/ConnectWalletBtn/ConnectWalletBtn"

import { useInvestorAppDataQuery } from "../../graphql"

import "./index.less"


const HomeBtns: FC = () => {
  const navigate = useNavigate()

  const handleLinkClick = (role: "investor" | "owner") => {
    localStorage.setItem("role", role)
    role === "investor" ? navigate("/login") : navigate("/auth-action-choice")
  }
  

  return (
    <Fragment>
      <Button type={"text"} onClick={() => handleLinkClick("owner")} className={"text-btn"}>
        List your Property
      </Button>
      <Button type={"default"} onClick={() => handleLinkClick("investor")} className={"outlined-btn"}>
        Log In
      </Button>
    </Fragment>
  )
}

const UserLoginedBtns: FC = () => {
  const { data } = useInvestorAppDataQuery({fetchPolicy: "no-cache"})

  const isPropertyOwner = localStorage.getItem("sto") === "2"

  return (
    <Fragment>
      {isPropertyOwner ? (
        <Button href={"/new-property"} className={"user-action-btn"}>
          <AddIcon />
          <Typography.Text>List Property</Typography.Text>
        </Button>
      ) : (
        <ConnectWalletButton
          isWalletConnectEnabled={data?.investorAppParameters.isInvestorDashboardConnectWalletEnabled}
          className={"user-action-btn"}
        />
      )}
      <NotificationButton />
      <UserMenuDropdownBtn />
    </Fragment>
  )
}

const UserMenu: FC<{ isUserLoggedIn: boolean }> = ({ isUserLoggedIn }) => {
  return (
    <Row align={"middle"} className={"user-menu-wrapper user-logined"}>
      {!isUserLoggedIn ? <HomeBtns /> : <UserLoginedBtns />}
    </Row>
  )
}

export { UserMenu }

