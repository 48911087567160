import { FC } from "react"

import { ReactComponent as LogoImage } from "../../assets/icons/Logo.svg"

import './index.less'
import { NavLink } from "react-router-dom"

const Logo: FC = () => {
  const isUserLoggedIn = !!localStorage.getItem("token")
  return (
    <NavLink to={isUserLoggedIn ? "/property-listings" : "/"} className={"logo-wrapper"}>
      <LogoImage className={"logo"} />
    </NavLink>
  )
}

export { Logo }
