import { Button, Card, Col, Divider, Row, Typography } from "antd";
import { FC } from "react"
import { NavLink } from "react-router-dom";

import { ReactComponent as InstagramIcon } from "../../../assets/icons/Instagram.svg"
import { ReactComponent as LinkedinIcon } from "../../../assets/icons/Linkedin.svg"

import "./Footer.less"

const Footer: FC = () => {
  return (
    <Card className={"footer container"}>      
      <Row className={"contacts-wrapper"}>
        <Col>
          {/* <Typography.Title level={2} className={"footer-title"}>Subscribe for Email Updates</Typography.Title>
          <Typography.Paragraph className={"description"}>Add your email below to receive updates from SmartBlocks Including notifications about new investments and properties</Typography.Paragraph> */}
          <iframe width={"100%"} src={"https://dittatech.activehosted.com/f/17"} style={{ height: "280px", border: "none", marginTop: "-14px"}}/>
        </Col>
        <Col>
          <Typography.Title level={3} className={"contact-type-title"}>Email</Typography.Title>
          <a href={"mailto:sbsales@smartblocks.agency"} className={"contact"}>sbsales@smartblocks.agency</a>
        </Col>
        <Col>
          <Typography.Title level={3} className={"contact-type-title"}>Address</Typography.Title>
          <Typography.Text className={"contact address"}>2647 Gateway Rd, #105-520, Carlsbad, Ca 92009</Typography.Text>
        </Col>
        <Col className={"legacy-col"}>
          <Typography.Title level={3} className={"contact-type-title"}>Legal Pages</Typography.Title>
          <NavLink to={"/privacy-policy"} className={"contact"}>Privacy Policy</NavLink>
          <NavLink to={"/terms-of-service"} className={"contact"}>Terms of Service</NavLink>
          <NavLink to={"/cookie-policy"} className={"contact"}>Cookies Policy</NavLink>
        </Col>		
      </Row>
      <Divider />
      <Row justify={"space-between"} align={"middle"}>
        <Typography.Text className={"rights"}>2024 SmartBlocks. All Rights Reserved.</Typography.Text>
        <div className={"soc-links-wrapper"}>
          <Button type={"link"} href={"https://www.instagram.com/smartblocks.xyz"} target={"_blank"} rel ={"noreferrer noopener"} className={"soc-link-btn"}><InstagramIcon /></Button>
          <Button type={"link"} href={"https://www.linkedin.com/company/86104509"} target={"_blank"} rel ={"noreferrer noopener"} className={"soc-link-btn"}><LinkedinIcon /></Button>
        </div>
      </Row>
    </Card>
  )
}

export { Footer }
