import { Button, Dropdown, Typography } from "antd"
import { FC, useState, useEffect } from "react"
import { NavLink, useNavigate } from "react-router-dom"

import { ReactComponent as ArrowIcon } from "../../assets/icons/Arrow.svg"

import authService from "../../api/auth"

import { User } from "../../helpers"

import "./index.less"

const UserMenuDropdownBtn: FC = () => {
  const [currentUser, setCurrentUser] = useState<User>()
  const navigate = useNavigate()

  const isPropertyOwner = localStorage.getItem("sto") === "2"
  const user = localStorage.getItem("user")
  const isSumsub = localStorage.getItem("kycProvider")

  const handleLogOutClick = async () => {
    await authService.signOut()
    navigate("/login")
  }

  useEffect(() => {
    const defaultUser = {
      id: "",
      lastName: "",
      firstName: "",
    }
    setCurrentUser(JSON.parse(user || "") || defaultUser)
  }, [user])

  const investorMenuList = [
    {
      key: 1,
      label: <NavLink to={`${isSumsub ? "/profile/sumsub" : "/profile/personal-information"}`}>My Profile</NavLink>
    },
    {
      key: 2,
      label: <NavLink to={"/active-investments"}>My Investments</NavLink>
    },
    {
      key: 3,
      label: <NavLink to={"/documents"}>Documents</NavLink>
    },
    {
      key: 6,
      label: <NavLink to={"/messages"}>Messages</NavLink>
    }
  ]

  const items = [
    {
      key: 5,
      label: (
        <Button block onClick={handleLogOutClick} className={"log-out-btn"}>
          Log Out
        </Button>
      )
    }
  ]

  !isPropertyOwner && items.unshift(...investorMenuList)

  return (
    <Dropdown      
      trigger={["click"]}
      placement={"bottomRight"}
      menu={{items}}
    >
      <Button type={"link"} className={"user-btn"}>
        <Typography.Text className={"user-name"}>{currentUser?.firstName} {currentUser?.lastName}</Typography.Text> 
        <ArrowIcon className={"arrow-icon"} />
      </Button>
    </Dropdown>
  )
}

export { UserMenuDropdownBtn }