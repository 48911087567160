import { BuyAlertStatus } from "../graphql"

export { calculateFundedAmount, calculateFundedPercent, calculateprojectCost, calculateSharesAmount } from "./calculations"
export { downloadFile } from "./downloadFiles"
export {
  findByTitle,
  getCountriesSelectList,
  getMetaCountriesList,
  getMetaPropertySubtypesList,
  getMetaPropertyTypesList,
  getPictureUrl,  
  isPictureUrl,
  numberUI
} from "./formatData"
export { parseJSON } from "./parseJson"
export { type GqlError, type User } from "./types"
export { checkPass, isPhoneValid } from "./validation"


export const allBuyAlerts = [
  BuyAlertStatus.Accepted,
  BuyAlertStatus.Pending,
  BuyAlertStatus.PendingDocuments,
  BuyAlertStatus.AccreditationPending,
  BuyAlertStatus.PaymentOngoing,
  BuyAlertStatus.AccreditationRequired,
  BuyAlertStatus.PaymentAwaiting,
  BuyAlertStatus.PaymentFailure,
  BuyAlertStatus.BlockchainProcessing,
  BuyAlertStatus.Unused,
  BuyAlertStatus.Declined,
]