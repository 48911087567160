import imgPlaceholder from "../assets/images/imgPlaceholder.png"

import { Option } from "../components/ui/Select/Select"

import { VisibleOfferings } from "../graphql"

export const findByTitle = (list: VisibleOfferings[], search: string): VisibleOfferings[] => search !== "" ? list?.filter(prop => prop.title.toLowerCase().includes(search.toLowerCase())) : list

export const getCountriesSelectList = (countries: string[] | undefined, notSorted?: boolean): Option[] | undefined => {
  const filteredCountries = countries?.filter(country => country !== "Bouvet Island" && country !== "France Metropolitan")
  const selectCountriesList = filteredCountries 
    ?.map(country => {
      switch (country) {
        case "Bosnia and Herzegowina": 
          return {
            countries: [{
              value: country,
              label: "Bosnia and Herzegovina"        
            }]
          }
        case "Brunei Darussalam": 
          return {
            countries: [{
              value: country,
              label: "Brunei"
            }]
          }
        case "Congo":
          return {
            countries: [{
              value: country,
              label: "Republic of the Congo"
            },
            {
              value: country,
              label: "Democratic Republic of the Congo"
            }]
          }
        case "Ivory Coast": 
          return {
            countries: [{
              value: country,
              label: "Côte d'Ivoire"
            }]
          }
        case "East Timor": 
          return {
            countries: [{
              value: country,
              label: "Timor-Leste"        
            }]
          }
        case "Holy See": 
          return {
            countries: [{
              value: country,
              label: "Vatican City"
            }]
          } 
        case "Korea": 
          return {
            countries: [{
              value: country,
              label: "South Korea"        
            }]
          }
        case "Lao": 
          return {
            countries: [{
              value: country,
              label: "Laos"        
            }]
          }        
        case "Libyan Arab Jamahiriya": 
          return {
            countries: [{
              value: country,
              label: "Libya"        
            }]
          }
        case "Republic of North Macedonia": 
          return {
            countries: [{
              value: country,
              label: "Macedonia"        
            }]
          }
        case "Saint LUCIA": 
          return {
            countries: [{
              value: country,
              label: "Saint Lucia"        
            }]
          }
        default: 
          return {
            countries: [{
              value: country,
              label: country        
            }]
          }
      }    
    })
    .flatMap(country => country.countries)
    
  return notSorted ? selectCountriesList : selectCountriesList?.sort((firstCountry, secondCountry) => firstCountry?.label.localeCompare(secondCountry?.label))
}

export const getMetaPropertyTypesList = (propertyList: VisibleOfferings[]): string[] => {
  const typesList = propertyList
    ?.map(prop => {
    const proptype = prop?.meta?.find(meta => meta.key === "PropertyType")?.value
    return proptype && proptype !== "" ? proptype : "Other"})
    ?.filter((type, index, array) => array.indexOf(type) === index)
    ?.sort((firstType, secondType) => firstType?.localeCompare(secondType as string) as number) ?? []
  const otherIndex = typesList.findIndex(country => country === "Other")
  otherIndex !== -1 && typesList.splice(otherIndex, 1) && typesList.push("Other")
  return typesList
}

export const getMetaCountriesList = (propertyList: VisibleOfferings[]): (string | undefined)[] | undefined => {
  const countriesList = propertyList 
    ?.map(prop => {
      const country = prop?.meta?.find(meta => meta.key === "Country")?.value
      return country === "" ? "Other" : country
    })
    ?.filter((country, index, array) => array.indexOf(country) === index)
    ?.sort((firstCountry, secondCountry) => firstCountry?.localeCompare(secondCountry as string) as number) ?? []  
  const otherIndex = countriesList.findIndex(country => country === "Other")
  otherIndex !== -1 && countriesList.splice(otherIndex, 1) && countriesList.push("Other")
  return countriesList
}
  

export const getMetaPropertySubtypesList = (propertyList: VisibleOfferings[], type: string): string[] => {
  const subtypesList = propertyList
    ?.filter(prop => prop?.meta?.find(meta => meta.key === "PropertyType")?.value === type)
    ?.map(prop => prop?.meta?.find(meta => meta.key === "PropertySubtype")?.value || "Other" )
    ?.filter((subtype, index, array) => array.indexOf(subtype) === index)
    ?.sort((firstType, secondType) => firstType?.localeCompare(secondType as string) as number) ?? []
  const otherIndex = subtypesList.findIndex(country => country === "Other")
  otherIndex !== -1 && subtypesList.splice(otherIndex, 1) && subtypesList.push("Other")
  return subtypesList
}

export const getPictureUrl = (url: string | undefined): string => {
  if (!url) return imgPlaceholder
  const pictureAddress = url?.split("/")
  return pictureAddress?.[pictureAddress?.length - 1] === "null" ? imgPlaceholder : url 
}

export const isPictureUrl = (url: string | undefined): boolean => {
  if (!url) return false
  const pictureAddress = url?.split("/")
  return !(pictureAddress?.[pictureAddress?.length - 1] === "null") 
}

export const numberUI = (number: number): string => {
  return number.toLocaleString("fr", {
    useGrouping: true,
  })
}
