import { Button, Drawer, Row, Space, Typography } from "antd"
import { FC, MouseEvent, useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { NavLink, useNavigate } from "react-router-dom"
import "./CookieBar.less"

const CookieBar: FC<{desktopOnMobile: boolean}> = ({desktopOnMobile}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [cookiesAccepted, setCookiesAccepted] = useState<boolean | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const cookies = localStorage.getItem("smartblock-cookies");
    setCookiesAccepted(cookies === "true" ? true : cookies === "false" ? false : null);
    setOpen(cookies === null);
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem("smartblock-cookies", "true");
    setCookiesAccepted(true);
    setOpen(false);
  };

  const handleDeclineCookies = () => {
    localStorage.setItem("smartblock-cookies", "false");
    setCookiesAccepted(false);
    setOpen(false);
  };

  const handleNavigateToPolicy = (event: MouseEvent) => {
    event.preventDefault();
    setOpen(false);
    setCookiesAccepted(false);
    navigate("/cookie-policy");
  };

  const script = `(function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-${import.meta.env.VITE_GOOGLE_MANAGER_ID}');`

  return (
    <>
      <Helmet>
        {cookiesAccepted && (
          <script>
            {`${script}`}
          </script>
        )}
      </Helmet>
      <Drawer
        mask={false}
        closeIcon={false}
        className={`cookie-bar ${desktopOnMobile && "desktop-on-mobile"}`}
        placement="bottom"
        open={open}
        height="unset"
      >
        <Row wrap={false} className={"container"} style={{width: "100%"}}>
          <Typography.Text className="content">
            By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts. For more detailed information about the cookies we use, see our{" "}
            <NavLink to="/cookie-policy" className={"link"} onClick={handleNavigateToPolicy}>Cookies Policy</NavLink>.
          </Typography.Text>
          <Space size={24}>
            <Button className="decline-btn" onClick={handleDeclineCookies}>
              Decline
            </Button>
            <Button className="submit-btn" onClick={handleAcceptCookies}>
              Accept All Cookies
            </Button>
          </Space>
        </Row>
      </Drawer>
    </>
  );
};

export { CookieBar };
