import { Col, Row } from "antd"
import { FC } from "react"
import { useLocation } from "react-router-dom"

import { Logo } from "../../logo"
import { HeaderMenu } from "../../menu"
import { UserMenu } from "../../userMenu"

import "./Header.less"

const Header: FC = () => {
  const { pathname } = useLocation()
  const token = localStorage.getItem("token")
  
  const isUnloggedInMenuPages = ["/", "/cookie-policy", "/privacy-policy", "/terms-of-service", "/about-us", "/faq", "/how-smartblocks-works", "/why-use-smartblocks"]
  
  const isUserLoggedIn = !!localStorage.getItem("token")
  const isUnloggedInMenu = !isUserLoggedIn && isUnloggedInMenuPages.includes(pathname)

  return (
    <Row align={"middle"} justify={"space-between"} className={`header-wrapper container ${!token ? "unlogined" : "logined"}`}>
      <Col>
        <Row align={"middle"} className={"navigation-wrapper"}>
          <Logo />
          {(isUserLoggedIn || isUnloggedInMenu) && <HeaderMenu isHomeMenuItems={isUnloggedInMenu} pathname={pathname} />}          
        </Row>        
      </Col>
      <Col>
        <UserMenu isUserLoggedIn={isUserLoggedIn}  />
      </Col>
    </Row>
  )
}

export { Header }
