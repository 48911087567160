import { PhoneNumberUtil } from "google-libphonenumber"

const phoneUtil = PhoneNumberUtil.getInstance()

export const isPhoneValid = (phone: string): boolean => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
  } catch (error) {
    return false
  }
}

export const checkPass = (value: string): boolean => {
  let passChecks = 0
  passChecks += /[a-z]/.test(value) ? 1 : 0
  passChecks += /[A-Z]/.test(value) ? 1 : 0
  passChecks += /\d/.test(value) ? 1 : 0
  passChecks += /[^\w\d\s]/.test(value) ? 1 : 0
  return passChecks > 2
}
